import type { AutocompleteTypes, TextFieldTypes } from '@ionic/core'
import { IonDatetime, IonInput, IonItem, IonLabel, IonText, IonTextarea } from '@ionic/react'
import clsx from 'clsx'
import { eyeOffOutline, eyeOutline } from 'ionicons/icons'
import { useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import type { RegisterOptions } from 'react-hook-form/dist/types/validator'
import { useTranslation } from 'react-i18next'

import { emailRule } from '../../../lib/form/rules'
import Button from '../../Button'
import CollapsibleContent from '../../Collapsible/CollapsibleContent'

import './style.scss'

interface FormTextFieldProps {
    name: string
    label: string
    prefix?: string
    autoComplete?: AutocompleteTypes
    maxLength?: number
    multiline?: boolean
    rules?: Omit<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs'>
    required?: boolean
    disabled?: boolean
    hidden?: boolean
    type?: TextFieldTypes
    defaultValue?: string | number | null
    className?: string
}

const FormTextField: React.FC<FormTextFieldProps> = ({
    name,
    label,
    prefix,
    autoComplete,
    maxLength,
    multiline = false,
    rules,
    required,
    disabled,
    hidden,
    type = 'text',
    defaultValue,
    className,
}) => {

    const { control, formState } = useFormContext()
    const error = formState.errors[name]
    const [focused, setFocused] = useState<boolean>(false)
    const [inputType, setInputType] = useState<TextFieldTypes>(type)
    const { t } = useTranslation()

    const mergedRules = rules ?? {}
    if (required) {
        mergedRules.required = { value: true, message: t('errors.required', { label }) }
    }

    if (type === 'email') {
        mergedRules.pattern = emailRule(t, label).pattern
    }

    if (maxLength) {
        mergedRules.maxLength = {
            value: maxLength,
            message: t('errors.maxLength', { number: maxLength }),
        }
    }

    return (
        <div>
            <div
                className={clsx('form-input', { 'form-input--focused': focused, 'form-input--hidden': hidden })}
            >
                <IonItem
                    lines='none'
                    className='form-input__item'
                >
                    <IonLabel
                        hidden={hidden}
                        position={type === 'date' ? 'stacked' : 'floating'}
                        className={clsx('form-input__label', { 'error': !!error, 'form-input__label--date': type === 'date' })}
                    >
                        {label + (required ? '*' : '')}
                    </IonLabel>
                    <Controller
                        control={control}
                        name={name}
                        rules={mergedRules}
                        defaultValue={defaultValue}
                        render={({ field }) => {
                            switch (type) {
                                case 'date': {
                                    return (
                                        <IonDatetime
                                            hidden={hidden}
                                            presentation='date'
                                            doneText={t('buttons.done')}
                                            cancelText={t('buttons.cancel')}
                                            value={field.value}
                                            onIonChange={e => {
                                                field.onChange(e)
                                                setFocused(false)
                                                field.onBlur()
                                            }}
                                            onClick={() => {
                                                setFocused(true)
                                            }}
                                            onIonCancel={() => {
                                                setFocused(false)
                                                field.onBlur()
                                            }}
                                            name={name}
                                            className={clsx(className, 'form-input__input', 'form-input__input--date')}
                                            disabled={disabled}
                                        />
                                    )
                                }
                                default: {
                                    return !multiline ? (
                                        <div
                                            className='form-input__wrapper'
                                        >
                                            <IonInput
                                                hidden={hidden}
                                                autocomplete={autoComplete}
                                                className={clsx(className, 'form-input__input', { 'form-input__input__password': type === 'password' })}
                                                name={name}
                                                type={inputType}
                                                value={field.value}
                                                onIonChange={field.onChange}
                                                onIonFocus={() => {
                                                    setFocused(true)
                                                }}
                                                onBlur={() => {
                                                    setFocused(false)
                                                    field.onBlur()
                                                }}
                                                disabled={disabled}
                                            >
                                                {prefix && (
                                                    <span
                                                        className={clsx('form-input__prefix',
                                                            { 'form-input__prefix--hidden': !field.value && !focused })}
                                                    >
                                                        {prefix}
                                                    </span>
                                                )}
                                            </IonInput>
                                            {type === 'password' && (
                                                <Button
                                                    tabIndex={-1}
                                                    fill='clear'
                                                    onClick={() => {
                                                        setInputType(prevType => prevType === 'password' ? 'text' : 'password')
                                                    }}
                                                    className='form-input__eye-button'
                                                    icon={inputType === 'password' ? eyeOutline : eyeOffOutline}
                                                />
                                            )}
                                        </div>
                                    ) : (
                                        <>
                                            <IonTextarea
                                                hidden={hidden}
                                                className='form-input__input'
                                                name={name}
                                                value={field.value}
                                                onIonChange={field.onChange}
                                                onIonFocus={() => {
                                                    setFocused(true)
                                                }}
                                                onBlur={() => {
                                                    setFocused(false)
                                                    field.onBlur()
                                                }}
                                                autoGrow
                                                disabled={disabled}
                                            />
                                            {maxLength && (
                                                <span
                                                    className='form-input__input__length'
                                                >
                                                    {field?.value?.length ?? 0}
                                                    {' / '}
                                                    {maxLength}
                                                </span>
                                            )}
                                        </>
                                    )
                                }
                            }

                        }}
                    />
                </IonItem>
            </div>
            <CollapsibleContent
                className='form-input__error-container'
                open={!!error}
            >
                <IonText
                    className='form-input__error-text font-s-regular'
                    color='danger'
                >
                    {error?.message}
                </IonText>
            </CollapsibleContent>
        </div>
    )
}

export default FormTextField
