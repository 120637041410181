import {
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardTitle,
    IonCol,
    IonGrid, IonIcon,
    IonRow,
    IonText,
} from '@ionic/react'
import clsx from 'clsx'
import { arrowForward, mailUnreadOutline } from 'ionicons/icons'
import { useCallback, useState } from 'react'
import type { ErrorOption } from 'react-hook-form/dist/types/errors'
import { useTranslation } from 'react-i18next'
import type { Swiper as SwiperClass } from 'swiper'
import { Controller } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js'

import Button from '../../components/Button'
import Content from '../../components/Content'
import Form from '../../components/Form'
import FormTextField from '../../components/Form/FormTextField'
import Header from '../../components/Header'
import ItemList from '../../components/ItemList'
import Page from '../../components/Page'
import { useDoimailMutation, useRegisterMutation } from '../../lib/apollo/types'

import './style.scss'

const slideOpts = {
    spaceBetween: 16,
    allowTouchMove: false,
}

const Register: React.FC = () => {
    const { t } = useTranslation()
    const [registrationComplete, setRegistrationComplete] = useState<boolean>(false)
    const [controlledSwiper, setControlledSwiper] = useState<SwiperClass | null>(null)
    const [errors, setErrors] = useState<{ name: string, error: ErrorOption }[] | undefined>()

    const [register] = useRegisterMutation({
        onError: () => setErrors([{
            name: 'name',
            error: { type: 'validate', message: t('registration.usernameTaken') },
        }]),
    })

    const [doimail] = useDoimailMutation()
    const sendRegistration = useCallback(async (formData) => {
        const response = await register({ variables: { input: formData } })

        if (response.data) {
            controlledSwiper?.slideNext()
            const variables = {
                input: {
                    token: response.data.register.token,
                    email: response.data.register.user.email,
                    subject: t('registration.subject'),
                    name: `${response.data.register.user.firstName} ${response.data.register.user.lastName}`,
                },
            }

            const mailResponse = await doimail({
                variables,
            })

            if (mailResponse.data) {
                setRegistrationComplete(true)
            }
        }
    }, [register, doimail, controlledSwiper])

    return (
        <Page
            trackingTitle='register'
            className='register'
        >
            <Header
                title={t('title.register')}
            />
            <Content
                fullscreen
            >
                <IonGrid>
                    <IonRow>
                        <IonCol>
                            <IonCard>
                                <Swiper
                                    {...slideOpts}
                                    modules={[Controller]}
                                    onSwiper={setControlledSwiper}
                                >
                                    <SwiperSlide>
                                        <IonCardContent>
                                            <Form
                                                accessoryBarVisible
                                                onSubmit={(formData) => sendRegistration(formData)}
                                                errors={errors}
                                                autoComplete='on'
                                            >
                                                <ItemList>
                                                    <FormTextField
                                                        required
                                                        name='name'
                                                        autoComplete='new-password'
                                                        label={t('registration.username')}
                                                    />
                                                    <FormTextField
                                                        required
                                                        name='password'
                                                        type='password'
                                                        autoComplete='new-password'
                                                        label={t('registration.password')}
                                                    />
                                                    <FormTextField
                                                        required
                                                        name='email'
                                                        type='email'
                                                        autoComplete='new-password'
                                                        label={t('registration.email')}
                                                    />
                                                    <FormTextField
                                                        required
                                                        name='firstName'
                                                        autoComplete='new-password'
                                                        label={t('registration.firstName')}
                                                    />
                                                    <FormTextField
                                                        required
                                                        name='lastName'
                                                        autoComplete='new-password'
                                                        label={t('registration.lastName')}
                                                    />
                                                </ItemList>
                                                <div
                                                    className='register__card-button-bar'
                                                >
                                                    <Button
                                                        icon={arrowForward}
                                                        type='submit'
                                                        color='secondary'
                                                    >
                                                        {t('registration.finish')}
                                                    </Button>
                                                </div>
                                            </Form>
                                        </IonCardContent>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <IonCardHeader>
                                            <IonCardTitle>
                                                <h4>{t('registration.finished.title')}</h4>
                                            </IonCardTitle>
                                        </IonCardHeader>
                                        <IonCardContent>
                                            <div
                                                className='register__summary'
                                            >
                                                <div>
                                                    <IonText
                                                        color='primary'
                                                    >
                                                        {t('registration.finished.text')}
                                                    </IonText>
                                                </div>
                                                <div>
                                                    <IonIcon
                                                        color='secondary'
                                                        className={clsx('register__summary__mail-icon',
                                                            { 'register__summary__mail-icon--visible': registrationComplete })}
                                                        icon={mailUnreadOutline}
                                                    />
                                                </div>
                                            </div>
                                        </IonCardContent>
                                    </SwiperSlide>
                                </Swiper>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </Content>
        </Page>
    )
}

export default Register
