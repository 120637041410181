/* eslint no-param-reassign: 0 */

import { WATCHLIST_CAPTION } from '../../components/Playlist/PlayListConstants'

import activityDataTransform from './helper/activityDataTransform'
import talentTransform from './helper/talentTransform'
import type { Playlist } from './types'
import { PermissionLevel } from './types'

const responseTransformer = async (response: any, typeName: string): Promise<any> => {
    if (!response?.json) {
        return null
    }

    // FIXME: remove if middleware is new
    const jsonString = JSON.stringify(await response.json())
    const json = JSON.parse(jsonString.replaceAll('"image":"//', '"image":"https://'))
    switch (typeName) {
        case 'ActivitiesResponse': {
            return {
                ...json,
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                collection: json.collection.map(activity => ({
                    ...activity,
                    data: activityDataTransform(activity),
                })),
            }
        }
        case 'Activity': {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            return json.map(activity => ({
                ...activity,
                data: activityDataTransform(activity),
            }))
        }
        case 'Network': {
            return {
                ...json,
                networkRoles: json.networkRoles ?? ['none'],
                asset: json._links?.image?.url?.split('/')[json._links?.image?.url?.split('/').length - 1],
            }
        }
        case 'NetworkMyTalentResponse': {
            return {
                ...json,
                collection: json.collection.map((talent: any) => talentTransform(({
                    ...talent,
                    permissionLevel: talent.personification ? PermissionLevel.Personal : PermissionLevel.Administrative,
                }))),
            }
        }
        case 'Authentication': {
            return {
                ...json,
                user: {
                    ...json.user,
                    asset: json.user._links.image?.url?.split('/')[json.user._links.image?.url?.split('/').length - 1],
                },
            }
        }
        case 'User': {
            return {
                ...json,
                asset: json._links.image?.url?.split('/')[json._links.image?.url?.split('/').length - 1],
            }
        }
        case 'NetworkTalentResponse':
        case 'PlaylistTalentResponse':
        case 'NetworkTalents': {
            return {
                ...json,
                collection: json.collection.map(talentTransform),
            }
        }
        case 'Talent': return talentTransform(json)
        case 'SharedTalentResponse': {
            return {
                ...json,
                talent: talentTransform(json.talent),
                roleModel: talentTransform(json.roleModel),
            }
        }
        case 'SearchNetworkCollection':
        case 'SearchUser': {
            json.meta.sortOptions = Object.entries<string>(json.meta.sortOptions).map(([value, key]: [string, string]) => ({
                key,
                value,
            }))
            return json
        }
        case 'SearchTalent': {
            json.meta.sortOptions = Object.entries<string>(json.meta.sortOptions).map(([value, key]: [string, string]) => ({ key, value }))
            json.collection = json.collection.map(talentTransform)

            return json
        }
        case 'UserStatusResponse': {
            const resp: { [key: string]: any } = {}
            json.forEach((entry: { [key: string]: any }) => {
                Object.entries(entry).forEach(([key, value]) => {
                    if(key === 'JMTnetworks') {
                        resp.jmtNetworks = Object.keys(value)
                    } else {
                        resp[key] = value
                    }
                })
            })
            return resp
        }
        case 'TalentCustomMeta': {
            if((json.collection ?? []).length  === 0) {
                if(json.data) {
                    return {
                        id: json.id,
                        height: json.data.height?.value.en,
                        weight: json.data.weight?.value.en,
                        foot: json.data.foot?.value.en,
                        club: json.data.club?.value.en,
                        team: json.data.team?.value.en,
                        rolemodelid: json.data.rolemodelid?.value.en,
                        status: json.data.status?.value.en,
                        salutation: json.data.salutation?.value.en,
                        topskillsindexes: json.data.topskillsindexes?.value.en.split(',').filter(Boolean),
                    }
                }

                return {}
            }

            return {
                id: json.collection[0].id,
                height: json.collection[0].data.height?.value.en,
                weight: json.collection[0].data.weight?.value.en,
                foot: json.collection[0].data.foot?.value.en,
                club: json.collection[0].data.club?.value.en,
                team: json.collection[0].data.team?.value.en,
                rolemodelid: json.collection[0].data.rolemodelid?.value.en,
                status: json.collection[0].data.status?.value.en,
                salutation: json.collection[0].data.salutation?.value.en,
                topskillsindexes: json.collection[0].data.topskillsindexes?.value.en.split(',').filter(Boolean),
            }
        }
        case 'Layer': {
            if (json.layers) {
                return Object.entries(json.layers).map(([key, val]: [string, any]) => ({
                    name: key,
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    dataViews: Object.entries(val[0].dataViews).map(([k, { values }]) => ({ key: +k, values })),
                }))
            }
            return {}
        }
        case 'NetworkTokenInvitation': {
            return {
                ...json,
                tokens: Object.entries(json.tokens).map(([email, token]) => ({ email, token })),
            }
        }
        case 'Playlists': {
            // ENSURE WATCHLIST ON FIRST POSITION
            json.collection.sort((a: Playlist, b: Playlist) => {
                if (a.caption === WATCHLIST_CAPTION) return -1
                if (b.caption === WATCHLIST_CAPTION) return 1
                return 0
            })
            return json
        }
        case 'MySpaceTalentsResponse': {
            return {
                ...json,
                collection: json.collection.map(({ network, talent }: { network: any, talent: any }) => ({
                    talent: {
                        ...talentTransform(talent),
                    },
                    network: {
                        ...network,
                    },
                })),
            }
        }
        default: {
            return json
        }
    }
}

export default responseTransformer
