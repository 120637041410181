import type { RegisterOptions } from 'react-hook-form/dist/types/validator'
import type { TFunction } from 'react-i18next'

export const emailRule = (t: TFunction, label: string): Omit<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs'> => ({
    pattern: {
        value: /\S+@\S+\.\S+/,
        message: t('errors.email', { label }),
    },
})

export const passwordRule = (t: TFunction): Omit<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs'> => ({
    minLength: {
        value: 8,
        message: t('errors.minLength', { number: 8 }),
    },
    pattern: {
        value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).+$/,
        message: t('errors.passwordStrength'),
    },
    validate: value => {
        return !!value
    },
})
