import type { Maybe } from './apollo/types'

const getAge = (dateString?: Maybe<string>): number | undefined => {
    if(!dateString) {
        return undefined
    }

    const today = new Date()
    const birthDate = new Date(dateString)
    const age = today.getFullYear() - birthDate.getFullYear()
    const m = today.getMonth() - birthDate.getMonth()
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        return age - 1
    }
    return age
}

export default getAge
