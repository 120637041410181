import { Capacitor } from '@capacitor/core'
import { IonCard, IonGrid, IonRow, IonText } from '@ionic/react'
import OneSignal from 'onesignal-cordova-plugin'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { ENV_NAME } from '../../lib/apollo/config'
import {
    NetworkState,
    useIsTalentInNetworkQuery,
    useJmtNetworksQuery,
    useListMyPersonificationsQuery, useNetworkQuery, useNetworksQuery, useNetworkTalentsQuery, useNetworkUsersQuery,
    UserType,
    useUserMetaDataQuery,
} from '../../lib/apollo/types'
import { useUser } from '../../providers/Auth/hooks'
import Button from '../Button'
import Buttons from '../Buttons'
import Divider from '../Divider'
import { PREVIEW_THRESHOLD } from '../NewTalentCard/_Core/useIsPreviewMode'

import './style.scss'

type NextStep = {
    description: string,
    buttonText: string,
    link: string,
}

const NextSteps: React.FC = () => {
    const { t } = useTranslation()
    const user = useUser()
    const { data: metaData } = useUserMetaDataQuery({
        variables: {
            userId: user.user.id,
        },
    })

    const { data: personification } = useListMyPersonificationsQuery({
        variables: {
            userId: user.user?.id,
        },
    })

    const { data: networks } = useNetworksQuery({
        variables: {
            userId: user.user?.id,
            search: '&start=1&limit=15',
        },
    })

    const { data: firstNetworkTalents } = useNetworkTalentsQuery({
        variables: {
            userId: user.user?.id,
            networkId: networks?.networks.collection[0]?.id ?? '0',
        },
        skip: !networks?.networks.collection || !user.user?.id,
    })

    const { data: firstNetworkUsers } = useNetworkUsersQuery({
        variables: {
            userId: user.user?.id,
            networkId: networks?.networks.collection[0]?.id ?? '0',
        },
        skip: !networks?.networks.collection || !user.user?.id,
    })

    const [notInNetwork, setNotInNetwork] = useState<boolean | undefined>(undefined)
    const [talentIsNotInMarketplace, setTalentIsNotInMarketplace] = useState<boolean | undefined>(undefined)

    const jmtNetworks = useJmtNetworksQuery()
    const marketplaceNetwork = useMemo(() =>
        jmtNetworks?.data?.jmtNetworks.JMTnetworks.collection.find((network) => network.caption === 'Join.My.Talent.Engine.Marketplace')
    , [jmtNetworks])

    useNetworkQuery({
        skip: !marketplaceNetwork?.id,
        variables: {
            userId: user.user.id,
            networkId: marketplaceNetwork?.id ?? '',
        },
        onError: () => {
            setNotInNetwork(() => true)
        },
    })

    useIsTalentInNetworkQuery({
        skip: !marketplaceNetwork?.id || !personification?.listMyPersonifications?.collection?.[0]?.id,
        variables: {
            userId: user.user.id,
            talentId: personification?.listMyPersonifications?.collection?.[0]?.id ?? '',
            networkId: marketplaceNetwork?.id ?? '',
        },
        onCompleted: ({ isTalentInNetwork }) => {
            if (isTalentInNetwork.state === NetworkState.InNetwork) {
                setTalentIsNotInMarketplace(() => false)
            } else {
                setTalentIsNotInMarketplace(() => true)
            }
        },
        onError: () => { setTalentIsNotInMarketplace(() => true) },
    })

    const next = useMemo<NextStep | null>(() => {
        if(Capacitor.isNativePlatform()) {
            OneSignal.addTrigger('prompt_notification', '0')
        }

        if (!metaData?.userMetaData?.user?.data) {
            return null
        }

        // TYPE Player
        if (metaData.userMetaData.user.data.userType?.some(type => type === UserType.Player)) {
            const talent = personification?.listMyPersonifications?.collection?.[0]
            // Has Talentcard?
            if (!talent) {
                return {
                    description: t('dashboard.nextSteps.createTalent.description'),
                    buttonText: t('dashboard.nextSteps.createTalent.button'),
                    link: '/profile/tab/personification',
                }
            }

            // Has Votes?
            if (+(talent.rateCount ?? '0') < PREVIEW_THRESHOLD) {
                return {
                    description: t('dashboard.nextSteps.getYourSkillsConfirmed.description'),
                    buttonText: t('dashboard.nextSteps.getYourSkillsConfirmed.button'),
                    link: `/profile/network/undefined/talents/${talent.id}/details`,
                }
            }

            // Has selected Topskills?
            if (talent.customMeta?.topskillsindexes?.length === 0) {
                return {
                    description: t('dashboard.nextSteps.selectTopSkills.description'),
                    buttonText: t('dashboard.nextSteps.selectTopSkills.button'),
                    link: `/profile/network/undefined/talents/${talent.id}/details`,
                }
            }

            // Has Role Model
            if (!talent.customMeta?.rolemodelid) {
                return {
                    description: t('dashboard.nextSteps.selectRoleModel.description'),
                    buttonText: t('dashboard.nextSteps.selectRoleModel.button'),
                    link: `/profile/network/undefined/talents/${talent.id}/details`,
                }
            }

            // Has given a status
            // if (!talent.customMeta?.status) {
            //     return {
            //         description: t('dashboard.nextSteps.addStatus.description'),
            //         buttonText: t('dashboard.nextSteps.addStatus.button'),
            //         link: `/profile/network/undefined/talents/${talent.id}/details`,
            //     }
            // }

            if (notInNetwork) {
                return {
                    description: t('dashboard.nextSteps.joinMarketplace.description'),
                    buttonText: t('dashboard.nextSteps.joinMarketplace.button'),
                    link: '/marketplace',
                }
            }

            if(talentIsNotInMarketplace) {
                return {
                    description: t('dashboard.nextSteps.joinMarketplaceWithTalent.description'),
                    buttonText: t('dashboard.nextSteps.joinMarketplaceWithTalent.button'),
                    link: '/marketplace',
                }
            }
            if(ENV_NAME === 'sandbox') {
                return {
                    description: t('sandbox.nextSteps.player.description'),
                    buttonText: t('sandbox.nextSteps.player.button'),
                    link: '/profile/network/undefined/talents/1104/details',
                }
            }
        }
        // TYPE Player END
        if (metaData.userMetaData.user.data.userType?.some(type => type === UserType.Trainer)) {
            if(networks?.networks.collection.length === 0) {
                return {
                    description: t('dashboard.nextSteps.createTeam.description'),
                    buttonText: t('dashboard.nextSteps.createTeam.button'),
                    link: '/home/networks',
                }
            }
            if(ENV_NAME === 'sandbox') {
                return {
                    description: t('sandbox.nextSteps.trainer.description'),
                    buttonText: t('sandbox.nextSteps.trainer.button'),
                    link: '/home/network/5/detail',
                }
            }
        }
        // Has Teams?
        if(networks?.networks.collection.length === 0) {
            return {
                description: t('dashboard.nextSteps.searchTeam.description'),
                buttonText: t('dashboard.nextSteps.searchTeam.button'),
                link: '/home/search',
            }
        }
        if(Capacitor.isNativePlatform()) {
            OneSignal.getDeviceState((state) => {
                if (!state.subscribed || state.pushDisabled) {
                    OneSignal.addTrigger('prompt_notification', '1')
                }
            })
        }

        // Has Team without Talents?
        if(firstNetworkTalents?.networkTalents?.collection.length === 0) {
            return {
                description: t('dashboard.nextSteps.inviteTalents.description'),
                buttonText: t('dashboard.nextSteps.inviteTalents.button'),
                link: `/home/network/${networks?.networks.collection[0].id}/detail`,
            }
        }

        // Has Team without other Users?
        if(firstNetworkUsers?.networkUsers.collection.length && firstNetworkUsers.networkUsers.collection.length <= 1) {
            return {
                description: t('dashboard.nextSteps.inviteUsers.description'),
                buttonText: t('dashboard.nextSteps.inviteUsers.button'),
                link: `/home/network/${networks?.networks.collection[0].id}/detail`,
            }
        }

        return null

    }, [metaData, personification, networks, firstNetworkTalents])

    if (!metaData?.userMetaData?.user?.data || !next || !personification?.listMyPersonifications) {
        return null
    }

    return (
        <>
            <IonCard
                className='ion-padding next-steps-card'
            >
                <IonGrid>
                    <IonRow>
                        <IonText
                            color='light'
                        >
                            <h2
                                className='ion-margin-bottom'
                            >
                                {t('dashboard.nextSteps.title')}
                            </h2>
                        </IonText>
                    </IonRow>
                    <IonRow>
                        <IonText
                            className='ion-margin-bottom'
                            color='light'
                        >
                            <span>
                                {next.description}
                            </span>
                        </IonText>
                    </IonRow>
                    <IonRow
                        className='ion-margin-top'
                    >
                        <Buttons>
                            <Button
                                routerLink={next.link}
                                color='secondary'
                            >
                                {next.buttonText}
                            </Button>
                        </Buttons>
                    </IonRow>
                </IonGrid>
            </IonCard>
            <Divider />
        </>
    )
}

export default NextSteps
