import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import HttpApi from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'

const API_TOKEN: string | undefined = process.env.REACT_APP_TRANSLATION_API_TOKEN
const BE_URL = 'https://translations.krischey-schulz.de'

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .use(HttpApi)
    .use(LanguageDetector)
    .init({
        fallbackLng: 'en',
        backend: {
            parse(data: string): { [p: string]: any } {
                return JSON.parse(data).translations ?? {}
            },
            parsePayload(namespace, key) {
                return { key }
            },
            crossDomain: true,
            loadPath: `${BE_URL}/translations/{{lng}}`,
            addPath: `${BE_URL}/translations/add/de`,
            customHeaders: API_TOKEN ? () => {
                return { 'authorization': API_TOKEN }
            } : undefined,
        },
        saveMissing: !!API_TOKEN,
        interpolation: {
            escapeValue: false, // react already safes from xss
        },
        react: {
            useSuspense: false,
        },
        returnNull: false,
        returnEmptyString: false,
    })

export default i18n
