import { peopleOutline } from 'ionicons/icons'
import { useTranslation } from 'react-i18next'

import type { User } from '../../../../lib/apollo/types'
import { RelationshipsDocument, useRelationshipsQuery, useUserFollowMutation } from '../../../../lib/apollo/types'
import { useUser } from '../../../../providers/Auth/hooks'
import useToast from '../../../../providers/Toast/hooks'
import ContextMenuItem from '../../../ContextMenu/ContextMenuItem/ContentMenuItem'

interface UserFollowContextMenuItemProps {
    user: User
}

const UserFollowContextMenuItem: React.FC<UserFollowContextMenuItemProps> = ({ user }) => {
    const { t } = useTranslation()
    const [present] = useToast()
    const me = useUser()

    const { data } = useRelationshipsQuery({ fetchPolicy: 'cache-only', variables: { userId: me.user.id } })
    const followed = data?.followed?.collection?.some(f => f.id === user.id)

    const [toggleFollow] = useUserFollowMutation({
        variables: { method: followed ? 'UNLINK' : 'LINK', userId: me.user.id, url: `/api/user/${user.id}` },
        onCompleted: () => {
            present(t(followed ? 'user.unfollowToast' : 'user.followToast', { name: `${user.firstName} ${user.lastName}` }), 'success')
        },
        refetchQueries: [{
            query: RelationshipsDocument,
            variables: {
                userId: me.user.id,
            },
        }],
    })

    return (
        <ContextMenuItem
            disabled={!data?.followed}
            onClick={() => { toggleFollow() }}
            icon={peopleOutline}
            label={t(followed ? 'user.unfollow' : 'user.follow')}
        />
    )
}

export default UserFollowContextMenuItem
