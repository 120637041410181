import { Browser } from '@capacitor/browser'
import { IonText, IonFab } from '@ionic/react'
import { arrowBack, arrowForward, checkmark } from 'ionicons/icons'
import { useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Controller } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js'
import type { Swiper as SwiperClass } from 'swiper/types'

import Button from '../../components/Button'
import Content from '../../components/Content'
import Form from '../../components/Form'
import FormSelectButtons from '../../components/Form/FormSelectButtons'
import FormTextField from '../../components/Form/FormTextField'
import FormToggle from '../../components/Form/FormToggle'
import Header from '../../components/Header'
import ItemList from '../../components/ItemList'
import Page from '../../components/Page'
import SalutationSelect from '../../components/SalutationSelect'
import {
    useCreateUserMetaDataMutation, UserMetaDataDocument,
    useUpdateUserMetaDataMutation, useUserLinkImageMutation,
    useUserMetaDataQuery, useUserStatusQuery,
} from '../../lib/apollo/types'
import type { UserMetaDataInput } from '../../lib/apollo/types'
import { useUser } from '../../providers/Auth/hooks'
import useToast from '../../providers/Toast/hooks'

import './style.scss'

const slideOpts = {
    spaceBetween: 16,
    allowTouchMove: false,
    pagination: {
        el: '.swiper-pagination',
        type: 'progressbar',
    },
}

const Onboarding: React.FC = () => {
    const { t } = useTranslation()
    const [formData, setFormData] = useState({})
    const [controlledSwiper, setControlledSwiper] = useState<SwiperClass | null>(null)
    const [show] = useToast()
    const user = useUser()

    // join system networks if needed by asking for status:
    useUserStatusQuery({
        variables: {
            userId: user.user.id,
        },
    })

    const [linkProfileMutation] = useUserLinkImageMutation()

    const [createUserMetaData] = useCreateUserMetaDataMutation({
        onError: () => {
            show(t('onboarding.savingError'), 'danger')
        },
        refetchQueries: [{
            query: UserMetaDataDocument,
            variables: {
                userId: user.user.id,
            },
        }],
    })
    const [updateUserMetaData] = useUpdateUserMetaDataMutation({
        onError: () => {
            show(t('onboarding.savingError'), 'danger')
        },
        refetchQueries: [{
            query: UserMetaDataDocument,
            variables: {
                userId: user.user.id,
            },
        }],
    })

    const { data } = useUserMetaDataQuery({
        variables: {
            userId: user.user.id,
        },
    })

    const [onboardingComplete, setOnboardingComplete] = useState(false)

    useEffect(() => {
        if (!onboardingComplete) {
            return
        }

        const patchData = async () => {
            const { image, ...metaData } = formData as UserMetaDataInput & { image: string }

            const variables = {
                input: {
                    ...metaData,
                    username: user.user.name,
                },
                userId: user.user.id,
            }

            if (!data?.userMetaData?.user?.data) {
                await createUserMetaData({ variables })
            } else {
                await updateUserMetaData({ variables })
            }
            // keep this order to prevent loss of image link
            if(image) {
                await linkProfileMutation({
                    variables: {
                        url: `/api/asset/${image}`,
                        userId: user.user.id,
                    },
                })
            }
        }

        patchData()
    }, [onboardingComplete, data])

    if (!data) {
        return null
    }

    return (
        <Page
            trackingTitle='onboarding'
            className='onboarding'
        >
            <Header
                buttonLeft='menu'
                title={t('onboarding.title')}
            />
            <Content>
                <Swiper
                    {...slideOpts}
                    modules={[Controller]}
                    pagination
                    onSwiper={setControlledSwiper}
                    className='onboarding__slides'
                >
                    <SwiperSlide>
                        <Form
                            accessoryBarVisible
                            onSubmit={() => {
                                controlledSwiper?.slideNext()
                                setFormData(state => ({
                                    ...state,
                                    behavior: {
                                        termsAcceptedAt: new Date().toISOString(),
                                        termsVersionAccepted: 1,
                                    },
                                }))
                            }}
                        >
                            <div
                                className='ion-margin-bottom'
                            >
                                {t('onboarding.welcome')}
                            </div>
                            <Trans
                                i18nKey='onboarding.termsAndConditionsText'
                            >
                                Zur Verwendung von Join My Talent musst du die
                                <IonText
                                    color='secondary'
                                    onClick={() => Browser.open({
                                        url: 'https://joinmytalent.com/nutzungbedingungen/',
                                    })}
                                >
                                    Nutzungsbedingungen
                                </IonText>
                                akzeptieren. Bitte lies diese sorgfältig durch.
                            </Trans>
                            <FormToggle
                                required
                                name='termsAccepted'
                                label={t('onboarding.acceptTermsAndConditions')}
                            />
                            <IonFab
                                vertical='bottom'
                                horizontal='end'
                            >
                                <Button
                                    round
                                    icon={arrowForward}
                                    type='submit'
                                    color='secondary'
                                />
                            </IonFab>
                        </Form>
                    </SwiperSlide>
                    {/* <SwiperSlide> */}
                    {/*    <Form */}
                    {/*        accessoryBarVisible */}
                    {/*        onSubmit={(v) => { */}
                    {/*            controlledSwiper?.slideNext() */}
                    {/*            setFormData((state: any) => ({ */}
                    {/*                ...state, */}
                    {/*                ...v, */}
                    {/*            })) */}
                    {/*        }} */}
                    {/*    > */}
                    {/*        <FormImageInput */}
                    {/*            size={{ width: 300, height: 400 }} */}
                    {/*            name='image' */}
                    {/*            label={t('user.profileImage')} */}
                    {/*        /> */}
                    {/*        <FormTextField */}
                    {/*            name='phone' */}
                    {/*            type='tel' */}
                    {/*            label={t('user.phone')} */}
                    {/*            defaultValue={data.userMetaData?.user?.data?.phone} */}
                    {/*        /> */}
                    {/*        <IonFab */}
                    {/*            vertical='bottom' */}
                    {/*            horizontal='end' */}
                    {/*        > */}
                    {/*            <Button */}
                    {/*                round */}
                    {/*                icon={arrowBack} */}
                    {/*                color='primary' */}
                    {/*                fill='outline' */}
                    {/*                onClick={() => controlledSwiper?.slidePrev()} */}
                    {/*            /> */}
                    {/*            <Button */}
                    {/*                round */}
                    {/*                icon={arrowForward} */}
                    {/*                type='submit' */}
                    {/*                color='secondary' */}
                    {/*            /> */}
                    {/*        </IonFab> */}
                    {/*    </Form> */}
                    {/* </SwiperSlide> */}
                    <SwiperSlide>
                        <Form
                            accessoryBarVisible
                            onSubmit={(v) => {
                                controlledSwiper?.slideNext()
                                setFormData((state: any) => ({
                                    ...state,
                                    ...v,
                                }))
                            }}
                        >
                            <div
                                className='ion-margin-bottom'
                            >
                                {t('onboarding.weNeedSomeData')}
                            </div>
                            <ItemList>
                                <SalutationSelect />

                                <FormTextField
                                    required
                                    name='birthdate'
                                    type='date'
                                    label={t('user.birthdate')}
                                    defaultValue={data.userMetaData?.user?.data?.birthdate}
                                />
                            </ItemList>
                            <IonFab
                                vertical='bottom'
                                horizontal='end'
                            >
                                <Button
                                    round
                                    icon={arrowBack}
                                    color='primary'
                                    fill='outline'
                                    onClick={() => controlledSwiper?.slidePrev()}
                                />
                                <Button
                                    round
                                    icon={arrowForward}
                                    type='submit'
                                    color='secondary'
                                />
                            </IonFab>
                        </Form>
                    </SwiperSlide>
                    <SwiperSlide>
                        <Form
                            accessoryBarVisible
                            onSubmit={(v) => {
                                controlledSwiper?.slideNext()
                                setFormData((state: any) => ({
                                    ...state,
                                    ...v,
                                }))
                            }}
                        >
                            <FormTextField
                                required
                                name='country'
                                label={t('user.country')}
                                defaultValue={data.userMetaData?.user?.data?.country}
                            />
                            <FormTextField
                                required
                                name='city'
                                label={t('user.city')}
                                defaultValue={data.userMetaData?.user?.data?.city}
                            />
                            <FormTextField
                                required
                                name='zip'
                                label={t('user.zip')}
                                defaultValue={data.userMetaData?.user?.data?.zip}
                            />
                            <FormTextField
                                name='street'
                                label={t('user.street')}
                                defaultValue={data.userMetaData?.user?.data?.street}
                            />
                            <IonFab
                                vertical='bottom'
                                horizontal='end'
                            >
                                <Button
                                    round
                                    icon={arrowBack}
                                    color='primary'
                                    fill='outline'
                                    onClick={() => controlledSwiper?.slidePrev()}
                                />
                                <Button
                                    round
                                    icon={arrowForward}
                                    type='submit'
                                    color='secondary'
                                />
                            </IonFab>
                        </Form>
                    </SwiperSlide>
                    <SwiperSlide>
                        <Form
                            accessoryBarVisible
                            onSubmit={(v) => {
                                // controlledSwiper?.slideNext()
                                setFormData((state: any) => ({
                                    ...state,
                                    ...v,
                                }))
                                setOnboardingComplete(true)
                            }}
                        >
                            <h2>{t('onboarding.role.headline')}</h2>
                            <div>
                                <IonText
                                    className='ion-margin-bottom'
                                    color='medium'
                                >
                                    {t('onboarding.role.description')}
                                </IonText>
                            </div>
                            <FormSelectButtons
                                name='userType'
                                label={t('user.userType.label')}
                                multiple
                                defaultValue={data.userMetaData?.user?.data?.userType ? data.userMetaData.user?.data.userType as string[] : ['other']}
                                options={[
                                    {
                                        text: t('user.userType.player'),
                                        value: 'player',
                                    },
                                    {
                                        text: t('user.userType.trainer'),
                                        value: 'trainer',
                                    },
                                    {
                                        text: t('user.userType.supervisor'),
                                        value: 'supervisor',
                                    },
                                    {
                                        text: t('user.userType.scout'),
                                        value: 'scout',
                                    },
                                    {
                                        text: t('user.userType.relatives'),
                                        value: 'relatives',
                                    },
                                    {
                                        text: t('user.userType.other'),
                                        value: 'other',
                                    },
                                ]}
                            />
                            <IonFab
                                vertical='bottom'
                                horizontal='end'
                            >
                                <Button
                                    round
                                    icon={arrowBack}
                                    color='primary'
                                    fill='outline'
                                    onClick={() => controlledSwiper?.slidePrev()}
                                />
                                <Button
                                    round
                                    icon={checkmark}
                                    type='submit'
                                    color='secondary'
                                />
                            </IonFab>
                        </Form>
                    </SwiperSlide>
                    {/* <SwiperSlide> */}
                    {/*    <Form */}
                    {/*        accessoryBarVisible */}
                    {/*        onSubmit={(v) => { */}
                    {/*            controlledSwiper?.slideNext() */}
                    {/*            setFormData((state: any) => ({ */}
                    {/*                ...state, */}
                    {/*                ...v, */}
                    {/*            })) */}
                    {/*        }} */}
                    {/*    > */}
                    {/*        <FormTextField */}
                    {/*            name='club' */}
                    {/*            label={t('user.club')} */}
                    {/*            defaultValue={data.userMetaData?.user?.data?.club} */}
                    {/*        /> */}
                    {/*        <FormTextField */}
                    {/*            name='team' */}
                    {/*            label={t('user.team')} */}
                    {/*            defaultValue={data.userMetaData?.user?.data?.team} */}
                    {/*        /> */}
                    {/*        <FormTextField */}
                    {/*            name='year' */}
                    {/*            label={t('user.year')} */}
                    {/*            type='number' */}
                    {/*            defaultValue={data.userMetaData?.user?.data?.year} */}
                    {/*        /> */}
                    {/*        <IonFab */}
                    {/*            vertical='bottom' */}
                    {/*            horizontal='end' */}
                    {/*        > */}
                    {/*            <Button */}
                    {/*                round */}
                    {/*                icon={arrowBack} */}
                    {/*                color='primary' */}
                    {/*                fill='outline' */}
                    {/*                onClick={() => controlledSwiper?.slidePrev()} */}
                    {/*            /> */}
                    {/*            <Button */}
                    {/*                round */}
                    {/*                icon={arrowForward} */}
                    {/*                type='submit' */}
                    {/*                color='secondary' */}
                    {/*            /> */}
                    {/*        </IonFab> */}
                    {/*    </Form> */}
                    {/* </SwiperSlide> */}
                    {/* <SwiperSlide> */}
                    {/*    <Form */}
                    {/*        accessoryBarVisible */}
                    {/*        onSubmit={(v) => { */}
                    {/*            setFormData((state: any) => ({ */}
                    {/*                ...state, */}
                    {/*                ...v, */}
                    {/*            })) */}
                    {/*            setOnboardingComplete(true) */}
                    {/*        }} */}
                    {/*    > */}
                    {/*        <FormSelectButtons */}
                    {/*            name='experience' */}
                    {/*            label={t('user.experience.label')} */}
                    {/*            defaultValue={data.userMetaData?.user?.data?.experience ? [data.userMetaData.user.data.experience.toString()] : undefined} */}
                    {/*            options={[ */}
                    {/*                { */}
                    {/*                    text: t('user.experience.professional'), */}
                    {/*                    value: 'professional', */}
                    {/*                }, */}
                    {/*                { */}
                    {/*                    text: t('user.experience.semi-professional'), */}
                    {/*                    value: 'semi-professional', */}
                    {/*                }, */}
                    {/*                { */}
                    {/*                    text: t('user.experience.amateur'), */}
                    {/*                    value: 'amateur', */}
                    {/*                }, */}
                    {/*            ]} */}
                    {/*        /> */}
                    {/*        <FormSelectButtons */}
                    {/*            name='ageCategory' */}
                    {/*            label={t('user.ageCategory.label')} */}
                    {/*            defaultValue={data.userMetaData?.user?.data?.ageCategory ? [data.userMetaData.user.data.ageCategory.toString()] : undefined} */}
                    {/*            options={[ */}
                    {/*                { */}
                    {/*                    text: t('user.ageCategory.kids'), */}
                    {/*                    value: 'kids', */}
                    {/*                }, */}
                    {/*                { */}
                    {/*                    text: t('user.ageCategory.juniors'), */}
                    {/*                    value: 'juniors', */}
                    {/*                }, */}
                    {/*                { */}
                    {/*                    text: t('user.ageCategory.men'), */}
                    {/*                    value: 'men', */}
                    {/*                }, */}
                    {/*                { */}
                    {/*                    text: t('user.ageCategory.women'), */}
                    {/*                    value: 'women', */}
                    {/*                }, */}
                    {/*            ]} */}
                    {/*        /> */}
                    {/*        <FormTextField */}
                    {/*            name='aboutMe' */}
                    {/*            label={t('user.aboutMe')} */}
                    {/*            type='text' */}
                    {/*            maxLength={100} */}
                    {/*            multiline */}
                    {/*            defaultValue={data.userMetaData?.user?.data?.aboutMe} */}
                    {/*        /> */}
                    {/*        <IonFab */}
                    {/*            vertical='bottom' */}
                    {/*            horizontal='end' */}
                    {/*        > */}
                    {/*            <Button */}
                    {/*                round */}
                    {/*                icon={arrowBack} */}
                    {/*                color='primary' */}
                    {/*                fill='outline' */}
                    {/*                onClick={() => controlledSwiper?.slidePrev()} */}
                    {/*            /> */}
                    {/*            <Button */}
                    {/*                round */}
                    {/*                icon={checkmark} */}
                    {/*                type='submit' */}
                    {/*                color='secondary' */}
                    {/*            /> */}
                    {/*        </IonFab> */}
                    {/*    </Form> */}
                    {/* </SwiperSlide> */}
                </Swiper>
            </Content>
        </Page>
    )
}

export default Onboarding
