import type { IonButton } from '@ionic/react'
import clsx from 'clsx'
import { list } from 'ionicons/icons'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import SkillsListView from '..'
import type { Talent, TalentDataView, TalentPosition } from '../../../../../lib/apollo/types'
import Button from '../../../../Button'
import Modal from '../../../../Modal'

import './style.scss'

interface SkillsListViewButtonProps {
    talentPosition?: TalentPosition
    talent1?: Talent
    talent2?: Talent
    dataView1?: TalentDataView[]
    dataView2?: TalentDataView[]
    activeIndex?: number
    color1?: string
    color2?: string
    label?: string
    disableIcon?: boolean
    under16: boolean
}

const SkillsListViewButton: React.FC<SkillsListViewButtonProps & React.ComponentProps<typeof IonButton>> = ({
    talentPosition,
    talent1,
    talent2,
    dataView1,
    dataView2,
    activeIndex,
    color1,
    color2,
    disableIcon,
    label,
    under16,
    ...buttonProps
}) => {
    const { t } = useTranslation()
    const [showModal, setShowModal] = useState(false)

    return (
        <>
            { talentPosition && (
                <Modal
                    onClose={() => { setShowModal(() => false) }}
                    modalTitle={(
                        <div
                            className='skills-list-view-btn__modal-title-wrapper'
                        >
                            { (talent1 || talent2) ? (
                                <div
                                    className='skills-list-view-btn__modal-title-img-wrapper'
                                >
                                    { talent1 && (
                                        <img
                                            style={{ borderColor: color1 }}
                                            className={clsx('skills-list-view-btn__modal-title-img', {
                                                [`skills-list-view-btn__modal-title-img--${color1}`]: !!color1,
                                            })}
                                            alt='Profil'
                                            src={talent1.image ?? ''}
                                        />
                                    )}
                                    { talent2 && (
                                        <img
                                            style={{ borderColor: color2 }}
                                            className={clsx('skills-list-view-btn__modal-title-img', {
                                                [`skills-list-view-btn__modal-title-img--${color2}`]: !!color2,
                                            })}
                                            alt='Profil'
                                            src={talent2.image ?? ''}
                                        />
                                    )}
                                </div>
                            ) : (
                                <span>
                                    {t('talent.listView')}
                                </span>
                            )}
                        </div>
                    )}
                    isOpen={showModal}
                >
                    <SkillsListView
                        talentPosition={talentPosition}
                        dataView1={dataView1}
                        dataView2={dataView2}
                        activeIndex={activeIndex}
                        color1={color1}
                        color2={color2}
                        under16={under16}
                    />
                </Modal>
            )}

            <Button
                disabled={!dataView1 || dataView1.length === 0}
                onClick={() => { setShowModal(() => true) }}
                fill='clear'
                color='light'
                icon={disableIcon ? undefined : list}
                {...buttonProps}
            >
                { label ?? t('talent.listView') }
            </Button>
        </>
    )
}

export default SkillsListViewButton
