import { addOutline } from 'ionicons/icons'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useRouteMatch } from 'react-router'

import Content from '../../../../components/Content'
import ContextMenuItem from '../../../../components/ContextMenu/ContextMenuItem/ContentMenuItem'
import ItemList from '../../../../components/ItemList'
import Page from '../../../../components/Page'
import SearchHeader from '../../../../components/Search/SearchHeader'
import SearchResultList from '../../../../components/Search/SearchResultList'
import UserListItem from '../../../../components/User/UserListItem'
import type { MapEntry, User } from '../../../../lib/apollo/types'
import {
    NetworkInvitationType,
    NetworkUsersDocument,
    useInviteOrApplyToNetworkMutation, useJmtNetworksQuery,
    useNetworkQuery,
    useSearchUserLazyQuery,
} from '../../../../lib/apollo/types'
import { useUser } from '../../../../providers/Auth/hooks'
import useToast from '../../../../providers/Toast/hooks'

import NetworkInviteUserViaLink from './NetworkInviteUserViaLink'

const NetworkAddUsers: React.FC = () => {
    const { t } = useTranslation()
    const { params: { networkId } } = useRouteMatch<{ networkId: string }>()
    const user = useUser()

    const { data: network } = useNetworkQuery({
        variables: {
            networkId,
            userId: user.user.id,
        },
    })

    const { data: systemnetworks } = useJmtNetworksQuery()
    const systemNetworkId = useMemo(() =>
        systemnetworks?.jmtNetworks.JMTnetworks.collection.find((sysNetwork) => sysNetwork.caption === 'Join.My.Talent.Engine')?.id
    , [systemnetworks])

    const [search, setSearch] = useState<{ value?: string, event: any }>()
    const [results, setResults] = useState<User[]>([])
    const [sortOptions, setSortOptions] = useState<MapEntry[]>([])
    const [sortOption, setSortOption] = useState<string>()

    const [present] = useToast()

    const [inviteToNetworkMutation] = useInviteOrApplyToNetworkMutation({
        onError: (e: any) => {
            if (e.networkError?.statusCode === 409) {
                present(t('network.user.alreadyInNetwork', { user: `${user.user.firstName} ${user.user.lastName}` }),'warning')
            } else {
                present(t('errors.unknown'),'danger')
            }
        },
        refetchQueries: [
            {
                query: NetworkUsersDocument,
                variables: {
                    userId: user.user.id,
                    networkId: network?.network.id,
                },
            },
        ],
    })

    const [lazySearchUserQuery, { loading }] = useSearchUserLazyQuery({
        onCompleted: data => {
            setResults(data.searchUser?.collection ?? [])
            setSortOptions(data.searchUser?.meta.sortOptions ?? [])
        },
    })

    const onAddUserClick = useCallback(async (u: User) => {
        if (!network?.network.id) {
            return
        }
        const response = await inviteToNetworkMutation({
            variables: {
                input: {
                    networkId: network.network.id,
                    targetUserId: u.id,
                    type: NetworkInvitationType.Invitation,
                    message: t('networks.invitation', {
                        user: `${user.user.firstName} ${user.user.lastName}`,
                        network: network.network.caption,
                    }),
                },
            },
        })

        if (!response.errors) {
            present(t('networks.invitationComplete'),'success')
        }
    }, [network])

    useEffect(() => {
        if (!search?.value) {
            return
        }

        lazySearchUserQuery({
            variables: {
                query: search.value,
                sort: sortOption ?? '',
                meId: user.user.id,
                networkId: systemNetworkId ?? '0',
            },
        })
    }, [search])

    useEffect(() => {
        if (sortOption && search?.value) {
            lazySearchUserQuery({
                variables: {
                    query: search.value,
                    sort: sortOption ?? '',
                    meId: user.user.id,
                    networkId: systemNetworkId ?? '0',
                },
            })
        }
    }, [sortOption])

    return (
        <Page
            trackingTitle='network add users'
            className='network-add-user'
        >
            <SearchHeader
                setSearch={setSearch}
                search={search}
            />
            <Content
                fullscreen
            >
                <SearchResultList
                    headline={t('search.users')}
                    loading={loading}
                    results={results}
                    sortOption={sortOption}
                    sortOptions={sortOptions}
                    onSortOptionChange={setSortOption}
                >
                    {(listResults: User[]) => {
                        return (
                            <>
                                <ItemList>
                                    {listResults.map(r => (
                                        <UserListItem
                                            key={r.id}
                                            user={r}
                                        >
                                            <ContextMenuItem
                                                icon={addOutline}
                                                onClick={() => onAddUserClick(r)}
                                                label={t('networks.addUsers')}
                                            />
                                        </UserListItem>
                                    ))}
                                </ItemList>
                                <NetworkInviteUserViaLink
                                    network={network?.network}
                                />
                            </>
                        )
                    }}
                </SearchResultList>
            </Content>
        </Page>
    )
}

export default NetworkAddUsers
