import { IonIcon, IonImg } from '@ionic/react'
import clsx from 'clsx'
import { bookmark, image } from 'ionicons/icons'
import { useTranslation } from 'react-i18next'

import Button from '../Button'

import './style.scss'

export type CustomAvatarType = 'person' | 'network' | 'person-reverse' | 'round' | 'playlist'

interface CustomAvatarProps {
    img?: string | null
    icon?: string
    alt?: string
    width?: number | string
    square?: boolean
    type?: CustomAvatarType
    onClick?: () => void
    className?: string
    iconBackground?: 'white' | 'none',
    fullIconHeight?: boolean,
    onDelete?: () => any,
}

const CustomAvatar: React.FC<CustomAvatarProps> = ({
    img,
    icon,
    alt,
    width = 80,
    type= 'person',
    onClick,
    className = '',
    iconBackground = 'none',
    fullIconHeight = false,
    onDelete,
}) => {

    const { t } = useTranslation()

    return (
        <div
            style={{ width }}
            className={clsx(
                className,
                'custom-avatar',
                `custom-avatar--${type}`, {
                    'custom-avatar--fallback': !img,
                },
            )}
        >
            <div
                className='custom-avatar__position-helper'
            >
                {icon ? (
                    <IonIcon
                        icon={icon}
                        className={clsx(
                            `background--${iconBackground}`,
                            { 'full-icon-height': fullIconHeight },
                        )}
                    />
                ) : (
                    <>
                        {type === 'playlist' && (
                            <IonIcon
                                className='custom-avatar__playlist-background'
                                icon={bookmark}
                            />
                        )}
                        <IonImg
                            alt={alt}
                            src={img ?? '/assets/icon/favicon.png'}
                            onClick={onClick}
                        />
                    </>
                )}
            </div>
            {onDelete && (
                <div
                    className='custom-avatar__delete-btn'
                >
                    <Button
                        color='danger'
                        icon={image}
                        onClick={onDelete}
                    >
                        {t('buttons.delete')}
                    </Button>
                </div>
            )}
        </div>
    )
}

export default CustomAvatar
