import { checkmark, search } from 'ionicons/icons'
import type { ReactElement } from 'react'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import Button from '../../../components/Button'
import Content from '../../../components/Content'
import Header from '../../../components/Header'
import Page from '../../../components/Page'
import { useJmtNetworksQuery, useNetworkQuery, useUserJoinMarketplaceMutation } from '../../../lib/apollo/types'
import { useUser } from '../../../providers/Auth/hooks'

const MarketplaceAuthProxy: React.FC = ({ children }): ReactElement | null => {
    const { t } = useTranslation()
    const me = useUser()
    const [notInNetwork, setNotInNetwork] = useState<boolean | undefined>(undefined)

    const [joinMarketplace] = useUserJoinMarketplaceMutation()

    const networks = useJmtNetworksQuery()
    const marketplaceNetwork = useMemo(() =>
        networks?.data?.jmtNetworks.JMTnetworks.collection.find((network) => network.caption === 'Join.My.Talent.Engine.Marketplace')
    , [networks])

    const network = useNetworkQuery({
        fetchPolicy: 'network-only',
        skip: !marketplaceNetwork?.id,
        variables: {
            userId: me.user.id,
            networkId: marketplaceNetwork?.id ?? '',
        },
        onError: () => {
            setNotInNetwork(() => true)
        },
    })

    if (network.data || notInNetwork === false) {
        return children as ReactElement
    }

    if (notInNetwork) {
        return (
            <Page
                trackingTitle='join marketplace'
            >
                <Header
                    title={t('title.marketplace')}
                    buttonLeft='menu'
                >
                    <Button
                        disabled
                        icon={search}
                        routerLink='/marketplace/search'
                    />
                </Header>
                <Content
                    fullscreen
                >
                    <div>
                        <h2>
                            { t('title.marketplace') }
                        </h2>
                        <p>
                            { t('marketplace.intro') }
                        </p>
                        <Button
                            onClick={async () => {
                                await joinMarketplace()
                                setNotInNetwork(() => false)
                            }}
                            icon={checkmark}
                            color='secondary'
                        >
                            { t('networks.join') }
                        </Button>
                    </div>
                </Content>
            </Page>
        )
    }

    return null
}
export default MarketplaceAuthProxy
