import { IonCard, IonSkeletonText, IonText } from '@ionic/react'
import { Trans, useTranslation } from 'react-i18next'

import { useTalentQuery } from '../../../lib/apollo/types'
import getAge from '../../../lib/getAge'
import { useUser } from '../../../providers/Auth/hooks'
import CustomAvatar from '../../CustomAvatar'
import SkillsListViewButton from '../../Talent/TalentID/SkillsListView/SkillsListViewButton'
import type { ActivityProps } from '../ActivityProps'
import DeletedActivity from '../DeletedActivity'
import useHours from '../useHours'

import './style.scss'

const VoteActivity: React.FC<ActivityProps> = ({ activity }) => {
    const { t } = useTranslation()

    const me = useUser()
    const myAction = activity.userID.toString() === me.user.id.toString()

    const talentId = activity.link.split('/').reverse()[1]

    const { data: talentData, loading } = useTalentQuery({ skip: !myAction, variables: { userId: me.user.id, talentId } })
    const hours = useHours(activity)

    if (talentData && !talentData.talent) return <DeletedActivity />

    return (
        <IonCard
            color='dark'
            className='vote-activity'
        >
            <CustomAvatar
                className='vote-activity__avatar'
                img={talentData?.talent.image}
                alt={talentData?.talent?.id}
            />

            <IonText
                className='vote-activity__text'
                color='light'
            >
                <Trans
                    i18nKey={`activity.vote.${myAction ? 'you' : 'me'}`}
                    values={{
                        name: talentData?.talent?.caption,
                        date: hours,
                    }}
                    components={{
                        strong: !loading ? <strong /> : (
                            <IonSkeletonText
                                className='vote-activity__skeleton'
                            />
                        ),
                        date: (
                            <IonText
                                className='vote-activity__text--small'
                                color='medium'
                            />
                        ),
                    }}
                />
            </IonText>

            { talentData && (
                <SkillsListViewButton
                    talentPosition={talentData.talent.metas.fieldPosition}
                    color1='secondary'
                    color='secondary'
                    fill='solid'
                    size='small'
                    disableIcon
                    label={t('talent.theVote')}
                    dataView1={[{
                        key: 0,
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        values: activity.data,
                    }]}
                    under16={(getAge(talentData.talent.metas.date) ?? 99) < 16}
                />
            )}
        </IonCard>
    )
}

export default VoteActivity
