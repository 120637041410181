import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'

import Buttons from '../../components/Buttons'
import Content from '../../components/Content'
import Header from '../../components/Header'
import ItemList from '../../components/ItemList'
import TalentCard from '../../components/NewTalentCard'
import Page from '../../components/Page'
import RoleModel from '../../components/RoleModel'
import TopSkills from '../../components/Talent/TopSkills'
import ChoseTopSkillsButton from '../../components/Talent/TopSkills/ChoseTopSkills/ChoseTopSkillsButton'
import TalentActionButtons from '../../components/TalentActionButtons'
import { PermissionLevel, useTalentQuery } from '../../lib/apollo/types'
import useWindowResizeListener from '../../lib/hooks/useWindowResizeListener'
import { useUser } from '../../providers/Auth/hooks'

import './style.scss'

export type TalentCardDetailPageMode = 'default' | 'details'

interface TalentParams {
    talentId: string
    mode?: TalentCardDetailPageMode
    networkId?: string
}

const TalentPage: React.FC = () => {
    const { talentId, mode, networkId } = useParams<TalentParams>()
    const { t } = useTranslation()
    const { isDesktop } = useWindowResizeListener()

    const user = useUser()
    const { data } = useTalentQuery({
        variables: {
            talentId, userId: user.user.id,
        },
        fetchPolicy: 'cache-and-network',
    })

    const caption = data?.talent?.caption.startsWith('playerTypes.')
        ? t(data.talent.caption)
        : data?.talent?.caption

    return (
        <Page
            trackingTitle='talent'
            className='talent-page'
        >
            <Header
                title={caption ?? ''}
            />
            <Content
                fullscreen
                className='talent-page__content'
            >
                <ItemList>
                    <div
                        className='display-flex ion-justify-content-center'
                    >
                        <TalentCard
                            talent={data?.talent}
                            networkId={networkId === 'undefined' ? undefined : networkId}
                        />
                    </div>
                    <TalentActionButtons
                        mode={mode}
                        talent={data?.talent}
                        networkId={networkId === 'undefined' ? undefined : networkId}
                    />
                </ItemList>

                {mode === 'details' && (
                    <>
                        <RoleModel
                            talent={data?.talent}
                        />
                        {!isDesktop && (
                            <>
                                <TopSkills
                                    asCard
                                    className='talent-page__topskills'
                                    talent={data?.talent}
                                    withHeadline
                                />
                                {data?.talent && data.talent.permissionLevel !== PermissionLevel.None && (
                                    <Buttons
                                        title={t('buttons.choseTopSkillsTitle')}
                                        subtitle={t('buttons.choseTopSkillsSubtitle')}
                                    >
                                        <ChoseTopSkillsButton
                                            talent={data.talent}
                                        />
                                    </Buttons>
                                )}
                            </>
                        )}
                    </>
                )}
            </Content>
        </Page>
    )
}

export default TalentPage
