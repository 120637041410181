import { IonCard, IonText } from '@ionic/react'
import { Trans } from 'react-i18next'

import type { NetworkInvitation } from '../../../lib/apollo/types'
import { useUser } from '../../../providers/Auth/hooks'
import AnswerInvitationButton from '../../Button/AnswerInvitationButton'
import CustomAvatar from '../../CustomAvatar'
import type { ActivityProps } from '../ActivityProps'
import useHours from '../useHours'

import './style.scss'

const InvitationActivity: React.FC<ActivityProps> = ({ activity }) => {
    const me = useUser()
    const invite = activity.userID.toString() === me.user.id.toString()
    const invitation = JSON.parse(activity.data) as NetworkInvitation

    const hours = useHours(activity)

    return (
        <IonCard
            className='invite-activity'
        >
            <CustomAvatar
                width={106.66}
                type='network'
                className='invite-activity__avatar'
                img={invitation.network?.image}
                alt={invitation.network?.id}
            />
            <IonText
                className='invite-activity__text'
                color='dark'
            >
                <Trans
                    i18nKey={`activity.invite.${invite ? 'invite' : 'network'}`}
                    values={{
                        network: invitation.network?.caption,
                        user: `${invitation.targetUser?.firstName} ${invitation.targetUser?.lastName}`,
                        date: hours,
                    }}
                    components={{
                        strong: <strong />,
                        date: (
                            <IonText
                                className='follow-activity__text--small'
                                color='medium'
                            />
                        ),
                    }}
                />
            </IonText>
            <AnswerInvitationButton
                size='small'
                fill='solid'
                color='secondary'
                invitation={invitation}
                isInvite={invite}
            />
        </IonCard>
    )
}

export default InvitationActivity
