import { IonLabel } from '@ionic/react'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useRelationshipsQuery } from '../../../../lib/apollo/types'
import { useUser } from '../../../../providers/Auth/hooks'
import FollowerIcon from '../../../Icon/RelationshipIcon/FollowerIcon'
import FollowingIcon from '../../../Icon/RelationshipIcon/FollowingIcon'
import FriendsIcon from '../../../Icon/RelationshipIcon/FriendsIcon'
import UserTeaserSlider from '../index'

const FFFUserTeaserSlider: React.FC = () => {
    const user = useUser()
    const { t } = useTranslation()
    const { data } = useRelationshipsQuery({ variables: { userId: user.user.id } })

    const sliderTabs = useMemo(() => {
        if (!data) return null
        const { followed, follower } = data

        const friends = followed?.collection.filter(u => u.isFriend)

        return [{
            label: (
                <>
                    <FollowingIcon
                        className='ion-margin-end'
                    />
                    <IonLabel>
                        {t('user.followed')}
                    </IonLabel>
                </>
            ),
            users: followed?.collection.filter(u => !u.isFriend) ?? [],
        },{
            label: (
                <>
                    <FollowerIcon
                        className='ion-margin-end'
                    />
                    <IonLabel>
                        {t('user.follower')}
                    </IonLabel>
                </>
            ),
            users: follower?.collection.filter(u => !u.isFriend) ?? [],
        },{
            label: (
                <>
                    <FriendsIcon
                        className='ion-margin-end'
                    />
                    <IonLabel>
                        {t('user.friends')}
                    </IonLabel>
                </>
            ),
            users: friends ?? [],
        }]
    }, [data])

    return (
        <UserTeaserSlider
            title={t('title.friends')}
            subtitle={t('title.sub.friends')}
            tabs={sliderTabs}
        />
    )
}

export default FFFUserTeaserSlider
