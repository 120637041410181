import { add, shirt } from 'ionicons/icons'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useContextSelector } from 'use-context-selector'

import type { Talent } from '../../../../lib/apollo/types'
import {
    NetworkTalentsDocument,
    useAddTalentsToNetworkMutation,
    useNetworksQuery,
} from '../../../../lib/apollo/types'
import useTabContext from '../../../../lib/hooks/useTabContext'
import { useUser } from '../../../../providers/Auth/hooks'
import useToast from '../../../../providers/Toast/hooks'
import Button from '../../../Button'
import ContextMenuItem from '../../../ContextMenu/ContextMenuItem/ContentMenuItem'
import { ContextMenuContext } from '../../../ContextMenu/context'
import Modal from '../../../Modal'
import NetworkListItem from '../../../Network/NetworkListItem'

interface TalentPlaylistContextMenuItemProps {
    talent: Talent
}

const TalentPlaylistContextMenuItem: React.FC<TalentPlaylistContextMenuItemProps> = ({ talent }) => {
    const tab = useTabContext()
    const { t } = useTranslation()
    const [present] = useToast()
    const me = useUser()

    const [showModal, setShowModal] = useState(false)
    const closeContextMenu = useContextSelector(ContextMenuContext, ctx => ctx.closeContextMenu)

    const [addTalentsToNetwork] = useAddTalentsToNetworkMutation({
        errorPolicy: 'none',
        onError: (e: any) => {
            switch (e.networkError.statusCode) {
                case 409: {
                    present(t('network.talentAlreadyAdded'), 'warning')
                    break
                }
                case 403: {
                    present(t('network.talentIsNeitherPublicNorOwn'), 'warning')
                    break
                }
                default: {
                    present(t('errors.unknown'),'danger')
                }
            }
        },
        onCompleted: () => {
            present(t('network.talentAdded'), 'success')
            setShowModal(() => false)
            closeContextMenu()
        },
    })
    const { data: { networks } = {} } = useNetworksQuery({
        variables: {
            userId: me.user.id,
            search: '&role=mod&limit=50',
        },
    })

    const onClick = useCallback((e) => {
        e.persist()
        e.stopPropagation()
        setShowModal(() => true)
    }, [setShowModal])

    return (
        <>
            <ContextMenuItem
                disableCloseContextMenuOnClick
                onClick={onClick}
                icon={shirt}
                label={t('network.addTalent')}
            />
            <Modal
                onClose={() => {
                    setShowModal(() => false)
                }}
                isOpen={showModal}
                modalTitle={t('network.addTalent')}
            >
                <h2>
                    {t('network.addTalent')}
                </h2>
                <div>
                    {networks?.collection.map(network => (
                        <NetworkListItem
                            hideContextMenu
                            onClick={() => {
                                addTalentsToNetwork({
                                    variables: {
                                        id: network.id,
                                        talents: { _links: { objectByNetwork: { url: [`/api/object/${talent.id}`] } } },
                                    },
                                    refetchQueries: [{
                                        query: NetworkTalentsDocument,
                                        variables: {
                                            userId: me.user.id,
                                            networkId: network.id,
                                        },
                                    }],
                                })
                            }}
                            key={network.id}
                            network={network}
                        />
                    ))}
                </div>
                <Button
                    icon={add}
                    color='secondary'
                    routerLink={`/${tab}/network/new/edit/[${talent.id}]`}
                    expand='block'
                    onClick={() => {
                        setShowModal(() => false)
                        closeContextMenu()
                    }}
                >
                    { t('network.add') }
                </Button>
            </Modal>
        </>
    )
}

export default TalentPlaylistContextMenuItem

