import { IonCard, IonCol, IonGrid, IonRow } from '@ionic/react'

import CustomAvatar from '../CustomAvatar'
import './style.scss'

interface IconListItemProps {
    icon: string
    routerLink?: string
    onClick: () => void
}

const IconListItem: React.FC<IconListItemProps> = ({ onClick, icon, routerLink, children }) => (
    <IonCard
        color='light'
        className='icon-list-item'
        routerLink={routerLink}
        onClick={onClick}
    >
        <CustomAvatar
            icon={icon}
            fullIconHeight
            iconBackground='none'
        />
        <IonGrid
            className='icon-list-item__grid'
        >
            <IonRow
                className='ion-align-items-center ion-justify-content-between ion-nowrap'
            >
                <IonCol>
                    { children }
                </IonCol>
            </IonRow>
        </IonGrid>
    </IonCard>
)

export default IconListItem
