import { timeOutline } from 'ionicons/icons'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import type { Talent } from '../../../../lib/apollo/types'
import {
    PlaylistDocument,
    PlaylistsDocument,
    useAddTalentsToPlaylistMutation,
    usePlaylistsQuery,
} from '../../../../lib/apollo/types'
import { useUser } from '../../../../providers/Auth/hooks'
import useToast from '../../../../providers/Toast/hooks'
import ContextMenuItem from '../../../ContextMenu/ContextMenuItem/ContentMenuItem'
import { WATCHLIST_CAPTION } from '../../../Playlist/PlayListConstants'

interface TalentWatchlistContextMenuItemProps {
    talent: Talent
    asButton?: boolean
}

const TalentWatchlistContextMenuItem: React.FC<TalentWatchlistContextMenuItemProps> = ({ talent }) => {
    const { t } = useTranslation()
    const [present] = useToast()
    const me = useUser()

    const { data: { playlists } = {} } = usePlaylistsQuery({ variables: { userId: me.user.id } })

    const watchlist = useMemo(() => {
        if (playlists?.collection && playlists.collection.length > 0) {
            return playlists.collection.find(p => p.caption === WATCHLIST_CAPTION)
        }
        return undefined
    }, [playlists])

    const [addTalentsToPlaylist, { loading }] = useAddTalentsToPlaylistMutation({
        onError: (e: any) => {
            if (e.networkError?.statusCode === 409) {
                present(t('playlist.alreadyAdded'), 'warning')
            } else {
                present(t('errors.unknown'),'danger')
            }
        },
        onCompleted: () => {
            present(t('playlist.talentAdded'), 'success')
        },
    })

    const onClick = useCallback(() => {
        addTalentsToPlaylist({
            variables: {
                id: watchlist?.id ?? '',
                talents: { _links: { objectByPlaylist: { url: [`/api/object/${talent.id}`] } } },
            },
            refetchQueries: [{
                query: PlaylistsDocument,
                variables: {
                    userId: me.user.id,
                },
            }, {
                query: PlaylistDocument,
                variables: {
                    userId: me.user.id,
                    id: watchlist?.id ?? '',
                },
            }],
        })
    }, [watchlist?.id])

    return (
        <ContextMenuItem
            disabled={loading || !playlists?.collection}
            onClick={onClick}
            icon={timeOutline}
            label={t('playlist.watchlists.addTalent')}
        />
    )
}

export default TalentWatchlistContextMenuItem

