import { Preferences } from '@capacitor/preferences'
import React, { useEffect, useReducer, useRef } from 'react'
import { createContext } from 'use-context-selector'

import type { AppAction, AppState } from './types'
import { AppActionType } from './types'

const initialState: AppState = {
    newActivities: [],
    newForeignActivities: [],
    marketplaceRecentSearches: [],
    initActions: [],
}

export const AppContext = createContext<{
    state: AppState,
    dispatch: React.Dispatch<AppAction>
}>({
    state: initialState,
    dispatch: () => undefined,
})

const reducer = (state: AppState, action: AppAction) => {
    switch (action.type) {
        case AppActionType.SetState: {
            return {
                ...state,
                ...action.payload,
            }
        }

        case AppActionType.MarketplaceSearchHistoryAdd: {
            const filteredRecentSearches = state.marketplaceRecentSearches
                .filter(({ data, type }) => !(data === action.payload.data && type === action.payload.type))

            return {
                ...state,
                marketplaceRecentSearches: [action.payload, ...filteredRecentSearches].slice(0, 10),
            }
        }

        case AppActionType.InitAppActionAdd: {
            return {
                ...state,
                initActions: [...state.initActions, action.action],
            }
        }

        case AppActionType.InitAppActionRemove: {
            return {
                ...state,
                initActions: state.initActions.filter(a => JSON.stringify(a) !== JSON.stringify(action.action)),
            }
        }

        default: return state
    }
}

const APPSTATE_STORAGE_KEY = 'jmt-app-state'

const AppStateProvider: React.FC = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState)
    const value = { state, dispatch }

    // LOAD APP STATE
    useEffect(() => {
        const loadInitialData = async () => {
            const { value: oldState } = await Preferences.get({ key: APPSTATE_STORAGE_KEY })
            if (oldState) {
                try {
                    const payload: Partial<AppState> = JSON.parse(oldState)

                    dispatch({
                        type: AppActionType.SetState,
                        payload,
                    })
                } catch (e) {
                    // eslint-disable-next-line no-console
                    console.error('Broken Appstate in Storage', e)
                }
            }
        }
        loadInitialData()
    }, [])

    // SAVE APP STATE
    const didMount = useRef(false)
    useEffect(() => {
        if (!didMount.current) {
            didMount.current = true
        } else {
            Preferences.set({
                key: APPSTATE_STORAGE_KEY,
                value: JSON.stringify(state),
            })
        }
    }, [state])

    return (
        <AppContext.Provider
            value={value}
        >
            {children}
        </AppContext.Provider>
    )
}

export default AppStateProvider
