import { share } from 'ionicons/icons'
import { useTranslation } from 'react-i18next'

import { useMyspaceQuery } from '../../lib/apollo/types'
import useNetworkInvite from '../../lib/hooks/useNetworkInvite'
import { useUser } from '../../providers/Auth/hooks'
import Button from '../Button'

const GetYourSkillsConfirmedButton: React.FC = () => {
    const { t } = useTranslation()
    const user = useUser()
    const personalSpace = useMyspaceQuery({
        variables: {
            userId: user.user.id,
        },
        skip: !user,
    })

    const invite = useNetworkInvite(personalSpace.data?.myspace, true)

    return (
        <Button
            color='secondary'
            icon={share}
            onClick={invite}
        >
            {t('talent.getYourSkillConfirmed')}
        </Button>
    )
}

export default GetYourSkillsConfirmedButton
