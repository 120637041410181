const handleCollection = (name: string): Record<string, unknown> => ({
    keyArgs: (keys: any) => Object.keys(keys).filter(k => k !== 'queryParams'),
    merge(existing: any, incoming: any) {
        if (!existing || incoming.meta.range.from === 1) {
            return incoming
        }

        return {
            [`collection@type({"name":"${name}"})`]: [
                ...existing[`collection@type({"name":"${name}"})`],
                ...incoming[`collection@type({"name":"${name}"})`],
            ],
            meta: {
                ...incoming.meta,
                from: existing.meta.from,
            },
        }
    },
})

export default handleCollection
