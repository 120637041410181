import { search } from 'ionicons/icons'
import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import type { Talent } from '../../../../lib/apollo/types'
import {
    NetworkState,
    useIsTalentInNetworkQuery,
    useJmtNetworksQuery,
    useTalentJoinMarketplaceMutation,
} from '../../../../lib/apollo/types'
import { useUser } from '../../../../providers/Auth/hooks'
import useToast from '../../../../providers/Toast/hooks'
import ContextMenuItem from '../../../ContextMenu/ContextMenuItem/ContentMenuItem'

interface TalentJoinMarketplaceContextMenuItemProps {
    talent: Talent
}

const TalentJoinMarketplaceContextMenuItem: React.FC<TalentJoinMarketplaceContextMenuItemProps> = ({ talent }) => {
    const me = useUser()
    const { t } = useTranslation()
    const [present] = useToast()

    const [isInMarketplace, setIsInMarketplace] = useState<boolean | undefined>(undefined)

    const networks = useJmtNetworksQuery()
    const marketplaceNetwork = useMemo(() =>
        networks?.data?.jmtNetworks.JMTnetworks.collection.find((network) => network.caption === 'Join.My.Talent.Engine.Marketplace')
    , [networks])

    useIsTalentInNetworkQuery({
        skip: !marketplaceNetwork?.id || !talent?.id,
        variables: {
            userId: me.user.id,
            talentId: talent?.id,
            networkId: marketplaceNetwork?.id ?? '',
        },
        onCompleted: ({ isTalentInNetwork }) => {
            if (isTalentInNetwork.state === NetworkState.InNetwork) {
                setIsInMarketplace(() => true)
            } else {
                setIsInMarketplace(() => false)
            }
        },
        onError: () => { setIsInMarketplace(() => false) },
    })

    const [joinMarketplace] = useTalentJoinMarketplaceMutation()

    const onClick = useCallback(async (e) => {
        e.persist()
        e.stopPropagation()

        if (isInMarketplace) {
            // TODO: LEAVE
            // setIsInMarketplace(() => false)
        } else {
            await joinMarketplace()
            present(t('talent.addedToMarketplace'), 'success')
            setIsInMarketplace(() => true)
        }
    }, [isInMarketplace])

    return (
        <ContextMenuItem
            disabled={isInMarketplace === undefined || isInMarketplace}
            onClick={onClick}
            icon={search}
            label={t('marketplace.join')}
        />
    )
}

export default TalentJoinMarketplaceContextMenuItem

