import { man } from 'ionicons/icons'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import type { Talent } from '../../../../lib/apollo/types'
import Button from '../../../Button'
import Modal from '../../../Modal'

import ChoseRoleModelForm from './ChoseRoleModelForm'

interface ChoseRoleModelButtonProps {
    talent?: Talent
    loading?: boolean
    color?: string
}

const ChoseRoleModelButton: React.FC<ChoseRoleModelButtonProps> = ({ color = 'secondary', talent, loading = false }) => {
    const { t } = useTranslation()
    const [showModal, setShowModal] = useState(false)

    return (
        <>
            { talent && (
                <Modal
                    onClose={() => { setShowModal(() => false) }}
                    modalTitle={t('talent.choseRoleModel')}
                    isOpen={showModal}
                >
                    <ChoseRoleModelForm
                        talent={talent}
                        onSelect={() => { setShowModal(() => false) }}
                    />
                </Modal>
            )}
            <Button
                loading={loading}
                expand='block'
                onClick={() => { setShowModal(() => true) }}
                fill='solid'
                color={color ?? 'secondary'}
                icon={man}
            >
                { t('talent.choseRoleModel') }
            </Button>
        </>
    )
}

export default ChoseRoleModelButton
