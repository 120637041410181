import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import Content from '../../components/Content'
import Header from '../../components/Header'
import TalentCard from '../../components/NewTalentCard'
import Page from '../../components/Page'
import type { Network } from '../../lib/apollo/types'
import { useJmtNetworksQuery, useNetworkQuery } from '../../lib/apollo/types'
import { useUser } from '../../providers/Auth/hooks'
import NetworkTalents from '../Network/NetworkTalents'

import RoleModelsContextMenu from './RoleModelsContextMenu'

const RoleModels: React.FC = () => {
    const { t } = useTranslation()
    const user = useUser()
    const { data: jmtNetworks } = useJmtNetworksQuery()

    const roleModelNetwork = useMemo<Network | undefined>(() => {
        return jmtNetworks?.jmtNetworks.JMTnetworks.collection.find(network => network.caption === 'Join.My.Talent.Engine.RoleModels')
    }, [jmtNetworks])

    const { data } = useNetworkQuery({
        variables: {
            networkId: roleModelNetwork?.id ?? '',
            userId: user.user.id,
        },
        skip: !roleModelNetwork?.id,
    })

    return (
        <Page
            trackingTitle='rolemodels'
        >
            <Header
                buttonLeft='back'
                title={t('title.rolemodels')}
            >
                <RoleModelsContextMenu
                    network={data?.network}
                />
            </Header>
            <Content
                fullscreen
            >
                { !data?.network ? (
                    <>
                        <TalentCard
                            type='listItem'
                        />
                        <TalentCard
                            type='listItem'
                        />
                        <TalentCard
                            type='listItem'
                        />
                        <TalentCard
                            type='listItem'
                        />
                    </>
                ) : (
                    <NetworkTalents
                        network={data.network}
                    />
                )}
            </Content>
        </Page>
    )

}

export default RoleModels
