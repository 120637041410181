import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};




export type ActivitiesResponse = {
  __typename?: 'ActivitiesResponse';
  collection: Array<Activity>;
  meta: ActivityMeta;
};

export type Activity = {
  __typename?: 'Activity';
  url: Scalars['String'];
  date: Scalars['String'];
  id: Scalars['ID'];
  link: Scalars['String'];
  method: Scalars['String'];
  type: Scalars['String'];
  userID: Scalars['Int'];
  data: Scalars['String'];
};

export type ActivityMeta = {
  __typename?: 'ActivityMeta';
  range: ActivityRange;
};

export type ActivityRange = {
  __typename?: 'ActivityRange';
  from: Scalars['Int'];
  to: Scalars['Int'];
  total: Scalars['Int'];
};

export enum AgeCategory {
  Kids = 'kids',
  Juniors = 'juniors',
  Men = 'men',
  Women = 'women'
}

export type ApplyNetworkWithTalentInput = {
  talentId: Scalars['Int'];
  networkId: Scalars['Int'];
};

export type Authentication = {
  __typename?: 'Authentication';
  token: Scalars['String'];
  user: User;
};

export type AuthenticationFormInput = {
  name: Scalars['String'];
  password: Scalars['String'];
  permanent: Scalars['Boolean'];
};

export type AuthenticationTokenInput = {
  token: Scalars['String'];
};

export enum ChartType {
  Radar = 'radar',
  Pie = 'pie',
  AdStage = 'adStage'
}

export type CurrentUser = {
  __typename?: 'CurrentUser';
  data?: Maybe<UserMetaData>;
  _created: Scalars['Int'];
};

export type DeleteAccountInput = {
  password: Scalars['String'];
};

export type DeleteAccountResponse = {
  __typename?: 'DeleteAccountResponse';
  message: Scalars['String'];
};

export type DoimailInput = {
  token: Scalars['String'];
  email: Scalars['String'];
  subject: Scalars['String'];
  name: Scalars['String'];
};

export enum Experience {
  Professional = 'professional',
  SemiProfessional = 'semiProfessional',
  Amateur = 'amateur'
}

export type FileUploadResponse = {
  __typename?: 'FileUploadResponse';
  id: Scalars['ID'];
  token: Scalars['String'];
  url: Scalars['String'];
  user: Scalars['String'];
  _links: Links;
  _created: Scalars['Int'];
  _updated: Scalars['Int'];
};

export type FollowUser = {
  follow_user: UrlInput;
};

export type FollowUserInput = {
  _links: FollowUser;
};

export type ImageInput = {
  image: UrlInput;
};

export type InNetworkState = {
  __typename?: 'InNetworkState';
  state: NetworkState;
};

export type InviteNetworkWithTalentInput = {
  link: Scalars['String'];
  targetUserId: Scalars['Int'];
  networkId: Scalars['Int'];
};

export type JmtNetworkResponse = {
  __typename?: 'JMTNetworkResponse';
  JMTnetworks: NetworksResponse;
};

export type LatestObject = {
  __typename?: 'LatestObject';
  image: Scalars['String'];
};

export type Layer = {
  __typename?: 'Layer';
  name: Scalars['String'];
  dataViews: Array<TalentDataView>;
};

export type Link = {
  __typename?: 'Link';
  rel: Scalars['String'];
  url: Scalars['String'];
};

export type LinkImageInput = {
  _links: ImageInput;
};

export type LinkUserInput = {
  _links: UserLinkInput;
};

export type Links = {
  __typename?: 'Links';
  self: Link;
  user: Link;
};

export type MapEntry = {
  __typename?: 'MapEntry';
  key: Scalars['String'];
  value: Scalars['String'];
};

export type Message = {
  __typename?: 'Message';
  message?: Maybe<Scalars['String']>;
};

export type MessageResponse = {
  __typename?: 'MessageResponse';
  message: Scalars['String'];
};

export type Meta = {
  __typename?: 'Meta';
  range?: Maybe<Range>;
  sortOptions?: Maybe<Array<MapEntry>>;
};

export type Mutation = {
  __typename?: 'Mutation';
  acceptOrDeclineNetworkInvitation: NetworkInvitation;
  addNetwork: Network;
  addPlaylist: Playlist;
  addRoleModel: MessageResponse;
  addTalentsToNetwork: Network;
  addTalentsToPlaylist: Playlist;
  addUsersToNetwork: Network;
  applyNetworkWithTalent: MessageResponse;
  createNetworkTokenInvitation: NetworkTokenInvitation;
  deleteAccount: DeleteAccountResponse;
  deleteNetwork?: Maybe<Scalars['Boolean']>;
  deletePlaylist?: Maybe<Scalars['Boolean']>;
  deleteTalent?: Maybe<Scalars['Boolean']>;
  doimail: Message;
  editNetwork: Network;
  editPlaylist: Playlist;
  inviteNetworkWithTalent: MessageResponse;
  inviteOrApplyToNetwork: NetworkInvitation;
  joinNetworkByToken: Network;
  networkLinkImage: Network;
  networkUnlinkImage: Network;
  recoverUsername: UserCollection;
  register: Authentication;
  removeTalentFromNetwork: Network;
  removeTalentsFromPlaylist: Playlist;
  removeUserFromNetwork: Network;
  requestPasswordChange: RequestPasswordChangeResponse;
  savePerformanceVote: PerformanceVote;
  saveTalent: Talent;
  saveTalentCustomMeta: TalentCustomMeta;
  saveUserMetaData: UserMetaDataSaveResponse;
  shareTalent: ShareTalentResponse;
  signin?: Maybe<Authentication>;
  signinWithToken: Authentication;
  talentJoinMarketplace: Network;
  talentLinkImage: Talent;
  talentLinkUser: Talent;
  talentUnlinkImage: Talent;
  toggleMod?: Maybe<Network>;
  transferOwner?: Maybe<Network>;
  updatePassword: User;
  updateUser: User;
  updateVote: Vote;
  uploadImage: FileUploadResponse;
  userFollow: User;
  userJoinMarketplace: Network;
  userLinkImage: User;
  userUnlinkImage: User;
  vote: Vote;
};


export type MutationAcceptOrDeclineNetworkInvitationArgs = {
  id: Scalars['ID'];
  input?: Maybe<NetworkApplyAnswerInput>;
};


export type MutationAddNetworkArgs = {
  input: NetworkInput;
};


export type MutationAddPlaylistArgs = {
  input: PlaylistInput;
};


export type MutationAddRoleModelArgs = {
  input?: Maybe<RoleModelInput>;
};


export type MutationAddTalentsToNetworkArgs = {
  id: Scalars['ID'];
  input: NetworkTalentsInput;
};


export type MutationAddTalentsToPlaylistArgs = {
  id: Scalars['String'];
  input: PlaylistTalentsInput;
};


export type MutationAddUsersToNetworkArgs = {
  id: Scalars['ID'];
  input: NetworkUsersInput;
};


export type MutationApplyNetworkWithTalentArgs = {
  input?: Maybe<ApplyNetworkWithTalentInput>;
};


export type MutationCreateNetworkTokenInvitationArgs = {
  userId: Scalars['ID'];
  networkId: Scalars['ID'];
  input: NetworkTokenInvitationInput;
};


export type MutationDeleteAccountArgs = {
  input: DeleteAccountInput;
};


export type MutationDeleteNetworkArgs = {
  id: Scalars['ID'];
};


export type MutationDeletePlaylistArgs = {
  id: Scalars['String'];
};


export type MutationDeleteTalentArgs = {
  talentId: Scalars['ID'];
};


export type MutationDoimailArgs = {
  input: DoimailInput;
};


export type MutationEditNetworkArgs = {
  id: Scalars['ID'];
  input: NetworkInput;
};


export type MutationEditPlaylistArgs = {
  id: Scalars['String'];
  input: PlaylistInput;
};


export type MutationInviteNetworkWithTalentArgs = {
  input?: Maybe<InviteNetworkWithTalentInput>;
};


export type MutationInviteOrApplyToNetworkArgs = {
  input: NetworkInvitationInput;
};


export type MutationJoinNetworkByTokenArgs = {
  input: NetworkTokenInput;
};


export type MutationNetworkLinkImageArgs = {
  input: LinkImageInput;
  networkId: Scalars['String'];
};


export type MutationNetworkUnlinkImageArgs = {
  input: LinkImageInput;
  networkId: Scalars['String'];
};


export type MutationRecoverUsernameArgs = {
  input: RecoverUsernameInput;
  queryParams?: Maybe<Scalars['String']>;
};


export type MutationRegisterArgs = {
  input: RegistrationInput;
};


export type MutationRemoveTalentFromNetworkArgs = {
  id: Scalars['ID'];
  input: NetworkTalentsInput;
};


export type MutationRemoveTalentsFromPlaylistArgs = {
  id: Scalars['String'];
  input: PlaylistTalentsInput;
};


export type MutationRemoveUserFromNetworkArgs = {
  id: Scalars['ID'];
  input: NetworkUsersInput;
};


export type MutationRequestPasswordChangeArgs = {
  input: RequestPasswordChangeInput;
};


export type MutationSavePerformanceVoteArgs = {
  input: PerformanceVoteInput;
};


export type MutationSaveTalentArgs = {
  input: TalentInput;
  classId?: Maybe<Scalars['String']>;
  talentId?: Maybe<Scalars['String']>;
};


export type MutationSaveTalentCustomMetaArgs = {
  input: TalentCustomMetaInput;
  talentId: Scalars['String'];
  metaDataId?: Maybe<Scalars['String']>;
};


export type MutationSaveUserMetaDataArgs = {
  input: UserMetaDataInput;
};


export type MutationShareTalentArgs = {
  input: ShareTalentInput;
};


export type MutationSigninArgs = {
  input: AuthenticationFormInput;
};


export type MutationSigninWithTokenArgs = {
  input: AuthenticationTokenInput;
};


export type MutationTalentJoinMarketplaceArgs = {
  input: Scalars['ID'];
};


export type MutationTalentLinkImageArgs = {
  input: LinkImageInput;
  talentId: Scalars['String'];
};


export type MutationTalentLinkUserArgs = {
  input: LinkUserInput;
  talentId: Scalars['String'];
};


export type MutationTalentUnlinkImageArgs = {
  input: LinkImageInput;
  talentId: Scalars['String'];
};


export type MutationToggleModArgs = {
  id: Scalars['String'];
  networkId: Scalars['String'];
  idUserTarget: Scalars['String'];
  input: Scalars['String'];
};


export type MutationTransferOwnerArgs = {
  id: Scalars['String'];
  networkId: Scalars['String'];
  idUserTarget: Scalars['String'];
  input: Scalars['String'];
};


export type MutationUpdatePasswordArgs = {
  input: PasswordInput;
  userId: Scalars['String'];
};


export type MutationUpdateUserArgs = {
  input: UserInput;
  userId: Scalars['String'];
};


export type MutationUpdateVoteArgs = {
  id: Scalars['ID'];
  input: VoteInput;
};


export type MutationUploadImageArgs = {
  input: Scalars['String'];
};


export type MutationUserFollowArgs = {
  input: FollowUserInput;
  userId: Scalars['String'];
  method: Scalars['String'];
};


export type MutationUserJoinMarketplaceArgs = {
  input: Scalars['ID'];
};


export type MutationUserLinkImageArgs = {
  input: LinkImageInput;
  userId: Scalars['String'];
};


export type MutationUserUnlinkImageArgs = {
  input: LinkImageInput;
  userId: Scalars['String'];
};


export type MutationVoteArgs = {
  talentId: Scalars['ID'];
  input: VoteInput;
};

export type MyPlaceTalent = {
  __typename?: 'MyPlaceTalent';
  talent: Talent;
  network: Network;
};

export type MySpaceTalentsResponse = {
  __typename?: 'MySpaceTalentsResponse';
  meta: Meta;
  collection: Array<MyPlaceTalent>;
};

export type Network = {
  __typename?: 'Network';
  id: Scalars['ID'];
  caption: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  owner?: Maybe<Scalars['String']>;
  public?: Maybe<Scalars['Boolean']>;
  open?: Maybe<Scalars['Boolean']>;
  bugTrackerEnabled?: Maybe<Scalars['Boolean']>;
  networkRoles?: Maybe<Array<Maybe<NetworkRole>>>;
  image?: Maybe<Scalars['String']>;
  asset?: Maybe<Scalars['String']>;
};

export type NetworkApplyAnswerInput = {
  status?: Maybe<NetworkInvitationStatusResponse>;
};

export type NetworkInput = {
  caption: Scalars['String'];
  description: Scalars['String'];
  public?: Maybe<Scalars['Boolean']>;
  open?: Maybe<Scalars['Boolean']>;
};

export type NetworkInvitation = {
  __typename?: 'NetworkInvitation';
  id: Scalars['ID'];
  networkId?: Maybe<Scalars['String']>;
  network?: Maybe<Network>;
  targetUser?: Maybe<User>;
  targetUserId?: Maybe<Scalars['String']>;
  type?: Maybe<NetworkInvitationType>;
  message?: Maybe<Scalars['String']>;
  status?: Maybe<NetworkInvitationStatus>;
};

export type NetworkInvitationInput = {
  networkId?: Maybe<Scalars['String']>;
  targetUserId?: Maybe<Scalars['String']>;
  type?: Maybe<NetworkInvitationType>;
  message?: Maybe<Scalars['String']>;
};

export enum NetworkInvitationStatus {
  Pending = 'pending',
  Accepted = 'accepted',
  Declined = 'declined'
}

export enum NetworkInvitationStatusResponse {
  Accepted = 'accepted',
  Declined = 'declined'
}

export type NetworkInvitationToken = {
  __typename?: 'NetworkInvitationToken';
  email: Scalars['String'];
  token: Scalars['String'];
};

export enum NetworkInvitationType {
  Invitation = 'invitation',
  Application = 'application'
}

export enum NetworkRole {
  Owner = 'owner',
  Mod = 'mod',
  Member = 'member',
  None = 'none'
}

export enum NetworkState {
  InNetwork = 'in_network',
  NotInNetwork = 'not_in_network'
}

export type NetworkTalentsInput = {
  _links: TalentLink;
};

export type NetworkTalentsResponse = {
  __typename?: 'NetworkTalentsResponse';
  collection: Array<Talent>;
  meta: Meta;
};

export type NetworkTokenInput = {
  token: Scalars['String'];
};

export type NetworkTokenInvitation = {
  __typename?: 'NetworkTokenInvitation';
  type: Scalars['String'];
  tokens: Array<NetworkInvitationToken>;
  user: User;
  network: Network;
  networkId: Scalars['String'];
  userId: Scalars['String'];
};

export type NetworkTokenInvitationInput = {
  type: Scalars['String'];
  recipients: Array<Recipient>;
  withObject?: Maybe<Scalars['Boolean']>;
};

export type NetworkUsersInput = {
  _links: UserLink;
};

export type NetworkUsersResponse = {
  __typename?: 'NetworkUsersResponse';
  collection: Array<User>;
  meta: Meta;
};

export type NetworkWithUsers = {
  __typename?: 'NetworkWithUsers';
  id: Scalars['ID'];
  caption: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  public?: Maybe<Scalars['Boolean']>;
  open?: Maybe<Scalars['Boolean']>;
  bugTrackerEnabled?: Maybe<Scalars['Boolean']>;
  networkRoles?: Maybe<Array<Maybe<NetworkRole>>>;
  image?: Maybe<Scalars['String']>;
  users?: Maybe<NetworkUsersResponse>;
};


export type NetworkWithUsersUsersArgs = {
  uId: Scalars['ID'];
};

export type NetworkWithUsersResponse = {
  __typename?: 'NetworkWithUsersResponse';
  collection: Array<NetworkWithUsers>;
  meta: Meta;
};

export type NetworksResponse = {
  __typename?: 'NetworksResponse';
  collection: Array<Network>;
  meta: Meta;
};

export type PasswordInput = {
  password: Scalars['String'];
  oldPassword: Scalars['String'];
};

export type PerformanceVote = {
  __typename?: 'PerformanceVote';
  talentId: Scalars['ID'];
  public?: Maybe<Scalars['Int']>;
  publicDetail?: Maybe<Array<Scalars['Int']>>;
  private?: Maybe<Array<Scalars['Int']>>;
  privateCount: Scalars['Int'];
  publicCount: Scalars['Int'];
};

export type PerformanceVoteInput = {
  talentId: Scalars['ID'];
  networkId: Scalars['String'];
  vote: Array<Maybe<Scalars['Int']>>;
};

export enum PermissionLevel {
  None = 'NONE',
  Administrative = 'ADMINISTRATIVE',
  Personal = 'PERSONAL'
}

export type Playlist = {
  __typename?: 'Playlist';
  id: Scalars['ID'];
  caption: Scalars['String'];
  public: Scalars['Boolean'];
  user: Scalars['String'];
  latestObject?: Maybe<LatestObject>;
};

export type PlaylistInput = {
  caption?: Maybe<Scalars['String']>;
  public?: Maybe<Scalars['String']>;
};

export type PlaylistTalentLink = {
  objectByPlaylist: UrlArray;
};

export type PlaylistTalentResponse = {
  __typename?: 'PlaylistTalentResponse';
  collection: Array<Talent>;
  meta: Meta;
};

export type PlaylistTalentsInput = {
  _links: PlaylistTalentLink;
};

export type PlaylistsResponse = {
  __typename?: 'PlaylistsResponse';
  collection: Array<Playlist>;
  meta: Meta;
};

export type Query = {
  __typename?: 'Query';
  activities: ActivitiesResponse;
  activitylevel: Scalars['Int'];
  followed?: Maybe<UserCollection>;
  follower?: Maybe<UserCollection>;
  isTalentInNetwork: InNetworkState;
  jmtNetworks: JmtNetworkResponse;
  listMarketplaceTalents: NetworkTalentsResponse;
  listMyPersonifications: NetworkTalentsResponse;
  listMyTalents: NetworkTalentsResponse;
  myspace: Network;
  myspaceTalents: MySpaceTalentsResponse;
  network: Network;
  networkFriends: NetworkUsersResponse;
  networkTalents: NetworkTalentsResponse;
  networkUsers: NetworkUsersResponse;
  networks: NetworksResponse;
  networksWithUsers: NetworkWithUsersResponse;
  newActivities: Array<Activity>;
  performanceVote: PerformanceVote;
  playlist: Playlist;
  playlistTalents: PlaylistTalentResponse;
  playlists: PlaylistsResponse;
  roleModelIds: Array<Scalars['Int']>;
  searchNetwork: SearchNetworkCollection;
  searchNetworkTalents: NetworkTalentsResponse;
  searchTalent: SearchTalent;
  searchUser: SearchUserCollection;
  sharedTalent: SharedTalentResponse;
  similar: NetworkTalentsResponse;
  similarNetwork: NetworkTalentsResponse;
  suggestTalent: Array<SuggestUser>;
  suggestUser: Array<SuggestUser>;
  talent: Talent;
  talentClass: TalentClass;
  talentLatestVote?: Maybe<Vote>;
  talentLayer?: Maybe<Array<Layer>>;
  user?: Maybe<User>;
  userMetaData?: Maybe<UserMetaDataResponse>;
  userPersonification: NetworkTalentsResponse;
  userStatus: UserStatusResponse;
  whoami: Authentication;
};


export type QueryActivitiesArgs = {
  queryParams?: Maybe<Scalars['String']>;
};


export type QueryActivitylevelArgs = {
  userId: Scalars['ID'];
};


export type QueryFollowedArgs = {
  userId: Scalars['ID'];
  queryParams?: Maybe<Scalars['String']>;
};


export type QueryFollowerArgs = {
  userId: Scalars['ID'];
  queryParams?: Maybe<Scalars['String']>;
};


export type QueryIsTalentInNetworkArgs = {
  userId: Scalars['String'];
  talentId: Scalars['ID'];
  networkId: Scalars['ID'];
};


export type QueryListMarketplaceTalentsArgs = {
  userId: Scalars['ID'];
  queryParams?: Maybe<Scalars['String']>;
};


export type QueryListMyPersonificationsArgs = {
  userId: Scalars['ID'];
  queryParams?: Maybe<Scalars['String']>;
};


export type QueryListMyTalentsArgs = {
  userId: Scalars['ID'];
  queryParams?: Maybe<Scalars['String']>;
};


export type QueryMyspaceArgs = {
  userId: Scalars['ID'];
};


export type QueryMyspaceTalentsArgs = {
  query: Scalars['String'];
  queryParams?: Maybe<Scalars['String']>;
};


export type QueryNetworkArgs = {
  userId: Scalars['ID'];
  networkId: Scalars['ID'];
};


export type QueryNetworkFriendsArgs = {
  userId: Scalars['String'];
  networkId: Scalars['String'];
  queryParams?: Maybe<Scalars['String']>;
};


export type QueryNetworkTalentsArgs = {
  userId: Scalars['ID'];
  networkId: Scalars['ID'];
  queryParams?: Maybe<Scalars['String']>;
};


export type QueryNetworkUsersArgs = {
  userId: Scalars['ID'];
  networkId: Scalars['ID'];
  queryParams?: Maybe<Scalars['String']>;
};


export type QueryNetworksArgs = {
  userId: Scalars['ID'];
  search?: Maybe<Scalars['String']>;
  queryParams?: Maybe<Scalars['String']>;
};


export type QueryNetworksWithUsersArgs = {
  userId: Scalars['ID'];
  search?: Maybe<Scalars['String']>;
  queryParams?: Maybe<Scalars['String']>;
};


export type QueryNewActivitiesArgs = {
  isoDate: Scalars['String'];
};


export type QueryPerformanceVoteArgs = {
  talentId: Scalars['ID'];
  network?: Maybe<Scalars['String']>;
};


export type QueryPlaylistArgs = {
  userId: Scalars['String'];
  id: Scalars['String'];
};


export type QueryPlaylistTalentsArgs = {
  userId: Scalars['String'];
  id: Scalars['ID'];
  queryParams?: Maybe<Scalars['String']>;
};


export type QueryPlaylistsArgs = {
  userId: Scalars['String'];
  queryParams?: Maybe<Scalars['String']>;
};


export type QuerySearchNetworkArgs = {
  query: Scalars['String'];
  sort?: Maybe<Scalars['String']>;
  queryParams?: Maybe<Scalars['String']>;
};


export type QuerySearchNetworkTalentsArgs = {
  userId: Scalars['String'];
  query: Scalars['String'];
  networkId: Scalars['String'];
  queryParams?: Maybe<Scalars['String']>;
};


export type QuerySearchTalentArgs = {
  query: Scalars['String'];
  sort: Scalars['String'];
  classId?: Maybe<Scalars['String']>;
  fifaCountry?: Maybe<Scalars['String']>;
  fieldPosition?: Maybe<Scalars['String']>;
  yearFrom?: Maybe<Scalars['String']>;
  yearTo?: Maybe<Scalars['String']>;
  age?: Maybe<Scalars['String']>;
  networkId?: Maybe<Scalars['String']>;
  queryParams?: Maybe<Scalars['String']>;
};


export type QuerySearchUserArgs = {
  query: Scalars['String'];
  meId: Scalars['String'];
  networkId: Scalars['String'];
  sort?: Maybe<Scalars['String']>;
  queryParams?: Maybe<Scalars['String']>;
};


export type QuerySharedTalentArgs = {
  hash?: Maybe<Scalars['ID']>;
};


export type QuerySimilarArgs = {
  userId?: Maybe<Scalars['String']>;
  talentId?: Maybe<Scalars['String']>;
  queryString?: Maybe<Scalars['String']>;
  queryParams?: Maybe<Scalars['String']>;
};


export type QuerySimilarNetworkArgs = {
  queryString?: Maybe<Scalars['String']>;
  userId: Scalars['ID'];
  networkId: Scalars['ID'];
  queryParams?: Maybe<Scalars['String']>;
};


export type QuerySuggestTalentArgs = {
  q?: Maybe<Scalars['String']>;
};


export type QuerySuggestUserArgs = {
  q: Scalars['String'];
};


export type QueryTalentArgs = {
  userId: Scalars['ID'];
  talentId: Scalars['ID'];
};


export type QueryTalentClassArgs = {
  id: Scalars['ID'];
};


export type QueryTalentLatestVoteArgs = {
  userId: Scalars['ID'];
  talentId: Scalars['ID'];
};


export type QueryTalentLayerArgs = {
  contextId: Scalars['ID'];
  talentId: Scalars['ID'];
  layerParams?: Maybe<Scalars['String']>;
};


export type QueryUserArgs = {
  userId: Scalars['ID'];
  meId: Scalars['ID'];
  networkId: Scalars['ID'];
};


export type QueryUserMetaDataArgs = {
  userId: Scalars['ID'];
};


export type QueryUserPersonificationArgs = {
  userId: Scalars['ID'];
  queryParams?: Maybe<Scalars['String']>;
};


export type QueryUserStatusArgs = {
  userId: Scalars['ID'];
};

export type Range = {
  __typename?: 'Range';
  from: Scalars['Int'];
  to: Scalars['Int'];
  total: Scalars['Int'];
};

export type Recipient = {
  email: Scalars['String'];
};

export type RecoverUsernameInput = {
  email: Scalars['String'];
};

export type RegistrationInput = {
  name: Scalars['String'];
  password: Scalars['String'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
};

export type RequestPasswordChangeInput = {
  name: Scalars['String'];
  password: Scalars['String'];
};

export type RequestPasswordChangeResponse = {
  __typename?: 'RequestPasswordChangeResponse';
  token: Scalars['String'];
  user: User;
};

export type RoleModelInput = {
  roleModelId: Scalars['Int'];
};

export enum Salutation {
  Male = 'male',
  Female = 'female'
}

export type Scalar = {
  __typename?: 'Scalar';
  caption: Scalars['String'];
  formula: Scalars['Boolean'];
  offset: Scalars['Int'];
};

export type SearchNetworkCollection = {
  __typename?: 'SearchNetworkCollection';
  meta: Meta;
  collection: Array<Network>;
};

export type SearchTalent = {
  __typename?: 'SearchTalent';
  collection: Array<Talent>;
  meta: Meta;
};

export type SearchUserCollection = {
  __typename?: 'SearchUserCollection';
  meta: Meta;
  collection: Array<User>;
};

export type ShareTalentInput = {
  talentId: Scalars['ID'];
  roleModel?: Maybe<Scalars['Boolean']>;
};

export type ShareTalentResponse = {
  __typename?: 'ShareTalentResponse';
  publicToken: Scalars['String'];
};

export type SharedTalentResponse = {
  __typename?: 'SharedTalentResponse';
  talent: Talent;
  roleModel?: Maybe<Talent>;
  sharedRoleModel: Scalars['Boolean'];
};

export type SuggestUser = {
  __typename?: 'SuggestUser';
  name: Scalars['String'];
};

export type Talent = {
  __typename?: 'Talent';
  id: Scalars['ID'];
  caption: Scalars['String'];
  dataViews: Array<TalentDataView>;
  image?: Maybe<Scalars['String']>;
  imageSource?: Maybe<Scalars['String']>;
  metas: TalentMeta;
  customMeta?: Maybe<TalentCustomMeta>;
  playlistCount?: Maybe<Scalars['Int']>;
  public?: Maybe<Scalars['Boolean']>;
  rateCount?: Maybe<Scalars['Int']>;
  tm_id?: Maybe<Scalars['Int']>;
  permissionLevel: PermissionLevel;
  profileId?: Maybe<Scalars['String']>;
  personification?: Maybe<Scalars['Boolean']>;
  asset?: Maybe<Scalars['String']>;
};

export type TalentClass = {
  __typename?: 'TalentClass';
  id: Scalars['ID'];
  dataViews: Array<TalentClassDataView>;
};

export type TalentClassDataView = {
  __typename?: 'TalentClassDataView';
  id: Scalars['ID'];
  caption: Scalars['String'];
  chartType: ChartType;
  withGroupLabels: Scalars['Boolean'];
  withLabelHover: Scalars['Boolean'];
  voteScalarGroups: Array<VoteScalarGroup>;
};

export type TalentCustomMeta = {
  __typename?: 'TalentCustomMeta';
  id?: Maybe<Scalars['ID']>;
  foot?: Maybe<Scalars['String']>;
  club?: Maybe<Scalars['String']>;
  team?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['Int']>;
  weight?: Maybe<Scalars['Int']>;
  rolemodelid?: Maybe<Scalars['Int']>;
  topskillsindexes?: Maybe<Array<Maybe<Scalars['Int']>>>;
  status?: Maybe<Scalars['String']>;
  salutation?: Maybe<Salutation>;
};

export type TalentCustomMetaInput = {
  id?: Maybe<Scalars['ID']>;
  foot?: Maybe<Scalars['String']>;
  club?: Maybe<Scalars['String']>;
  team?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['Int']>;
  weight?: Maybe<Scalars['Int']>;
  rolemodelid?: Maybe<Scalars['Int']>;
  topskillsindexes?: Maybe<Array<Maybe<Scalars['Int']>>>;
  status?: Maybe<Scalars['String']>;
  salutation?: Maybe<Salutation>;
};

export type TalentDataView = {
  __typename?: 'TalentDataView';
  key: Scalars['Int'];
  values: Array<Scalars['Int']>;
};

export type TalentInput = {
  caption: Scalars['String'];
  autoProfile?: Maybe<Scalars['Boolean']>;
  networkId?: Maybe<Scalars['String']>;
  autoPublicSelf?: Maybe<Scalars['Boolean']>;
  metas: TalentMetaInput;
};

export type TalentLink = {
  objectByNetwork: UrlArray;
};

export type TalentMeta = {
  __typename?: 'TalentMeta';
  fifaCountry: Scalars['String'];
  date: Scalars['String'];
  fieldPosition: TalentPosition;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
};

export type TalentMetaInput = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  date: Scalars['String'];
  fieldPosition?: Maybe<TalentPosition>;
  fifaCountry: Scalars['String'];
};

export enum TalentPosition {
  Offensive = 'offensive',
  Midfield = 'midfield',
  Defensive = 'defensive',
  Goal = 'goal'
}

export type UrlArray = {
  url: Array<Maybe<Scalars['String']>>;
};

export type UrlInput = {
  url: Scalars['String'];
};

export type User = {
  __typename?: 'User';
  id: Scalars['ID'];
  name: Scalars['String'];
  status?: Maybe<Scalars['String']>;
  public: Scalars['Boolean'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  networkRoles?: Maybe<Array<NetworkRole>>;
  image?: Maybe<Scalars['String']>;
  _links?: Maybe<UserLinks>;
  isFriend?: Maybe<Scalars['Boolean']>;
  followers?: Maybe<UserCollection>;
  followed?: Maybe<UserCollection>;
  userMetaData?: Maybe<UserMetaDataResponse>;
  asset?: Maybe<Scalars['String']>;
};

export type UserBehavior = {
  __typename?: 'UserBehavior';
  termsAcceptedAt: Scalars['String'];
  termsVersionAccepted: Scalars['Int'];
  onboardingDone: Array<Scalars['String']>;
  preferredPerformanceView?: Maybe<Scalars['String']>;
};

export type UserBehaviorInput = {
  termsAcceptedAt: Scalars['String'];
  termsVersionAccepted: Scalars['Int'];
  onboardingDone: Array<Scalars['String']>;
  preferredPerformanceView?: Maybe<Scalars['String']>;
};

export type UserCollection = {
  __typename?: 'UserCollection';
  collection: Array<User>;
  meta: Meta;
};

export type UserInput = {
  password?: Maybe<Scalars['String']>;
  newPassword?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  public?: Maybe<Scalars['Boolean']>;
};

export type UserLink = {
  userByNetwork: UrlArray;
};

export type UserLinkInput = {
  profile: UrlInput;
};

export type UserLinks = {
  __typename?: 'UserLinks';
  followers?: Maybe<Link>;
  followed_users?: Maybe<Link>;
};

export type UserMetaData = {
  __typename?: 'UserMetaData';
  username: Scalars['String'];
  birthdate: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  behavior?: Maybe<UserBehavior>;
  country: Scalars['String'];
  zip: Scalars['String'];
  city: Scalars['String'];
  street?: Maybe<Scalars['String']>;
  userType?: Maybe<Array<Maybe<UserType>>>;
  experience?: Maybe<Array<Maybe<Experience>>>;
  club?: Maybe<Scalars['String']>;
  team?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['Int']>;
  ageCategory?: Maybe<Array<Maybe<AgeCategory>>>;
  aboutMe?: Maybe<Scalars['String']>;
  termsAccepted?: Maybe<Scalars['Boolean']>;
  termsAcceptedAt?: Maybe<Scalars['String']>;
  activitiesLastViewed?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  salutation?: Maybe<Salutation>;
};

export type UserMetaDataCollection = {
  __typename?: 'UserMetaDataCollection';
  meta: Meta;
  collection?: Maybe<Array<CurrentUser>>;
};

export type UserMetaDataInput = {
  username: Scalars['ID'];
  birthdate: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  behavior?: Maybe<UserBehaviorInput>;
  country: Scalars['String'];
  zip: Scalars['String'];
  city: Scalars['String'];
  street?: Maybe<Scalars['String']>;
  userType?: Maybe<Array<Maybe<UserType>>>;
  experience?: Maybe<Array<Maybe<Experience>>>;
  club?: Maybe<Scalars['String']>;
  team?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['Int']>;
  ageCategory?: Maybe<Array<Maybe<AgeCategory>>>;
  aboutMe?: Maybe<Scalars['String']>;
  activitiesLastViewed?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  salutation?: Maybe<Salutation>;
};

export type UserMetaDataResponse = {
  __typename?: 'UserMetaDataResponse';
  user?: Maybe<CurrentUser>;
};

export type UserMetaDataSaveResponse = {
  __typename?: 'UserMetaDataSaveResponse';
  current_user?: Maybe<CurrentUser>;
};

export type UserStatusResponse = {
  __typename?: 'UserStatusResponse';
  selfPlayer?: Maybe<Scalars['String']>;
  selfUser?: Maybe<Scalars['ID']>;
  selfNetwork?: Maybe<Scalars['String']>;
  jmtNetworks?: Maybe<Array<Scalars['String']>>;
};

export enum UserType {
  Player = 'player',
  Trainer = 'trainer',
  Supervisor = 'supervisor',
  Scout = 'scout',
  Relatives = 'relatives',
  Other = 'other'
}

export type Vote = {
  __typename?: 'Vote';
  id: Scalars['ID'];
  cc?: Maybe<Scalars['String']>;
  object: Talent;
  qlty?: Maybe<Scalars['String']>;
  dataViews: Array<Scalars['Int']>;
  date: Scalars['Int'];
  values: Array<Scalars['Int']>;
};

export type VoteInput = {
  values: Array<Maybe<Scalars['Int']>>;
  networkId?: Maybe<Scalars['ID']>;
};

export type VoteScalarGroup = {
  __typename?: 'VoteScalarGroup';
  caption: Scalars['String'];
  offset: Scalars['Int'];
  scalars: Array<Scalar>;
};

export type SigninMutationVariables = Exact<{
  input: AuthenticationFormInput;
}>;


export type SigninMutation = (
  { __typename?: 'Mutation' }
  & { signin?: Maybe<(
    { __typename?: 'Authentication' }
    & Pick<Authentication, 'token'>
    & { user: (
      { __typename?: 'User' }
      & UserFragment
    ) }
  )> }
);

export type SigninWithTokenMutationVariables = Exact<{
  input: AuthenticationTokenInput;
}>;


export type SigninWithTokenMutation = (
  { __typename?: 'Mutation' }
  & { signinWithToken: (
    { __typename?: 'Authentication' }
    & Pick<Authentication, 'token'>
    & { user: (
      { __typename?: 'User' }
      & UserFragment
    ) }
  ) }
);

export type RegisterMutationVariables = Exact<{
  input: RegistrationInput;
}>;


export type RegisterMutation = (
  { __typename?: 'Mutation' }
  & { register: (
    { __typename?: 'Authentication' }
    & Pick<Authentication, 'token'>
    & { user: (
      { __typename?: 'User' }
      & UserFragment
    ) }
  ) }
);

export type DoimailMutationVariables = Exact<{
  input: DoimailInput;
}>;


export type DoimailMutation = (
  { __typename?: 'Mutation' }
  & { doimail: (
    { __typename?: 'Message' }
    & Pick<Message, 'message'>
  ) }
);

export type WhoamiQueryVariables = Exact<{ [key: string]: never; }>;


export type WhoamiQuery = (
  { __typename?: 'Query' }
  & { whoami: (
    { __typename?: 'Authentication' }
    & Pick<Authentication, 'token'>
    & { user: (
      { __typename?: 'User' }
      & UserFragment
    ) }
  ) }
);

export type UploadImageMutationVariables = Exact<{
  input: Scalars['String'];
}>;


export type UploadImageMutation = (
  { __typename?: 'Mutation' }
  & { uploadImage: (
    { __typename?: 'FileUploadResponse' }
    & Pick<FileUploadResponse, 'id' | 'token' | 'url' | 'user' | '_created' | '_updated'>
    & { _links: (
      { __typename?: 'Links' }
      & { self: (
        { __typename?: 'Link' }
        & Pick<Link, 'rel' | 'url'>
      ) }
    ) }
  ) }
);

export type PlaylistFragment = (
  { __typename?: 'Playlist' }
  & Pick<Playlist, 'id' | 'caption' | 'public' | 'user'>
  & { latestObject?: Maybe<(
    { __typename?: 'LatestObject' }
    & Pick<LatestObject, 'image'>
  )> }
);

export type NetworkFragment = (
  { __typename?: 'Network' }
  & Pick<Network, 'id' | 'caption' | 'description' | 'owner' | 'public' | 'open' | 'bugTrackerEnabled' | 'networkRoles' | 'image' | 'asset'>
);

export type NetworkSearchResultFragment = (
  { __typename?: 'Network' }
  & Pick<Network, 'id' | 'caption' | 'description' | 'owner' | 'public' | 'open' | 'bugTrackerEnabled' | 'image'>
);

export type JmtNetworkFragment = (
  { __typename?: 'Network' }
  & Pick<Network, 'id' | 'caption' | 'description' | 'public' | 'open' | 'bugTrackerEnabled' | 'networkRoles'>
);

export type NetworksOfUserQueryVariables = Exact<{
  userId: Scalars['ID'];
  search?: Maybe<Scalars['String']>;
  queryParams?: Maybe<Scalars['String']>;
}>;


export type NetworksOfUserQuery = (
  { __typename?: 'Query' }
  & { networks: (
    { __typename?: 'NetworksResponse' }
    & { collection: Array<(
      { __typename?: 'Network' }
      & NetworkFragment
    )>, meta: (
      { __typename?: 'Meta' }
      & { range?: Maybe<(
        { __typename?: 'Range' }
        & Pick<Range, 'from' | 'to' | 'total'>
      )>, sortOptions?: Maybe<Array<(
        { __typename?: 'MapEntry' }
        & Pick<MapEntry, 'key' | 'value'>
      )>> }
    ) }
  ) }
);

export type NetworksQueryVariables = Exact<{
  userId: Scalars['ID'];
  search?: Maybe<Scalars['String']>;
  queryParams?: Maybe<Scalars['String']>;
}>;


export type NetworksQuery = (
  { __typename?: 'Query' }
  & { networks: (
    { __typename?: 'NetworksResponse' }
    & { collection: Array<(
      { __typename?: 'Network' }
      & NetworkFragment
    )>, meta: (
      { __typename?: 'Meta' }
      & { range?: Maybe<(
        { __typename?: 'Range' }
        & Pick<Range, 'from' | 'to' | 'total'>
      )>, sortOptions?: Maybe<Array<(
        { __typename?: 'MapEntry' }
        & Pick<MapEntry, 'key' | 'value'>
      )>> }
    ) }
  ) }
);

export type NetworkQueryVariables = Exact<{
  userId: Scalars['ID'];
  networkId: Scalars['ID'];
}>;


export type NetworkQuery = (
  { __typename?: 'Query' }
  & { network: (
    { __typename?: 'Network' }
    & NetworkFragment
  ) }
);

export type PlaylistTalentsQueryVariables = Exact<{
  userId: Scalars['String'];
  id: Scalars['ID'];
  queryParams?: Maybe<Scalars['String']>;
}>;


export type PlaylistTalentsQuery = (
  { __typename?: 'Query' }
  & { playlistTalents: (
    { __typename?: 'PlaylistTalentResponse' }
    & { collection: Array<(
      { __typename?: 'Talent' }
      & TalentFragment
    )>, meta: (
      { __typename?: 'Meta' }
      & { range?: Maybe<(
        { __typename?: 'Range' }
        & Pick<Range, 'from' | 'to' | 'total'>
      )>, sortOptions?: Maybe<Array<(
        { __typename?: 'MapEntry' }
        & Pick<MapEntry, 'key' | 'value'>
      )>> }
    ) }
  ) }
);

export type PlaylistQueryVariables = Exact<{
  userId: Scalars['String'];
  id: Scalars['String'];
}>;


export type PlaylistQuery = (
  { __typename?: 'Query' }
  & { playlist: (
    { __typename?: 'Playlist' }
    & PlaylistFragment
  ) }
);

export type PlaylistsQueryVariables = Exact<{
  userId: Scalars['String'];
  queryParams?: Maybe<Scalars['String']>;
}>;


export type PlaylistsQuery = (
  { __typename?: 'Query' }
  & { playlists: (
    { __typename?: 'PlaylistsResponse' }
    & { collection: Array<(
      { __typename?: 'Playlist' }
      & PlaylistFragment
    )>, meta: (
      { __typename?: 'Meta' }
      & { range?: Maybe<(
        { __typename?: 'Range' }
        & Pick<Range, 'from' | 'to' | 'total'>
      )>, sortOptions?: Maybe<Array<(
        { __typename?: 'MapEntry' }
        & Pick<MapEntry, 'key' | 'value'>
      )>> }
    ) }
  ) }
);

export type AddPlaylistMutationVariables = Exact<{
  input: PlaylistInput;
}>;


export type AddPlaylistMutation = (
  { __typename?: 'Mutation' }
  & { addPlaylist: (
    { __typename?: 'Playlist' }
    & PlaylistFragment
  ) }
);

export type EditPlaylistMutationVariables = Exact<{
  id: Scalars['String'];
  input: PlaylistInput;
}>;


export type EditPlaylistMutation = (
  { __typename?: 'Mutation' }
  & { editPlaylist: (
    { __typename?: 'Playlist' }
    & PlaylistFragment
  ) }
);

export type DeletePlaylistMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeletePlaylistMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deletePlaylist'>
);

export type AddTalentsToPlaylistMutationVariables = Exact<{
  id: Scalars['String'];
  talents: PlaylistTalentsInput;
}>;


export type AddTalentsToPlaylistMutation = (
  { __typename?: 'Mutation' }
  & { addTalentsToPlaylist: (
    { __typename?: 'Playlist' }
    & PlaylistFragment
  ) }
);

export type RemoveTalentsFromPlaylistMutationVariables = Exact<{
  id: Scalars['String'];
  talents: PlaylistTalentsInput;
}>;


export type RemoveTalentsFromPlaylistMutation = (
  { __typename?: 'Mutation' }
  & { removeTalentsFromPlaylist: (
    { __typename?: 'Playlist' }
    & PlaylistFragment
  ) }
);

export type IsTalentInNetworkQueryVariables = Exact<{
  userId: Scalars['String'];
  talentId: Scalars['ID'];
  networkId: Scalars['ID'];
}>;


export type IsTalentInNetworkQuery = (
  { __typename?: 'Query' }
  & { isTalentInNetwork: (
    { __typename?: 'InNetworkState' }
    & Pick<InNetworkState, 'state'>
  ) }
);

export type MyspaceQueryVariables = Exact<{
  userId: Scalars['ID'];
}>;


export type MyspaceQuery = (
  { __typename?: 'Query' }
  & { myspace: (
    { __typename?: 'Network' }
    & NetworkFragment
  ) }
);

export type MyspaceTalentsQueryVariables = Exact<{
  query: Scalars['String'];
  queryParams?: Maybe<Scalars['String']>;
}>;


export type MyspaceTalentsQuery = (
  { __typename?: 'Query' }
  & { myspaceTalents: (
    { __typename?: 'MySpaceTalentsResponse' }
    & { meta: (
      { __typename?: 'Meta' }
      & { range?: Maybe<(
        { __typename?: 'Range' }
        & Pick<Range, 'from' | 'to' | 'total'>
      )>, sortOptions?: Maybe<Array<(
        { __typename?: 'MapEntry' }
        & Pick<MapEntry, 'key' | 'value'>
      )>> }
    ), collection: Array<(
      { __typename?: 'MyPlaceTalent' }
      & { network: (
        { __typename?: 'Network' }
        & NetworkFragment
      ), talent: (
        { __typename?: 'Talent' }
        & TalentFragment
      ) }
    )> }
  ) }
);

export type NetworkUsersQueryVariables = Exact<{
  userId: Scalars['ID'];
  networkId: Scalars['ID'];
  queryParams?: Maybe<Scalars['String']>;
}>;


export type NetworkUsersQuery = (
  { __typename?: 'Query' }
  & { networkUsers: (
    { __typename?: 'NetworkUsersResponse' }
    & { collection: Array<(
      { __typename?: 'User' }
      & UserFragment
    )>, meta: (
      { __typename?: 'Meta' }
      & { range?: Maybe<(
        { __typename?: 'Range' }
        & Pick<Range, 'from' | 'to' | 'total'>
      )>, sortOptions?: Maybe<Array<(
        { __typename?: 'MapEntry' }
        & Pick<MapEntry, 'key' | 'value'>
      )>> }
    ) }
  ) }
);

export type NetworkFriendsQueryVariables = Exact<{
  userId: Scalars['String'];
  networkId: Scalars['String'];
  queryParams?: Maybe<Scalars['String']>;
}>;


export type NetworkFriendsQuery = (
  { __typename?: 'Query' }
  & { networkFriends: (
    { __typename?: 'NetworkUsersResponse' }
    & { collection: Array<(
      { __typename?: 'User' }
      & UserFragment
    )>, meta: (
      { __typename?: 'Meta' }
      & { range?: Maybe<(
        { __typename?: 'Range' }
        & Pick<Range, 'from' | 'to' | 'total'>
      )>, sortOptions?: Maybe<Array<(
        { __typename?: 'MapEntry' }
        & Pick<MapEntry, 'key' | 'value'>
      )>> }
    ) }
  ) }
);

export type NetworkTalentsQueryVariables = Exact<{
  userId: Scalars['ID'];
  networkId: Scalars['ID'];
  queryParams?: Maybe<Scalars['String']>;
}>;


export type NetworkTalentsQuery = (
  { __typename?: 'Query' }
  & { networkTalents: (
    { __typename?: 'NetworkTalentsResponse' }
    & { collection: Array<(
      { __typename?: 'Talent' }
      & TalentFragment
    )>, meta: (
      { __typename?: 'Meta' }
      & { range?: Maybe<(
        { __typename?: 'Range' }
        & Pick<Range, 'from' | 'to' | 'total'>
      )>, sortOptions?: Maybe<Array<(
        { __typename?: 'MapEntry' }
        & Pick<MapEntry, 'key' | 'value'>
      )>> }
    ) }
  ) }
);

export type SearchNetworkTalentsQueryVariables = Exact<{
  userId: Scalars['String'];
  query: Scalars['String'];
  networkId: Scalars['String'];
}>;


export type SearchNetworkTalentsQuery = (
  { __typename?: 'Query' }
  & { searchNetworkTalents: (
    { __typename?: 'NetworkTalentsResponse' }
    & { collection: Array<(
      { __typename?: 'Talent' }
      & TalentFragment
    )>, meta: (
      { __typename?: 'Meta' }
      & { range?: Maybe<(
        { __typename?: 'Range' }
        & Pick<Range, 'from' | 'to' | 'total'>
      )>, sortOptions?: Maybe<Array<(
        { __typename?: 'MapEntry' }
        & Pick<MapEntry, 'key' | 'value'>
      )>> }
    ) }
  ) }
);

export type AddNetworkMutationVariables = Exact<{
  input: NetworkInput;
}>;


export type AddNetworkMutation = (
  { __typename?: 'Mutation' }
  & { addNetwork: (
    { __typename?: 'Network' }
    & NetworkFragment
  ) }
);

export type EditNetworkMutationVariables = Exact<{
  id: Scalars['ID'];
  input: NetworkInput;
}>;


export type EditNetworkMutation = (
  { __typename?: 'Mutation' }
  & { editNetwork: (
    { __typename?: 'Network' }
    & NetworkFragment
  ) }
);

export type DeleteNetworkMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteNetworkMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteNetwork'>
);

export type AddTalentsToNetworkMutationVariables = Exact<{
  id: Scalars['ID'];
  talents: NetworkTalentsInput;
}>;


export type AddTalentsToNetworkMutation = (
  { __typename?: 'Mutation' }
  & { addTalentsToNetwork: (
    { __typename?: 'Network' }
    & NetworkFragment
  ) }
);

export type AddUsersToNetworkMutationVariables = Exact<{
  id: Scalars['ID'];
  user: NetworkUsersInput;
}>;


export type AddUsersToNetworkMutation = (
  { __typename?: 'Mutation' }
  & { addUsersToNetwork: (
    { __typename?: 'Network' }
    & NetworkFragment
  ) }
);

export type RemoveUserFromNetworkMutationVariables = Exact<{
  id: Scalars['ID'];
  user: NetworkUsersInput;
}>;


export type RemoveUserFromNetworkMutation = (
  { __typename?: 'Mutation' }
  & { removeUserFromNetwork: (
    { __typename?: 'Network' }
    & NetworkFragment
  ) }
);

export type ToggleModMutationVariables = Exact<{
  id: Scalars['String'];
  networkId: Scalars['String'];
  idUserTarget: Scalars['String'];
}>;


export type ToggleModMutation = (
  { __typename?: 'Mutation' }
  & { toggleMod?: Maybe<(
    { __typename?: 'Network' }
    & Pick<Network, 'id'>
  )> }
);

export type TransferOwnerMutationVariables = Exact<{
  id: Scalars['String'];
  networkId: Scalars['String'];
  idUserTarget: Scalars['String'];
}>;


export type TransferOwnerMutation = (
  { __typename?: 'Mutation' }
  & { transferOwner?: Maybe<(
    { __typename?: 'Network' }
    & Pick<Network, 'id'>
  )> }
);

export type RemoveTalentFromNetworkMutationVariables = Exact<{
  id: Scalars['ID'];
  talent: NetworkTalentsInput;
}>;


export type RemoveTalentFromNetworkMutation = (
  { __typename?: 'Mutation' }
  & { removeTalentFromNetwork: (
    { __typename?: 'Network' }
    & NetworkFragment
  ) }
);

export type NetworkLinkImageMutationVariables = Exact<{
  url: Scalars['String'];
  networkId: Scalars['String'];
}>;


export type NetworkLinkImageMutation = (
  { __typename?: 'Mutation' }
  & { networkLinkImage: (
    { __typename?: 'Network' }
    & NetworkFragment
  ) }
);

export type NetworkUnlinkImageMutationVariables = Exact<{
  url: Scalars['String'];
  networkId: Scalars['String'];
}>;


export type NetworkUnlinkImageMutation = (
  { __typename?: 'Mutation' }
  & { networkUnlinkImage: (
    { __typename?: 'Network' }
    & NetworkFragment
  ) }
);

export type SearchNetworkQueryVariables = Exact<{
  query: Scalars['String'];
  sort?: Maybe<Scalars['String']>;
  queryParams?: Maybe<Scalars['String']>;
}>;


export type SearchNetworkQuery = (
  { __typename?: 'Query' }
  & { searchNetwork: (
    { __typename?: 'SearchNetworkCollection' }
    & { meta: (
      { __typename?: 'Meta' }
      & { range?: Maybe<(
        { __typename?: 'Range' }
        & Pick<Range, 'from' | 'to' | 'total'>
      )>, sortOptions?: Maybe<Array<(
        { __typename?: 'MapEntry' }
        & Pick<MapEntry, 'value' | 'key'>
      )>> }
    ), collection: Array<(
      { __typename?: 'Network' }
      & NetworkSearchResultFragment
    )> }
  ) }
);

export type JmtNetworksQueryVariables = Exact<{ [key: string]: never; }>;


export type JmtNetworksQuery = (
  { __typename?: 'Query' }
  & { jmtNetworks: (
    { __typename?: 'JMTNetworkResponse' }
    & { JMTnetworks: (
      { __typename?: 'NetworksResponse' }
      & { collection: Array<(
        { __typename?: 'Network' }
        & JmtNetworkFragment
      )> }
    ) }
  ) }
);

export type RelatedUsersFromMyNetworksQueryVariables = Exact<{
  userId: Scalars['ID'];
  queryParams?: Maybe<Scalars['String']>;
}>;


export type RelatedUsersFromMyNetworksQuery = (
  { __typename?: 'Query' }
  & { networksWithUsers: (
    { __typename?: 'NetworkWithUsersResponse' }
    & { collection: Array<(
      { __typename?: 'NetworkWithUsers' }
      & Pick<NetworkWithUsers, 'id' | 'caption' | 'description' | 'public' | 'open' | 'bugTrackerEnabled' | 'networkRoles' | 'image'>
      & { users?: Maybe<(
        { __typename?: 'NetworkUsersResponse' }
        & { collection: Array<(
          { __typename?: 'User' }
          & Pick<User, 'id'>
          & { userMetaData?: Maybe<(
            { __typename?: 'UserMetaDataResponse' }
            & UserMetadataResponseFragment
          )> }
          & UserFragment
        )> }
      )> }
    )>, meta: (
      { __typename?: 'Meta' }
      & { range?: Maybe<(
        { __typename?: 'Range' }
        & Pick<Range, 'from' | 'to' | 'total'>
      )>, sortOptions?: Maybe<Array<(
        { __typename?: 'MapEntry' }
        & Pick<MapEntry, 'key' | 'value'>
      )>> }
    ) }
  ) }
);

export type InviteOrApplyToNetworkMutationVariables = Exact<{
  input: NetworkInvitationInput;
}>;


export type InviteOrApplyToNetworkMutation = (
  { __typename?: 'Mutation' }
  & { inviteOrApplyToNetwork: (
    { __typename?: 'NetworkInvitation' }
    & Pick<NetworkInvitation, 'networkId' | 'targetUserId' | 'type' | 'message' | 'status'>
    & { network?: Maybe<(
      { __typename?: 'Network' }
      & NetworkFragment
    )>, targetUser?: Maybe<(
      { __typename?: 'User' }
      & UserFragment
    )> }
  ) }
);

export type AcceptOrDeclineNetworkInvitationMutationVariables = Exact<{
  id: Scalars['ID'];
  input: NetworkApplyAnswerInput;
}>;


export type AcceptOrDeclineNetworkInvitationMutation = (
  { __typename?: 'Mutation' }
  & { acceptOrDeclineNetworkInvitation: (
    { __typename?: 'NetworkInvitation' }
    & Pick<NetworkInvitation, 'networkId' | 'targetUserId' | 'type' | 'message' | 'status'>
    & { network?: Maybe<(
      { __typename?: 'Network' }
      & NetworkFragment
    )>, targetUser?: Maybe<(
      { __typename?: 'User' }
      & UserFragment
    )> }
  ) }
);

export type CreateNetworkTokenInvitationMutationVariables = Exact<{
  userId: Scalars['ID'];
  networkId: Scalars['ID'];
  input: NetworkTokenInvitationInput;
}>;


export type CreateNetworkTokenInvitationMutation = (
  { __typename?: 'Mutation' }
  & { createNetworkTokenInvitation: (
    { __typename?: 'NetworkTokenInvitation' }
    & Pick<NetworkTokenInvitation, 'networkId' | 'userId' | 'type'>
    & { network: (
      { __typename?: 'Network' }
      & NetworkFragment
    ), user: (
      { __typename?: 'User' }
      & UserFragment
    ), tokens: Array<(
      { __typename?: 'NetworkInvitationToken' }
      & Pick<NetworkInvitationToken, 'email' | 'token'>
    )> }
  ) }
);

export type JoinNetworkByTokenMutationVariables = Exact<{
  input: NetworkTokenInput;
}>;


export type JoinNetworkByTokenMutation = (
  { __typename?: 'Mutation' }
  & { joinNetworkByToken: (
    { __typename?: 'Network' }
    & NetworkFragment
  ) }
);

export type ApplyNetworkWithTalentMutationVariables = Exact<{
  input?: Maybe<ApplyNetworkWithTalentInput>;
}>;


export type ApplyNetworkWithTalentMutation = (
  { __typename?: 'Mutation' }
  & { applyNetworkWithTalent: (
    { __typename?: 'MessageResponse' }
    & Pick<MessageResponse, 'message'>
  ) }
);

export type InviteNetworkWithTalentMutationVariables = Exact<{
  input?: Maybe<InviteNetworkWithTalentInput>;
}>;


export type InviteNetworkWithTalentMutation = (
  { __typename?: 'Mutation' }
  & { inviteNetworkWithTalent: (
    { __typename?: 'MessageResponse' }
    & Pick<MessageResponse, 'message'>
  ) }
);

export type TalentFragment = (
  { __typename?: 'Talent' }
  & Pick<Talent, 'id' | 'caption' | 'image' | 'imageSource' | 'playlistCount' | 'public' | 'permissionLevel' | 'personification' | 'rateCount' | 'tm_id' | 'profileId' | 'asset'>
  & { dataViews: Array<(
    { __typename?: 'TalentDataView' }
    & Pick<TalentDataView, 'key' | 'values'>
  )>, metas: (
    { __typename?: 'TalentMeta' }
    & Pick<TalentMeta, 'date' | 'fieldPosition' | 'fifaCountry' | 'firstName' | 'lastName'>
  ), customMeta?: Maybe<(
    { __typename?: 'TalentCustomMeta' }
    & Pick<TalentCustomMeta, 'id' | 'foot' | 'club' | 'team' | 'height' | 'weight' | 'rolemodelid' | 'topskillsindexes' | 'status' | 'salutation'>
  )> }
);

export type SharedTalentFragment = (
  { __typename?: 'Talent' }
  & Pick<Talent, 'id' | 'caption' | 'image' | 'imageSource' | 'playlistCount' | 'public' | 'permissionLevel' | 'rateCount' | 'tm_id' | 'personification' | 'profileId'>
  & { dataViews: Array<(
    { __typename?: 'TalentDataView' }
    & Pick<TalentDataView, 'key' | 'values'>
  )>, metas: (
    { __typename?: 'TalentMeta' }
    & Pick<TalentMeta, 'date' | 'fieldPosition' | 'fifaCountry' | 'firstName' | 'lastName'>
  ), customMeta?: Maybe<(
    { __typename?: 'TalentCustomMeta' }
    & Pick<TalentCustomMeta, 'id' | 'foot' | 'club' | 'team' | 'height' | 'weight' | 'rolemodelid' | 'topskillsindexes' | 'status'>
  )> }
);

export type VoteFragment = (
  { __typename?: 'Vote' }
  & Pick<Vote, 'id' | 'values' | 'date'>
);

export type TalentIdLayerFragment = (
  { __typename?: 'Layer' }
  & Pick<Layer, 'name'>
  & { dataViews: Array<(
    { __typename?: 'TalentDataView' }
    & Pick<TalentDataView, 'key' | 'values'>
  )> }
);

export type PerformanceVoteFragment = (
  { __typename?: 'PerformanceVote' }
  & Pick<PerformanceVote, 'talentId' | 'private' | 'public' | 'privateCount' | 'publicCount' | 'publicDetail'>
);

export type RoleModelIdsQueryVariables = Exact<{ [key: string]: never; }>;


export type RoleModelIdsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'roleModelIds'>
);

export type TalentQueryVariables = Exact<{
  userId: Scalars['ID'];
  talentId: Scalars['ID'];
}>;


export type TalentQuery = (
  { __typename?: 'Query' }
  & { talent: (
    { __typename?: 'Talent' }
    & TalentFragment
  ) }
);

export type SharedTalentQueryVariables = Exact<{
  hash: Scalars['ID'];
}>;


export type SharedTalentQuery = (
  { __typename?: 'Query' }
  & { sharedTalent: (
    { __typename?: 'SharedTalentResponse' }
    & Pick<SharedTalentResponse, 'sharedRoleModel'>
    & { talent: (
      { __typename?: 'Talent' }
      & SharedTalentFragment
    ), roleModel?: Maybe<(
      { __typename?: 'Talent' }
      & SharedTalentFragment
    )> }
  ) }
);

export type ListMyPersonificationsQueryVariables = Exact<{
  userId: Scalars['ID'];
  queryParams?: Maybe<Scalars['String']>;
}>;


export type ListMyPersonificationsQuery = (
  { __typename?: 'Query' }
  & { listMyPersonifications: (
    { __typename?: 'NetworkTalentsResponse' }
    & { collection: Array<(
      { __typename?: 'Talent' }
      & TalentFragment
    )>, meta: (
      { __typename?: 'Meta' }
      & { range?: Maybe<(
        { __typename?: 'Range' }
        & Pick<Range, 'from' | 'to' | 'total'>
      )>, sortOptions?: Maybe<Array<(
        { __typename?: 'MapEntry' }
        & Pick<MapEntry, 'key' | 'value'>
      )>> }
    ) }
  ) }
);

export type UserPersonificationQueryVariables = Exact<{
  userId: Scalars['ID'];
  queryParams?: Maybe<Scalars['String']>;
}>;


export type UserPersonificationQuery = (
  { __typename?: 'Query' }
  & { userPersonification: (
    { __typename?: 'NetworkTalentsResponse' }
    & { collection: Array<(
      { __typename?: 'Talent' }
      & TalentFragment
    )>, meta: (
      { __typename?: 'Meta' }
      & { range?: Maybe<(
        { __typename?: 'Range' }
        & Pick<Range, 'from' | 'to' | 'total'>
      )>, sortOptions?: Maybe<Array<(
        { __typename?: 'MapEntry' }
        & Pick<MapEntry, 'key' | 'value'>
      )>> }
    ) }
  ) }
);

export type ListMyTalentsQueryVariables = Exact<{
  userId: Scalars['ID'];
  queryParams?: Maybe<Scalars['String']>;
}>;


export type ListMyTalentsQuery = (
  { __typename?: 'Query' }
  & { listMyTalents: (
    { __typename?: 'NetworkTalentsResponse' }
    & { collection: Array<(
      { __typename?: 'Talent' }
      & TalentFragment
    )>, meta: (
      { __typename?: 'Meta' }
      & { range?: Maybe<(
        { __typename?: 'Range' }
        & Pick<Range, 'from' | 'to' | 'total'>
      )>, sortOptions?: Maybe<Array<(
        { __typename?: 'MapEntry' }
        & Pick<MapEntry, 'key' | 'value'>
      )>> }
    ) }
  ) }
);

export type ListMarketplaceTalentsQueryVariables = Exact<{
  userId: Scalars['ID'];
  queryParams?: Maybe<Scalars['String']>;
}>;


export type ListMarketplaceTalentsQuery = (
  { __typename?: 'Query' }
  & { listMarketplaceTalents: (
    { __typename?: 'NetworkTalentsResponse' }
    & { collection: Array<(
      { __typename?: 'Talent' }
      & TalentFragment
    )>, meta: (
      { __typename?: 'Meta' }
      & { range?: Maybe<(
        { __typename?: 'Range' }
        & Pick<Range, 'from' | 'to' | 'total'>
      )>, sortOptions?: Maybe<Array<(
        { __typename?: 'MapEntry' }
        & Pick<MapEntry, 'key' | 'value'>
      )>> }
    ) }
  ) }
);

export type TalentClassQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type TalentClassQuery = (
  { __typename?: 'Query' }
  & { talentClass: (
    { __typename?: 'TalentClass' }
    & Pick<TalentClass, 'id'>
    & { dataViews: Array<(
      { __typename?: 'TalentClassDataView' }
      & Pick<TalentClassDataView, 'id' | 'chartType' | 'withGroupLabels' | 'withLabelHover'>
      & { voteScalarGroups: Array<(
        { __typename?: 'VoteScalarGroup' }
        & { scalars: Array<(
          { __typename?: 'Scalar' }
          & Pick<Scalar, 'caption' | 'formula' | 'offset'>
        )> }
      )> }
    )> }
  ) }
);

export type SuggestTalentQueryVariables = Exact<{
  q: Scalars['String'];
}>;


export type SuggestTalentQuery = (
  { __typename?: 'Query' }
  & { suggestTalent: Array<(
    { __typename?: 'SuggestUser' }
    & Pick<SuggestUser, 'name'>
  )> }
);

export type SearchTalentQueryVariables = Exact<{
  query: Scalars['String'];
  sort?: Maybe<Scalars['String']>;
  age?: Maybe<Scalars['String']>;
  classId?: Maybe<Scalars['String']>;
  fieldPosition?: Maybe<Scalars['String']>;
  fifaCountry?: Maybe<Scalars['String']>;
  yearFrom?: Maybe<Scalars['String']>;
  yearTo?: Maybe<Scalars['String']>;
  networkId?: Maybe<Scalars['String']>;
  queryParams?: Maybe<Scalars['String']>;
}>;


export type SearchTalentQuery = (
  { __typename?: 'Query' }
  & { searchTalent: (
    { __typename?: 'SearchTalent' }
    & { meta: (
      { __typename?: 'Meta' }
      & { range?: Maybe<(
        { __typename?: 'Range' }
        & Pick<Range, 'from' | 'to' | 'total'>
      )>, sortOptions?: Maybe<Array<(
        { __typename?: 'MapEntry' }
        & Pick<MapEntry, 'key' | 'value'>
      )>> }
    ), collection: Array<(
      { __typename?: 'Talent' }
      & TalentFragment
    )> }
  ) }
);

export type TalentJoinMarketplaceMutationVariables = Exact<{ [key: string]: never; }>;


export type TalentJoinMarketplaceMutation = (
  { __typename?: 'Mutation' }
  & { talentJoinMarketplace: (
    { __typename?: 'Network' }
    & NetworkFragment
  ) }
);

export type CreateTalentMutationVariables = Exact<{
  classId: Scalars['String'];
  input: TalentInput;
}>;


export type CreateTalentMutation = (
  { __typename?: 'Mutation' }
  & { saveTalent: (
    { __typename?: 'Talent' }
    & TalentFragment
  ) }
);

export type UpdateTalentMutationVariables = Exact<{
  input: TalentInput;
  talentId: Scalars['String'];
}>;


export type UpdateTalentMutation = (
  { __typename?: 'Mutation' }
  & { saveTalent: (
    { __typename?: 'Talent' }
    & TalentFragment
  ) }
);

export type TalentLinkImageMutationVariables = Exact<{
  url: Scalars['String'];
  talentId: Scalars['String'];
}>;


export type TalentLinkImageMutation = (
  { __typename?: 'Mutation' }
  & { talentLinkImage: (
    { __typename?: 'Talent' }
    & TalentFragment
  ) }
);

export type TalentUnlinkImageMutationVariables = Exact<{
  url: Scalars['String'];
  talentId: Scalars['String'];
}>;


export type TalentUnlinkImageMutation = (
  { __typename?: 'Mutation' }
  & { talentUnlinkImage: (
    { __typename?: 'Talent' }
    & TalentFragment
  ) }
);

export type CreateTalentCustomMetaMutationVariables = Exact<{
  input: TalentCustomMetaInput;
  talentId: Scalars['String'];
}>;


export type CreateTalentCustomMetaMutation = (
  { __typename?: 'Mutation' }
  & { saveTalentCustomMeta: (
    { __typename?: 'TalentCustomMeta' }
    & Pick<TalentCustomMeta, 'id' | 'foot' | 'club' | 'team' | 'height' | 'weight' | 'status'>
  ) }
);

export type UpdateTalentCustomMetaMutationVariables = Exact<{
  input: TalentCustomMetaInput;
  talentId: Scalars['String'];
  metaDataId: Scalars['String'];
}>;


export type UpdateTalentCustomMetaMutation = (
  { __typename?: 'Mutation' }
  & { saveTalentCustomMeta: (
    { __typename?: 'TalentCustomMeta' }
    & Pick<TalentCustomMeta, 'id' | 'foot' | 'club' | 'team' | 'height' | 'weight' | 'status'>
  ) }
);

export type TalentLinkUserMutationVariables = Exact<{
  input: LinkUserInput;
  talentId: Scalars['String'];
}>;


export type TalentLinkUserMutation = (
  { __typename?: 'Mutation' }
  & { talentLinkUser: (
    { __typename?: 'Talent' }
    & TalentFragment
  ) }
);

export type DeleteTalentMutationVariables = Exact<{
  talentId: Scalars['ID'];
}>;


export type DeleteTalentMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteTalent'>
);

export type TalentLatestVoteQueryVariables = Exact<{
  userId: Scalars['ID'];
  talentId: Scalars['ID'];
}>;


export type TalentLatestVoteQuery = (
  { __typename?: 'Query' }
  & { talentLatestVote?: Maybe<(
    { __typename?: 'Vote' }
    & VoteFragment
  )> }
);

export type VoteMutationVariables = Exact<{
  talentId: Scalars['ID'];
  input: VoteInput;
}>;


export type VoteMutation = (
  { __typename?: 'Mutation' }
  & { vote: (
    { __typename?: 'Vote' }
    & VoteFragment
  ) }
);

export type UpdateVoteMutationVariables = Exact<{
  voteId: Scalars['ID'];
  input: VoteInput;
}>;


export type UpdateVoteMutation = (
  { __typename?: 'Mutation' }
  & { updateVote: (
    { __typename?: 'Vote' }
    & VoteFragment
  ) }
);

export type SimilarQueryVariables = Exact<{
  userId?: Maybe<Scalars['String']>;
  talentId?: Maybe<Scalars['String']>;
  queryString?: Maybe<Scalars['String']>;
  queryParams?: Maybe<Scalars['String']>;
}>;


export type SimilarQuery = (
  { __typename?: 'Query' }
  & { similar: (
    { __typename?: 'NetworkTalentsResponse' }
    & { collection: Array<(
      { __typename?: 'Talent' }
      & TalentFragment
    )>, meta: (
      { __typename?: 'Meta' }
      & { range?: Maybe<(
        { __typename?: 'Range' }
        & Pick<Range, 'from' | 'to' | 'total'>
      )>, sortOptions?: Maybe<Array<(
        { __typename?: 'MapEntry' }
        & Pick<MapEntry, 'key' | 'value'>
      )>> }
    ) }
  ) }
);

export type SimilarNetworkQueryVariables = Exact<{
  queryString?: Maybe<Scalars['String']>;
  userId: Scalars['ID'];
  networkId: Scalars['ID'];
  queryParams?: Maybe<Scalars['String']>;
}>;


export type SimilarNetworkQuery = (
  { __typename?: 'Query' }
  & { similarNetwork: (
    { __typename?: 'NetworkTalentsResponse' }
    & { collection: Array<(
      { __typename?: 'Talent' }
      & TalentFragment
    )>, meta: (
      { __typename?: 'Meta' }
      & { range?: Maybe<(
        { __typename?: 'Range' }
        & Pick<Range, 'from' | 'to' | 'total'>
      )>, sortOptions?: Maybe<Array<(
        { __typename?: 'MapEntry' }
        & Pick<MapEntry, 'key' | 'value'>
      )>> }
    ) }
  ) }
);

export type MyTalentLayerQueryVariables = Exact<{
  userId: Scalars['ID'];
  talentId: Scalars['ID'];
  layerParams?: Maybe<Scalars['String']>;
}>;


export type MyTalentLayerQuery = (
  { __typename?: 'Query' }
  & { talentLayer?: Maybe<Array<(
    { __typename?: 'Layer' }
    & TalentIdLayerFragment
  )>> }
);

export type ShareTalentMutationVariables = Exact<{
  input: ShareTalentInput;
}>;


export type ShareTalentMutation = (
  { __typename?: 'Mutation' }
  & { shareTalent: (
    { __typename?: 'ShareTalentResponse' }
    & Pick<ShareTalentResponse, 'publicToken'>
  ) }
);

export type AddRoleModelMutationVariables = Exact<{
  input: RoleModelInput;
}>;


export type AddRoleModelMutation = (
  { __typename?: 'Mutation' }
  & { addRoleModel: (
    { __typename?: 'MessageResponse' }
    & Pick<MessageResponse, 'message'>
  ) }
);

export type SavePerformanceVoteMutationVariables = Exact<{
  input: PerformanceVoteInput;
}>;


export type SavePerformanceVoteMutation = (
  { __typename?: 'Mutation' }
  & { savePerformanceVote: (
    { __typename?: 'PerformanceVote' }
    & PerformanceVoteFragment
  ) }
);

export type PerformanceVoteQueryVariables = Exact<{
  talentId: Scalars['ID'];
  network?: Maybe<Scalars['String']>;
}>;


export type PerformanceVoteQuery = (
  { __typename?: 'Query' }
  & { performanceVote: (
    { __typename?: 'PerformanceVote' }
    & PerformanceVoteFragment
  ) }
);

export type UserFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id' | 'name' | 'status' | 'public' | 'email' | 'firstName' | 'lastName' | 'networkRoles' | 'image' | 'asset'>
);

export type UserMetadataFragment = (
  { __typename?: 'UserMetaData' }
  & Pick<UserMetaData, 'username' | 'aboutMe' | 'ageCategory' | 'birthdate' | 'city' | 'club' | 'country' | 'experience' | 'phone' | 'street' | 'team' | 'userType' | 'year' | 'zip' | 'activitiesLastViewed' | 'status' | 'salutation'>
  & { behavior?: Maybe<(
    { __typename?: 'UserBehavior' }
    & Pick<UserBehavior, 'termsAcceptedAt' | 'termsVersionAccepted' | 'onboardingDone' | 'preferredPerformanceView'>
  )> }
);

export type ActivityFragment = (
  { __typename?: 'Activity' }
  & Pick<Activity, 'date' | 'id' | 'link' | 'method' | 'type' | 'url' | 'userID' | 'data'>
);

export type UserMetadataResponseFragment = (
  { __typename?: 'UserMetaDataResponse' }
  & { user?: Maybe<(
    { __typename?: 'CurrentUser' }
    & Pick<CurrentUser, '_created'>
    & { data?: Maybe<(
      { __typename?: 'UserMetaData' }
      & UserMetadataFragment
    )> }
  )> }
);

export type UserMetadataSaveResponseFragment = (
  { __typename?: 'UserMetaDataSaveResponse' }
  & { current_user?: Maybe<(
    { __typename?: 'CurrentUser' }
    & { data?: Maybe<(
      { __typename?: 'UserMetaData' }
      & UserMetadataFragment
    )> }
  )> }
);

export type MetaFragment = (
  { __typename?: 'Meta' }
  & { range?: Maybe<(
    { __typename?: 'Range' }
    & Pick<Range, 'from' | 'to' | 'total'>
  )>, sortOptions?: Maybe<Array<(
    { __typename?: 'MapEntry' }
    & Pick<MapEntry, 'key' | 'value'>
  )>> }
);

export type RecoverUsernameMutationVariables = Exact<{
  input: RecoverUsernameInput;
  queryParams?: Maybe<Scalars['String']>;
}>;


export type RecoverUsernameMutation = (
  { __typename?: 'Mutation' }
  & { recoverUsername: (
    { __typename?: 'UserCollection' }
    & { collection: Array<(
      { __typename?: 'User' }
      & UserFragment
    )>, meta: (
      { __typename?: 'Meta' }
      & { range?: Maybe<(
        { __typename?: 'Range' }
        & Pick<Range, 'from' | 'to' | 'total'>
      )>, sortOptions?: Maybe<Array<(
        { __typename?: 'MapEntry' }
        & Pick<MapEntry, 'key' | 'value'>
      )>> }
    ) }
  ) }
);

export type RequestPasswordChangeMutationVariables = Exact<{
  input: RequestPasswordChangeInput;
}>;


export type RequestPasswordChangeMutation = (
  { __typename?: 'Mutation' }
  & { requestPasswordChange: (
    { __typename?: 'RequestPasswordChangeResponse' }
    & Pick<RequestPasswordChangeResponse, 'token'>
    & { user: (
      { __typename?: 'User' }
      & UserFragment
    ) }
  ) }
);

export type CreateUserMetaDataMutationVariables = Exact<{
  input: UserMetaDataInput;
}>;


export type CreateUserMetaDataMutation = (
  { __typename?: 'Mutation' }
  & { saveUserMetaData: (
    { __typename?: 'UserMetaDataSaveResponse' }
    & UserMetadataSaveResponseFragment
  ) }
);

export type UpdateUserMetaDataMutationVariables = Exact<{
  input: UserMetaDataInput;
}>;


export type UpdateUserMetaDataMutation = (
  { __typename?: 'Mutation' }
  & { saveUserMetaData: (
    { __typename?: 'UserMetaDataSaveResponse' }
    & UserMetadataSaveResponseFragment
  ) }
);

export type UserMetaDataQueryVariables = Exact<{
  userId: Scalars['ID'];
}>;


export type UserMetaDataQuery = (
  { __typename?: 'Query' }
  & { userMetaData?: Maybe<(
    { __typename?: 'UserMetaDataResponse' }
    & UserMetadataResponseFragment
  )> }
);

export type SuggestUserQueryVariables = Exact<{
  q: Scalars['String'];
}>;


export type SuggestUserQuery = (
  { __typename?: 'Query' }
  & { suggestUser: Array<(
    { __typename?: 'SuggestUser' }
    & Pick<SuggestUser, 'name'>
  )> }
);

export type SearchUserQueryVariables = Exact<{
  query: Scalars['String'];
  meId: Scalars['String'];
  networkId: Scalars['String'];
  sort?: Maybe<Scalars['String']>;
  queryParams?: Maybe<Scalars['String']>;
}>;


export type SearchUserQuery = (
  { __typename?: 'Query' }
  & { searchUser: (
    { __typename?: 'SearchUserCollection' }
    & { meta: (
      { __typename?: 'Meta' }
      & { range?: Maybe<(
        { __typename?: 'Range' }
        & Pick<Range, 'from' | 'to' | 'total'>
      )>, sortOptions?: Maybe<Array<(
        { __typename?: 'MapEntry' }
        & Pick<MapEntry, 'key' | 'value'>
      )>> }
    ), collection: Array<(
      { __typename?: 'User' }
      & UserFragment
    )> }
  ) }
);

export type UpdateUserMutationVariables = Exact<{
  input: UserInput;
  userId: Scalars['String'];
}>;


export type UpdateUserMutation = (
  { __typename?: 'Mutation' }
  & { updateUser: (
    { __typename?: 'User' }
    & UserFragment
  ) }
);

export type UpdatePasswordMutationVariables = Exact<{
  input: PasswordInput;
  userId: Scalars['String'];
}>;


export type UpdatePasswordMutation = (
  { __typename?: 'Mutation' }
  & { updatePassword: (
    { __typename?: 'User' }
    & UserFragment
  ) }
);

export type UserLinkImageMutationVariables = Exact<{
  url: Scalars['String'];
  userId: Scalars['String'];
}>;


export type UserLinkImageMutation = (
  { __typename?: 'Mutation' }
  & { userLinkImage: (
    { __typename?: 'User' }
    & UserFragment
  ) }
);

export type UserUnlinkImageMutationVariables = Exact<{
  url: Scalars['String'];
  userId: Scalars['String'];
}>;


export type UserUnlinkImageMutation = (
  { __typename?: 'Mutation' }
  & { userUnlinkImage: (
    { __typename?: 'User' }
    & UserFragment
  ) }
);

export type UserFollowMutationVariables = Exact<{
  url: Scalars['String'];
  userId: Scalars['String'];
  method: Scalars['String'];
}>;


export type UserFollowMutation = (
  { __typename?: 'Mutation' }
  & { userFollow: (
    { __typename?: 'User' }
    & Pick<User, 'id'>
  ) }
);

export type UserJoinMarketplaceMutationVariables = Exact<{ [key: string]: never; }>;


export type UserJoinMarketplaceMutation = (
  { __typename?: 'Mutation' }
  & { userJoinMarketplace: (
    { __typename?: 'Network' }
    & NetworkFragment
  ) }
);

export type DeleteAccountMutationVariables = Exact<{
  input: DeleteAccountInput;
}>;


export type DeleteAccountMutation = (
  { __typename?: 'Mutation' }
  & { deleteAccount: (
    { __typename?: 'DeleteAccountResponse' }
    & Pick<DeleteAccountResponse, 'message'>
  ) }
);

export type UserQueryVariables = Exact<{
  userId: Scalars['ID'];
  meId: Scalars['ID'];
  networkId: Scalars['ID'];
}>;


export type UserQuery = (
  { __typename?: 'Query' }
  & { user?: Maybe<(
    { __typename?: 'User' }
    & UserFragment
  )> }
);

export type UserStatusQueryVariables = Exact<{
  userId: Scalars['ID'];
}>;


export type UserStatusQuery = (
  { __typename?: 'Query' }
  & { userStatus: (
    { __typename?: 'UserStatusResponse' }
    & Pick<UserStatusResponse, 'selfNetwork' | 'selfPlayer' | 'selfUser' | 'jmtNetworks'>
  ) }
);

export type RelationshipsQueryVariables = Exact<{
  userId: Scalars['ID'];
  queryParams?: Maybe<Scalars['String']>;
}>;


export type RelationshipsQuery = (
  { __typename?: 'Query' }
  & { followed?: Maybe<(
    { __typename?: 'UserCollection' }
    & { collection: Array<(
      { __typename?: 'User' }
      & Pick<User, 'isFriend'>
      & UserFragment
    )>, meta: (
      { __typename?: 'Meta' }
      & { range?: Maybe<(
        { __typename?: 'Range' }
        & Pick<Range, 'from' | 'to' | 'total'>
      )>, sortOptions?: Maybe<Array<(
        { __typename?: 'MapEntry' }
        & Pick<MapEntry, 'key' | 'value'>
      )>> }
    ) }
  )>, follower?: Maybe<(
    { __typename?: 'UserCollection' }
    & { collection: Array<(
      { __typename?: 'User' }
      & Pick<User, 'isFriend'>
      & UserFragment
    )>, meta: (
      { __typename?: 'Meta' }
      & { range?: Maybe<(
        { __typename?: 'Range' }
        & Pick<Range, 'from' | 'to' | 'total'>
      )>, sortOptions?: Maybe<Array<(
        { __typename?: 'MapEntry' }
        & Pick<MapEntry, 'key' | 'value'>
      )>> }
    ) }
  )> }
);

export type NewActivitiesQueryVariables = Exact<{
  isoDate: Scalars['String'];
}>;


export type NewActivitiesQuery = (
  { __typename?: 'Query' }
  & { newActivities: Array<(
    { __typename?: 'Activity' }
    & ActivityFragment
  )> }
);

export type ActivitiesQueryVariables = Exact<{
  queryParams?: Maybe<Scalars['String']>;
}>;


export type ActivitiesQuery = (
  { __typename?: 'Query' }
  & { activities: (
    { __typename?: 'ActivitiesResponse' }
    & { meta: (
      { __typename?: 'ActivityMeta' }
      & { range: (
        { __typename?: 'ActivityRange' }
        & Pick<ActivityRange, 'from' | 'to' | 'total'>
      ) }
    ), collection: Array<(
      { __typename?: 'Activity' }
      & ActivityFragment
    )> }
  ) }
);

export type ActivitylevelQueryVariables = Exact<{
  userId: Scalars['ID'];
}>;


export type ActivitylevelQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'activitylevel'>
);

export const PlaylistFragmentDoc = gql`
    fragment playlist on Playlist {
  id
  caption
  public
  user
  latestObject {
    image
  }
}
    `;
export const NetworkFragmentDoc = gql`
    fragment network on Network {
  id
  caption
  description
  owner
  public
  open
  bugTrackerEnabled
  networkRoles
  image
  asset
}
    `;
export const NetworkSearchResultFragmentDoc = gql`
    fragment networkSearchResult on Network {
  id
  caption
  description
  owner
  public
  open
  bugTrackerEnabled
  image
}
    `;
export const JmtNetworkFragmentDoc = gql`
    fragment jmtNetwork on Network {
  id
  caption
  description
  public
  open
  bugTrackerEnabled
  networkRoles
}
    `;
export const TalentFragmentDoc = gql`
    fragment talent on Talent {
  id @export(as: "talentId")
  caption
  dataViews {
    key
    values
  }
  image
  imageSource
  metas @type(name: "TalentMetas") {
    date
    fieldPosition
    fifaCountry
    firstName
    lastName
  }
  customMeta @rest(type: "TalentCustomMeta", path: "/object/{exportVariables.talentId}/custom-meta-data", method: "GET") {
    id
    foot
    club
    team
    height
    weight
    rolemodelid
    topskillsindexes
    status
    salutation
  }
  playlistCount
  public
  permissionLevel
  personification
  rateCount
  tm_id
  profileId
  asset
}
    `;
export const SharedTalentFragmentDoc = gql`
    fragment sharedTalent on Talent {
  id
  caption
  dataViews {
    key
    values
  }
  image
  imageSource
  metas @type(name: "TalentMetas") {
    date
    fieldPosition
    fifaCountry
    firstName
    lastName
  }
  customMeta @type(name: "TalentCustomMeta") {
    id
    foot
    club
    team
    height
    weight
    rolemodelid
    topskillsindexes
    status
  }
  playlistCount
  public
  permissionLevel
  rateCount
  tm_id
  personification
  profileId
}
    `;
export const VoteFragmentDoc = gql`
    fragment vote on Vote {
  id
  values
  date
}
    `;
export const TalentIdLayerFragmentDoc = gql`
    fragment talentIDLayer on Layer {
  name
  dataViews @type(name: "TalentDataView") {
    key
    values
  }
}
    `;
export const PerformanceVoteFragmentDoc = gql`
    fragment performanceVote on PerformanceVote {
  talentId
  private
  public
  privateCount
  publicCount
  publicDetail
}
    `;
export const UserFragmentDoc = gql`
    fragment user on User {
  id
  name
  status
  public
  email
  firstName
  lastName
  networkRoles
  image
  asset
}
    `;
export const ActivityFragmentDoc = gql`
    fragment activity on Activity {
  date
  id
  link
  method
  type
  url
  userID
  data
}
    `;
export const UserMetadataFragmentDoc = gql`
    fragment userMetadata on UserMetaData {
  username
  aboutMe
  ageCategory
  behavior {
    termsAcceptedAt
    termsVersionAccepted
    onboardingDone
    preferredPerformanceView
  }
  birthdate
  city
  club
  country
  experience
  phone
  street
  team
  userType
  year
  zip
  activitiesLastViewed
  status
  salutation
}
    `;
export const UserMetadataResponseFragmentDoc = gql`
    fragment userMetadataResponse on UserMetaDataResponse {
  user {
    _created
    data @type(name: "UserMetaData") {
      ...userMetadata
    }
  }
}
    ${UserMetadataFragmentDoc}`;
export const UserMetadataSaveResponseFragmentDoc = gql`
    fragment userMetadataSaveResponse on UserMetaDataSaveResponse {
  current_user {
    data @type(name: "UserMetaData") {
      ...userMetadata
    }
  }
}
    ${UserMetadataFragmentDoc}`;
export const MetaFragmentDoc = gql`
    fragment meta on Meta {
  range {
    from
    to
    total
  }
  sortOptions @type(name: "MapEntry") {
    key
    value
  }
}
    `;
export const SigninDocument = gql`
    mutation signin($input: AuthenticationFormInput!) {
  signin(input: $input) @rest(type: "Authentication", path: "/user/signin", method: "POST") {
    token
    user @type(name: "User") {
      ...user
    }
  }
}
    ${UserFragmentDoc}`;
export type SigninMutationFn = Apollo.MutationFunction<SigninMutation, SigninMutationVariables>;

/**
 * __useSigninMutation__
 *
 * To run a mutation, you first call `useSigninMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSigninMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signinMutation, { data, loading, error }] = useSigninMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSigninMutation(baseOptions?: Apollo.MutationHookOptions<SigninMutation, SigninMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SigninMutation, SigninMutationVariables>(SigninDocument, options);
      }
export type SigninMutationHookResult = ReturnType<typeof useSigninMutation>;
export type SigninMutationResult = Apollo.MutationResult<SigninMutation>;
export type SigninMutationOptions = Apollo.BaseMutationOptions<SigninMutation, SigninMutationVariables>;
export const SigninWithTokenDocument = gql`
    mutation signinWithToken($input: AuthenticationTokenInput!) {
  signinWithToken(input: $input) @rest(type: "Authentication", path: "/user/signin", method: "POST") {
    token
    user @type(name: "User") {
      ...user
    }
  }
}
    ${UserFragmentDoc}`;
export type SigninWithTokenMutationFn = Apollo.MutationFunction<SigninWithTokenMutation, SigninWithTokenMutationVariables>;

/**
 * __useSigninWithTokenMutation__
 *
 * To run a mutation, you first call `useSigninWithTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSigninWithTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signinWithTokenMutation, { data, loading, error }] = useSigninWithTokenMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSigninWithTokenMutation(baseOptions?: Apollo.MutationHookOptions<SigninWithTokenMutation, SigninWithTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SigninWithTokenMutation, SigninWithTokenMutationVariables>(SigninWithTokenDocument, options);
      }
export type SigninWithTokenMutationHookResult = ReturnType<typeof useSigninWithTokenMutation>;
export type SigninWithTokenMutationResult = Apollo.MutationResult<SigninWithTokenMutation>;
export type SigninWithTokenMutationOptions = Apollo.BaseMutationOptions<SigninWithTokenMutation, SigninWithTokenMutationVariables>;
export const RegisterDocument = gql`
    mutation register($input: RegistrationInput!) {
  register(input: $input) @rest(type: "Authentication", path: "/user", method: "POST") {
    token
    user @type(name: "User") {
      ...user
    }
  }
}
    ${UserFragmentDoc}`;
export type RegisterMutationFn = Apollo.MutationFunction<RegisterMutation, RegisterMutationVariables>;

/**
 * __useRegisterMutation__
 *
 * To run a mutation, you first call `useRegisterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerMutation, { data, loading, error }] = useRegisterMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRegisterMutation(baseOptions?: Apollo.MutationHookOptions<RegisterMutation, RegisterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RegisterMutation, RegisterMutationVariables>(RegisterDocument, options);
      }
export type RegisterMutationHookResult = ReturnType<typeof useRegisterMutation>;
export type RegisterMutationResult = Apollo.MutationResult<RegisterMutation>;
export type RegisterMutationOptions = Apollo.BaseMutationOptions<RegisterMutation, RegisterMutationVariables>;
export const DoimailDocument = gql`
    mutation doimail($input: DoimailInput!) {
  doimail(input: $input) @rest(type: "Message", path: "/makro/doimail&hash={args.input.token}&email={args.input.email}&subject={args.input.subject}&name={args.input.name}", method: "POST") {
    message
  }
}
    `;
export type DoimailMutationFn = Apollo.MutationFunction<DoimailMutation, DoimailMutationVariables>;

/**
 * __useDoimailMutation__
 *
 * To run a mutation, you first call `useDoimailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDoimailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [doimailMutation, { data, loading, error }] = useDoimailMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDoimailMutation(baseOptions?: Apollo.MutationHookOptions<DoimailMutation, DoimailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DoimailMutation, DoimailMutationVariables>(DoimailDocument, options);
      }
export type DoimailMutationHookResult = ReturnType<typeof useDoimailMutation>;
export type DoimailMutationResult = Apollo.MutationResult<DoimailMutation>;
export type DoimailMutationOptions = Apollo.BaseMutationOptions<DoimailMutation, DoimailMutationVariables>;
export const WhoamiDocument = gql`
    query whoami {
  whoami {
    token
    user @type(name: "User") {
      ...user
    }
  }
}
    ${UserFragmentDoc}`;

/**
 * __useWhoamiQuery__
 *
 * To run a query within a React component, call `useWhoamiQuery` and pass it any options that fit your needs.
 * When your component renders, `useWhoamiQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWhoamiQuery({
 *   variables: {
 *   },
 * });
 */
export function useWhoamiQuery(baseOptions?: Apollo.QueryHookOptions<WhoamiQuery, WhoamiQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WhoamiQuery, WhoamiQueryVariables>(WhoamiDocument, options);
      }
export function useWhoamiLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WhoamiQuery, WhoamiQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WhoamiQuery, WhoamiQueryVariables>(WhoamiDocument, options);
        }
export type WhoamiQueryHookResult = ReturnType<typeof useWhoamiQuery>;
export type WhoamiLazyQueryHookResult = ReturnType<typeof useWhoamiLazyQuery>;
export type WhoamiQueryResult = Apollo.QueryResult<WhoamiQuery, WhoamiQueryVariables>;
export const UploadImageDocument = gql`
    mutation uploadImage($input: String!) {
  uploadImage(input: $input) @rest(type: "FileUploadResponse", path: "/asset&contentType=image", method: "POST", bodySerializer: "imageEncode") {
    id
    token
    url
    user
    _links {
      self {
        rel
        url
      }
    }
    _created
    _updated
  }
}
    `;
export type UploadImageMutationFn = Apollo.MutationFunction<UploadImageMutation, UploadImageMutationVariables>;

/**
 * __useUploadImageMutation__
 *
 * To run a mutation, you first call `useUploadImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadImageMutation, { data, loading, error }] = useUploadImageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUploadImageMutation(baseOptions?: Apollo.MutationHookOptions<UploadImageMutation, UploadImageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadImageMutation, UploadImageMutationVariables>(UploadImageDocument, options);
      }
export type UploadImageMutationHookResult = ReturnType<typeof useUploadImageMutation>;
export type UploadImageMutationResult = Apollo.MutationResult<UploadImageMutation>;
export type UploadImageMutationOptions = Apollo.BaseMutationOptions<UploadImageMutation, UploadImageMutationVariables>;
export const NetworksOfUserDocument = gql`
    query networksOfUser($userId: ID!, $search: String = "", $queryParams: String = "") {
  networks(userId: $userId, search: $search, queryParams: $queryParams) @rest(type: "Networks", path: "/user/{args.userId}/network{args.search}{args.queryParams}", method: "GET") {
    collection @type(name: "Network") {
      ...network
    }
    meta {
      range {
        from
        to
        total
      }
      sortOptions {
        key
        value
      }
    }
  }
}
    ${NetworkFragmentDoc}`;

/**
 * __useNetworksOfUserQuery__
 *
 * To run a query within a React component, call `useNetworksOfUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useNetworksOfUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNetworksOfUserQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      search: // value for 'search'
 *      queryParams: // value for 'queryParams'
 *   },
 * });
 */
export function useNetworksOfUserQuery(baseOptions: Apollo.QueryHookOptions<NetworksOfUserQuery, NetworksOfUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NetworksOfUserQuery, NetworksOfUserQueryVariables>(NetworksOfUserDocument, options);
      }
export function useNetworksOfUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NetworksOfUserQuery, NetworksOfUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NetworksOfUserQuery, NetworksOfUserQueryVariables>(NetworksOfUserDocument, options);
        }
export type NetworksOfUserQueryHookResult = ReturnType<typeof useNetworksOfUserQuery>;
export type NetworksOfUserLazyQueryHookResult = ReturnType<typeof useNetworksOfUserLazyQuery>;
export type NetworksOfUserQueryResult = Apollo.QueryResult<NetworksOfUserQuery, NetworksOfUserQueryVariables>;
export const NetworksDocument = gql`
    query networks($userId: ID!, $search: String = "", $queryParams: String = "") {
  networks(userId: $userId, search: $search, queryParams: $queryParams) @rest(type: "Networks", path: "/my/{args.userId}/network{args.search}{args.queryParams}", method: "GET") {
    collection @type(name: "Network") {
      ...network
    }
    meta {
      range {
        from
        to
        total
      }
      sortOptions {
        key
        value
      }
    }
  }
}
    ${NetworkFragmentDoc}`;

/**
 * __useNetworksQuery__
 *
 * To run a query within a React component, call `useNetworksQuery` and pass it any options that fit your needs.
 * When your component renders, `useNetworksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNetworksQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      search: // value for 'search'
 *      queryParams: // value for 'queryParams'
 *   },
 * });
 */
export function useNetworksQuery(baseOptions: Apollo.QueryHookOptions<NetworksQuery, NetworksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NetworksQuery, NetworksQueryVariables>(NetworksDocument, options);
      }
export function useNetworksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NetworksQuery, NetworksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NetworksQuery, NetworksQueryVariables>(NetworksDocument, options);
        }
export type NetworksQueryHookResult = ReturnType<typeof useNetworksQuery>;
export type NetworksLazyQueryHookResult = ReturnType<typeof useNetworksLazyQuery>;
export type NetworksQueryResult = Apollo.QueryResult<NetworksQuery, NetworksQueryVariables>;
export const NetworkDocument = gql`
    query network($userId: ID!, $networkId: ID!) {
  network(userId: $userId, networkId: $networkId) @rest(type: "Network", path: "/my/{args.userId}/network/{args.networkId}", method: "GET") {
    ...network
  }
}
    ${NetworkFragmentDoc}`;

/**
 * __useNetworkQuery__
 *
 * To run a query within a React component, call `useNetworkQuery` and pass it any options that fit your needs.
 * When your component renders, `useNetworkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNetworkQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      networkId: // value for 'networkId'
 *   },
 * });
 */
export function useNetworkQuery(baseOptions: Apollo.QueryHookOptions<NetworkQuery, NetworkQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NetworkQuery, NetworkQueryVariables>(NetworkDocument, options);
      }
export function useNetworkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NetworkQuery, NetworkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NetworkQuery, NetworkQueryVariables>(NetworkDocument, options);
        }
export type NetworkQueryHookResult = ReturnType<typeof useNetworkQuery>;
export type NetworkLazyQueryHookResult = ReturnType<typeof useNetworkLazyQuery>;
export type NetworkQueryResult = Apollo.QueryResult<NetworkQuery, NetworkQueryVariables>;
export const PlaylistTalentsDocument = gql`
    query playlistTalents($userId: String!, $id: ID!, $queryParams: String = "") {
  playlistTalents(userId: $userId, id: $id, queryParams: $queryParams) @rest(type: "PlaylistTalentResponse", path: "/my/{args.userId}/playlist/{args.id}/object{args.queryParams}", method: "GET") {
    collection @type(name: "Talent") {
      ...talent
    }
    meta {
      range {
        from
        to
        total
      }
      sortOptions {
        key
        value
      }
    }
  }
}
    ${TalentFragmentDoc}`;

/**
 * __usePlaylistTalentsQuery__
 *
 * To run a query within a React component, call `usePlaylistTalentsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlaylistTalentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlaylistTalentsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      id: // value for 'id'
 *      queryParams: // value for 'queryParams'
 *   },
 * });
 */
export function usePlaylistTalentsQuery(baseOptions: Apollo.QueryHookOptions<PlaylistTalentsQuery, PlaylistTalentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PlaylistTalentsQuery, PlaylistTalentsQueryVariables>(PlaylistTalentsDocument, options);
      }
export function usePlaylistTalentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PlaylistTalentsQuery, PlaylistTalentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PlaylistTalentsQuery, PlaylistTalentsQueryVariables>(PlaylistTalentsDocument, options);
        }
export type PlaylistTalentsQueryHookResult = ReturnType<typeof usePlaylistTalentsQuery>;
export type PlaylistTalentsLazyQueryHookResult = ReturnType<typeof usePlaylistTalentsLazyQuery>;
export type PlaylistTalentsQueryResult = Apollo.QueryResult<PlaylistTalentsQuery, PlaylistTalentsQueryVariables>;
export const PlaylistDocument = gql`
    query playlist($userId: String!, $id: String!) {
  playlist(userId: $userId, id: $id) @rest(type: "Playlist", path: "/my/{args.userId}/playlist/{args.id}", method: "GET") {
    ...playlist
  }
}
    ${PlaylistFragmentDoc}`;

/**
 * __usePlaylistQuery__
 *
 * To run a query within a React component, call `usePlaylistQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlaylistQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlaylistQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePlaylistQuery(baseOptions: Apollo.QueryHookOptions<PlaylistQuery, PlaylistQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PlaylistQuery, PlaylistQueryVariables>(PlaylistDocument, options);
      }
export function usePlaylistLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PlaylistQuery, PlaylistQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PlaylistQuery, PlaylistQueryVariables>(PlaylistDocument, options);
        }
export type PlaylistQueryHookResult = ReturnType<typeof usePlaylistQuery>;
export type PlaylistLazyQueryHookResult = ReturnType<typeof usePlaylistLazyQuery>;
export type PlaylistQueryResult = Apollo.QueryResult<PlaylistQuery, PlaylistQueryVariables>;
export const PlaylistsDocument = gql`
    query playlists($userId: String!, $queryParams: String = "") {
  playlists(userId: $userId, queryParams: $queryParams) @rest(type: "Playlists", path: "/my/{args.userId}/playlist{args.queryParams}", method: "GET") {
    collection @type(name: "Playlist") {
      ...playlist
    }
    meta {
      range {
        from
        to
        total
      }
      sortOptions {
        key
        value
      }
    }
  }
}
    ${PlaylistFragmentDoc}`;

/**
 * __usePlaylistsQuery__
 *
 * To run a query within a React component, call `usePlaylistsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlaylistsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlaylistsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      queryParams: // value for 'queryParams'
 *   },
 * });
 */
export function usePlaylistsQuery(baseOptions: Apollo.QueryHookOptions<PlaylistsQuery, PlaylistsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PlaylistsQuery, PlaylistsQueryVariables>(PlaylistsDocument, options);
      }
export function usePlaylistsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PlaylistsQuery, PlaylistsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PlaylistsQuery, PlaylistsQueryVariables>(PlaylistsDocument, options);
        }
export type PlaylistsQueryHookResult = ReturnType<typeof usePlaylistsQuery>;
export type PlaylistsLazyQueryHookResult = ReturnType<typeof usePlaylistsLazyQuery>;
export type PlaylistsQueryResult = Apollo.QueryResult<PlaylistsQuery, PlaylistsQueryVariables>;
export const AddPlaylistDocument = gql`
    mutation addPlaylist($input: PlaylistInput!) {
  addPlaylist(input: $input) @rest(type: "Playlist", path: "/playlist", method: "POST") {
    ...playlist
  }
}
    ${PlaylistFragmentDoc}`;
export type AddPlaylistMutationFn = Apollo.MutationFunction<AddPlaylistMutation, AddPlaylistMutationVariables>;

/**
 * __useAddPlaylistMutation__
 *
 * To run a mutation, you first call `useAddPlaylistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPlaylistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPlaylistMutation, { data, loading, error }] = useAddPlaylistMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddPlaylistMutation(baseOptions?: Apollo.MutationHookOptions<AddPlaylistMutation, AddPlaylistMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddPlaylistMutation, AddPlaylistMutationVariables>(AddPlaylistDocument, options);
      }
export type AddPlaylistMutationHookResult = ReturnType<typeof useAddPlaylistMutation>;
export type AddPlaylistMutationResult = Apollo.MutationResult<AddPlaylistMutation>;
export type AddPlaylistMutationOptions = Apollo.BaseMutationOptions<AddPlaylistMutation, AddPlaylistMutationVariables>;
export const EditPlaylistDocument = gql`
    mutation editPlaylist($id: String!, $input: PlaylistInput!) {
  editPlaylist(id: $id, input: $input) @rest(type: "Playlist", path: "/playlist/{args.id}", method: "PATCH") {
    ...playlist
  }
}
    ${PlaylistFragmentDoc}`;
export type EditPlaylistMutationFn = Apollo.MutationFunction<EditPlaylistMutation, EditPlaylistMutationVariables>;

/**
 * __useEditPlaylistMutation__
 *
 * To run a mutation, you first call `useEditPlaylistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditPlaylistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editPlaylistMutation, { data, loading, error }] = useEditPlaylistMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditPlaylistMutation(baseOptions?: Apollo.MutationHookOptions<EditPlaylistMutation, EditPlaylistMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditPlaylistMutation, EditPlaylistMutationVariables>(EditPlaylistDocument, options);
      }
export type EditPlaylistMutationHookResult = ReturnType<typeof useEditPlaylistMutation>;
export type EditPlaylistMutationResult = Apollo.MutationResult<EditPlaylistMutation>;
export type EditPlaylistMutationOptions = Apollo.BaseMutationOptions<EditPlaylistMutation, EditPlaylistMutationVariables>;
export const DeletePlaylistDocument = gql`
    mutation deletePlaylist($id: String!) {
  deletePlaylist(id: $id) @rest(type: "Network", path: "/playlist/{args.id}", method: "DELETE")
}
    `;
export type DeletePlaylistMutationFn = Apollo.MutationFunction<DeletePlaylistMutation, DeletePlaylistMutationVariables>;

/**
 * __useDeletePlaylistMutation__
 *
 * To run a mutation, you first call `useDeletePlaylistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePlaylistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePlaylistMutation, { data, loading, error }] = useDeletePlaylistMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePlaylistMutation(baseOptions?: Apollo.MutationHookOptions<DeletePlaylistMutation, DeletePlaylistMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePlaylistMutation, DeletePlaylistMutationVariables>(DeletePlaylistDocument, options);
      }
export type DeletePlaylistMutationHookResult = ReturnType<typeof useDeletePlaylistMutation>;
export type DeletePlaylistMutationResult = Apollo.MutationResult<DeletePlaylistMutation>;
export type DeletePlaylistMutationOptions = Apollo.BaseMutationOptions<DeletePlaylistMutation, DeletePlaylistMutationVariables>;
export const AddTalentsToPlaylistDocument = gql`
    mutation addTalentsToPlaylist($id: String!, $talents: PlaylistTalentsInput!) {
  addTalentsToPlaylist(id: $id, input: $talents) @rest(type: "Playlist", path: "/playlist/{args.id}&method=LINK", method: "PUT") {
    ...playlist
  }
}
    ${PlaylistFragmentDoc}`;
export type AddTalentsToPlaylistMutationFn = Apollo.MutationFunction<AddTalentsToPlaylistMutation, AddTalentsToPlaylistMutationVariables>;

/**
 * __useAddTalentsToPlaylistMutation__
 *
 * To run a mutation, you first call `useAddTalentsToPlaylistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddTalentsToPlaylistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addTalentsToPlaylistMutation, { data, loading, error }] = useAddTalentsToPlaylistMutation({
 *   variables: {
 *      id: // value for 'id'
 *      talents: // value for 'talents'
 *   },
 * });
 */
export function useAddTalentsToPlaylistMutation(baseOptions?: Apollo.MutationHookOptions<AddTalentsToPlaylistMutation, AddTalentsToPlaylistMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddTalentsToPlaylistMutation, AddTalentsToPlaylistMutationVariables>(AddTalentsToPlaylistDocument, options);
      }
export type AddTalentsToPlaylistMutationHookResult = ReturnType<typeof useAddTalentsToPlaylistMutation>;
export type AddTalentsToPlaylistMutationResult = Apollo.MutationResult<AddTalentsToPlaylistMutation>;
export type AddTalentsToPlaylistMutationOptions = Apollo.BaseMutationOptions<AddTalentsToPlaylistMutation, AddTalentsToPlaylistMutationVariables>;
export const RemoveTalentsFromPlaylistDocument = gql`
    mutation removeTalentsFromPlaylist($id: String!, $talents: PlaylistTalentsInput!) {
  removeTalentsFromPlaylist(id: $id, input: $talents) @rest(type: "Playlist", path: "/playlist/{args.id}&method=UNLINK", method: "PUT") {
    ...playlist
  }
}
    ${PlaylistFragmentDoc}`;
export type RemoveTalentsFromPlaylistMutationFn = Apollo.MutationFunction<RemoveTalentsFromPlaylistMutation, RemoveTalentsFromPlaylistMutationVariables>;

/**
 * __useRemoveTalentsFromPlaylistMutation__
 *
 * To run a mutation, you first call `useRemoveTalentsFromPlaylistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveTalentsFromPlaylistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeTalentsFromPlaylistMutation, { data, loading, error }] = useRemoveTalentsFromPlaylistMutation({
 *   variables: {
 *      id: // value for 'id'
 *      talents: // value for 'talents'
 *   },
 * });
 */
export function useRemoveTalentsFromPlaylistMutation(baseOptions?: Apollo.MutationHookOptions<RemoveTalentsFromPlaylistMutation, RemoveTalentsFromPlaylistMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveTalentsFromPlaylistMutation, RemoveTalentsFromPlaylistMutationVariables>(RemoveTalentsFromPlaylistDocument, options);
      }
export type RemoveTalentsFromPlaylistMutationHookResult = ReturnType<typeof useRemoveTalentsFromPlaylistMutation>;
export type RemoveTalentsFromPlaylistMutationResult = Apollo.MutationResult<RemoveTalentsFromPlaylistMutation>;
export type RemoveTalentsFromPlaylistMutationOptions = Apollo.BaseMutationOptions<RemoveTalentsFromPlaylistMutation, RemoveTalentsFromPlaylistMutationVariables>;
export const IsTalentInNetworkDocument = gql`
    query isTalentInNetwork($userId: String!, $talentId: ID!, $networkId: ID!) {
  isTalentInNetwork(userId: $userId, talentId: $talentId, networkId: $networkId) @rest(type: "InNetworkState", path: "/my/{args.userId}/network/{args.networkId}/object/{args.talentId}", method: "GET") {
    state
  }
}
    `;

/**
 * __useIsTalentInNetworkQuery__
 *
 * To run a query within a React component, call `useIsTalentInNetworkQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsTalentInNetworkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsTalentInNetworkQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      talentId: // value for 'talentId'
 *      networkId: // value for 'networkId'
 *   },
 * });
 */
export function useIsTalentInNetworkQuery(baseOptions: Apollo.QueryHookOptions<IsTalentInNetworkQuery, IsTalentInNetworkQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IsTalentInNetworkQuery, IsTalentInNetworkQueryVariables>(IsTalentInNetworkDocument, options);
      }
export function useIsTalentInNetworkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IsTalentInNetworkQuery, IsTalentInNetworkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IsTalentInNetworkQuery, IsTalentInNetworkQueryVariables>(IsTalentInNetworkDocument, options);
        }
export type IsTalentInNetworkQueryHookResult = ReturnType<typeof useIsTalentInNetworkQuery>;
export type IsTalentInNetworkLazyQueryHookResult = ReturnType<typeof useIsTalentInNetworkLazyQuery>;
export type IsTalentInNetworkQueryResult = Apollo.QueryResult<IsTalentInNetworkQuery, IsTalentInNetworkQueryVariables>;
export const MyspaceDocument = gql`
    query myspace($userId: ID!) {
  myspace(userId: $userId) @rest(type: "Network", path: "/makro/getmynetwork", method: "GET") {
    ...network
  }
}
    ${NetworkFragmentDoc}`;

/**
 * __useMyspaceQuery__
 *
 * To run a query within a React component, call `useMyspaceQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyspaceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyspaceQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useMyspaceQuery(baseOptions: Apollo.QueryHookOptions<MyspaceQuery, MyspaceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MyspaceQuery, MyspaceQueryVariables>(MyspaceDocument, options);
      }
export function useMyspaceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MyspaceQuery, MyspaceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MyspaceQuery, MyspaceQueryVariables>(MyspaceDocument, options);
        }
export type MyspaceQueryHookResult = ReturnType<typeof useMyspaceQuery>;
export type MyspaceLazyQueryHookResult = ReturnType<typeof useMyspaceLazyQuery>;
export type MyspaceQueryResult = Apollo.QueryResult<MyspaceQuery, MyspaceQueryVariables>;
export const MyspaceTalentsDocument = gql`
    query myspaceTalents($query: String!, $queryParams: String = "") {
  myspaceTalents(query: $query, queryParams: $queryParams) @rest(type: "MySpaceTalentsResponse", path: "/makro/myplacetalents{args.query}{args.queryParams}", method: "GET") {
    meta {
      range {
        from
        to
        total
      }
      sortOptions {
        key
        value
      }
    }
    collection {
      network @type(name: "Network") {
        ...network
      }
      talent @type(name: "Talent") {
        ...talent
      }
    }
  }
}
    ${NetworkFragmentDoc}
${TalentFragmentDoc}`;

/**
 * __useMyspaceTalentsQuery__
 *
 * To run a query within a React component, call `useMyspaceTalentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyspaceTalentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyspaceTalentsQuery({
 *   variables: {
 *      query: // value for 'query'
 *      queryParams: // value for 'queryParams'
 *   },
 * });
 */
export function useMyspaceTalentsQuery(baseOptions: Apollo.QueryHookOptions<MyspaceTalentsQuery, MyspaceTalentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MyspaceTalentsQuery, MyspaceTalentsQueryVariables>(MyspaceTalentsDocument, options);
      }
export function useMyspaceTalentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MyspaceTalentsQuery, MyspaceTalentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MyspaceTalentsQuery, MyspaceTalentsQueryVariables>(MyspaceTalentsDocument, options);
        }
export type MyspaceTalentsQueryHookResult = ReturnType<typeof useMyspaceTalentsQuery>;
export type MyspaceTalentsLazyQueryHookResult = ReturnType<typeof useMyspaceTalentsLazyQuery>;
export type MyspaceTalentsQueryResult = Apollo.QueryResult<MyspaceTalentsQuery, MyspaceTalentsQueryVariables>;
export const NetworkUsersDocument = gql`
    query networkUsers($userId: ID!, $networkId: ID!, $queryParams: String = "") {
  networkUsers(userId: $userId, networkId: $networkId, queryParams: $queryParams) @rest(type: "NetworkUsers", path: "/my/{args.userId}/network/{args.networkId}/user{args.queryParams}", method: "GET") {
    collection @type(name: "User") {
      ...user
    }
    meta {
      range {
        from
        to
        total
      }
      sortOptions {
        key
        value
      }
    }
  }
}
    ${UserFragmentDoc}`;

/**
 * __useNetworkUsersQuery__
 *
 * To run a query within a React component, call `useNetworkUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useNetworkUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNetworkUsersQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      networkId: // value for 'networkId'
 *      queryParams: // value for 'queryParams'
 *   },
 * });
 */
export function useNetworkUsersQuery(baseOptions: Apollo.QueryHookOptions<NetworkUsersQuery, NetworkUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NetworkUsersQuery, NetworkUsersQueryVariables>(NetworkUsersDocument, options);
      }
export function useNetworkUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NetworkUsersQuery, NetworkUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NetworkUsersQuery, NetworkUsersQueryVariables>(NetworkUsersDocument, options);
        }
export type NetworkUsersQueryHookResult = ReturnType<typeof useNetworkUsersQuery>;
export type NetworkUsersLazyQueryHookResult = ReturnType<typeof useNetworkUsersLazyQuery>;
export type NetworkUsersQueryResult = Apollo.QueryResult<NetworkUsersQuery, NetworkUsersQueryVariables>;
export const NetworkFriendsDocument = gql`
    query networkFriends($userId: String!, $networkId: String!, $queryParams: String = "") {
  networkFriends(
    userId: $userId
    networkId: $networkId
    queryParams: $queryParams
  ) @rest(type: "NetworkUsersResponse", path: "/my/{args.userId}/network/{args.networkId}/friend{args.queryParams}", method: "GET") {
    collection @type(name: "User") {
      ...user
    }
    meta {
      range {
        from
        to
        total
      }
      sortOptions {
        key
        value
      }
    }
  }
}
    ${UserFragmentDoc}`;

/**
 * __useNetworkFriendsQuery__
 *
 * To run a query within a React component, call `useNetworkFriendsQuery` and pass it any options that fit your needs.
 * When your component renders, `useNetworkFriendsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNetworkFriendsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      networkId: // value for 'networkId'
 *      queryParams: // value for 'queryParams'
 *   },
 * });
 */
export function useNetworkFriendsQuery(baseOptions: Apollo.QueryHookOptions<NetworkFriendsQuery, NetworkFriendsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NetworkFriendsQuery, NetworkFriendsQueryVariables>(NetworkFriendsDocument, options);
      }
export function useNetworkFriendsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NetworkFriendsQuery, NetworkFriendsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NetworkFriendsQuery, NetworkFriendsQueryVariables>(NetworkFriendsDocument, options);
        }
export type NetworkFriendsQueryHookResult = ReturnType<typeof useNetworkFriendsQuery>;
export type NetworkFriendsLazyQueryHookResult = ReturnType<typeof useNetworkFriendsLazyQuery>;
export type NetworkFriendsQueryResult = Apollo.QueryResult<NetworkFriendsQuery, NetworkFriendsQueryVariables>;
export const NetworkTalentsDocument = gql`
    query networkTalents($userId: ID!, $networkId: ID!, $queryParams: String = "") {
  networkTalents(
    userId: $userId
    networkId: $networkId
    queryParams: $queryParams
  ) @rest(type: "NetworkTalents", path: "/my/{args.userId}/network/{args.networkId}/object{args.queryParams}", method: "GET") {
    collection @type(name: "Talent") {
      ...talent
    }
    meta {
      range {
        from
        to
        total
      }
      sortOptions {
        key
        value
      }
    }
  }
}
    ${TalentFragmentDoc}`;

/**
 * __useNetworkTalentsQuery__
 *
 * To run a query within a React component, call `useNetworkTalentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useNetworkTalentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNetworkTalentsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      networkId: // value for 'networkId'
 *      queryParams: // value for 'queryParams'
 *   },
 * });
 */
export function useNetworkTalentsQuery(baseOptions: Apollo.QueryHookOptions<NetworkTalentsQuery, NetworkTalentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NetworkTalentsQuery, NetworkTalentsQueryVariables>(NetworkTalentsDocument, options);
      }
export function useNetworkTalentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NetworkTalentsQuery, NetworkTalentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NetworkTalentsQuery, NetworkTalentsQueryVariables>(NetworkTalentsDocument, options);
        }
export type NetworkTalentsQueryHookResult = ReturnType<typeof useNetworkTalentsQuery>;
export type NetworkTalentsLazyQueryHookResult = ReturnType<typeof useNetworkTalentsLazyQuery>;
export type NetworkTalentsQueryResult = Apollo.QueryResult<NetworkTalentsQuery, NetworkTalentsQueryVariables>;
export const SearchNetworkTalentsDocument = gql`
    query searchNetworkTalents($userId: String!, $query: String!, $networkId: String!) {
  searchNetworkTalents(userId: $userId, query: $query, networkId: $networkId) @rest(type: "NetworkTalents", path: "/my/{args.userId}/network/{args.networkId}/object/search?query={args.query}", method: "GET") {
    collection @type(name: "Talent") {
      ...talent
    }
    meta {
      range {
        from
        to
        total
      }
      sortOptions {
        key
        value
      }
    }
  }
}
    ${TalentFragmentDoc}`;

/**
 * __useSearchNetworkTalentsQuery__
 *
 * To run a query within a React component, call `useSearchNetworkTalentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchNetworkTalentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchNetworkTalentsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      query: // value for 'query'
 *      networkId: // value for 'networkId'
 *   },
 * });
 */
export function useSearchNetworkTalentsQuery(baseOptions: Apollo.QueryHookOptions<SearchNetworkTalentsQuery, SearchNetworkTalentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchNetworkTalentsQuery, SearchNetworkTalentsQueryVariables>(SearchNetworkTalentsDocument, options);
      }
export function useSearchNetworkTalentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchNetworkTalentsQuery, SearchNetworkTalentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchNetworkTalentsQuery, SearchNetworkTalentsQueryVariables>(SearchNetworkTalentsDocument, options);
        }
export type SearchNetworkTalentsQueryHookResult = ReturnType<typeof useSearchNetworkTalentsQuery>;
export type SearchNetworkTalentsLazyQueryHookResult = ReturnType<typeof useSearchNetworkTalentsLazyQuery>;
export type SearchNetworkTalentsQueryResult = Apollo.QueryResult<SearchNetworkTalentsQuery, SearchNetworkTalentsQueryVariables>;
export const AddNetworkDocument = gql`
    mutation addNetwork($input: NetworkInput!) {
  addNetwork(input: $input) @rest(type: "Network", path: "/network", method: "POST") {
    ...network
  }
}
    ${NetworkFragmentDoc}`;
export type AddNetworkMutationFn = Apollo.MutationFunction<AddNetworkMutation, AddNetworkMutationVariables>;

/**
 * __useAddNetworkMutation__
 *
 * To run a mutation, you first call `useAddNetworkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddNetworkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addNetworkMutation, { data, loading, error }] = useAddNetworkMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddNetworkMutation(baseOptions?: Apollo.MutationHookOptions<AddNetworkMutation, AddNetworkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddNetworkMutation, AddNetworkMutationVariables>(AddNetworkDocument, options);
      }
export type AddNetworkMutationHookResult = ReturnType<typeof useAddNetworkMutation>;
export type AddNetworkMutationResult = Apollo.MutationResult<AddNetworkMutation>;
export type AddNetworkMutationOptions = Apollo.BaseMutationOptions<AddNetworkMutation, AddNetworkMutationVariables>;
export const EditNetworkDocument = gql`
    mutation editNetwork($id: ID!, $input: NetworkInput!) {
  editNetwork(id: $id, input: $input) @rest(type: "Network", path: "/network/{args.id}", method: "PATCH") {
    ...network
  }
}
    ${NetworkFragmentDoc}`;
export type EditNetworkMutationFn = Apollo.MutationFunction<EditNetworkMutation, EditNetworkMutationVariables>;

/**
 * __useEditNetworkMutation__
 *
 * To run a mutation, you first call `useEditNetworkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditNetworkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editNetworkMutation, { data, loading, error }] = useEditNetworkMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditNetworkMutation(baseOptions?: Apollo.MutationHookOptions<EditNetworkMutation, EditNetworkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditNetworkMutation, EditNetworkMutationVariables>(EditNetworkDocument, options);
      }
export type EditNetworkMutationHookResult = ReturnType<typeof useEditNetworkMutation>;
export type EditNetworkMutationResult = Apollo.MutationResult<EditNetworkMutation>;
export type EditNetworkMutationOptions = Apollo.BaseMutationOptions<EditNetworkMutation, EditNetworkMutationVariables>;
export const DeleteNetworkDocument = gql`
    mutation deleteNetwork($id: ID!) {
  deleteNetwork(id: $id) @rest(path: "/network/{args.id}", method: "DELETE")
}
    `;
export type DeleteNetworkMutationFn = Apollo.MutationFunction<DeleteNetworkMutation, DeleteNetworkMutationVariables>;

/**
 * __useDeleteNetworkMutation__
 *
 * To run a mutation, you first call `useDeleteNetworkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteNetworkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteNetworkMutation, { data, loading, error }] = useDeleteNetworkMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteNetworkMutation(baseOptions?: Apollo.MutationHookOptions<DeleteNetworkMutation, DeleteNetworkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteNetworkMutation, DeleteNetworkMutationVariables>(DeleteNetworkDocument, options);
      }
export type DeleteNetworkMutationHookResult = ReturnType<typeof useDeleteNetworkMutation>;
export type DeleteNetworkMutationResult = Apollo.MutationResult<DeleteNetworkMutation>;
export type DeleteNetworkMutationOptions = Apollo.BaseMutationOptions<DeleteNetworkMutation, DeleteNetworkMutationVariables>;
export const AddTalentsToNetworkDocument = gql`
    mutation addTalentsToNetwork($id: ID!, $talents: NetworkTalentsInput!) {
  addTalentsToNetwork(id: $id, input: $talents) @rest(type: "Network", path: "/network/{args.id}&method=LINK", method: "PUT") {
    ...network
  }
}
    ${NetworkFragmentDoc}`;
export type AddTalentsToNetworkMutationFn = Apollo.MutationFunction<AddTalentsToNetworkMutation, AddTalentsToNetworkMutationVariables>;

/**
 * __useAddTalentsToNetworkMutation__
 *
 * To run a mutation, you first call `useAddTalentsToNetworkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddTalentsToNetworkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addTalentsToNetworkMutation, { data, loading, error }] = useAddTalentsToNetworkMutation({
 *   variables: {
 *      id: // value for 'id'
 *      talents: // value for 'talents'
 *   },
 * });
 */
export function useAddTalentsToNetworkMutation(baseOptions?: Apollo.MutationHookOptions<AddTalentsToNetworkMutation, AddTalentsToNetworkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddTalentsToNetworkMutation, AddTalentsToNetworkMutationVariables>(AddTalentsToNetworkDocument, options);
      }
export type AddTalentsToNetworkMutationHookResult = ReturnType<typeof useAddTalentsToNetworkMutation>;
export type AddTalentsToNetworkMutationResult = Apollo.MutationResult<AddTalentsToNetworkMutation>;
export type AddTalentsToNetworkMutationOptions = Apollo.BaseMutationOptions<AddTalentsToNetworkMutation, AddTalentsToNetworkMutationVariables>;
export const AddUsersToNetworkDocument = gql`
    mutation addUsersToNetwork($id: ID!, $user: NetworkUsersInput!) {
  addUsersToNetwork(id: $id, input: $user) @rest(type: "Network", path: "/network/{args.id}&method=LINK", method: "PUT") {
    ...network
  }
}
    ${NetworkFragmentDoc}`;
export type AddUsersToNetworkMutationFn = Apollo.MutationFunction<AddUsersToNetworkMutation, AddUsersToNetworkMutationVariables>;

/**
 * __useAddUsersToNetworkMutation__
 *
 * To run a mutation, you first call `useAddUsersToNetworkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddUsersToNetworkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addUsersToNetworkMutation, { data, loading, error }] = useAddUsersToNetworkMutation({
 *   variables: {
 *      id: // value for 'id'
 *      user: // value for 'user'
 *   },
 * });
 */
export function useAddUsersToNetworkMutation(baseOptions?: Apollo.MutationHookOptions<AddUsersToNetworkMutation, AddUsersToNetworkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddUsersToNetworkMutation, AddUsersToNetworkMutationVariables>(AddUsersToNetworkDocument, options);
      }
export type AddUsersToNetworkMutationHookResult = ReturnType<typeof useAddUsersToNetworkMutation>;
export type AddUsersToNetworkMutationResult = Apollo.MutationResult<AddUsersToNetworkMutation>;
export type AddUsersToNetworkMutationOptions = Apollo.BaseMutationOptions<AddUsersToNetworkMutation, AddUsersToNetworkMutationVariables>;
export const RemoveUserFromNetworkDocument = gql`
    mutation removeUserFromNetwork($id: ID!, $user: NetworkUsersInput!) {
  removeUserFromNetwork(id: $id, input: $user) @rest(type: "Network", path: "/network/{args.id}&method=UNLINK", method: "PUT") {
    ...network
  }
}
    ${NetworkFragmentDoc}`;
export type RemoveUserFromNetworkMutationFn = Apollo.MutationFunction<RemoveUserFromNetworkMutation, RemoveUserFromNetworkMutationVariables>;

/**
 * __useRemoveUserFromNetworkMutation__
 *
 * To run a mutation, you first call `useRemoveUserFromNetworkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveUserFromNetworkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeUserFromNetworkMutation, { data, loading, error }] = useRemoveUserFromNetworkMutation({
 *   variables: {
 *      id: // value for 'id'
 *      user: // value for 'user'
 *   },
 * });
 */
export function useRemoveUserFromNetworkMutation(baseOptions?: Apollo.MutationHookOptions<RemoveUserFromNetworkMutation, RemoveUserFromNetworkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveUserFromNetworkMutation, RemoveUserFromNetworkMutationVariables>(RemoveUserFromNetworkDocument, options);
      }
export type RemoveUserFromNetworkMutationHookResult = ReturnType<typeof useRemoveUserFromNetworkMutation>;
export type RemoveUserFromNetworkMutationResult = Apollo.MutationResult<RemoveUserFromNetworkMutation>;
export type RemoveUserFromNetworkMutationOptions = Apollo.BaseMutationOptions<RemoveUserFromNetworkMutation, RemoveUserFromNetworkMutationVariables>;
export const ToggleModDocument = gql`
    mutation toggleMod($id: String!, $networkId: String!, $idUserTarget: String!) {
  toggleMod(
    id: $id
    networkId: $networkId
    idUserTarget: $idUserTarget
    input: "💩"
  ) @rest(type: "Network", path: "/my/{args.id}/network/{args.networkId}/toggle_mod&idUserTarget={args.idUserTarget}", method: "PATCH", bodySerializer: "noBody") {
    id
  }
}
    `;
export type ToggleModMutationFn = Apollo.MutationFunction<ToggleModMutation, ToggleModMutationVariables>;

/**
 * __useToggleModMutation__
 *
 * To run a mutation, you first call `useToggleModMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleModMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleModMutation, { data, loading, error }] = useToggleModMutation({
 *   variables: {
 *      id: // value for 'id'
 *      networkId: // value for 'networkId'
 *      idUserTarget: // value for 'idUserTarget'
 *   },
 * });
 */
export function useToggleModMutation(baseOptions?: Apollo.MutationHookOptions<ToggleModMutation, ToggleModMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ToggleModMutation, ToggleModMutationVariables>(ToggleModDocument, options);
      }
export type ToggleModMutationHookResult = ReturnType<typeof useToggleModMutation>;
export type ToggleModMutationResult = Apollo.MutationResult<ToggleModMutation>;
export type ToggleModMutationOptions = Apollo.BaseMutationOptions<ToggleModMutation, ToggleModMutationVariables>;
export const TransferOwnerDocument = gql`
    mutation transferOwner($id: String!, $networkId: String!, $idUserTarget: String!) {
  transferOwner(
    id: $id
    networkId: $networkId
    idUserTarget: $idUserTarget
    input: "💩"
  ) @rest(type: "Network", path: "/my/{args.id}/network/{args.networkId}/transfer_owner&idUserTarget={args.idUserTarget}", method: "PATCH", bodySerializer: "noBody") {
    id
  }
}
    `;
export type TransferOwnerMutationFn = Apollo.MutationFunction<TransferOwnerMutation, TransferOwnerMutationVariables>;

/**
 * __useTransferOwnerMutation__
 *
 * To run a mutation, you first call `useTransferOwnerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTransferOwnerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [transferOwnerMutation, { data, loading, error }] = useTransferOwnerMutation({
 *   variables: {
 *      id: // value for 'id'
 *      networkId: // value for 'networkId'
 *      idUserTarget: // value for 'idUserTarget'
 *   },
 * });
 */
export function useTransferOwnerMutation(baseOptions?: Apollo.MutationHookOptions<TransferOwnerMutation, TransferOwnerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TransferOwnerMutation, TransferOwnerMutationVariables>(TransferOwnerDocument, options);
      }
export type TransferOwnerMutationHookResult = ReturnType<typeof useTransferOwnerMutation>;
export type TransferOwnerMutationResult = Apollo.MutationResult<TransferOwnerMutation>;
export type TransferOwnerMutationOptions = Apollo.BaseMutationOptions<TransferOwnerMutation, TransferOwnerMutationVariables>;
export const RemoveTalentFromNetworkDocument = gql`
    mutation removeTalentFromNetwork($id: ID!, $talent: NetworkTalentsInput!) {
  removeTalentFromNetwork(id: $id, input: $talent) @rest(type: "Network", path: "/network/{args.id}&method=UNLINK", method: "PUT") {
    ...network
  }
}
    ${NetworkFragmentDoc}`;
export type RemoveTalentFromNetworkMutationFn = Apollo.MutationFunction<RemoveTalentFromNetworkMutation, RemoveTalentFromNetworkMutationVariables>;

/**
 * __useRemoveTalentFromNetworkMutation__
 *
 * To run a mutation, you first call `useRemoveTalentFromNetworkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveTalentFromNetworkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeTalentFromNetworkMutation, { data, loading, error }] = useRemoveTalentFromNetworkMutation({
 *   variables: {
 *      id: // value for 'id'
 *      talent: // value for 'talent'
 *   },
 * });
 */
export function useRemoveTalentFromNetworkMutation(baseOptions?: Apollo.MutationHookOptions<RemoveTalentFromNetworkMutation, RemoveTalentFromNetworkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveTalentFromNetworkMutation, RemoveTalentFromNetworkMutationVariables>(RemoveTalentFromNetworkDocument, options);
      }
export type RemoveTalentFromNetworkMutationHookResult = ReturnType<typeof useRemoveTalentFromNetworkMutation>;
export type RemoveTalentFromNetworkMutationResult = Apollo.MutationResult<RemoveTalentFromNetworkMutation>;
export type RemoveTalentFromNetworkMutationOptions = Apollo.BaseMutationOptions<RemoveTalentFromNetworkMutation, RemoveTalentFromNetworkMutationVariables>;
export const NetworkLinkImageDocument = gql`
    mutation networkLinkImage($url: String!, $networkId: String!) {
  networkLinkImage(input: {_links: {image: {url: $url}}}, networkId: $networkId) @rest(type: "Network", method: "PUT", path: "/network/{args.networkId}&method=LINK") {
    ...network
  }
}
    ${NetworkFragmentDoc}`;
export type NetworkLinkImageMutationFn = Apollo.MutationFunction<NetworkLinkImageMutation, NetworkLinkImageMutationVariables>;

/**
 * __useNetworkLinkImageMutation__
 *
 * To run a mutation, you first call `useNetworkLinkImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNetworkLinkImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [networkLinkImageMutation, { data, loading, error }] = useNetworkLinkImageMutation({
 *   variables: {
 *      url: // value for 'url'
 *      networkId: // value for 'networkId'
 *   },
 * });
 */
export function useNetworkLinkImageMutation(baseOptions?: Apollo.MutationHookOptions<NetworkLinkImageMutation, NetworkLinkImageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<NetworkLinkImageMutation, NetworkLinkImageMutationVariables>(NetworkLinkImageDocument, options);
      }
export type NetworkLinkImageMutationHookResult = ReturnType<typeof useNetworkLinkImageMutation>;
export type NetworkLinkImageMutationResult = Apollo.MutationResult<NetworkLinkImageMutation>;
export type NetworkLinkImageMutationOptions = Apollo.BaseMutationOptions<NetworkLinkImageMutation, NetworkLinkImageMutationVariables>;
export const NetworkUnlinkImageDocument = gql`
    mutation networkUnlinkImage($url: String!, $networkId: String!) {
  networkUnlinkImage(input: {_links: {image: {url: $url}}}, networkId: $networkId) @rest(type: "Network", method: "PUT", path: "/network/{args.networkId}&method=UNLINK") {
    ...network
  }
}
    ${NetworkFragmentDoc}`;
export type NetworkUnlinkImageMutationFn = Apollo.MutationFunction<NetworkUnlinkImageMutation, NetworkUnlinkImageMutationVariables>;

/**
 * __useNetworkUnlinkImageMutation__
 *
 * To run a mutation, you first call `useNetworkUnlinkImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNetworkUnlinkImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [networkUnlinkImageMutation, { data, loading, error }] = useNetworkUnlinkImageMutation({
 *   variables: {
 *      url: // value for 'url'
 *      networkId: // value for 'networkId'
 *   },
 * });
 */
export function useNetworkUnlinkImageMutation(baseOptions?: Apollo.MutationHookOptions<NetworkUnlinkImageMutation, NetworkUnlinkImageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<NetworkUnlinkImageMutation, NetworkUnlinkImageMutationVariables>(NetworkUnlinkImageDocument, options);
      }
export type NetworkUnlinkImageMutationHookResult = ReturnType<typeof useNetworkUnlinkImageMutation>;
export type NetworkUnlinkImageMutationResult = Apollo.MutationResult<NetworkUnlinkImageMutation>;
export type NetworkUnlinkImageMutationOptions = Apollo.BaseMutationOptions<NetworkUnlinkImageMutation, NetworkUnlinkImageMutationVariables>;
export const SearchNetworkDocument = gql`
    query searchNetwork($query: String!, $sort: String = "&sort=newestFirst", $queryParams: String = "") {
  searchNetwork(query: $query, sort: $sort, queryParams: $queryParams) @rest(type: "SearchNetworkCollection", path: "/network/search&query={args.query}{args.sort}{args.queryParams}", method: "GET") {
    meta {
      range {
        from
        to
        total
      }
      sortOptions {
        value
        key
      }
    }
    collection @type(name: "Network") {
      ...networkSearchResult
    }
  }
}
    ${NetworkSearchResultFragmentDoc}`;

/**
 * __useSearchNetworkQuery__
 *
 * To run a query within a React component, call `useSearchNetworkQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchNetworkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchNetworkQuery({
 *   variables: {
 *      query: // value for 'query'
 *      sort: // value for 'sort'
 *      queryParams: // value for 'queryParams'
 *   },
 * });
 */
export function useSearchNetworkQuery(baseOptions: Apollo.QueryHookOptions<SearchNetworkQuery, SearchNetworkQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchNetworkQuery, SearchNetworkQueryVariables>(SearchNetworkDocument, options);
      }
export function useSearchNetworkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchNetworkQuery, SearchNetworkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchNetworkQuery, SearchNetworkQueryVariables>(SearchNetworkDocument, options);
        }
export type SearchNetworkQueryHookResult = ReturnType<typeof useSearchNetworkQuery>;
export type SearchNetworkLazyQueryHookResult = ReturnType<typeof useSearchNetworkLazyQuery>;
export type SearchNetworkQueryResult = Apollo.QueryResult<SearchNetworkQuery, SearchNetworkQueryVariables>;
export const JmtNetworksDocument = gql`
    query jmtNetworks {
  jmtNetworks @rest(type: "Network", path: "/makro/jmtnetworks", method: "GET") {
    JMTnetworks {
      collection @type(name: "Network") {
        ...jmtNetwork
      }
    }
  }
}
    ${JmtNetworkFragmentDoc}`;

/**
 * __useJmtNetworksQuery__
 *
 * To run a query within a React component, call `useJmtNetworksQuery` and pass it any options that fit your needs.
 * When your component renders, `useJmtNetworksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJmtNetworksQuery({
 *   variables: {
 *   },
 * });
 */
export function useJmtNetworksQuery(baseOptions?: Apollo.QueryHookOptions<JmtNetworksQuery, JmtNetworksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<JmtNetworksQuery, JmtNetworksQueryVariables>(JmtNetworksDocument, options);
      }
export function useJmtNetworksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<JmtNetworksQuery, JmtNetworksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<JmtNetworksQuery, JmtNetworksQueryVariables>(JmtNetworksDocument, options);
        }
export type JmtNetworksQueryHookResult = ReturnType<typeof useJmtNetworksQuery>;
export type JmtNetworksLazyQueryHookResult = ReturnType<typeof useJmtNetworksLazyQuery>;
export type JmtNetworksQueryResult = Apollo.QueryResult<JmtNetworksQuery, JmtNetworksQueryVariables>;
export const RelatedUsersFromMyNetworksDocument = gql`
    query relatedUsersFromMyNetworks($userId: ID!, $queryParams: String = "") {
  networksWithUsers(userId: $userId, queryParams: $queryParams) @rest(type: "NetworkWithUsers", path: "/my/{args.userId}/network{args.queryParams}", method: "GET") {
    collection @type(name: "Network") {
      id @export(as: "networkId")
      caption
      description
      public
      open
      bugTrackerEnabled
      networkRoles
      image
      users(uId: $userId) @rest(type: "NetworkUsers", path: "/my/{args.uId}/network/{exportVariables.networkId}/user", method: "GET") {
        collection @type(name: "User") {
          ...user
          id @export(as: "memberId")
          userMetaData @rest(type: "UserMetaDataResponse", path: "/makro/userdata/{exportVariables.memberId}", method: "GET") {
            ...userMetadataResponse
          }
        }
      }
    }
    meta {
      range {
        from
        to
        total
      }
      sortOptions {
        key
        value
      }
    }
  }
}
    ${UserFragmentDoc}
${UserMetadataResponseFragmentDoc}`;

/**
 * __useRelatedUsersFromMyNetworksQuery__
 *
 * To run a query within a React component, call `useRelatedUsersFromMyNetworksQuery` and pass it any options that fit your needs.
 * When your component renders, `useRelatedUsersFromMyNetworksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRelatedUsersFromMyNetworksQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      queryParams: // value for 'queryParams'
 *   },
 * });
 */
export function useRelatedUsersFromMyNetworksQuery(baseOptions: Apollo.QueryHookOptions<RelatedUsersFromMyNetworksQuery, RelatedUsersFromMyNetworksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RelatedUsersFromMyNetworksQuery, RelatedUsersFromMyNetworksQueryVariables>(RelatedUsersFromMyNetworksDocument, options);
      }
export function useRelatedUsersFromMyNetworksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RelatedUsersFromMyNetworksQuery, RelatedUsersFromMyNetworksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RelatedUsersFromMyNetworksQuery, RelatedUsersFromMyNetworksQueryVariables>(RelatedUsersFromMyNetworksDocument, options);
        }
export type RelatedUsersFromMyNetworksQueryHookResult = ReturnType<typeof useRelatedUsersFromMyNetworksQuery>;
export type RelatedUsersFromMyNetworksLazyQueryHookResult = ReturnType<typeof useRelatedUsersFromMyNetworksLazyQuery>;
export type RelatedUsersFromMyNetworksQueryResult = Apollo.QueryResult<RelatedUsersFromMyNetworksQuery, RelatedUsersFromMyNetworksQueryVariables>;
export const InviteOrApplyToNetworkDocument = gql`
    mutation inviteOrApplyToNetwork($input: NetworkInvitationInput!) {
  inviteOrApplyToNetwork(input: $input) @rest(type: "NetworkInvitation", path: "/invitation", method: "POST") {
    networkId
    network @type(name: "Network") {
      ...network
    }
    targetUser @type(name: "User") {
      ...user
    }
    targetUserId
    type
    message
    status
  }
}
    ${NetworkFragmentDoc}
${UserFragmentDoc}`;
export type InviteOrApplyToNetworkMutationFn = Apollo.MutationFunction<InviteOrApplyToNetworkMutation, InviteOrApplyToNetworkMutationVariables>;

/**
 * __useInviteOrApplyToNetworkMutation__
 *
 * To run a mutation, you first call `useInviteOrApplyToNetworkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteOrApplyToNetworkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteOrApplyToNetworkMutation, { data, loading, error }] = useInviteOrApplyToNetworkMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInviteOrApplyToNetworkMutation(baseOptions?: Apollo.MutationHookOptions<InviteOrApplyToNetworkMutation, InviteOrApplyToNetworkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InviteOrApplyToNetworkMutation, InviteOrApplyToNetworkMutationVariables>(InviteOrApplyToNetworkDocument, options);
      }
export type InviteOrApplyToNetworkMutationHookResult = ReturnType<typeof useInviteOrApplyToNetworkMutation>;
export type InviteOrApplyToNetworkMutationResult = Apollo.MutationResult<InviteOrApplyToNetworkMutation>;
export type InviteOrApplyToNetworkMutationOptions = Apollo.BaseMutationOptions<InviteOrApplyToNetworkMutation, InviteOrApplyToNetworkMutationVariables>;
export const AcceptOrDeclineNetworkInvitationDocument = gql`
    mutation acceptOrDeclineNetworkInvitation($id: ID!, $input: NetworkApplyAnswerInput!) {
  acceptOrDeclineNetworkInvitation(id: $id, input: $input) @rest(type: "NetworkInvitation", path: "/invitation/{args.id}", method: "PATCH") {
    networkId
    network @type(name: "Network") {
      ...network
    }
    targetUser @type(name: "User") {
      ...user
    }
    targetUserId
    type
    message
    status
  }
}
    ${NetworkFragmentDoc}
${UserFragmentDoc}`;
export type AcceptOrDeclineNetworkInvitationMutationFn = Apollo.MutationFunction<AcceptOrDeclineNetworkInvitationMutation, AcceptOrDeclineNetworkInvitationMutationVariables>;

/**
 * __useAcceptOrDeclineNetworkInvitationMutation__
 *
 * To run a mutation, you first call `useAcceptOrDeclineNetworkInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptOrDeclineNetworkInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptOrDeclineNetworkInvitationMutation, { data, loading, error }] = useAcceptOrDeclineNetworkInvitationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAcceptOrDeclineNetworkInvitationMutation(baseOptions?: Apollo.MutationHookOptions<AcceptOrDeclineNetworkInvitationMutation, AcceptOrDeclineNetworkInvitationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AcceptOrDeclineNetworkInvitationMutation, AcceptOrDeclineNetworkInvitationMutationVariables>(AcceptOrDeclineNetworkInvitationDocument, options);
      }
export type AcceptOrDeclineNetworkInvitationMutationHookResult = ReturnType<typeof useAcceptOrDeclineNetworkInvitationMutation>;
export type AcceptOrDeclineNetworkInvitationMutationResult = Apollo.MutationResult<AcceptOrDeclineNetworkInvitationMutation>;
export type AcceptOrDeclineNetworkInvitationMutationOptions = Apollo.BaseMutationOptions<AcceptOrDeclineNetworkInvitationMutation, AcceptOrDeclineNetworkInvitationMutationVariables>;
export const CreateNetworkTokenInvitationDocument = gql`
    mutation createNetworkTokenInvitation($userId: ID!, $networkId: ID!, $input: NetworkTokenInvitationInput!) {
  createNetworkTokenInvitation(
    input: $input
    networkId: $networkId
    userId: $userId
  ) @rest(type: "NetworkTokenInvitation", path: "/my/{args.userId}/network/{args.networkId}/invitation", method: "POST") {
    networkId
    network @type(name: "Network") {
      ...network
    }
    user @type(name: "User") {
      ...user
    }
    userId
    type
    tokens @type(name: "NetworkInvitationToken") {
      email
      token
    }
  }
}
    ${NetworkFragmentDoc}
${UserFragmentDoc}`;
export type CreateNetworkTokenInvitationMutationFn = Apollo.MutationFunction<CreateNetworkTokenInvitationMutation, CreateNetworkTokenInvitationMutationVariables>;

/**
 * __useCreateNetworkTokenInvitationMutation__
 *
 * To run a mutation, you first call `useCreateNetworkTokenInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNetworkTokenInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNetworkTokenInvitationMutation, { data, loading, error }] = useCreateNetworkTokenInvitationMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      networkId: // value for 'networkId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateNetworkTokenInvitationMutation(baseOptions?: Apollo.MutationHookOptions<CreateNetworkTokenInvitationMutation, CreateNetworkTokenInvitationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateNetworkTokenInvitationMutation, CreateNetworkTokenInvitationMutationVariables>(CreateNetworkTokenInvitationDocument, options);
      }
export type CreateNetworkTokenInvitationMutationHookResult = ReturnType<typeof useCreateNetworkTokenInvitationMutation>;
export type CreateNetworkTokenInvitationMutationResult = Apollo.MutationResult<CreateNetworkTokenInvitationMutation>;
export type CreateNetworkTokenInvitationMutationOptions = Apollo.BaseMutationOptions<CreateNetworkTokenInvitationMutation, CreateNetworkTokenInvitationMutationVariables>;
export const JoinNetworkByTokenDocument = gql`
    mutation joinNetworkByToken($input: NetworkTokenInput!) {
  joinNetworkByToken(input: $input) @rest(type: "Network", path: "/user/doi", method: "POST") {
    ...network
  }
}
    ${NetworkFragmentDoc}`;
export type JoinNetworkByTokenMutationFn = Apollo.MutationFunction<JoinNetworkByTokenMutation, JoinNetworkByTokenMutationVariables>;

/**
 * __useJoinNetworkByTokenMutation__
 *
 * To run a mutation, you first call `useJoinNetworkByTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJoinNetworkByTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [joinNetworkByTokenMutation, { data, loading, error }] = useJoinNetworkByTokenMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useJoinNetworkByTokenMutation(baseOptions?: Apollo.MutationHookOptions<JoinNetworkByTokenMutation, JoinNetworkByTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<JoinNetworkByTokenMutation, JoinNetworkByTokenMutationVariables>(JoinNetworkByTokenDocument, options);
      }
export type JoinNetworkByTokenMutationHookResult = ReturnType<typeof useJoinNetworkByTokenMutation>;
export type JoinNetworkByTokenMutationResult = Apollo.MutationResult<JoinNetworkByTokenMutation>;
export type JoinNetworkByTokenMutationOptions = Apollo.BaseMutationOptions<JoinNetworkByTokenMutation, JoinNetworkByTokenMutationVariables>;
export const ApplyNetworkWithTalentDocument = gql`
    mutation applyNetworkWithTalent($input: ApplyNetworkWithTalentInput) {
  applyNetworkWithTalent(input: $input) @rest(type: "MessageResponse", path: "/makro/applynetworkwithtalent", method: "POST") {
    message
  }
}
    `;
export type ApplyNetworkWithTalentMutationFn = Apollo.MutationFunction<ApplyNetworkWithTalentMutation, ApplyNetworkWithTalentMutationVariables>;

/**
 * __useApplyNetworkWithTalentMutation__
 *
 * To run a mutation, you first call `useApplyNetworkWithTalentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApplyNetworkWithTalentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [applyNetworkWithTalentMutation, { data, loading, error }] = useApplyNetworkWithTalentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useApplyNetworkWithTalentMutation(baseOptions?: Apollo.MutationHookOptions<ApplyNetworkWithTalentMutation, ApplyNetworkWithTalentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApplyNetworkWithTalentMutation, ApplyNetworkWithTalentMutationVariables>(ApplyNetworkWithTalentDocument, options);
      }
export type ApplyNetworkWithTalentMutationHookResult = ReturnType<typeof useApplyNetworkWithTalentMutation>;
export type ApplyNetworkWithTalentMutationResult = Apollo.MutationResult<ApplyNetworkWithTalentMutation>;
export type ApplyNetworkWithTalentMutationOptions = Apollo.BaseMutationOptions<ApplyNetworkWithTalentMutation, ApplyNetworkWithTalentMutationVariables>;
export const InviteNetworkWithTalentDocument = gql`
    mutation inviteNetworkWithTalent($input: InviteNetworkWithTalentInput) {
  inviteNetworkWithTalent(input: $input) @rest(type: "MessageResponse", path: "/makro/invitenetworkwithtalent", method: "POST") {
    message
  }
}
    `;
export type InviteNetworkWithTalentMutationFn = Apollo.MutationFunction<InviteNetworkWithTalentMutation, InviteNetworkWithTalentMutationVariables>;

/**
 * __useInviteNetworkWithTalentMutation__
 *
 * To run a mutation, you first call `useInviteNetworkWithTalentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteNetworkWithTalentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteNetworkWithTalentMutation, { data, loading, error }] = useInviteNetworkWithTalentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInviteNetworkWithTalentMutation(baseOptions?: Apollo.MutationHookOptions<InviteNetworkWithTalentMutation, InviteNetworkWithTalentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InviteNetworkWithTalentMutation, InviteNetworkWithTalentMutationVariables>(InviteNetworkWithTalentDocument, options);
      }
export type InviteNetworkWithTalentMutationHookResult = ReturnType<typeof useInviteNetworkWithTalentMutation>;
export type InviteNetworkWithTalentMutationResult = Apollo.MutationResult<InviteNetworkWithTalentMutation>;
export type InviteNetworkWithTalentMutationOptions = Apollo.BaseMutationOptions<InviteNetworkWithTalentMutation, InviteNetworkWithTalentMutationVariables>;
export const RoleModelIdsDocument = gql`
    query roleModelIds {
  roleModelIds @rest(type: "Int", path: "/makro/rolemodelids")
}
    `;

/**
 * __useRoleModelIdsQuery__
 *
 * To run a query within a React component, call `useRoleModelIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRoleModelIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRoleModelIdsQuery({
 *   variables: {
 *   },
 * });
 */
export function useRoleModelIdsQuery(baseOptions?: Apollo.QueryHookOptions<RoleModelIdsQuery, RoleModelIdsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RoleModelIdsQuery, RoleModelIdsQueryVariables>(RoleModelIdsDocument, options);
      }
export function useRoleModelIdsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RoleModelIdsQuery, RoleModelIdsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RoleModelIdsQuery, RoleModelIdsQueryVariables>(RoleModelIdsDocument, options);
        }
export type RoleModelIdsQueryHookResult = ReturnType<typeof useRoleModelIdsQuery>;
export type RoleModelIdsLazyQueryHookResult = ReturnType<typeof useRoleModelIdsLazyQuery>;
export type RoleModelIdsQueryResult = Apollo.QueryResult<RoleModelIdsQuery, RoleModelIdsQueryVariables>;
export const TalentDocument = gql`
    query talent($userId: ID!, $talentId: ID!) {
  talent(userId: $userId, talentId: $talentId) @rest(type: "Talent", path: "/my/{args.userId}/object/{args.talentId}", method: "GET") {
    ...talent
  }
}
    ${TalentFragmentDoc}`;

/**
 * __useTalentQuery__
 *
 * To run a query within a React component, call `useTalentQuery` and pass it any options that fit your needs.
 * When your component renders, `useTalentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTalentQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      talentId: // value for 'talentId'
 *   },
 * });
 */
export function useTalentQuery(baseOptions: Apollo.QueryHookOptions<TalentQuery, TalentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TalentQuery, TalentQueryVariables>(TalentDocument, options);
      }
export function useTalentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TalentQuery, TalentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TalentQuery, TalentQueryVariables>(TalentDocument, options);
        }
export type TalentQueryHookResult = ReturnType<typeof useTalentQuery>;
export type TalentLazyQueryHookResult = ReturnType<typeof useTalentLazyQuery>;
export type TalentQueryResult = Apollo.QueryResult<TalentQuery, TalentQueryVariables>;
export const SharedTalentDocument = gql`
    query sharedTalent($hash: ID!) {
  sharedTalent(hash: $hash) @rest(type: "SharedTalentResponse", path: "/makro/shareTalent/{args.hash}", method: "GET") {
    talent @type(name: "Talent") {
      ...sharedTalent
    }
    roleModel @type(name: "Talent") {
      ...sharedTalent
    }
    sharedRoleModel
  }
}
    ${SharedTalentFragmentDoc}`;

/**
 * __useSharedTalentQuery__
 *
 * To run a query within a React component, call `useSharedTalentQuery` and pass it any options that fit your needs.
 * When your component renders, `useSharedTalentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSharedTalentQuery({
 *   variables: {
 *      hash: // value for 'hash'
 *   },
 * });
 */
export function useSharedTalentQuery(baseOptions: Apollo.QueryHookOptions<SharedTalentQuery, SharedTalentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SharedTalentQuery, SharedTalentQueryVariables>(SharedTalentDocument, options);
      }
export function useSharedTalentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SharedTalentQuery, SharedTalentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SharedTalentQuery, SharedTalentQueryVariables>(SharedTalentDocument, options);
        }
export type SharedTalentQueryHookResult = ReturnType<typeof useSharedTalentQuery>;
export type SharedTalentLazyQueryHookResult = ReturnType<typeof useSharedTalentLazyQuery>;
export type SharedTalentQueryResult = Apollo.QueryResult<SharedTalentQuery, SharedTalentQueryVariables>;
export const ListMyPersonificationsDocument = gql`
    query listMyPersonifications($userId: ID!, $queryParams: String = "") {
  listMyPersonifications(userId: $userId, queryParams: $queryParams) @rest(type: "NetworkTalentResponse", path: "/my/{args.userId}/user/{args.userId}/personification{args.queryParams}", method: "GET") {
    collection @type(name: "Talent") {
      ...talent
    }
    meta {
      range {
        from
        to
        total
      }
      sortOptions {
        key
        value
      }
    }
  }
}
    ${TalentFragmentDoc}`;

/**
 * __useListMyPersonificationsQuery__
 *
 * To run a query within a React component, call `useListMyPersonificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListMyPersonificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListMyPersonificationsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      queryParams: // value for 'queryParams'
 *   },
 * });
 */
export function useListMyPersonificationsQuery(baseOptions: Apollo.QueryHookOptions<ListMyPersonificationsQuery, ListMyPersonificationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListMyPersonificationsQuery, ListMyPersonificationsQueryVariables>(ListMyPersonificationsDocument, options);
      }
export function useListMyPersonificationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListMyPersonificationsQuery, ListMyPersonificationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListMyPersonificationsQuery, ListMyPersonificationsQueryVariables>(ListMyPersonificationsDocument, options);
        }
export type ListMyPersonificationsQueryHookResult = ReturnType<typeof useListMyPersonificationsQuery>;
export type ListMyPersonificationsLazyQueryHookResult = ReturnType<typeof useListMyPersonificationsLazyQuery>;
export type ListMyPersonificationsQueryResult = Apollo.QueryResult<ListMyPersonificationsQuery, ListMyPersonificationsQueryVariables>;
export const UserPersonificationDocument = gql`
    query userPersonification($userId: ID!, $queryParams: String = "") {
  userPersonification(userId: $userId, queryParams: $queryParams) @rest(type: "User", method: "GET", path: "/user/{args.userId}/personification{args.queryParams}") {
    collection @type(name: "Talent") {
      ...talent
    }
    meta {
      range {
        from
        to
        total
      }
      sortOptions {
        key
        value
      }
    }
  }
}
    ${TalentFragmentDoc}`;

/**
 * __useUserPersonificationQuery__
 *
 * To run a query within a React component, call `useUserPersonificationQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserPersonificationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserPersonificationQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      queryParams: // value for 'queryParams'
 *   },
 * });
 */
export function useUserPersonificationQuery(baseOptions: Apollo.QueryHookOptions<UserPersonificationQuery, UserPersonificationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserPersonificationQuery, UserPersonificationQueryVariables>(UserPersonificationDocument, options);
      }
export function useUserPersonificationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserPersonificationQuery, UserPersonificationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserPersonificationQuery, UserPersonificationQueryVariables>(UserPersonificationDocument, options);
        }
export type UserPersonificationQueryHookResult = ReturnType<typeof useUserPersonificationQuery>;
export type UserPersonificationLazyQueryHookResult = ReturnType<typeof useUserPersonificationLazyQuery>;
export type UserPersonificationQueryResult = Apollo.QueryResult<UserPersonificationQuery, UserPersonificationQueryVariables>;
export const ListMyTalentsDocument = gql`
    query listMyTalents($userId: ID!, $queryParams: String = "") {
  listMyTalents(userId: $userId, queryParams: $queryParams) @rest(type: "NetworkMyTalentResponse", path: "/my/{args.userId}/object{args.queryParams}", method: "GET") {
    collection @type(name: "Talent") {
      ...talent
    }
    meta {
      range {
        from
        to
        total
      }
      sortOptions {
        key
        value
      }
    }
  }
}
    ${TalentFragmentDoc}`;

/**
 * __useListMyTalentsQuery__
 *
 * To run a query within a React component, call `useListMyTalentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListMyTalentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListMyTalentsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      queryParams: // value for 'queryParams'
 *   },
 * });
 */
export function useListMyTalentsQuery(baseOptions: Apollo.QueryHookOptions<ListMyTalentsQuery, ListMyTalentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListMyTalentsQuery, ListMyTalentsQueryVariables>(ListMyTalentsDocument, options);
      }
export function useListMyTalentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListMyTalentsQuery, ListMyTalentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListMyTalentsQuery, ListMyTalentsQueryVariables>(ListMyTalentsDocument, options);
        }
export type ListMyTalentsQueryHookResult = ReturnType<typeof useListMyTalentsQuery>;
export type ListMyTalentsLazyQueryHookResult = ReturnType<typeof useListMyTalentsLazyQuery>;
export type ListMyTalentsQueryResult = Apollo.QueryResult<ListMyTalentsQuery, ListMyTalentsQueryVariables>;
export const ListMarketplaceTalentsDocument = gql`
    query listMarketplaceTalents($userId: ID!, $queryParams: String = "") {
  listMarketplaceTalents(userId: $userId, queryParams: $queryParams) @rest(type: "NetworkTalentResponse", path: "/object", method: "GET") {
    collection @type(name: "Talent") {
      ...talent
    }
    meta {
      range {
        from
        to
        total
      }
      sortOptions {
        key
        value
      }
    }
  }
}
    ${TalentFragmentDoc}`;

/**
 * __useListMarketplaceTalentsQuery__
 *
 * To run a query within a React component, call `useListMarketplaceTalentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListMarketplaceTalentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListMarketplaceTalentsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      queryParams: // value for 'queryParams'
 *   },
 * });
 */
export function useListMarketplaceTalentsQuery(baseOptions: Apollo.QueryHookOptions<ListMarketplaceTalentsQuery, ListMarketplaceTalentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListMarketplaceTalentsQuery, ListMarketplaceTalentsQueryVariables>(ListMarketplaceTalentsDocument, options);
      }
export function useListMarketplaceTalentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListMarketplaceTalentsQuery, ListMarketplaceTalentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListMarketplaceTalentsQuery, ListMarketplaceTalentsQueryVariables>(ListMarketplaceTalentsDocument, options);
        }
export type ListMarketplaceTalentsQueryHookResult = ReturnType<typeof useListMarketplaceTalentsQuery>;
export type ListMarketplaceTalentsLazyQueryHookResult = ReturnType<typeof useListMarketplaceTalentsLazyQuery>;
export type ListMarketplaceTalentsQueryResult = Apollo.QueryResult<ListMarketplaceTalentsQuery, ListMarketplaceTalentsQueryVariables>;
export const TalentClassDocument = gql`
    query talentClass($id: ID!) {
  talentClass(id: $id) @rest(type: "TalentClass", path: "/class/{args.id}", method: "GET") {
    id
    dataViews {
      id
      chartType
      withGroupLabels
      withLabelHover
      voteScalarGroups {
        scalars {
          caption
          formula
          offset
        }
      }
    }
  }
}
    `;

/**
 * __useTalentClassQuery__
 *
 * To run a query within a React component, call `useTalentClassQuery` and pass it any options that fit your needs.
 * When your component renders, `useTalentClassQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTalentClassQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTalentClassQuery(baseOptions: Apollo.QueryHookOptions<TalentClassQuery, TalentClassQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TalentClassQuery, TalentClassQueryVariables>(TalentClassDocument, options);
      }
export function useTalentClassLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TalentClassQuery, TalentClassQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TalentClassQuery, TalentClassQueryVariables>(TalentClassDocument, options);
        }
export type TalentClassQueryHookResult = ReturnType<typeof useTalentClassQuery>;
export type TalentClassLazyQueryHookResult = ReturnType<typeof useTalentClassLazyQuery>;
export type TalentClassQueryResult = Apollo.QueryResult<TalentClassQuery, TalentClassQueryVariables>;
export const SuggestTalentDocument = gql`
    query suggestTalent($q: String!) {
  suggestTalent(q: $q) @rest(type: "SuggestUser", path: "/object/suggest?q={args.q}", method: "GET") {
    name
  }
}
    `;

/**
 * __useSuggestTalentQuery__
 *
 * To run a query within a React component, call `useSuggestTalentQuery` and pass it any options that fit your needs.
 * When your component renders, `useSuggestTalentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSuggestTalentQuery({
 *   variables: {
 *      q: // value for 'q'
 *   },
 * });
 */
export function useSuggestTalentQuery(baseOptions: Apollo.QueryHookOptions<SuggestTalentQuery, SuggestTalentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SuggestTalentQuery, SuggestTalentQueryVariables>(SuggestTalentDocument, options);
      }
export function useSuggestTalentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SuggestTalentQuery, SuggestTalentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SuggestTalentQuery, SuggestTalentQueryVariables>(SuggestTalentDocument, options);
        }
export type SuggestTalentQueryHookResult = ReturnType<typeof useSuggestTalentQuery>;
export type SuggestTalentLazyQueryHookResult = ReturnType<typeof useSuggestTalentLazyQuery>;
export type SuggestTalentQueryResult = Apollo.QueryResult<SuggestTalentQuery, SuggestTalentQueryVariables>;
export const SearchTalentDocument = gql`
    query searchTalent($query: String!, $sort: String = "&sort=newestFirst", $age: String = "", $classId: String = "", $fieldPosition: String = "", $fifaCountry: String = "", $yearFrom: String = "", $yearTo: String = "", $networkId: String = "", $queryParams: String = "") {
  searchTalent(
    query: $query
    sort: $sort
    age: $age
    classId: $classId
    fieldPosition: $fieldPosition
    fifaCountry: $fifaCountry
    yearFrom: $yearFrom
    yearTo: $yearTo
    networkId: $networkId
    queryParams: $queryParams
  ) @rest(type: "SearchTalent", path: "/network/{args.networkId}/object/search&query={args.query}{args.sort}{args.age}{args.classId}{args.fieldPosition}{args.fifaCountry}{args.yearFrom}{args.yearTo}{args.queryParams}", method: "GET") {
    meta {
      range {
        from
        to
        total
      }
      sortOptions {
        key
        value
      }
    }
    collection @type(name: "Talent") {
      ...talent
    }
  }
}
    ${TalentFragmentDoc}`;

/**
 * __useSearchTalentQuery__
 *
 * To run a query within a React component, call `useSearchTalentQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchTalentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchTalentQuery({
 *   variables: {
 *      query: // value for 'query'
 *      sort: // value for 'sort'
 *      age: // value for 'age'
 *      classId: // value for 'classId'
 *      fieldPosition: // value for 'fieldPosition'
 *      fifaCountry: // value for 'fifaCountry'
 *      yearFrom: // value for 'yearFrom'
 *      yearTo: // value for 'yearTo'
 *      networkId: // value for 'networkId'
 *      queryParams: // value for 'queryParams'
 *   },
 * });
 */
export function useSearchTalentQuery(baseOptions: Apollo.QueryHookOptions<SearchTalentQuery, SearchTalentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchTalentQuery, SearchTalentQueryVariables>(SearchTalentDocument, options);
      }
export function useSearchTalentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchTalentQuery, SearchTalentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchTalentQuery, SearchTalentQueryVariables>(SearchTalentDocument, options);
        }
export type SearchTalentQueryHookResult = ReturnType<typeof useSearchTalentQuery>;
export type SearchTalentLazyQueryHookResult = ReturnType<typeof useSearchTalentLazyQuery>;
export type SearchTalentQueryResult = Apollo.QueryResult<SearchTalentQuery, SearchTalentQueryVariables>;
export const TalentJoinMarketplaceDocument = gql`
    mutation talentJoinMarketplace {
  talentJoinMarketplace(input: "💩") @rest(type: "Network", method: "POST", path: "/makro/joinmarketplacewithtalent", bodySerializer: "noBody") {
    ...network
  }
}
    ${NetworkFragmentDoc}`;
export type TalentJoinMarketplaceMutationFn = Apollo.MutationFunction<TalentJoinMarketplaceMutation, TalentJoinMarketplaceMutationVariables>;

/**
 * __useTalentJoinMarketplaceMutation__
 *
 * To run a mutation, you first call `useTalentJoinMarketplaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTalentJoinMarketplaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [talentJoinMarketplaceMutation, { data, loading, error }] = useTalentJoinMarketplaceMutation({
 *   variables: {
 *   },
 * });
 */
export function useTalentJoinMarketplaceMutation(baseOptions?: Apollo.MutationHookOptions<TalentJoinMarketplaceMutation, TalentJoinMarketplaceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TalentJoinMarketplaceMutation, TalentJoinMarketplaceMutationVariables>(TalentJoinMarketplaceDocument, options);
      }
export type TalentJoinMarketplaceMutationHookResult = ReturnType<typeof useTalentJoinMarketplaceMutation>;
export type TalentJoinMarketplaceMutationResult = Apollo.MutationResult<TalentJoinMarketplaceMutation>;
export type TalentJoinMarketplaceMutationOptions = Apollo.BaseMutationOptions<TalentJoinMarketplaceMutation, TalentJoinMarketplaceMutationVariables>;
export const CreateTalentDocument = gql`
    mutation createTalent($classId: String!, $input: TalentInput!) {
  saveTalent(classId: $classId, input: $input) @rest(type: "Talent", path: "/class/{args.classId}/object", method: "POST") {
    ...talent
  }
}
    ${TalentFragmentDoc}`;
export type CreateTalentMutationFn = Apollo.MutationFunction<CreateTalentMutation, CreateTalentMutationVariables>;

/**
 * __useCreateTalentMutation__
 *
 * To run a mutation, you first call `useCreateTalentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTalentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTalentMutation, { data, loading, error }] = useCreateTalentMutation({
 *   variables: {
 *      classId: // value for 'classId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTalentMutation(baseOptions?: Apollo.MutationHookOptions<CreateTalentMutation, CreateTalentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTalentMutation, CreateTalentMutationVariables>(CreateTalentDocument, options);
      }
export type CreateTalentMutationHookResult = ReturnType<typeof useCreateTalentMutation>;
export type CreateTalentMutationResult = Apollo.MutationResult<CreateTalentMutation>;
export type CreateTalentMutationOptions = Apollo.BaseMutationOptions<CreateTalentMutation, CreateTalentMutationVariables>;
export const UpdateTalentDocument = gql`
    mutation updateTalent($input: TalentInput!, $talentId: String!) {
  saveTalent(input: $input, talentId: $talentId) @rest(type: "Talent", path: "/object/{args.talentId}", method: "PATCH") {
    ...talent
  }
}
    ${TalentFragmentDoc}`;
export type UpdateTalentMutationFn = Apollo.MutationFunction<UpdateTalentMutation, UpdateTalentMutationVariables>;

/**
 * __useUpdateTalentMutation__
 *
 * To run a mutation, you first call `useUpdateTalentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTalentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTalentMutation, { data, loading, error }] = useUpdateTalentMutation({
 *   variables: {
 *      input: // value for 'input'
 *      talentId: // value for 'talentId'
 *   },
 * });
 */
export function useUpdateTalentMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTalentMutation, UpdateTalentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTalentMutation, UpdateTalentMutationVariables>(UpdateTalentDocument, options);
      }
export type UpdateTalentMutationHookResult = ReturnType<typeof useUpdateTalentMutation>;
export type UpdateTalentMutationResult = Apollo.MutationResult<UpdateTalentMutation>;
export type UpdateTalentMutationOptions = Apollo.BaseMutationOptions<UpdateTalentMutation, UpdateTalentMutationVariables>;
export const TalentLinkImageDocument = gql`
    mutation talentLinkImage($url: String!, $talentId: String!) {
  talentLinkImage(input: {_links: {image: {url: $url}}}, talentId: $talentId) @rest(type: "Talent", method: "PUT", path: "/object/{args.talentId}&method=LINK") {
    ...talent
  }
}
    ${TalentFragmentDoc}`;
export type TalentLinkImageMutationFn = Apollo.MutationFunction<TalentLinkImageMutation, TalentLinkImageMutationVariables>;

/**
 * __useTalentLinkImageMutation__
 *
 * To run a mutation, you first call `useTalentLinkImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTalentLinkImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [talentLinkImageMutation, { data, loading, error }] = useTalentLinkImageMutation({
 *   variables: {
 *      url: // value for 'url'
 *      talentId: // value for 'talentId'
 *   },
 * });
 */
export function useTalentLinkImageMutation(baseOptions?: Apollo.MutationHookOptions<TalentLinkImageMutation, TalentLinkImageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TalentLinkImageMutation, TalentLinkImageMutationVariables>(TalentLinkImageDocument, options);
      }
export type TalentLinkImageMutationHookResult = ReturnType<typeof useTalentLinkImageMutation>;
export type TalentLinkImageMutationResult = Apollo.MutationResult<TalentLinkImageMutation>;
export type TalentLinkImageMutationOptions = Apollo.BaseMutationOptions<TalentLinkImageMutation, TalentLinkImageMutationVariables>;
export const TalentUnlinkImageDocument = gql`
    mutation talentUnlinkImage($url: String!, $talentId: String!) {
  talentUnlinkImage(input: {_links: {image: {url: $url}}}, talentId: $talentId) @rest(type: "Talent", method: "PUT", path: "/object/{args.talentId}&method=UNLINK") {
    ...talent
  }
}
    ${TalentFragmentDoc}`;
export type TalentUnlinkImageMutationFn = Apollo.MutationFunction<TalentUnlinkImageMutation, TalentUnlinkImageMutationVariables>;

/**
 * __useTalentUnlinkImageMutation__
 *
 * To run a mutation, you first call `useTalentUnlinkImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTalentUnlinkImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [talentUnlinkImageMutation, { data, loading, error }] = useTalentUnlinkImageMutation({
 *   variables: {
 *      url: // value for 'url'
 *      talentId: // value for 'talentId'
 *   },
 * });
 */
export function useTalentUnlinkImageMutation(baseOptions?: Apollo.MutationHookOptions<TalentUnlinkImageMutation, TalentUnlinkImageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TalentUnlinkImageMutation, TalentUnlinkImageMutationVariables>(TalentUnlinkImageDocument, options);
      }
export type TalentUnlinkImageMutationHookResult = ReturnType<typeof useTalentUnlinkImageMutation>;
export type TalentUnlinkImageMutationResult = Apollo.MutationResult<TalentUnlinkImageMutation>;
export type TalentUnlinkImageMutationOptions = Apollo.BaseMutationOptions<TalentUnlinkImageMutation, TalentUnlinkImageMutationVariables>;
export const CreateTalentCustomMetaDocument = gql`
    mutation createTalentCustomMeta($input: TalentCustomMetaInput!, $talentId: String!) {
  saveTalentCustomMeta(talentId: $talentId, input: $input) @rest(type: "TalentCustomMeta", method: "POST", path: "/object/{args.talentId}/custom-meta-data&json2json=true", bodySerializer: "talentCustomMeta") {
    id
    foot
    club
    team
    height
    weight
    status
  }
}
    `;
export type CreateTalentCustomMetaMutationFn = Apollo.MutationFunction<CreateTalentCustomMetaMutation, CreateTalentCustomMetaMutationVariables>;

/**
 * __useCreateTalentCustomMetaMutation__
 *
 * To run a mutation, you first call `useCreateTalentCustomMetaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTalentCustomMetaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTalentCustomMetaMutation, { data, loading, error }] = useCreateTalentCustomMetaMutation({
 *   variables: {
 *      input: // value for 'input'
 *      talentId: // value for 'talentId'
 *   },
 * });
 */
export function useCreateTalentCustomMetaMutation(baseOptions?: Apollo.MutationHookOptions<CreateTalentCustomMetaMutation, CreateTalentCustomMetaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTalentCustomMetaMutation, CreateTalentCustomMetaMutationVariables>(CreateTalentCustomMetaDocument, options);
      }
export type CreateTalentCustomMetaMutationHookResult = ReturnType<typeof useCreateTalentCustomMetaMutation>;
export type CreateTalentCustomMetaMutationResult = Apollo.MutationResult<CreateTalentCustomMetaMutation>;
export type CreateTalentCustomMetaMutationOptions = Apollo.BaseMutationOptions<CreateTalentCustomMetaMutation, CreateTalentCustomMetaMutationVariables>;
export const UpdateTalentCustomMetaDocument = gql`
    mutation updateTalentCustomMeta($input: TalentCustomMetaInput!, $talentId: String!, $metaDataId: String!) {
  saveTalentCustomMeta(
    talentId: $talentId
    input: $input
    metaDataId: $metaDataId
  ) @rest(type: "TalentCustomMeta", method: "PATCH", path: "/object/{args.talentId}/custom-meta-data/{args.metaDataId}&json2json=true", bodySerializer: "talentCustomMeta") {
    id
    foot
    club
    team
    height
    weight
    status
  }
}
    `;
export type UpdateTalentCustomMetaMutationFn = Apollo.MutationFunction<UpdateTalentCustomMetaMutation, UpdateTalentCustomMetaMutationVariables>;

/**
 * __useUpdateTalentCustomMetaMutation__
 *
 * To run a mutation, you first call `useUpdateTalentCustomMetaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTalentCustomMetaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTalentCustomMetaMutation, { data, loading, error }] = useUpdateTalentCustomMetaMutation({
 *   variables: {
 *      input: // value for 'input'
 *      talentId: // value for 'talentId'
 *      metaDataId: // value for 'metaDataId'
 *   },
 * });
 */
export function useUpdateTalentCustomMetaMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTalentCustomMetaMutation, UpdateTalentCustomMetaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTalentCustomMetaMutation, UpdateTalentCustomMetaMutationVariables>(UpdateTalentCustomMetaDocument, options);
      }
export type UpdateTalentCustomMetaMutationHookResult = ReturnType<typeof useUpdateTalentCustomMetaMutation>;
export type UpdateTalentCustomMetaMutationResult = Apollo.MutationResult<UpdateTalentCustomMetaMutation>;
export type UpdateTalentCustomMetaMutationOptions = Apollo.BaseMutationOptions<UpdateTalentCustomMetaMutation, UpdateTalentCustomMetaMutationVariables>;
export const TalentLinkUserDocument = gql`
    mutation talentLinkUser($input: LinkUserInput!, $talentId: String!) {
  talentLinkUser(input: $input, talentId: $talentId) @rest(type: "Talent", method: "PUT", path: "/object/{args.talentId}&method=LINK") {
    ...talent
  }
}
    ${TalentFragmentDoc}`;
export type TalentLinkUserMutationFn = Apollo.MutationFunction<TalentLinkUserMutation, TalentLinkUserMutationVariables>;

/**
 * __useTalentLinkUserMutation__
 *
 * To run a mutation, you first call `useTalentLinkUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTalentLinkUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [talentLinkUserMutation, { data, loading, error }] = useTalentLinkUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *      talentId: // value for 'talentId'
 *   },
 * });
 */
export function useTalentLinkUserMutation(baseOptions?: Apollo.MutationHookOptions<TalentLinkUserMutation, TalentLinkUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TalentLinkUserMutation, TalentLinkUserMutationVariables>(TalentLinkUserDocument, options);
      }
export type TalentLinkUserMutationHookResult = ReturnType<typeof useTalentLinkUserMutation>;
export type TalentLinkUserMutationResult = Apollo.MutationResult<TalentLinkUserMutation>;
export type TalentLinkUserMutationOptions = Apollo.BaseMutationOptions<TalentLinkUserMutation, TalentLinkUserMutationVariables>;
export const DeleteTalentDocument = gql`
    mutation deleteTalent($talentId: ID!) {
  deleteTalent(talentId: $talentId) @rest(type: "Void", method: "DELETE", path: "/object/{args.talentId}")
}
    `;
export type DeleteTalentMutationFn = Apollo.MutationFunction<DeleteTalentMutation, DeleteTalentMutationVariables>;

/**
 * __useDeleteTalentMutation__
 *
 * To run a mutation, you first call `useDeleteTalentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTalentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTalentMutation, { data, loading, error }] = useDeleteTalentMutation({
 *   variables: {
 *      talentId: // value for 'talentId'
 *   },
 * });
 */
export function useDeleteTalentMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTalentMutation, DeleteTalentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteTalentMutation, DeleteTalentMutationVariables>(DeleteTalentDocument, options);
      }
export type DeleteTalentMutationHookResult = ReturnType<typeof useDeleteTalentMutation>;
export type DeleteTalentMutationResult = Apollo.MutationResult<DeleteTalentMutation>;
export type DeleteTalentMutationOptions = Apollo.BaseMutationOptions<DeleteTalentMutation, DeleteTalentMutationVariables>;
export const TalentLatestVoteDocument = gql`
    query talentLatestVote($userId: ID!, $talentId: ID!) {
  talentLatestVote(userId: $userId, talentId: $talentId) @rest(type: "Vote", path: "/my/{args.userId}/object/{args.talentId}/latestVote", method: "GET") {
    ...vote
  }
}
    ${VoteFragmentDoc}`;

/**
 * __useTalentLatestVoteQuery__
 *
 * To run a query within a React component, call `useTalentLatestVoteQuery` and pass it any options that fit your needs.
 * When your component renders, `useTalentLatestVoteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTalentLatestVoteQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      talentId: // value for 'talentId'
 *   },
 * });
 */
export function useTalentLatestVoteQuery(baseOptions: Apollo.QueryHookOptions<TalentLatestVoteQuery, TalentLatestVoteQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TalentLatestVoteQuery, TalentLatestVoteQueryVariables>(TalentLatestVoteDocument, options);
      }
export function useTalentLatestVoteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TalentLatestVoteQuery, TalentLatestVoteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TalentLatestVoteQuery, TalentLatestVoteQueryVariables>(TalentLatestVoteDocument, options);
        }
export type TalentLatestVoteQueryHookResult = ReturnType<typeof useTalentLatestVoteQuery>;
export type TalentLatestVoteLazyQueryHookResult = ReturnType<typeof useTalentLatestVoteLazyQuery>;
export type TalentLatestVoteQueryResult = Apollo.QueryResult<TalentLatestVoteQuery, TalentLatestVoteQueryVariables>;
export const VoteDocument = gql`
    mutation vote($talentId: ID!, $input: VoteInput!) {
  vote(talentId: $talentId, input: $input) @rest(type: "Vote", path: "/object/{args.talentId}/vote", method: "POST") {
    ...vote
  }
}
    ${VoteFragmentDoc}`;
export type VoteMutationFn = Apollo.MutationFunction<VoteMutation, VoteMutationVariables>;

/**
 * __useVoteMutation__
 *
 * To run a mutation, you first call `useVoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [voteMutation, { data, loading, error }] = useVoteMutation({
 *   variables: {
 *      talentId: // value for 'talentId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useVoteMutation(baseOptions?: Apollo.MutationHookOptions<VoteMutation, VoteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VoteMutation, VoteMutationVariables>(VoteDocument, options);
      }
export type VoteMutationHookResult = ReturnType<typeof useVoteMutation>;
export type VoteMutationResult = Apollo.MutationResult<VoteMutation>;
export type VoteMutationOptions = Apollo.BaseMutationOptions<VoteMutation, VoteMutationVariables>;
export const UpdateVoteDocument = gql`
    mutation updateVote($voteId: ID!, $input: VoteInput!) {
  updateVote(id: $voteId, input: $input) @rest(type: "Vote", path: "/vote/{args.id}", method: "PATCH") {
    ...vote
  }
}
    ${VoteFragmentDoc}`;
export type UpdateVoteMutationFn = Apollo.MutationFunction<UpdateVoteMutation, UpdateVoteMutationVariables>;

/**
 * __useUpdateVoteMutation__
 *
 * To run a mutation, you first call `useUpdateVoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateVoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateVoteMutation, { data, loading, error }] = useUpdateVoteMutation({
 *   variables: {
 *      voteId: // value for 'voteId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateVoteMutation(baseOptions?: Apollo.MutationHookOptions<UpdateVoteMutation, UpdateVoteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateVoteMutation, UpdateVoteMutationVariables>(UpdateVoteDocument, options);
      }
export type UpdateVoteMutationHookResult = ReturnType<typeof useUpdateVoteMutation>;
export type UpdateVoteMutationResult = Apollo.MutationResult<UpdateVoteMutation>;
export type UpdateVoteMutationOptions = Apollo.BaseMutationOptions<UpdateVoteMutation, UpdateVoteMutationVariables>;
export const SimilarDocument = gql`
    query similar($userId: String, $talentId: String, $queryString: String, $queryParams: String = "") {
  similar(
    userId: $userId
    talentId: $talentId
    queryString: $queryString
    queryParams: $queryParams
  ) @rest(type: "NetworkTalents", path: "/my/{args.userId}/object/{args.talentId}/similar{args.queryString}{args.queryParams}", method: "GET") {
    collection @type(name: "Talent") {
      ...talent
    }
    meta {
      range {
        from
        to
        total
      }
      sortOptions {
        key
        value
      }
    }
  }
}
    ${TalentFragmentDoc}`;

/**
 * __useSimilarQuery__
 *
 * To run a query within a React component, call `useSimilarQuery` and pass it any options that fit your needs.
 * When your component renders, `useSimilarQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSimilarQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      talentId: // value for 'talentId'
 *      queryString: // value for 'queryString'
 *      queryParams: // value for 'queryParams'
 *   },
 * });
 */
export function useSimilarQuery(baseOptions?: Apollo.QueryHookOptions<SimilarQuery, SimilarQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SimilarQuery, SimilarQueryVariables>(SimilarDocument, options);
      }
export function useSimilarLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SimilarQuery, SimilarQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SimilarQuery, SimilarQueryVariables>(SimilarDocument, options);
        }
export type SimilarQueryHookResult = ReturnType<typeof useSimilarQuery>;
export type SimilarLazyQueryHookResult = ReturnType<typeof useSimilarLazyQuery>;
export type SimilarQueryResult = Apollo.QueryResult<SimilarQuery, SimilarQueryVariables>;
export const SimilarNetworkDocument = gql`
    query similarNetwork($queryString: String = "", $userId: ID!, $networkId: ID!, $queryParams: String = "") {
  similarNetwork(
    queryString: $queryString
    userId: $userId
    networkId: $networkId
    queryParams: $queryParams
  ) @rest(type: "NetworkTalents", path: "/my/{args.userId}/network/{args.networkId}/object/similar{args.queryString}{args.queryParams}", method: "GET") {
    collection @type(name: "Talent") {
      ...talent
    }
    meta {
      range {
        from
        to
        total
      }
      sortOptions {
        key
        value
      }
    }
  }
}
    ${TalentFragmentDoc}`;

/**
 * __useSimilarNetworkQuery__
 *
 * To run a query within a React component, call `useSimilarNetworkQuery` and pass it any options that fit your needs.
 * When your component renders, `useSimilarNetworkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSimilarNetworkQuery({
 *   variables: {
 *      queryString: // value for 'queryString'
 *      userId: // value for 'userId'
 *      networkId: // value for 'networkId'
 *      queryParams: // value for 'queryParams'
 *   },
 * });
 */
export function useSimilarNetworkQuery(baseOptions: Apollo.QueryHookOptions<SimilarNetworkQuery, SimilarNetworkQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SimilarNetworkQuery, SimilarNetworkQueryVariables>(SimilarNetworkDocument, options);
      }
export function useSimilarNetworkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SimilarNetworkQuery, SimilarNetworkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SimilarNetworkQuery, SimilarNetworkQueryVariables>(SimilarNetworkDocument, options);
        }
export type SimilarNetworkQueryHookResult = ReturnType<typeof useSimilarNetworkQuery>;
export type SimilarNetworkLazyQueryHookResult = ReturnType<typeof useSimilarNetworkLazyQuery>;
export type SimilarNetworkQueryResult = Apollo.QueryResult<SimilarNetworkQuery, SimilarNetworkQueryVariables>;
export const MyTalentLayerDocument = gql`
    query myTalentLayer($userId: ID!, $talentId: ID!, $layerParams: String) {
  talentLayer(contextId: $userId, talentId: $talentId, layerParams: $layerParams) @rest(type: "Layer", path: "/my/{args.contextId}/object/{args.talentId}/aggregation{args.layerParams}", method: "GET") {
    ...talentIDLayer
  }
}
    ${TalentIdLayerFragmentDoc}`;

/**
 * __useMyTalentLayerQuery__
 *
 * To run a query within a React component, call `useMyTalentLayerQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyTalentLayerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyTalentLayerQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      talentId: // value for 'talentId'
 *      layerParams: // value for 'layerParams'
 *   },
 * });
 */
export function useMyTalentLayerQuery(baseOptions: Apollo.QueryHookOptions<MyTalentLayerQuery, MyTalentLayerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MyTalentLayerQuery, MyTalentLayerQueryVariables>(MyTalentLayerDocument, options);
      }
export function useMyTalentLayerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MyTalentLayerQuery, MyTalentLayerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MyTalentLayerQuery, MyTalentLayerQueryVariables>(MyTalentLayerDocument, options);
        }
export type MyTalentLayerQueryHookResult = ReturnType<typeof useMyTalentLayerQuery>;
export type MyTalentLayerLazyQueryHookResult = ReturnType<typeof useMyTalentLayerLazyQuery>;
export type MyTalentLayerQueryResult = Apollo.QueryResult<MyTalentLayerQuery, MyTalentLayerQueryVariables>;
export const ShareTalentDocument = gql`
    mutation shareTalent($input: ShareTalentInput!) {
  shareTalent(input: $input) @rest(type: "ShareTalentResponse", path: "/makro/sharetalent", method: "POST") {
    publicToken
  }
}
    `;
export type ShareTalentMutationFn = Apollo.MutationFunction<ShareTalentMutation, ShareTalentMutationVariables>;

/**
 * __useShareTalentMutation__
 *
 * To run a mutation, you first call `useShareTalentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useShareTalentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [shareTalentMutation, { data, loading, error }] = useShareTalentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useShareTalentMutation(baseOptions?: Apollo.MutationHookOptions<ShareTalentMutation, ShareTalentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ShareTalentMutation, ShareTalentMutationVariables>(ShareTalentDocument, options);
      }
export type ShareTalentMutationHookResult = ReturnType<typeof useShareTalentMutation>;
export type ShareTalentMutationResult = Apollo.MutationResult<ShareTalentMutation>;
export type ShareTalentMutationOptions = Apollo.BaseMutationOptions<ShareTalentMutation, ShareTalentMutationVariables>;
export const AddRoleModelDocument = gql`
    mutation addRoleModel($input: RoleModelInput!) {
  addRoleModel(input: $input) @rest(type: "MessageResponse", path: "/makro/addrolemodel", method: "POST") {
    message
  }
}
    `;
export type AddRoleModelMutationFn = Apollo.MutationFunction<AddRoleModelMutation, AddRoleModelMutationVariables>;

/**
 * __useAddRoleModelMutation__
 *
 * To run a mutation, you first call `useAddRoleModelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddRoleModelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addRoleModelMutation, { data, loading, error }] = useAddRoleModelMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddRoleModelMutation(baseOptions?: Apollo.MutationHookOptions<AddRoleModelMutation, AddRoleModelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddRoleModelMutation, AddRoleModelMutationVariables>(AddRoleModelDocument, options);
      }
export type AddRoleModelMutationHookResult = ReturnType<typeof useAddRoleModelMutation>;
export type AddRoleModelMutationResult = Apollo.MutationResult<AddRoleModelMutation>;
export type AddRoleModelMutationOptions = Apollo.BaseMutationOptions<AddRoleModelMutation, AddRoleModelMutationVariables>;
export const SavePerformanceVoteDocument = gql`
    mutation savePerformanceVote($input: PerformanceVoteInput!) {
  savePerformanceVote(input: $input) @rest(type: "PerformanceVote", path: "/makro/performanceVote", method: "POST") {
    ...performanceVote
  }
}
    ${PerformanceVoteFragmentDoc}`;
export type SavePerformanceVoteMutationFn = Apollo.MutationFunction<SavePerformanceVoteMutation, SavePerformanceVoteMutationVariables>;

/**
 * __useSavePerformanceVoteMutation__
 *
 * To run a mutation, you first call `useSavePerformanceVoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSavePerformanceVoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [savePerformanceVoteMutation, { data, loading, error }] = useSavePerformanceVoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSavePerformanceVoteMutation(baseOptions?: Apollo.MutationHookOptions<SavePerformanceVoteMutation, SavePerformanceVoteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SavePerformanceVoteMutation, SavePerformanceVoteMutationVariables>(SavePerformanceVoteDocument, options);
      }
export type SavePerformanceVoteMutationHookResult = ReturnType<typeof useSavePerformanceVoteMutation>;
export type SavePerformanceVoteMutationResult = Apollo.MutationResult<SavePerformanceVoteMutation>;
export type SavePerformanceVoteMutationOptions = Apollo.BaseMutationOptions<SavePerformanceVoteMutation, SavePerformanceVoteMutationVariables>;
export const PerformanceVoteDocument = gql`
    query performanceVote($talentId: ID!, $network: String) {
  performanceVote(talentId: $talentId, network: $network) @rest(type: "PerformanceVote", path: "/makro/performanceVote/{args.talentId}{args.network}", method: "GET") {
    ...performanceVote
  }
}
    ${PerformanceVoteFragmentDoc}`;

/**
 * __usePerformanceVoteQuery__
 *
 * To run a query within a React component, call `usePerformanceVoteQuery` and pass it any options that fit your needs.
 * When your component renders, `usePerformanceVoteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePerformanceVoteQuery({
 *   variables: {
 *      talentId: // value for 'talentId'
 *      network: // value for 'network'
 *   },
 * });
 */
export function usePerformanceVoteQuery(baseOptions: Apollo.QueryHookOptions<PerformanceVoteQuery, PerformanceVoteQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PerformanceVoteQuery, PerformanceVoteQueryVariables>(PerformanceVoteDocument, options);
      }
export function usePerformanceVoteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PerformanceVoteQuery, PerformanceVoteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PerformanceVoteQuery, PerformanceVoteQueryVariables>(PerformanceVoteDocument, options);
        }
export type PerformanceVoteQueryHookResult = ReturnType<typeof usePerformanceVoteQuery>;
export type PerformanceVoteLazyQueryHookResult = ReturnType<typeof usePerformanceVoteLazyQuery>;
export type PerformanceVoteQueryResult = Apollo.QueryResult<PerformanceVoteQuery, PerformanceVoteQueryVariables>;
export const RecoverUsernameDocument = gql`
    mutation recoverUsername($input: RecoverUsernameInput!, $queryParams: String = "") {
  recoverUsername(input: $input, queryParams: $queryParams) @rest(path: "/user/recover{args.queryParams}", method: "POST") {
    collection @type(name: "User") {
      ...user
    }
    meta {
      range {
        from
        to
        total
      }
      sortOptions {
        key
        value
      }
    }
  }
}
    ${UserFragmentDoc}`;
export type RecoverUsernameMutationFn = Apollo.MutationFunction<RecoverUsernameMutation, RecoverUsernameMutationVariables>;

/**
 * __useRecoverUsernameMutation__
 *
 * To run a mutation, you first call `useRecoverUsernameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRecoverUsernameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [recoverUsernameMutation, { data, loading, error }] = useRecoverUsernameMutation({
 *   variables: {
 *      input: // value for 'input'
 *      queryParams: // value for 'queryParams'
 *   },
 * });
 */
export function useRecoverUsernameMutation(baseOptions?: Apollo.MutationHookOptions<RecoverUsernameMutation, RecoverUsernameMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RecoverUsernameMutation, RecoverUsernameMutationVariables>(RecoverUsernameDocument, options);
      }
export type RecoverUsernameMutationHookResult = ReturnType<typeof useRecoverUsernameMutation>;
export type RecoverUsernameMutationResult = Apollo.MutationResult<RecoverUsernameMutation>;
export type RecoverUsernameMutationOptions = Apollo.BaseMutationOptions<RecoverUsernameMutation, RecoverUsernameMutationVariables>;
export const RequestPasswordChangeDocument = gql`
    mutation requestPasswordChange($input: RequestPasswordChangeInput!) {
  requestPasswordChange(input: $input) @rest(path: "/user/recover", method: "POST") {
    token
    user {
      ...user
    }
  }
}
    ${UserFragmentDoc}`;
export type RequestPasswordChangeMutationFn = Apollo.MutationFunction<RequestPasswordChangeMutation, RequestPasswordChangeMutationVariables>;

/**
 * __useRequestPasswordChangeMutation__
 *
 * To run a mutation, you first call `useRequestPasswordChangeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestPasswordChangeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestPasswordChangeMutation, { data, loading, error }] = useRequestPasswordChangeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRequestPasswordChangeMutation(baseOptions?: Apollo.MutationHookOptions<RequestPasswordChangeMutation, RequestPasswordChangeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RequestPasswordChangeMutation, RequestPasswordChangeMutationVariables>(RequestPasswordChangeDocument, options);
      }
export type RequestPasswordChangeMutationHookResult = ReturnType<typeof useRequestPasswordChangeMutation>;
export type RequestPasswordChangeMutationResult = Apollo.MutationResult<RequestPasswordChangeMutation>;
export type RequestPasswordChangeMutationOptions = Apollo.BaseMutationOptions<RequestPasswordChangeMutation, RequestPasswordChangeMutationVariables>;
export const CreateUserMetaDataDocument = gql`
    mutation createUserMetaData($input: UserMetaDataInput!) {
  saveUserMetaData(input: $input) @rest(type: "UserMetaDataSaveResponse", path: "/makro/userdata", method: "POST") {
    ...userMetadataSaveResponse
  }
}
    ${UserMetadataSaveResponseFragmentDoc}`;
export type CreateUserMetaDataMutationFn = Apollo.MutationFunction<CreateUserMetaDataMutation, CreateUserMetaDataMutationVariables>;

/**
 * __useCreateUserMetaDataMutation__
 *
 * To run a mutation, you first call `useCreateUserMetaDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMetaDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMetaDataMutation, { data, loading, error }] = useCreateUserMetaDataMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUserMetaDataMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserMetaDataMutation, CreateUserMetaDataMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserMetaDataMutation, CreateUserMetaDataMutationVariables>(CreateUserMetaDataDocument, options);
      }
export type CreateUserMetaDataMutationHookResult = ReturnType<typeof useCreateUserMetaDataMutation>;
export type CreateUserMetaDataMutationResult = Apollo.MutationResult<CreateUserMetaDataMutation>;
export type CreateUserMetaDataMutationOptions = Apollo.BaseMutationOptions<CreateUserMetaDataMutation, CreateUserMetaDataMutationVariables>;
export const UpdateUserMetaDataDocument = gql`
    mutation updateUserMetaData($input: UserMetaDataInput!) {
  saveUserMetaData(input: $input) @rest(type: "UserMetaDataSaveResponse", path: "/makro/userdata", method: "PUT") {
    ...userMetadataSaveResponse
  }
}
    ${UserMetadataSaveResponseFragmentDoc}`;
export type UpdateUserMetaDataMutationFn = Apollo.MutationFunction<UpdateUserMetaDataMutation, UpdateUserMetaDataMutationVariables>;

/**
 * __useUpdateUserMetaDataMutation__
 *
 * To run a mutation, you first call `useUpdateUserMetaDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMetaDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMetaDataMutation, { data, loading, error }] = useUpdateUserMetaDataMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserMetaDataMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMetaDataMutation, UpdateUserMetaDataMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserMetaDataMutation, UpdateUserMetaDataMutationVariables>(UpdateUserMetaDataDocument, options);
      }
export type UpdateUserMetaDataMutationHookResult = ReturnType<typeof useUpdateUserMetaDataMutation>;
export type UpdateUserMetaDataMutationResult = Apollo.MutationResult<UpdateUserMetaDataMutation>;
export type UpdateUserMetaDataMutationOptions = Apollo.BaseMutationOptions<UpdateUserMetaDataMutation, UpdateUserMetaDataMutationVariables>;
export const UserMetaDataDocument = gql`
    query userMetaData($userId: ID!) {
  userMetaData(userId: $userId) @rest(type: "UserMetaDataResponse", path: "/makro/userdata/{args.userId}", method: "GET") {
    ...userMetadataResponse
  }
}
    ${UserMetadataResponseFragmentDoc}`;

/**
 * __useUserMetaDataQuery__
 *
 * To run a query within a React component, call `useUserMetaDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserMetaDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserMetaDataQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUserMetaDataQuery(baseOptions: Apollo.QueryHookOptions<UserMetaDataQuery, UserMetaDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserMetaDataQuery, UserMetaDataQueryVariables>(UserMetaDataDocument, options);
      }
export function useUserMetaDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserMetaDataQuery, UserMetaDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserMetaDataQuery, UserMetaDataQueryVariables>(UserMetaDataDocument, options);
        }
export type UserMetaDataQueryHookResult = ReturnType<typeof useUserMetaDataQuery>;
export type UserMetaDataLazyQueryHookResult = ReturnType<typeof useUserMetaDataLazyQuery>;
export type UserMetaDataQueryResult = Apollo.QueryResult<UserMetaDataQuery, UserMetaDataQueryVariables>;
export const SuggestUserDocument = gql`
    query suggestUser($q: String!) {
  suggestUser(q: $q) @rest(type: "SuggestUser", path: "/user/suggest?q={args.q}", method: "GET") {
    name
  }
}
    `;

/**
 * __useSuggestUserQuery__
 *
 * To run a query within a React component, call `useSuggestUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useSuggestUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSuggestUserQuery({
 *   variables: {
 *      q: // value for 'q'
 *   },
 * });
 */
export function useSuggestUserQuery(baseOptions: Apollo.QueryHookOptions<SuggestUserQuery, SuggestUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SuggestUserQuery, SuggestUserQueryVariables>(SuggestUserDocument, options);
      }
export function useSuggestUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SuggestUserQuery, SuggestUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SuggestUserQuery, SuggestUserQueryVariables>(SuggestUserDocument, options);
        }
export type SuggestUserQueryHookResult = ReturnType<typeof useSuggestUserQuery>;
export type SuggestUserLazyQueryHookResult = ReturnType<typeof useSuggestUserLazyQuery>;
export type SuggestUserQueryResult = Apollo.QueryResult<SuggestUserQuery, SuggestUserQueryVariables>;
export const SearchUserDocument = gql`
    query searchUser($query: String!, $meId: String!, $networkId: String!, $sort: String = "&sort=newestFirst", $queryParams: String = "") {
  searchUser(
    query: $query
    meId: $meId
    networkId: $networkId
    sort: $sort
    queryParams: $queryParams
  ) @rest(type: "SearchUser", path: "/my/{args.meId}/network/{args.networkId}/user/search&query={args.query}{args.sort}{args.queryParams}", method: "GET") {
    meta {
      range {
        from
        to
        total
      }
      sortOptions {
        key
        value
      }
    }
    collection @type(name: "User") {
      ...user
    }
  }
}
    ${UserFragmentDoc}`;

/**
 * __useSearchUserQuery__
 *
 * To run a query within a React component, call `useSearchUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchUserQuery({
 *   variables: {
 *      query: // value for 'query'
 *      meId: // value for 'meId'
 *      networkId: // value for 'networkId'
 *      sort: // value for 'sort'
 *      queryParams: // value for 'queryParams'
 *   },
 * });
 */
export function useSearchUserQuery(baseOptions: Apollo.QueryHookOptions<SearchUserQuery, SearchUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchUserQuery, SearchUserQueryVariables>(SearchUserDocument, options);
      }
export function useSearchUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchUserQuery, SearchUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchUserQuery, SearchUserQueryVariables>(SearchUserDocument, options);
        }
export type SearchUserQueryHookResult = ReturnType<typeof useSearchUserQuery>;
export type SearchUserLazyQueryHookResult = ReturnType<typeof useSearchUserLazyQuery>;
export type SearchUserQueryResult = Apollo.QueryResult<SearchUserQuery, SearchUserQueryVariables>;
export const UpdateUserDocument = gql`
    mutation updateUser($input: UserInput!, $userId: String!) {
  updateUser(input: $input, userId: $userId) @rest(type: "User", path: "/user/{args.userId}", method: "PATCH") {
    ...user
  }
}
    ${UserFragmentDoc}`;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const UpdatePasswordDocument = gql`
    mutation updatePassword($input: PasswordInput!, $userId: String!) {
  updatePassword(input: $input, userId: $userId) @rest(type: "User", path: "/user/{args.userId}", method: "PATCH") {
    ...user
  }
}
    ${UserFragmentDoc}`;
export type UpdatePasswordMutationFn = Apollo.MutationFunction<UpdatePasswordMutation, UpdatePasswordMutationVariables>;

/**
 * __useUpdatePasswordMutation__
 *
 * To run a mutation, you first call `useUpdatePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePasswordMutation, { data, loading, error }] = useUpdatePasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUpdatePasswordMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePasswordMutation, UpdatePasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePasswordMutation, UpdatePasswordMutationVariables>(UpdatePasswordDocument, options);
      }
export type UpdatePasswordMutationHookResult = ReturnType<typeof useUpdatePasswordMutation>;
export type UpdatePasswordMutationResult = Apollo.MutationResult<UpdatePasswordMutation>;
export type UpdatePasswordMutationOptions = Apollo.BaseMutationOptions<UpdatePasswordMutation, UpdatePasswordMutationVariables>;
export const UserLinkImageDocument = gql`
    mutation userLinkImage($url: String!, $userId: String!) {
  userLinkImage(input: {_links: {image: {url: $url}}}, userId: $userId) @rest(type: "User", method: "PUT", path: "/user/{args.userId}&method=LINK") {
    ...user
  }
}
    ${UserFragmentDoc}`;
export type UserLinkImageMutationFn = Apollo.MutationFunction<UserLinkImageMutation, UserLinkImageMutationVariables>;

/**
 * __useUserLinkImageMutation__
 *
 * To run a mutation, you first call `useUserLinkImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserLinkImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userLinkImageMutation, { data, loading, error }] = useUserLinkImageMutation({
 *   variables: {
 *      url: // value for 'url'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUserLinkImageMutation(baseOptions?: Apollo.MutationHookOptions<UserLinkImageMutation, UserLinkImageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UserLinkImageMutation, UserLinkImageMutationVariables>(UserLinkImageDocument, options);
      }
export type UserLinkImageMutationHookResult = ReturnType<typeof useUserLinkImageMutation>;
export type UserLinkImageMutationResult = Apollo.MutationResult<UserLinkImageMutation>;
export type UserLinkImageMutationOptions = Apollo.BaseMutationOptions<UserLinkImageMutation, UserLinkImageMutationVariables>;
export const UserUnlinkImageDocument = gql`
    mutation userUnlinkImage($url: String!, $userId: String!) {
  userUnlinkImage(input: {_links: {image: {url: $url}}}, userId: $userId) @rest(type: "User", method: "PUT", path: "/user/{args.userId}&method=UNLINK") {
    ...user
  }
}
    ${UserFragmentDoc}`;
export type UserUnlinkImageMutationFn = Apollo.MutationFunction<UserUnlinkImageMutation, UserUnlinkImageMutationVariables>;

/**
 * __useUserUnlinkImageMutation__
 *
 * To run a mutation, you first call `useUserUnlinkImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserUnlinkImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userUnlinkImageMutation, { data, loading, error }] = useUserUnlinkImageMutation({
 *   variables: {
 *      url: // value for 'url'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUserUnlinkImageMutation(baseOptions?: Apollo.MutationHookOptions<UserUnlinkImageMutation, UserUnlinkImageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UserUnlinkImageMutation, UserUnlinkImageMutationVariables>(UserUnlinkImageDocument, options);
      }
export type UserUnlinkImageMutationHookResult = ReturnType<typeof useUserUnlinkImageMutation>;
export type UserUnlinkImageMutationResult = Apollo.MutationResult<UserUnlinkImageMutation>;
export type UserUnlinkImageMutationOptions = Apollo.BaseMutationOptions<UserUnlinkImageMutation, UserUnlinkImageMutationVariables>;
export const UserFollowDocument = gql`
    mutation userFollow($url: String!, $userId: String!, $method: String!) {
  userFollow(
    input: {_links: {follow_user: {url: $url}}}
    userId: $userId
    method: $method
  ) @rest(type: "User", method: "PUT", path: "/user/{args.userId}&method={args.method}") {
    id
  }
}
    `;
export type UserFollowMutationFn = Apollo.MutationFunction<UserFollowMutation, UserFollowMutationVariables>;

/**
 * __useUserFollowMutation__
 *
 * To run a mutation, you first call `useUserFollowMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserFollowMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userFollowMutation, { data, loading, error }] = useUserFollowMutation({
 *   variables: {
 *      url: // value for 'url'
 *      userId: // value for 'userId'
 *      method: // value for 'method'
 *   },
 * });
 */
export function useUserFollowMutation(baseOptions?: Apollo.MutationHookOptions<UserFollowMutation, UserFollowMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UserFollowMutation, UserFollowMutationVariables>(UserFollowDocument, options);
      }
export type UserFollowMutationHookResult = ReturnType<typeof useUserFollowMutation>;
export type UserFollowMutationResult = Apollo.MutationResult<UserFollowMutation>;
export type UserFollowMutationOptions = Apollo.BaseMutationOptions<UserFollowMutation, UserFollowMutationVariables>;
export const UserJoinMarketplaceDocument = gql`
    mutation userJoinMarketplace {
  userJoinMarketplace(input: "💩") @rest(type: "Network", method: "POST", path: "/makro/joinmarketplacewithuser", bodySerializer: "noBody") {
    ...network
  }
}
    ${NetworkFragmentDoc}`;
export type UserJoinMarketplaceMutationFn = Apollo.MutationFunction<UserJoinMarketplaceMutation, UserJoinMarketplaceMutationVariables>;

/**
 * __useUserJoinMarketplaceMutation__
 *
 * To run a mutation, you first call `useUserJoinMarketplaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserJoinMarketplaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userJoinMarketplaceMutation, { data, loading, error }] = useUserJoinMarketplaceMutation({
 *   variables: {
 *   },
 * });
 */
export function useUserJoinMarketplaceMutation(baseOptions?: Apollo.MutationHookOptions<UserJoinMarketplaceMutation, UserJoinMarketplaceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UserJoinMarketplaceMutation, UserJoinMarketplaceMutationVariables>(UserJoinMarketplaceDocument, options);
      }
export type UserJoinMarketplaceMutationHookResult = ReturnType<typeof useUserJoinMarketplaceMutation>;
export type UserJoinMarketplaceMutationResult = Apollo.MutationResult<UserJoinMarketplaceMutation>;
export type UserJoinMarketplaceMutationOptions = Apollo.BaseMutationOptions<UserJoinMarketplaceMutation, UserJoinMarketplaceMutationVariables>;
export const DeleteAccountDocument = gql`
    mutation deleteAccount($input: DeleteAccountInput!) {
  deleteAccount(input: $input) @rest(type: "DeleteAccountResponse", method: "POST", path: "/makro/deleteaccount") {
    message
  }
}
    `;
export type DeleteAccountMutationFn = Apollo.MutationFunction<DeleteAccountMutation, DeleteAccountMutationVariables>;

/**
 * __useDeleteAccountMutation__
 *
 * To run a mutation, you first call `useDeleteAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAccountMutation, { data, loading, error }] = useDeleteAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteAccountMutation(baseOptions?: Apollo.MutationHookOptions<DeleteAccountMutation, DeleteAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteAccountMutation, DeleteAccountMutationVariables>(DeleteAccountDocument, options);
      }
export type DeleteAccountMutationHookResult = ReturnType<typeof useDeleteAccountMutation>;
export type DeleteAccountMutationResult = Apollo.MutationResult<DeleteAccountMutation>;
export type DeleteAccountMutationOptions = Apollo.BaseMutationOptions<DeleteAccountMutation, DeleteAccountMutationVariables>;
export const UserDocument = gql`
    query user($userId: ID!, $meId: ID!, $networkId: ID!) {
  user(userId: $userId, meId: $meId, networkId: $networkId) @rest(type: "User", method: "GET", path: "/my/{args.meId}/network/{args.networkId}/user/{args.userId}") {
    ...user
  }
}
    ${UserFragmentDoc}`;

/**
 * __useUserQuery__
 *
 * To run a query within a React component, call `useUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      meId: // value for 'meId'
 *      networkId: // value for 'networkId'
 *   },
 * });
 */
export function useUserQuery(baseOptions: Apollo.QueryHookOptions<UserQuery, UserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserQuery, UserQueryVariables>(UserDocument, options);
      }
export function useUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserQuery, UserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserQuery, UserQueryVariables>(UserDocument, options);
        }
export type UserQueryHookResult = ReturnType<typeof useUserQuery>;
export type UserLazyQueryHookResult = ReturnType<typeof useUserLazyQuery>;
export type UserQueryResult = Apollo.QueryResult<UserQuery, UserQueryVariables>;
export const UserStatusDocument = gql`
    query userStatus($userId: ID!) {
  userStatus(userId: $userId) @rest(type: "UserStatusResponse", method: "GET", path: "/makro/playerprofile/0&userid={args.userId}&useronly=1") {
    selfNetwork
    selfPlayer
    selfUser
    jmtNetworks
  }
}
    `;

/**
 * __useUserStatusQuery__
 *
 * To run a query within a React component, call `useUserStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserStatusQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUserStatusQuery(baseOptions: Apollo.QueryHookOptions<UserStatusQuery, UserStatusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserStatusQuery, UserStatusQueryVariables>(UserStatusDocument, options);
      }
export function useUserStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserStatusQuery, UserStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserStatusQuery, UserStatusQueryVariables>(UserStatusDocument, options);
        }
export type UserStatusQueryHookResult = ReturnType<typeof useUserStatusQuery>;
export type UserStatusLazyQueryHookResult = ReturnType<typeof useUserStatusLazyQuery>;
export type UserStatusQueryResult = Apollo.QueryResult<UserStatusQuery, UserStatusQueryVariables>;
export const RelationshipsDocument = gql`
    query relationships($userId: ID!, $queryParams: String = "") {
  followed(userId: $userId, queryParams: $queryParams) @rest(type: "UserCollection", path: "/my/{args.userId}/user/{args.userId}/followed_users{args.queryParams}", method: "GET") {
    collection @type(name: "User") {
      ...user
      isFriend
    }
    meta {
      range {
        from
        to
        total
      }
      sortOptions {
        key
        value
      }
    }
  }
  follower(userId: $userId, queryParams: $queryParams) @rest(type: "UserCollection", path: "/my/{args.userId}/user/{args.userId}/followers{args.queryParams}", method: "GET") {
    collection @type(name: "User") {
      ...user
      isFriend
    }
    meta {
      range {
        from
        to
        total
      }
      sortOptions {
        key
        value
      }
    }
  }
}
    ${UserFragmentDoc}`;

/**
 * __useRelationshipsQuery__
 *
 * To run a query within a React component, call `useRelationshipsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRelationshipsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRelationshipsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      queryParams: // value for 'queryParams'
 *   },
 * });
 */
export function useRelationshipsQuery(baseOptions: Apollo.QueryHookOptions<RelationshipsQuery, RelationshipsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RelationshipsQuery, RelationshipsQueryVariables>(RelationshipsDocument, options);
      }
export function useRelationshipsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RelationshipsQuery, RelationshipsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RelationshipsQuery, RelationshipsQueryVariables>(RelationshipsDocument, options);
        }
export type RelationshipsQueryHookResult = ReturnType<typeof useRelationshipsQuery>;
export type RelationshipsLazyQueryHookResult = ReturnType<typeof useRelationshipsLazyQuery>;
export type RelationshipsQueryResult = Apollo.QueryResult<RelationshipsQuery, RelationshipsQueryVariables>;
export const NewActivitiesDocument = gql`
    query newActivities($isoDate: String!) {
  newActivities(isoDate: $isoDate) @rest(type: "Activity", method: "GET", path: "/makro/newactivities/{args.isoDate}") {
    ...activity
  }
}
    ${ActivityFragmentDoc}`;

/**
 * __useNewActivitiesQuery__
 *
 * To run a query within a React component, call `useNewActivitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useNewActivitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewActivitiesQuery({
 *   variables: {
 *      isoDate: // value for 'isoDate'
 *   },
 * });
 */
export function useNewActivitiesQuery(baseOptions: Apollo.QueryHookOptions<NewActivitiesQuery, NewActivitiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NewActivitiesQuery, NewActivitiesQueryVariables>(NewActivitiesDocument, options);
      }
export function useNewActivitiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NewActivitiesQuery, NewActivitiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NewActivitiesQuery, NewActivitiesQueryVariables>(NewActivitiesDocument, options);
        }
export type NewActivitiesQueryHookResult = ReturnType<typeof useNewActivitiesQuery>;
export type NewActivitiesLazyQueryHookResult = ReturnType<typeof useNewActivitiesLazyQuery>;
export type NewActivitiesQueryResult = Apollo.QueryResult<NewActivitiesQuery, NewActivitiesQueryVariables>;
export const ActivitiesDocument = gql`
    query activities($queryParams: String = "") {
  activities(queryParams: $queryParams) @rest(type: "ActivitiesResponse", method: "GET", path: "/makro/activitylog{args.queryParams}") {
    meta {
      range {
        from
        to
        total
      }
    }
    collection @type(name: "Activity") {
      ...activity
    }
  }
}
    ${ActivityFragmentDoc}`;

/**
 * __useActivitiesQuery__
 *
 * To run a query within a React component, call `useActivitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useActivitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActivitiesQuery({
 *   variables: {
 *      queryParams: // value for 'queryParams'
 *   },
 * });
 */
export function useActivitiesQuery(baseOptions?: Apollo.QueryHookOptions<ActivitiesQuery, ActivitiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ActivitiesQuery, ActivitiesQueryVariables>(ActivitiesDocument, options);
      }
export function useActivitiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ActivitiesQuery, ActivitiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ActivitiesQuery, ActivitiesQueryVariables>(ActivitiesDocument, options);
        }
export type ActivitiesQueryHookResult = ReturnType<typeof useActivitiesQuery>;
export type ActivitiesLazyQueryHookResult = ReturnType<typeof useActivitiesLazyQuery>;
export type ActivitiesQueryResult = Apollo.QueryResult<ActivitiesQuery, ActivitiesQueryVariables>;
export const ActivitylevelDocument = gql`
    query activitylevel($userId: ID!) {
  activitylevel(userId: $userId) @rest(path: "/makro/activitylevel/{args.userId}", method: "GET")
}
    `;

/**
 * __useActivitylevelQuery__
 *
 * To run a query within a React component, call `useActivitylevelQuery` and pass it any options that fit your needs.
 * When your component renders, `useActivitylevelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActivitylevelQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useActivitylevelQuery(baseOptions: Apollo.QueryHookOptions<ActivitylevelQuery, ActivitylevelQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ActivitylevelQuery, ActivitylevelQueryVariables>(ActivitylevelDocument, options);
      }
export function useActivitylevelLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ActivitylevelQuery, ActivitylevelQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ActivitylevelQuery, ActivitylevelQueryVariables>(ActivitylevelDocument, options);
        }
export type ActivitylevelQueryHookResult = ReturnType<typeof useActivitylevelQuery>;
export type ActivitylevelLazyQueryHookResult = ReturnType<typeof useActivitylevelLazyQuery>;
export type ActivitylevelQueryResult = Apollo.QueryResult<ActivitylevelQuery, ActivitylevelQueryVariables>;