import { useMemo } from 'react'

import { createPieGraphs } from './talentIdUtils'

interface PieProps {
    values: number[]
    compareValues?: number[]
    id: string
}

const Pie: React.FC<PieProps> = ({ id, values, compareValues }) => {
    const graphs = useMemo(() => createPieGraphs([values])[0], [values])
    const graphs2 = useMemo(() => compareValues ? createPieGraphs([compareValues])[0] : null, [compareValues])

    if (!graphs2) {
        return (
            <>
                {graphs.map(graph => (
                    <path
                        key={graph}
                        d={graph}
                        transform='translate(250,250)'
                        className='path--color1'
                    />
                ))}
            </>
        )
    }

    return (
        <>
            {graphs.map((graph, i) => (
                <g
                    transform='translate(250, 250)'
                >
                    <defs>
                        <mask
                            id={`${id}-${i}-pie-background`}
                        >
                            <rect
                                x='-300'
                                y='-300'
                                width='600'
                                height='600'
                                style={{ fill:'white' }}
                            />
                            <path
                                d={graphs2[i]}
                                style={{ fill:'black', fillOpacity: 1 }}
                            />
                        </mask>

                        <mask
                            id={`${id}-${i}-pie-foreground`}
                        >
                            <rect
                                x='-300'
                                y='-300'
                                width='600'
                                height='600'
                                style={{ fill: 'white' }}
                            />
                            <path
                                d={graph}
                                style={{ fill:'black', fillOpacity: 1 }}
                            />
                        </mask>

                        <mask
                            id={`${id}-${i}-pie-top`}
                        >
                            <path
                                d={graph}
                                style={{ fill:'white', fillOpacity: 1 }}
                            />
                        </mask>
                    </defs>

                    <path
                        d={graphs2[i]}
                        mask={`url(#${id}-${i}-pie-foreground`}
                        className='path--color2'
                    />
                    <path
                        d={graph}
                        mask={`url(#${id}-${i}-pie-background`}
                        className='path--color1'
                    />
                    <path
                        d={graphs2[i]}
                        mask={`url(#${id}-${i}-pie-top`}
                        className='path--overlap'
                    />

                    <path
                        d={graph}
                        className='path--combined'
                    />
                </g>
            ))}
        </>
    )
}

export default Pie
