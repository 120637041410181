import { Capacitor } from '@capacitor/core'
import { Share } from '@capacitor/share'
import { useIonAlert, useIonRouter } from '@ionic/react'
import { pencilOutline, share, trashBin } from 'ionicons/icons'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import ContextMenu from '../../components/ContextMenu'
import ContextMenuItem from '../../components/ContextMenu/ContextMenuItem/ContentMenuItem'
import { WATCHLIST_CAPTION } from '../../components/Playlist/PlayListConstants'
import { FE_URL } from '../../lib/apollo/config'
import type { Playlist } from '../../lib/apollo/types'
import { PlaylistDocument, PlaylistsDocument, useDeletePlaylistMutation } from '../../lib/apollo/types'
import { useUser } from '../../providers/Auth/hooks'
import useToast from '../../providers/Toast/hooks'

interface PlaylistContextMenuProps {
    playlist?: Playlist
    redirectAfterDelete?: boolean
}

const PlaylistContextMenu: React.FC<PlaylistContextMenuProps> = ({ redirectAfterDelete = false, playlist }) => {
    const { t } = useTranslation()
    const [showToast] = useToast()
    const me = useUser()
    const { push } = useIonRouter()
    const [show] = useIonAlert()

    const [deletePlaylist] = useDeletePlaylistMutation({
        refetchQueries: [{
            query: PlaylistsDocument,
            variables: {
                userId: me.user.id,
            },
        }, {
            query: PlaylistDocument,
            variables: {
                userId: me.user.id,
                id: playlist?.id,
            },
        }],
        onCompleted: () => {
            showToast(t('playlist.removed'), 'success')
            if (redirectAfterDelete) {
                push('/playlists', 'back')
            }
        },
    })

    const shareClick = useCallback(() => {
        const URL = `${FE_URL}/playlists/${playlist?.id}`

        if (!Capacitor.isNativePlatform()) {
            show({
                message: URL,
                buttons: [
                    {
                        text: t('playlist.linkCopy'),
                        handler: async () => {
                            await navigator.clipboard.writeText(URL)
                            showToast(t('playlist.linkCopied'), 'success')
                        },
                        role: 'success',
                    },
                ],
            })
            return
        }

        Share.share({
            dialogTitle: t('playlist.dialogTitle'),
            title: t('playlist.title'),
            text: t('playlist.text', { name: playlist?.caption }),
            url: URL,
        })
    }, [playlist?.id])

    return (
        <ContextMenu
            size='small'
            fill='clear'
            color='dark'
        >
            <ContextMenuItem
                disabled={playlist?.caption === WATCHLIST_CAPTION}
                icon={trashBin}
                label={t('playlist.remove')}
                onClick={() => {
                    deletePlaylist({ variables: { id: playlist?.id ?? '' } })
                }}
            />
            <ContextMenuItem
                disabled={playlist?.caption === WATCHLIST_CAPTION}
                icon={pencilOutline}
                label={t('playlist.edit')}
                onClick={() => { push(`/playlists/addoredit/${playlist?.id}`) }}
            />
            <ContextMenuItem
                disabled={!playlist?.public}
                icon={share}
                label={t('playlist.share')}
                onClick={shareClick}
            />
        </ContextMenu>
    )
}

export default PlaylistContextMenu
