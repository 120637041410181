import { useMatomo } from '@datapunt/matomo-tracker-react'
import { footballOutline } from 'ionicons/icons'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import type { Talent } from '../../../../../lib/apollo/types'
import { MatomoEventCategory } from '../../../../../lib/tracking/eventCategories'
import useToast from '../../../../../providers/Toast/hooks'
import Button from '../../../../Button'
import Modal from '../../../../Modal'
import ChoseTopSkills from '../index'

interface ChoseRoleModelButtonProps {
    talent?: Talent
    color?: string
}

const ChoseTopSkillsButton: React.FC<ChoseRoleModelButtonProps> = ({ color = 'secondary', talent }) => {
    const { t } = useTranslation()
    const [showModal, setShowModal] = useState(false)
    const [present] = useToast()

    const { trackEvent } = useMatomo()

    const onClick = useCallback(() => {
        if (+(talent?.rateCount ?? '0') >= 1) {
            trackEvent({
                action: 'start-edit-topskills',
                category: MatomoEventCategory.Talent,
            })
            setShowModal(() => true)
        } else {
            present(t('talent.topskills.minVotesHint'), 'warning')
        }

    }, [talent?.rateCount])

    return (
        <>
            { talent && (
                <Modal
                    onClose={() => { setShowModal(() => false) }}
                    modalTitle={t('talent.topskills.modalTitle')}
                    isOpen={showModal}
                >
                    <ChoseTopSkills
                        talent={talent}
                        onFinish={() => { setShowModal(() => false) }}
                    />
                </Modal>
            )}
            <Button
                expand='block'
                onClick={onClick}
                fill='solid'
                color={color}
                icon={footballOutline}
            >
                { t(talent?.personification ? 'talent.topskills.chose' : 'talent.topskills.choseScout') }
            </Button>
        </>
    )
}

export default ChoseTopSkillsButton
