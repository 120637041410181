import type { PureQueryOptions } from '@apollo/client'
import type { IonButton } from '@ionic/react'
import { IonText } from '@ionic/react'
import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import type { NetworkInvitation } from '../../../lib/apollo/types'
import {
    NetworkInvitationStatusResponse,
    NetworksDocument,
    NetworkUsersDocument,
    NewActivitiesDocument,
    useAcceptOrDeclineNetworkInvitationMutation,
    useUserMetaDataQuery,
} from '../../../lib/apollo/types'
import { useUser } from '../../../providers/Auth/hooks'
import Buttons from '../../Buttons'
import ItemList from '../../ItemList'
import Modal from '../../Modal'
import UserCardMedium from '../../User/UserCard/UserCardMedium'
import Button from '../index'

import './style.scss'

const AnswerInvitationButton: React.FC<{ invitation: NetworkInvitation, isInvite: boolean } & React.ComponentProps<typeof IonButton>> = ({
    isInvite,
    invitation,
    ...buttonProps
}) => {
    const { t } = useTranslation()
    const [isOpen, setIsOpen] = useState(false)
    const [acceptOrDeclineNetworkInvitationMutation] = useAcceptOrDeclineNetworkInvitationMutation()

    const user = useUser()
    const { data: userMetaData } = useUserMetaDataQuery({ skip: !user, variables: { userId: user?.user.id } })
    const isoDate = useMemo(() => {
        const aLV = userMetaData?.userMetaData?.user?.data?.activitiesLastViewed
        if (aLV) {
            return aLV
        }
        const createdTimeStamp = userMetaData?.userMetaData?.user?._created
        if (createdTimeStamp) {
            return new Date(createdTimeStamp).toISOString()
        }
        return ''
    }, [userMetaData])

    const acceptInvitation = useCallback(async () => {
        await acceptOrDeclineNetworkInvitationMutation({
            variables: {
                input: {
                    status: NetworkInvitationStatusResponse.Accepted,
                },
                id: invitation.id,
            },
            refetchQueries: [
                {
                    query: NewActivitiesDocument,
                    variables: {
                        isoDate,
                    },
                },
                isInvite ? {
                    query: NetworksDocument,
                    variables: {
                        userId: user.user.id,
                    },
                } : undefined,
                isInvite ? {
                    query: NetworksDocument,
                    variables: {
                        userId: user.user.id,
                        search: '&start=1&limit=15',
                    },
                } : undefined,
            ].filter(Boolean) as PureQueryOptions[],
        })
        setIsOpen(false)
    }, [invitation])

    const declineInvitation = useCallback(async () => {
        await acceptOrDeclineNetworkInvitationMutation({
            variables: {
                input: {
                    status: NetworkInvitationStatusResponse.Declined,
                },
                id: invitation.id,
            },
            refetchQueries: [
                {
                    query: NewActivitiesDocument,
                    variables: {
                        isoDate,
                    },
                },
                {
                    query: NetworkUsersDocument,
                    variables: {
                        userId: user.user.id,
                        networkId: invitation.networkId,
                    },
                },
            ],
        })
        setIsOpen(false)
    }, [invitation])

    return (
        <>
            <Button
                {...buttonProps}
                onClick={() => setIsOpen(true)}
            >
                {t('buttons.answer')}
            </Button>
            <Modal
                size='fullscreen'
                isOpen={isOpen}
                modalTitle={t('title.invitation')}
                onClose={() => setIsOpen(false)}
            >
                {!!invitation.targetUser && (
                    <ItemList>
                        <div
                            className='invitation-answer__user-card'
                        >
                            <UserCardMedium
                                disableFollowButton
                                user={invitation.targetUser ?? undefined}
                            />
                        </div>

                        <div
                            className='invitation-answer__content'
                        >
                            <div
                                className='invitation-answer__text-wrapper'
                            >
                                <IonText
                                    className='invitation-answer__text ion-text-center n2br'
                                >
                                    <em>
                                        {invitation.message}
                                    </em>
                                </IonText>
                            </div>
                            { invitation.targetUser && (
                                <Buttons
                                    subtitle={t('invitiation.pretext', { name: invitation.targetUser.firstName, team: invitation.network?.caption ?? '' })}
                                >
                                    <Button
                                        color='secondary'
                                        onClick={acceptInvitation}
                                    >
                                        {t('buttons.accept')}
                                    </Button>
                                    <Button
                                        onClick={declineInvitation}
                                    >
                                        {t('buttons.decline')}
                                    </Button>
                                </Buttons>
                            )}
                        </div>
                    </ItemList>
                )}
            </Modal>
        </>
    )
}

export default AnswerInvitationButton
