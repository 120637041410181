import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useContextSelector } from 'use-context-selector'

import useTabContext from '../../lib/hooks/useTabContext'
import { AppContext } from '../AppState'
import Button from '../Button'
import { getNewForeignActivitesBadge, showTabBarRoutes } from '../TabBar'
import './style.scss'

const links = [
    'home',
    'profile',
    'marketplace',
    'activities',
    'playlists',
]

const DesktopMenu: React.FC = () => {
    const { t } = useTranslation()
    const activeUrl = useTabContext()

    const newForeignActivities = useContextSelector(AppContext, state => state.state.newForeignActivities)

    const badge = useMemo(() => {
        return getNewForeignActivitesBadge(newForeignActivities)
    }, [newForeignActivities])

    if (!showTabBarRoutes.includes(`/${activeUrl}`)) return null

    return (
        <div
            className='desktop-menu'
        >
            { links.map(link => (
                <Button
                    key={link}
                    className='ion-margin-horizontal'
                    fill='clear'
                    color={activeUrl === link ? 'secondary' : undefined}
                    routerLink={`/${link}`}
                    routerOptions={{ unmount: true }}
                    badge={link === 'activities' ? badge : undefined}
                >
                    {t(`tabbar.${link}`)}
                </Button>
            ))}
        </div>
    )
}

export default DesktopMenu
