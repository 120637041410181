import { IonCard, IonText } from '@ionic/react'
import { Trans } from 'react-i18next'

import { useListMyPersonificationsQuery, useNetworkQuery } from '../../../lib/apollo/types'
import { useUser } from '../../../providers/Auth/hooks'
import AnswerTalentInvitiationButton from '../../Button/AnswerTalentInvitationButton'
import CustomAvatar from '../../CustomAvatar'
import type { ActivityProps } from '../ActivityProps'
import useHours from '../useHours'

export type TalentInvitation = { link: string, targetUserId: number, networkId: number }

const ApplyTalentActivity: React.FC<ActivityProps> = ({ activity }) => {
    const me = useUser()
    const invite = activity.userID.toString() === me.user.id.toString()
    const application = JSON.parse(activity.data) as TalentInvitation

    const { data: myPersonifications } = useListMyPersonificationsQuery({
        variables: {
            userId: me.user.id,
        },
    })

    const hours = useHours(activity)

    const { data: network } = useNetworkQuery({
        variables: {
            userId: me.user.id,
            networkId: `${application.networkId}`,
        },
    })

    return (
        <IonCard
            className='invite-activity'
        >
            <CustomAvatar
                width={106.66}
                type='network'
                className='invite-activity__avatar'
                img={network?.network.image}
                alt={network?.network.id}
            />
            <IonText
                className='invite-activity__text'
                color='dark'
            >
                <Trans
                    i18nKey={`activity.invite.${invite ? 'invite' : 'network'}`}
                    values={{
                        network: network?.network?.caption,
                        user: `${myPersonifications?.listMyPersonifications.collection?.[0]?.caption}`,
                        date: hours,
                    }}
                    components={{
                        strong: <strong />,
                        date: (
                            <IonText
                                className='follow-activity__text--small'
                                color='medium'
                            />
                        ),
                    }}
                />
            </IonText>
            <AnswerTalentInvitiationButton
                size='small'
                fill='solid'
                color='secondary'
                application={application}
                userId={activity.userID}
            />
        </IonCard>
    )
}

export default ApplyTalentActivity
