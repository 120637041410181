import { useMemo } from 'react'

import { useUser } from '../../providers/Auth/hooks'
import type { NetworkTalentsResponse } from '../apollo/types'
import { useJmtNetworksQuery, useNetworkTalentsQuery } from '../apollo/types'

const useRoleModels = (): NetworkTalentsResponse | undefined => {
    const me = useUser()
    const networks = useJmtNetworksQuery()

    const roleModelNetwork = useMemo(() =>
        networks?.data?.jmtNetworks.JMTnetworks.collection.find((network) => network.caption === 'Join.My.Talent.Engine.RoleModels')
    , [networks])

    const { data: { networkTalents } = { } } = useNetworkTalentsQuery({
        skip: !roleModelNetwork?.id || !me?.user,
        variables: {
            networkId: roleModelNetwork?.id ?? '',
            userId: me.user.id,
        },
    })

    return networkTalents
}

export default useRoleModels
