import { Preferences } from '@capacitor/preferences'
import {
    IonCard,
    IonCardContent,
    IonCol,
    IonGrid,
    IonImg, IonRouterContext,
    IonRow, useIonRouter,
} from '@ionic/react'
import { footballOutline, help, logInOutline, person } from 'ionicons/icons'
import { useCallback, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'

import Button from '../../components/Button'
import Content from '../../components/Content'
import Form from '../../components/Form'
import FormCheckbox from '../../components/Form/FormCheckbox'
import FormTextField from '../../components/Form/FormTextField'
import Header from '../../components/Header'
import Page from '../../components/Page'
import { ENV_NAME } from '../../lib/apollo/config'
import STORAGE_KEYS from '../../lib/storageKeys'
import { useSignIn, useUser } from '../../providers/Auth/hooks'
import useToast from '../../providers/Toast/hooks'

import './style.scss'

const Login: React.FC = () => {
    const { t } = useTranslation()
    const user = useUser()
    const [showToast] = useToast()

    const [loggingIn, setLoggingIn] = useState(false)
    const signin = useSignIn({
        onError: () => {
            setLoggingIn(false)
            showToast(t('login.failed'), 'danger')
        },
    })

    const history = useHistory<{ referrer?: string }>()
    const { push } = useIonRouter()
    const ionRouterContext = useContext(IonRouterContext)
    const queryParams = new URLSearchParams(window.location.search)
    const loginhack123 = queryParams.get('loginhack123')
    const [username, setUsername] = useState<string | null>()
    // const biometricAuth = useBiometricAuth()
    // const [backdrop, setBackdrop] = useState(false)

    // useEffect(() => {
    //     if (!biometricAuth.enabled) {
    //         return
    //     }
    //
    //     setBackdrop(true)
    //     biometricAuth.getCredentials().then((credentials: Credentials | null) => {
    //         setBackdrop(false)
    //         if (credentials) {
    //             signin(credentials.username, credentials.password, false)
    //         }
    //     })
    // }, [setBackdrop, biometricAuth.available])

    useEffect(() => {
        const setUserName = async () => {
            const uname = await Preferences.get({ key: STORAGE_KEYS.LOGIN.LAST_USERNAME })
            setUsername(uname.value)
        }

        setUserName()
    }, [])

    useEffect(() => {
        if (user?.user && ionRouterContext.routeInfo.pathname === '/login') {
            ionRouterContext.push(history.location.state?.referrer ?? '/', 'root')
        }
    }, [user, ionRouterContext.routeInfo.pathname])

    const onSubmit = useCallback(({ name, password, permanent }) => {
        setLoggingIn(true)
        try {
            Preferences.set({ key: STORAGE_KEYS.LOGIN.LAST_USERNAME, value: name })
            signin(name, password, permanent)
        } catch (e) {
            setLoggingIn(false)
        }
    }, [signin, setLoggingIn])

    return (
        <Page
            trackingTitle='login'
        >
            {/* {backdrop && ( */}
            {/*    <IonBackdrop */}
            {/*        tappable={false} */}
            {/*    /> */}
            {/* )} */}
            <Header
                title={t('title.login')}
                buttonLeft='none'
            />
            <Content
                fullscreen
            >
                <IonGrid
                    className='login__grid'
                >
                    <IonRow>
                        <IonCol>
                            <IonCard
                                className='login-card'
                            >
                                <IonImg
                                    className='login-card__image'
                                    src='/assets/icon/favicon.png'
                                />
                                <IonCardContent>
                                    {(ENV_NAME !== 'sandbox' || loginhack123 !== null) ? (
                                        <Form
                                            onSubmit={onSubmit}
                                        >
                                            {typeof username !== 'undefined' && (
                                                <FormTextField
                                                    className='id__login-name'
                                                    required
                                                    name='name'
                                                    label={t('login.username')}
                                                    defaultValue={username}
                                                />
                                            )}
                                            <FormTextField
                                                required
                                                className='id__login-password'
                                                name='password'
                                                label={t('login.password')}
                                                type='password'
                                            />
                                            <FormCheckbox
                                                defaultValue
                                                className='id__login-permanent'
                                                name='permanent'
                                                label={t('login.permanent')}
                                            />
                                            <div
                                                className='login-card__button-bar'
                                            >
                                                <Button
                                                    loading={loggingIn}
                                                    fill='solid'
                                                    color='secondary'
                                                    size='default'
                                                    type='submit'
                                                    slot='end'
                                                    className='id__login-button'
                                                    icon={logInOutline}
                                                >
                                                    {t('login.login')}
                                                </Button>
                                                <Button
                                                    color='primary'
                                                    size='default'
                                                    slot='start'
                                                    fill='outline'
                                                    className='id__register-button'
                                                    icon={footballOutline}
                                                    routerLink='/register'
                                                >
                                                    {t('login.register')}
                                                </Button>
                                                <Button
                                                    color='transparent'
                                                    size='default'
                                                    slot='start'
                                                    fill='clear'
                                                    className='id__register-button'
                                                    icon={help}
                                                    routerLink='/forgotpassword'
                                                >
                                                    {t('login.help')}
                                                </Button>
                                            </div>
                                        </Form>
                                    ) : (
                                        <>
                                            <div
                                                className='login-card__intro-text'
                                            >
                                                {t('sandbox.login.intro')}
                                            </div>
                                            <div
                                                className='login-card__button-bar'
                                            >
                                                <Button
                                                    loading={loggingIn}
                                                    fill='solid'
                                                    color='secondary'
                                                    size='default'
                                                    slot='end'
                                                    className='id__login-button'
                                                    icon={person}
                                                    onClick={() =>
                                                        onSubmit({
                                                            name: 'jmtTrainer',
                                                            password: 'eiThoomipah9',
                                                            permanent: false,
                                                        })}
                                                >
                                                    {`Markus (${t('user.userType.trainer')})`}
                                                </Button>
                                                <Button
                                                    loading={loggingIn}
                                                    color='secondary'
                                                    size='default'
                                                    slot='start'
                                                    className='id__register-button'
                                                    icon={footballOutline}
                                                    onClick={() => {
                                                        onSubmit({
                                                            name: 'user1001571',
                                                            password: 'eiThoomipah9',
                                                            permanent: false,
                                                        })
                                                        setTimeout(() => {
                                                            push('/profile/network/undefined/talents/1104/details')
                                                        }, 350)
                                                    }}
                                                >
                                                    {`Luca (${t('user.userType.player')})`}
                                                </Button>
                                            </div>
                                        </>
                                    )}
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </Content>
        </Page>
    )
}

export default Login
