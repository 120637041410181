import { IonCard, IonCol, IonGrid, IonRow, useIonRouter } from '@ionic/react'
import { swapHorizontal } from 'ionicons/icons'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { TalentPosition, useTalentQuery } from '../../lib/apollo/types'
import getAge from '../../lib/getAge'
import { useUser } from '../../providers/Auth/hooks'
import useToast from '../../providers/Toast/hooks'
import Button from '../Button'
import TalentID from '../Talent/TalentID'
import SkillsListViewButton from '../Talent/TalentID/SkillsListView/SkillsListViewButton'

import CompareMeta from './CompareMeta'
import ComparisonTalent from './ComparisonTalent'

import './style.scss'

interface ComparisonProps {
    talentId1: string,
    talentId2: string,
}

const Comparison: React.FC<ComparisonProps> = ({
    talentId1,
    talentId2,
}) => {
    const { t } = useTranslation()
    const { push } = useIonRouter()
    const [present] = useToast()
    const [activeIndex, setActiveIndex] = useState<number>(0)
    const user = useUser()
    const { data: { talent: talent1 } = {} } = useTalentQuery({ variables: { userId: user.user.id, talentId: talentId1 } })
    const { data: { talent: talent2 } = {} } = useTalentQuery({ variables: { userId: user.user.id, talentId: talentId2 } })

    // t1 is keeper !== t2 is keeper
    const talentsAreIncomparable = (talent1?.metas.fieldPosition === TalentPosition.Goal) !== (talent2?.metas.fieldPosition === TalentPosition.Goal)

    useEffect(() => {
        if (talent1 && talent2 && talentsAreIncomparable) {
            present(t('talent.incomparable'), 'warning')
            push(`/comparison/${talentId1}`, 'none')
        }
    }, [talentsAreIncomparable])

    if (!talent1 || !talent2 || talentsAreIncomparable) return null

    return (
        <IonCard
            color='primary'
            className='comparison'
        >
            <IonGrid
                className='comparison__head'
            >
                <IonRow
                    className='comparison__talent-row ion-justify-content-between ion-nowrap ion-margin-bottom'
                >
                    <ComparisonTalent
                        talent={talent1}
                    />

                    <ComparisonTalent
                        color='tertiary'
                        talent={talent2}
                        showDeleteButton
                        routerLink={`/comparison/${talent1.id}`}
                    />

                    <Button
                        className='comparison__swap-btn'
                        icon={swapHorizontal}
                        onClick={() => { push(`/comparison/${talentId2}/${talentId1}`, 'none') }}
                    />
                </IonRow>
                <IonRow>
                    <CompareMeta
                        talent1={talent1}
                        talent2={talent2}
                    />
                </IonRow>
            </IonGrid>
            <IonGrid>
                <IonRow
                    className='comparison__talent-id-wrapper ion-justify-content-center'
                >
                    <IonCol>
                        <IonCard
                            color='primary'
                            className='comparison__talent-id-card'
                        >
                            <IonGrid>
                                <IonRow
                                    className='ion-margin-bottom'
                                >
                                    <TalentID
                                        talentPosition={talent1.metas.fieldPosition}
                                        dataView1={talent1.dataViews}
                                        dataView2={talent2.dataViews}
                                        backgroundColor='black'
                                        color1='secondary'
                                        interactive
                                        onIndexChange={setActiveIndex}
                                        under16={(getAge(talent1.metas.date) ?? 99) < 16 || (getAge(talent2.metas.date) ?? 99) < 16}
                                    />
                                </IonRow>
                                <IonRow
                                    className='ion-justify-content-center'
                                >
                                    <SkillsListViewButton
                                        color='light'
                                        color1='secondary'
                                        color2='tertiary'
                                        talentPosition={talent1.metas.fieldPosition}
                                        dataView1={talent1.dataViews}
                                        dataView2={talent2.dataViews}
                                        talent1={talent1}
                                        talent2={talent2}
                                        activeIndex={activeIndex}
                                        under16={(getAge(talent1.metas.date) ?? 99) < 16 || (getAge(talent2.metas.date) ?? 99) < 16}
                                    />
                                </IonRow>
                            </IonGrid>
                        </IonCard>
                    </IonCol>
                </IonRow>
            </IonGrid>
        </IonCard>
    )
}

export default Comparison
