import { useIonRouter } from '@ionic/react'
import { arrowBack, arrowForward, checkmark } from 'ionicons/icons'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Controller } from 'swiper'
import type { SwiperProps } from 'swiper/react/swiper-react'
import { Swiper } from 'swiper/react/swiper-react.js'
import type { Swiper as SwiperClass } from 'swiper/types'

import useOnboarding from '../../../providers/Onboarding/hooks'
import Button from '../../Button'
import Modal from '../../Modal'

import './style.scss'

type OnboardingModalProps = {
    title: string | JSX.Element
    id: string
    sort?: number
}

const slideOptions: SwiperProps = {
    autoHeight: false,
    height: 100,
    spaceBetween: 16,
    allowTouchMove: false,
}

const OnboardingModal: React.FC<OnboardingModalProps> = ({ children, title, id, sort = 0 }) => {

    const { mount, unmount, current, finish } = useOnboarding()
    const { routeInfo: { pathname } } = useIonRouter()

    const modalRef = useRef<HTMLIonModalElement>(null)

    const [controlledSwiper, setControlledSwiper] = useState<SwiperClass | null>(null)
    const [activeIndex, setActiveIndex] = useState(0)

    const lastSlideIdx = useMemo(() => {
        if(Array.isArray(children)) {
            return children.filter(Boolean).length - 1
        }
        return 0

    }, [children])

    const onNextClick = useCallback(() => {
        if(activeIndex < lastSlideIdx) {
            controlledSwiper?.slideTo(activeIndex + 1)
        } else {
            finish(id)
        }
    }, [activeIndex, lastSlideIdx, controlledSwiper, finish])

    useEffect(() => {
        mount({
            type: 'modal',
            id,
            sort,
        })

        return () => {
            unmount(id)
        }
    }, [id])

    useEffect(() => {
        modalRef?.current?.dismiss()
    }, [pathname])

    return (
        <Modal
            ref={modalRef}
            modalTitle={title}
            isOpen={current?.id === id}
            className='onboarding-modal'
            onDidDismiss={(() => {setActiveIndex(0)})}
            footer={(
                <div
                    className='onboarding-modal__footer'
                >
                    <div
                        className='onboarding-modal__footer__buttons'
                    >
                        {activeIndex > 0 && (
                            <Button
                                round
                                icon={arrowBack}
                                onClick={() => controlledSwiper?.slideTo(activeIndex - 1)}
                            />
                        )}
                        <Button
                            round
                            color='secondary'
                            icon={activeIndex === lastSlideIdx ? checkmark : arrowForward}
                            onClick={onNextClick}
                        />
                    </div>
                </div>
            )}
        >
            <Swiper
                {...slideOptions}
                modules={[Controller]}
                onSwiper={setControlledSwiper}
                onSlideChange={swiper => setActiveIndex(swiper.activeIndex)}
            >
                <div
                    className='onboarding-modal__content'
                >
                    {children}
                </div>
            </Swiper>
        </Modal>
    )
}

export default OnboardingModal
