import { IonGrid, IonRow, IonText } from '@ionic/react'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import type { User , UserType } from '../../../../lib/apollo/types'
import { useRelatedUsersFromMyNetworksQuery } from '../../../../lib/apollo/types'
import { useUser } from '../../../../providers/Auth/hooks'
import NetworkAddCardSmall from '../../../Network/NetworkCard/NetworkCardSmall/NetworkAddCardSmall'
import type { UserTeaserSliderTab } from '../index'
import UserTeaserSlider from '../index'

import './style.scss'

const RelatedUserTeaserSlider: React.FC = () => {
    const user = useUser()
    const { data } = useRelatedUsersFromMyNetworksQuery({ variables: { userId: user.user.id } })
    const { t }  = useTranslation()
    const tabsWithUser = useMemo<UserTeaserSliderTab[] | null>(() => {
        if(!data) {
            return null
        }

        const tabs: { [key: string]: { [userId: string]: User } } = {}

        data?.networksWithUsers.collection.forEach(network => {
            network.users?.collection.filter(u => u.id !== user.user.id).forEach((u: User) => {
                u.userMetaData?.user?.data?.userType?.forEach((type: UserType | null) => {
                    if(!type) return
                    if(!tabs[type as string]) {
                        tabs[type as string] = {}
                    }

                    tabs[type as string][u.id] = u
                })
            })
        })

        return Object.entries(tabs).map(([key, value]) => ({
            label: t(`user.userType.${key}`),
            users: Object.values(value),
        }))
    }, [data])

    if(tabsWithUser?.length === 0) {
        return (
            <IonGrid>
                <IonRow>
                    <h2
                        className='ion-margin-bottom'
                    >
                        { t('title.relatedUser') }
                    </h2>
                </IonRow>
                <IonRow>
                    <IonText
                        className='ion-margin-bottom'
                        color='medium'
                    >
                        <span>
                            { t('title.sub.relatedUser') }
                        </span>
                    </IonText>
                </IonRow>
                <IonRow>
                    <div
                        className='related-user-teaser__fallback'
                    >
                        <NetworkAddCardSmall
                            subtitleKey='releatedUser.createNetwork'
                        />
                    </div>
                </IonRow>
            </IonGrid>
        )
    }

    return (
        <UserTeaserSlider
            title={t('title.relatedUser')}
            subtitle={t('title.sub.relatedUser')}
            tabs={tabsWithUser}
        />
    )
}

export default RelatedUserTeaserSlider
