import { eye, heart, personAddOutline, personOutline, shirtOutline } from 'ionicons/icons'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { SwiperSlide } from 'swiper/react/swiper-react'

import type { Talent } from '../../../../lib/apollo/types'
import { useNetworkQuery } from '../../../../lib/apollo/types'
import { useUser } from '../../../../providers/Auth/hooks'
import SelectableFriendsModal from '../../../Modal/SelectableFriendsModal'
import OnboardingModal from '../../../OnboardingWizard/OnboardingModal'
import type { SelectWithOrderState } from '../../../SelectWithOrder'
import SelectWithOrder from '../../../SelectWithOrder'

import './style.scss'

interface SkillsSelectLayersViewProps {
    talent: Talent
    networkId?: string
    selected: SelectWithOrderState,
    setSelected:  React.Dispatch<React.SetStateAction<SelectWithOrderState>>
}

const SkillsSelectLayersView: React.FC<SkillsSelectLayersViewProps> = ({ talent, networkId, selected, setSelected }) => {
    const me = useUser()

    const network = useNetworkQuery({
        skip: !networkId,
        variables: {
            userId: me.user.id,
            networkId: networkId ?? '',
        },
    })
    const [selectFriendsModalOpen, setSelectFriendsModalOpen] = useState(false)

    const showFriends = (network.data?.network && !network.data?.network.open)

    const { t } = useTranslation()

    const options = useMemo<SelectWithOrderState>(() => {
        const o : SelectWithOrderState = [
            {
                value: 'public',
                label: t('talent.layers.public'),
                icon: eye,
            },
            {
                value: 'self',
                label: t('talent.layers.self'),
                icon: personOutline,
            },
        ]

        if (talent.permissionLevel === 'PERSONAL') {
            o.push(
                {
                    value: 'followed',
                    label: t('talent.layers.followed'),
                    icon: personAddOutline,
                },
            )
        }

        if (networkId !== 'undefined' && !!networkId) {
            o.push(
                {
                    value: 'network',
                    label: t('talent.layers.network'),
                    icon: shirtOutline,
                },
            )
        }

        if (showFriends) {
            o.push(
                {
                    value: 'network_friends',
                    label: t('user.friends'),
                    className: 'color-secondary',
                    multiple: true,
                    onClick: () => setSelectFriendsModalOpen(true),
                    icon: heart,
                },
            )
        }

        return o
    }, [talent, networkId, showFriends])

    return (
        <>
            <OnboardingModal
                title={t('layers.onboarding.title')}
                id='layers-page'
            >
                <SwiperSlide>
                    <h1>
                        {t('talent.layers.selectLayers')}
                    </h1>
                    <SelectWithOrder
                        max={2}
                        selected={selected}
                        setSelected={setSelected}
                        options={options}
                        className='skills-select-layers__reorder'
                    />
                    <div>
                        <p>{t('layers.onboarding.1')}</p>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <SelectWithOrder
                        max={2}
                        selected={selected}
                        setSelected={setSelected}
                        options={options}
                        className='skills-select-layers__reorder'
                    />
                    <div>
                        <p>{t('layers.onboarding.2')}</p>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <SelectWithOrder
                        max={2}
                        selected={selected}
                        setSelected={setSelected}
                        options={options}
                        className='skills-select-layers__reorder'
                    />
                    <div>
                        <p>{t('layers.onboarding.3')}</p>
                    </div>
                </SwiperSlide>
            </OnboardingModal>
            <SelectWithOrder
                max={2}
                selected={selected}
                setSelected={setSelected}
                options={options}
                className='skills-select-layers__reorder'
            />
            <SelectableFriendsModal
                networkId={networkId}
                onSubmit={ids => {
                    setSelected(prevState => [...prevState, {
                        value: 'network_friends',
                        label: t('user.friends'),
                        className: 'color-secondary',
                        payload: `&users=${ids.map(u => u.id).join(',')}&networkId=${networkId}`,
                        multiple: true,
                        icon: heart,
                    }],
                    )
                }}
                onClose={() => {
                    setSelectFriendsModalOpen(false)
                    // clear empty friendlist on close (e.g. no friends available)
                    setSelected(prevState => [...prevState.filter(s => s.value !== 'network_friends' || !!s.payload)])
                }}
                open={selectFriendsModalOpen}
            />
        </>
    )

}

export default SkillsSelectLayersView
