import clsx from 'clsx'
import { useRouteMatch } from 'react-router'

import Content from '../../../components/Content'
import TalentCard from '../../../components/NewTalentCard'
import Page from '../../../components/Page'
import type { Talent } from '../../../lib/apollo/types'
import { useSharedTalentQuery } from '../../../lib/apollo/types'

import './style.scss'

const SharedTalentPreview: React.FC = () => {
    const { params: { hash } } = useRouteMatch<{ hash: string }>()
    const { data } = useSharedTalentQuery({
        variables: {
            hash,
        },
        skip: !hash,
    })

    if (!data) return null

    return (
        <Page
            className={clsx('shared-talent-card-preview', {
                'shared-talent-card-preview--rolemodel': data.sharedTalent.sharedRoleModel,
            })}
        >
            <Content
                fullscreen
                className='shared-talent-card-preview__content'
            >
                <div
                    className='shared-talent-card-preview__talent-grid display-flex ion-justify-content-center'
                >
                    <div
                        className='shared-talent-card-preview__talent'
                    >
                        <TalentCard
                            talent={data.sharedTalent.talent}
                        />
                    </div>

                    { data.sharedTalent.sharedRoleModel && (
                        <div
                            className='shared-talent-card-preview__rolemodel'
                        >
                            <TalentCard
                                talent={data.sharedTalent.roleModel as Talent}
                                similarTalent={data.sharedTalent.talent}
                            />
                        </div>
                    )}
                </div>
            </Content>
        </Page>
    )
}

export default SharedTalentPreview
