import { IonCheckbox, IonItem, IonLabel, IonText, useIonPopover } from '@ionic/react'
import clsx from 'clsx'
import { informationCircleOutline } from 'ionicons/icons'
import { useCallback, useEffect } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import Button from '../../Button'
import CollapsibleContent from '../../Collapsible/CollapsibleContent'
import './style.scss'

interface FormCheckboxProps {
    name: string
    label: string
    color?: string
    required?: boolean
    defaultValue?: boolean
    className?: string
    hint? : string
}

const Hint: React.FC<{ onHide: () => void, hint: string }> = ({ onHide, hint }) => (
    <IonItem
        lines='none'
        onClick={onHide}
    >
        {hint}
    </IonItem>
)

const FormCheckbox: React.FC<FormCheckboxProps> = ({
    required,
    name,
    label,
    color = 'secondary',
    defaultValue,
    className,
    hint,
}) => {
    const { control, formState, setValue } = useFormContext()
    const error = formState.errors[name]

    const { t } = useTranslation()
    const mergedRules = required ? { required: { value: true, message: t('errors.required', { label }) } } : {}

    const [present, dismiss] = useIonPopover(Hint, { onHide: () => { dismiss() }, hint })
    const onHintClick = useCallback((event: any) => {
        event.stopPropagation()
        event.preventDefault()
        present({ event })
    }, [])

    useEffect(() => {
        setValue(name, !!defaultValue)
    }, [])

    return (
        <div>
            <div
                className='form-checkbox'
            >
                <IonItem
                    lines='none'
                >
                    <Controller
                        control={control}
                        name={name}
                        rules={mergedRules}
                        render={({ field }) => {
                            return (
                                <IonCheckbox
                                    slot='start'
                                    className={className}
                                    color={color}
                                    checked={!!field.value}
                                    onIonChange={(e) => field.onChange(e.detail.checked)}
                                    onBlur={field.onBlur}
                                />
                            )
                        }}
                    />
                    <IonLabel
                        className={clsx({ 'error': !!error })}
                    >
                        {label + (required ? '*' : '')}
                    </IonLabel>
                    {hint && (
                        <Button
                            fill='clear'
                            slot='end'
                            icon={informationCircleOutline}
                            onClick={onHintClick}
                        />
                    )}
                </IonItem>
            </div>
            <CollapsibleContent
                open={!!error}
            >
                <IonText
                    className='form-checkbox__error-text font-s-regular'
                    color='danger'
                >
                    {error?.message}
                </IonText>
            </CollapsibleContent>
        </div>
    )
}

export default FormCheckbox
