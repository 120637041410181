import {
    IonCard,
    IonCardContent,
    IonCardTitle,
    IonIcon,
    IonItem,
    IonLabel,
    IonText,
} from '@ionic/react'
import { footballOutline, informationCircleOutline } from 'ionicons/icons'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useRouteMatch } from 'react-router'
import { Controller } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js'
import type { Swiper as SwiperClass } from 'swiper/types'

import Button from '../../components/Button'
import Content from '../../components/Content'
import Header from '../../components/Header'
import ItemList from '../../components/ItemList'
import TalentCard from '../../components/NewTalentCard'
import Page from '../../components/Page'
import Segment from '../../components/Segment'
import TalentActionButtons from '../../components/TalentActionButtons'
import UserCardMedium from '../../components/User/UserCard/UserCardMedium'
import {
    PermissionLevel,
    useListMyPersonificationsQuery,
} from '../../lib/apollo/types'
import { useUser } from '../../providers/Auth/hooks'

import './style.scss'

export const tabs = ['user', 'personification']

const slideOptions = {
    spaceBetween: 16,
}
const Profile: React.FC = () => {
    const { t } = useTranslation()
    const user = useUser()
    const [currentTab, setCurrentTab] = useState<string>('0')
    const [controlledSwiper, setControlledSwiper] = useState<SwiperClass | null>(null)
    const { params: { tab } } = useRouteMatch<{ tab: 'detail' | 'personification' }>()

    const onSegmentChange = useCallback((e: CustomEvent) => {
        if (!controlledSwiper) {
            return
        }
        setCurrentTab(e.detail.value)
        controlledSwiper.slideTo(e.detail.value)
    }, [controlledSwiper, setCurrentTab])

    const onSlideWillChange = useCallback((e: SwiperClass) => {
        setCurrentTab(e.activeIndex.toString())
    }, [])

    const { data: myPersonifications } = useListMyPersonificationsQuery({
        variables: {
            userId: user.user.id,
        },
    })

    useEffect(() => {
        if(!tab || !controlledSwiper) return
        controlledSwiper?.slideTo(tabs.indexOf(tab))
    }, [tab, controlledSwiper])

    return (
        <Page
            trackingTitle='profile'
        >
            <Header
                title={t('title.profile')}
                buttonLeft='menu'
            />
            {user.user && !!myPersonifications?.listMyPersonifications.collection && (
                <Content
                    fullscreen
                    className='profile'
                >
                    <Segment
                        value={currentTab}
                        onIonChange={onSegmentChange}
                        tabs={tabs.map((v: string) => ({ tab: t(`profile.${v}`) }))}
                    />
                    <Swiper
                        {...slideOptions}
                        modules={[Controller]}
                        onSwiper={setControlledSwiper}
                        onSlideChange={onSlideWillChange}
                    >
                        <SwiperSlide>
                            <div
                                className='profile__card-wrapper'
                            >
                                <UserCardMedium
                                    user={user.user}
                                />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            {myPersonifications?.listMyPersonifications.collection?.[0]?.id ? (
                                <ItemList>
                                    <div
                                        className='display-flex ion-justify-content-center'
                                    >
                                        <TalentCard
                                            permissionLevel={PermissionLevel.Personal}
                                            talent={myPersonifications.listMyPersonifications.collection[0]}
                                        />
                                    </div>
                                    <TalentActionButtons
                                        permissionLevel={PermissionLevel.Personal}
                                        talent={myPersonifications.listMyPersonifications.collection[0]}
                                    />
                                </ItemList>
                            ) : (
                                <IonCard
                                    color='primary'
                                >
                                    <IonCardContent>
                                        <IonCardTitle>{t('profile.createTalent.title')}</IonCardTitle>
                                        <IonItem
                                            lines='none'
                                        >
                                            <IonIcon
                                                icon={informationCircleOutline}
                                                slot='start'
                                                color='medium'
                                            />
                                            <IonLabel
                                                className='ion-text-wrap'
                                            >
                                                <IonText
                                                    color='medium'
                                                >
                                                    {t('profile.createTalent.info')}
                                                </IonText>
                                            </IonLabel>
                                        </IonItem>
                                        <IonItem
                                            lines='none'
                                        >
                                            <Button
                                                icon={footballOutline}
                                                color='secondary'
                                                size='default'
                                                className='profile__create-btn'
                                                routerLink='/profile/edit/talent/new/true'
                                            >
                                                {t('profile.createTalent.create')}
                                            </Button>
                                        </IonItem>
                                    </IonCardContent>
                                </IonCard>
                            )}
                        </SwiperSlide>
                    </Swiper>
                </Content>
            )}
        </Page>
    )
}

export default Profile
