import { search } from 'ionicons/icons'
import { useTranslation } from 'react-i18next'
import { SwiperSlide } from 'swiper/react/swiper-react'

import Button from '../../components/Button'
import Content from '../../components/Content'
import Divider from '../../components/Divider'
import Header from '../../components/Header'
import NetworksTeaserSlider from '../../components/Network/NetworkTeaserSlider'
import NextSteps from '../../components/NextSteps'
import OnboardingModal from '../../components/OnboardingWizard/OnboardingModal'
import Page from '../../components/Page'
import PlaylistsTeaserSlider from '../../components/Playlist/PlaylistTeaserSlider'
import FemaleRoleModelTeaserSlider from '../../components/RoleModel/FemaleRoleModelTeaserSlider'
import RoleModelTeaserCard from '../../components/RoleModel/RoleModelTeaserCard'
import RoleModelTeaserSlider from '../../components/RoleModel/RoleModelTeaserSlider'
import FFFUserTeaserSlider from '../../components/User/UserTeaserSlider/FFFUserTeaserSlider'
import RelatedUserTeaserSlider from '../../components/User/UserTeaserSlider/RelatedUserTeaserSlider'
import { UserType, useUserMetaDataQuery } from '../../lib/apollo/types'
import { useUser } from '../../providers/Auth/hooks'
import MarketplaceTeaserCard from '../Marketplace/MarketplaceTeaserCard'

import './style.scss'

const Home: React.FC = () => {
    const { t } = useTranslation()
    const user = useUser()
    const { data: userMetaData } = useUserMetaDataQuery({
        variables: {
            userId: user.user?.id ?? '',
        },
        skip: !user.user,
    })

    return (
        <Page
            trackingTitle='home'
        >
            <Header
                buttonLeft='menu'
                title={t('title.home')}
            >
                <Button
                    id='home-search'
                    icon={search}
                    routerLink='/home/search'
                />
            </Header>
            <Content
                fullscreen
            >

                <OnboardingModal
                    title={t('home.onboarding.title')}
                    id='home-page'
                >
                    <SwiperSlide>
                        <div
                            className='ion-justify-content-center'
                        >
                            <img
                                alt='icon'
                                src='/assets/icon/favicon.png'
                                width={200}
                            />
                        </div>
                        <h1>
                            Join My Talent
                        </h1>
                        <div>
                            {t('home.onboarding.intro')}
                        </div>
                    </SwiperSlide>
                    {userMetaData?.userMetaData?.user?.data?.userType?.some(type => type === UserType.Player) && (
                        <SwiperSlide>
                            <div>
                                {t('home.onboarding.firstSteps.player')}
                            </div>
                        </SwiperSlide>
                    )}
                    {userMetaData?.userMetaData?.user?.data?.userType?.some(type => type === UserType.Trainer || type === UserType.Scout) && (
                        <SwiperSlide>
                            <div>
                                {t('home.onboarding.firstSteps.trainer')}
                            </div>
                        </SwiperSlide>
                    )}
                    {userMetaData?.userMetaData?.user?.data?.userType?.some(type => type === UserType.Trainer || type === UserType.Scout) && (
                        <SwiperSlide>
                            <div>
                                {t('home.onboarding.firstSteps.trainer_2')}
                            </div>
                        </SwiperSlide>
                    )}
                </OnboardingModal>

                <NextSteps />
                <NetworksTeaserSlider />
                <Divider />
                <FemaleRoleModelTeaserSlider />
                <MarketplaceTeaserCard />
                <Divider />
                <RoleModelTeaserSlider />
                <Divider />
                <PlaylistsTeaserSlider />
                <Divider />
                <RelatedUserTeaserSlider />
                <Divider />
                <FFFUserTeaserSlider />
                <Divider />
                <RoleModelTeaserCard
                    usePersonification
                />
            </Content>
        </Page>
    )
}

export default Home
