import { Capacitor } from '@capacitor/core'
import { Share } from '@capacitor/share'
import { useIonAlert } from '@ionic/react'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { DEEPLINK_JOIN_MY_SPACE, DEEPLINK_JOIN_NETWORK } from '../../components/DeeplinkRouter/deeplinkRoutes'
import { useUser } from '../../providers/Auth/hooks'
import useToast from '../../providers/Toast/hooks'
import { FE_URL } from '../apollo/config'
import type { Network } from '../apollo/types'
import { useCreateNetworkTokenInvitationMutation, useListMyPersonificationsQuery } from '../apollo/types'

type UseNetworkInvite = (
    network?: Network,
    personalSpace?: boolean,
    withTalent?: boolean,
) => () => Promise<void>

const useNetworkInvite: UseNetworkInvite = (network, personalSpace = false, withTalent = false) => {
    const user = useUser()
    const { t } = useTranslation()
    const [present] = useToast()
    const [show] = useIonAlert()
    const [createNetworkTokenInvitationMutation] = useCreateNetworkTokenInvitationMutation()

    const { data } = useListMyPersonificationsQuery({
        variables: {
            userId: user.user.id,
        },
        skip: !personalSpace,
    })

    return useCallback<() => Promise<void>>(async () => {
        if (!network) return

        const response = await createNetworkTokenInvitationMutation({
            variables: {
                networkId: network.id,
                userId: user.user.id,
                input: {
                    type: 'invitationEmail',
                    withObject: withTalent,
                    recipients: [{
                        email: 'aasd@aasd.de',
                    }],
                },
            },
        })
        const shareUrl = FE_URL +
            (personalSpace ? DEEPLINK_JOIN_MY_SPACE : DEEPLINK_JOIN_NETWORK) +
            response.data?.createNetworkTokenInvitation.tokens[0].token

        if (!Capacitor.isNativePlatform()) {
            show({
                message: shareUrl,
                buttons: [
                    {
                        text: t(`${personalSpace ? 'myspace' : 'networks'}.invite.linkCopy`),
                        handler: async () => {
                            await navigator.clipboard.writeText(shareUrl)
                            present(t(`${personalSpace ? 'myspace' : 'networks'}.invite.linkCopied`), 'success')
                        },
                        role: 'success',
                    },
                ],
            })
            return
        }

        Share.share({
            dialogTitle: t(`${personalSpace ? 'myspace' : 'networks'}.invite.dialogTitle`),
            title: t(`${personalSpace ? 'myspace' : 'networks'}.invite.title`),
            text: t(`${personalSpace ? 'myspace' : 'networks'}.invite.text`, { network: personalSpace ? data?.listMyPersonifications.collection[0]?.caption : network.caption }),
            url: shareUrl,
        })

    }, [network, personalSpace, data, show, present])
}

export default useNetworkInvite
