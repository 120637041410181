import { IonCard } from '@ionic/react'
import { close } from 'ionicons/icons'

import Dots from '../../../assets/img/dots.svg'
import Oval from '../../../assets/img/oval.svg'
import Stripes from '../../../assets/img/stripes.svg'
import type { Talent } from '../../../lib/apollo/types'
import Button from '../../Button'

import './style.scss'

interface ComparisonTalentProps {
    talent: Talent,
    color?: 'tertiary' | 'secondary'
    routerLink?: string
    showDeleteButton?: boolean
}

const ComparisonTalent: React.FC<ComparisonTalentProps> = ({ showDeleteButton = false, talent, color = 'secondary', routerLink = undefined }) => {

    return (
        <IonCard
            routerLink={routerLink}
            className={`comparison-talent comparison-talent--${color}`}
            color='secondary'
        >
            <img
                alt='Stripes'
                src={Stripes}
                className='comparison-talent__background'
            />

            <div
                className='comparison-talent__img-container'
            >
                <img
                    alt='Design Dots'
                    src={Dots}
                    className='comparison-talent__dots1'
                />
                <img
                    alt='Design Dots'
                    src={Dots}
                    className='comparison-talent__dots2'
                />
                <img
                    alt='Border'
                    src={Oval}
                    className='comparison-talent__border'
                />
                { talent.image ? (
                    <img
                        alt='Talent'
                        src={talent.image}
                        className='comparison-talent__img'
                    />
                )
                    : (
                        <div
                            className='comparison-talent__img'
                        />
                    )}
            </div>

            <h4
                className='ion-text-center ion-text-wrap'
            >
                {talent.caption}
            </h4>

            { showDeleteButton && (
                <Button
                    className='comparison-talent__delete-btn'
                    icon={close}
                    fill='clear'
                    size='small'
                />
            )}
        </IonCard>
    )
}

export default ComparisonTalent
