import { IonAlert, useIonRouter } from '@ionic/react'
import { mail } from 'ionicons/icons'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useContext } from 'use-context-selector'

import type { Network } from '../../../lib/apollo/types'
import { NetworkInvitationType, NetworksDocument, useInviteOrApplyToNetworkMutation } from '../../../lib/apollo/types'
import { useUser } from '../../../providers/Auth/hooks'
import useToast from '../../../providers/Toast/hooks'
import ContextMenuItem from '../../ContextMenu/ContextMenuItem/ContentMenuItem'
import { ContextMenuContext } from '../../ContextMenu/context'

interface NetworkDeleteContextMenuItemProps {
    network: Network
}

const NetworkApplyContextMenuItem: React.FC<NetworkDeleteContextMenuItemProps> = ({ network }) => {
    const { t } = useTranslation()
    const user = useUser()
    const [present] = useToast()
    const router = useIonRouter()
    const [applyToNetworkMutation] = useInviteOrApplyToNetworkMutation()
    const [isOpen, setIsOpen] = useState(false)

    const context = useContext(ContextMenuContext)

    const onContextMenuItemClick = useCallback(() => {
        setIsOpen(true)
    }, [setIsOpen])

    const onApplyToNetworkClick = async (message: string) => {
        if(!user.user?.id) {
            return
        }
        try {
            const response = await applyToNetworkMutation({
                variables: {
                    input: {
                        targetUserId: user.user.id,
                        type: NetworkInvitationType.Application,
                        networkId: network.id,
                        message,
                    },
                },
                refetchQueries: [
                    {
                        query: NetworksDocument,
                        variables: {
                            userId: user.user.id,
                            search: '&start=1&limit=15',
                        },
                    }],
            })
            if (!response.errors) {
                present(t('networks.joinComplete', { name: network.caption }), 'success')
                router.goBack()
            }
        } catch (e) {
            if (e.networkError?.statusCode === 409) {
                present(t('networks.alreadyRequestedToJoin'), 'warning')
            } else {
                present(t('errors.unknown'), 'danger')
            }
        }
        context.closeContextMenu()
    }
    return (
        <>
            <ContextMenuItem
                onClick={onContextMenuItemClick}
                label={t('networks.join')}
                icon={mail}
                disableCloseContextMenuOnClick
            />
            <IonAlert
                isOpen={isOpen}
                inputs={[
                    {
                        value: t('networks.apply.defaultMessage'),
                        name: 'message',
                        type: 'textarea',
                    },
                ]}
                buttons={[
                    {
                        text: t('buttons.cancel'),
                        role: 'cancel',
                        cssClass: 'secondary',
                        handler: () => {
                            context.closeContextMenu()
                        },
                    },
                    {
                        text: t('buttons.ok'),
                        handler: ({ message }) => {
                            onApplyToNetworkClick(message)
                        },
                    },
                ]}
            />
        </>
    )
}

export default NetworkApplyContextMenuItem
