const STORAGE_KEYS = {
    API: {
        LOGIN_TOKEN: 'api.login-token',
    },
    BIOMETRIC: {
        ENABLED: 'biometric.enabled',
    },
    LOGIN: {
        LAST_USERNAME: 'login.lastUsername',
    },
    TALENT: {
        PREFERRED_PERFORMANCE: 'talent.preferredPerformance',
    },
}

export default STORAGE_KEYS
