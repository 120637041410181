import { IonGrid, IonRow, IonText } from '@ionic/react'
import { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useRouteMatch } from 'react-router'
import { useContextSelector } from 'use-context-selector'

import { AppContext } from '../../../components/AppState'
import { AppActionType } from '../../../components/AppState/types'
import Content from '../../../components/Content'
import Header from '../../../components/Header'
import InfiniteScrollWithHook from '../../../components/InfiniteScrollWithHook'
import ItemList from '../../../components/ItemList'
import TalentCard from '../../../components/NewTalentCard'
import Page from '../../../components/Page'
import type {
    Talent } from '../../../lib/apollo/types'
import {
    TalentPosition,
    useJmtNetworksQuery,
    useSimilarNetworkLazyQuery,
    useTalentQuery,
} from '../../../lib/apollo/types'
import { useUser } from '../../../providers/Auth/hooks'

interface MarketplaceSearchSimilarParams {
    talentId: string
}

const MarketplaceSearchSimilar: React.FC = () => {
    const dispatch = useContextSelector(AppContext, state => state.dispatch)
    const { t } = useTranslation()
    const me = useUser()
    const { params: { talentId } } = useRouteMatch<MarketplaceSearchSimilarParams>()

    const { data: { talent } = {} } = useTalentQuery({
        variables: { userId: me.user.id, talentId },
    })

    const values = useMemo(() => {
        if (!talent) return null
        return talent.dataViews.find(({ key }) => key === 0)?.values
    }, [talent])

    const talentClass = useMemo(() => {
        if (!talent) return undefined
        return talent.metas.fieldPosition ===  TalentPosition.Goal ? '2' : '1'
    }, [talent])

    const networks = useJmtNetworksQuery()
    const marketplaceNetwork = useMemo(() =>
        networks?.data?.jmtNetworks.JMTnetworks.collection.find((network) => network.caption === 'Join.My.Talent.Engine.Marketplace')
    , [networks])

    useEffect(() => {
        if (!talent || !talentClass) return
        dispatch({
            type: AppActionType.MarketplaceSearchHistoryAdd,
            payload: {
                type: 'similar',
                data: talent.id,
            },
        })
    }, [talent])

    return (
        <Page
            trackingTitle='marketplace-search-similar'
        >
            <Header
                title={t('title.similar')}
            />
            <Content
                fullscreen
            >
                <IonGrid>
                    <IonRow>
                        <IonText
                            className='ion-margin-bottom n2br'
                            color='medium'
                        >
                            <span>
                                {t('marketplace.similar.notManyTalentsYet')}
                            </span>
                        </IonText>
                    </IonRow>
                </IonGrid>
                <ItemList>
                    <InfiniteScrollWithHook
                        skip={!marketplaceNetwork?.id || !values || !talentClass}
                        lazyHook={useSimilarNetworkLazyQuery}
                        variables={{
                            networkId: marketplaceNetwork?.id ?? '',
                            queryString: `&class=${talentClass}&values=${values?.map(n => Math.round(+n))}`,
                        }}
                    >
                        {(similarNetworkTalents: Talent[]) => similarNetworkTalents.map(tal => (
                            <TalentCard
                                type='listItem'
                                key={tal.id}
                                talent={tal}
                            />

                        ))}
                    </InfiniteScrollWithHook>
                </ItemList>
            </Content>
        </Page>
    )
}

export default MarketplaceSearchSimilar
