import clsx from 'clsx'
import { useState } from 'react'

import { useTalentCardContext } from '../_Core/Context'

import TalentCardMediumBack from './TalentCardMediumBack'
import TalentCardMediumFront from './TalentCardMediumFront'
import TalentCardPreviewOverlay from './TalentCardMediumPreviewOverlay'

import './style.scss'

const TalentCardMedium: React.FC = () => {
    const { preview } = useTalentCardContext()
    const [showBack, setShowBack] = useState(false)

    return (
        <div
            className={clsx('talentcard-medium')}
        >
            <TalentCardMediumFront
                onFlipCardClick={() => { setShowBack(() => true) }}
                visible={!showBack}
            />
            <TalentCardMediumBack
                onFlipCardClick={() => { setShowBack(() => false) }}
                visible={showBack}
            />

            { preview && ( <TalentCardPreviewOverlay /> )}
        </div>
    )
}

export default TalentCardMedium
