import { useMatomo } from '@datapunt/matomo-tracker-react'
import { IonCard, IonCol, IonGrid, IonIcon, IonLabel, IonRange, IonRow, IonText, useIonAlert } from '@ionic/react'
import { chevronDown, chevronUp, informationCircleOutline, reorderTwo } from 'ionicons/icons'
import { Fragment, useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import type { Talent , TalentDataView } from '../../../lib/apollo/types'
import {
    PerformanceVoteDocument,
    useSavePerformanceVoteMutation,
} from '../../../lib/apollo/types'
import getAge from '../../../lib/getAge'
import { MatomoEventCategory } from '../../../lib/tracking/eventCategories'
import Button from '../../Button'
import Buttons from '../../Buttons'
import type { LayerI } from '../TalentID/Layer'
import useLayers from '../TalentID/useLayers'
import { LABEL_TO_VALUE_FACTOR, VALUE_TO_LABEL_ARRAY } from '../TalentVoteForm'

import './style.scss'

const getColor = (val: number | null = 0) => {
    if (val === null) {
        return 'light'
    }
    return val > 0 ? 'success' : val < 0 ? 'danger' : 'tertiary'
}

type TalentPerformanceVoteProps = {
    talent: Talent
    onVote: () => any
    networkId: string
    aggregation?: TalentDataView
}
const TalentPerformanceVoteForm: React.FC<TalentPerformanceVoteProps> = ({ talent, onVote, networkId, aggregation }) => {
    const { t, i18n } = useTranslation()
    const getTranslation = useCallback((key: string) => {
        const under16 = (getAge(talent.metas.date) ?? 99) < 16
        if(under16 && i18n.exists(`${key}_u16`)) {
            return t(`${key}_u16`)
        }

        return t(key)
    }, [i18n])
    const { trackEvent } = useMatomo()
    const [present] = useIonAlert()
    const layers = useLayers(talent.metas.fieldPosition, talent.dataViews, undefined, (getAge(talent.metas.date) ?? 99) < 16)

    const layer: LayerI | undefined = useMemo(() => {
        return layers.find(l => l.values.length === 8)
    }, [layers])

    const [performance, setPerformance] = useState<(number)[]>([0,0,0,0,0,0,0,0])

    const voteDisabled = useMemo(() => {
        return performance.filter(p => p === null).length > 0
    }, [performance])

    const [vote, { loading }] = useSavePerformanceVoteMutation({
        refetchQueries: [{
            query: PerformanceVoteDocument,
            variables: {
                talentId: talent.id,
                network: `&network=${networkId}`,
            },
        }],
    })

    const submitVote = useCallback(async () => {
        if (voteDisabled) {
            return
        }
        await vote({
            variables: {
                input: {
                    vote: performance,
                    talentId: talent.id,
                    networkId,
                },
            },
        })
        trackEvent({
            action: 'save-performance-vote',
            category: MatomoEventCategory.Talent,
        })
        onVote()
    }, [performance, vote, onVote, voteDisabled])

    const getPinLabel = useCallback(value => VALUE_TO_LABEL_ARRAY[+value.toFixed(0)], [])

    if (!layer || !aggregation) return null

    return (
        <>
            <IonCard
                color='dark'
                className='talent-performance-vote__card'
            >
                <IonGrid
                    className='talent-performance-vote__form'
                >
                    {layer.values.map((v, i) => (
                        <Fragment
                            // eslint-disable-next-line react/no-array-index-key
                            key={i}
                        >
                            <IonRow>
                                <IonCol
                                    size='6'
                                    className='ion-align-items-end'
                                >
                                    <IonLabel
                                        onClick={() => present(getTranslation( `${layer.translationKeys[i]}_tip`))}
                                    >
                                        {layer.translations[i]}
                                        <IonIcon
                                            icon={informationCircleOutline}
                                        />
                                    </IonLabel>
                                </IonCol>
                                <IonCol
                                    size='6'
                                >
                                    <IonRow>
                                        <IonCol
                                            className='ion-text-center'
                                            size='4'
                                        >
                                            <Button
                                                size='small'
                                                color='danger'
                                                fill={performance[i] === -1 ? 'solid' : 'outline'}
                                                icon={chevronDown}
                                                onClick={() => setPerformance(values => {
                                                    const newState = [...values]
                                                    newState[i] = -1
                                                    return newState
                                                })}
                                            />
                                        </IonCol>
                                        <IonCol
                                            className='ion-text-center'
                                            size='4'
                                        >
                                            <Button
                                                size='small'
                                                color='tertiary'
                                                fill={performance[i] === 0 ? 'solid' : 'outline'}
                                                icon={reorderTwo}
                                                onClick={() => setPerformance(values => {
                                                    const newState = [...values]
                                                    newState[i] = 0
                                                    return newState
                                                })}
                                            />
                                        </IonCol>
                                        <IonCol
                                            className='ion-text-center'
                                            size='4'
                                        >
                                            <Button
                                                size='small'
                                                color='success'
                                                fill={performance[i] === 1 ? 'solid' : 'outline'}
                                                icon={chevronUp}
                                                onClick={() => setPerformance(values => {
                                                    const newState = [...values]
                                                    newState[i] = 1
                                                    return newState
                                                })}
                                            />
                                        </IonCol>
                                    </IonRow>
                                </IonCol>
                            </IonRow>
                            <IonRow
                                className='talent-performance-vote__slider-row ion-align-items-center ion-justify-content-between'
                            >
                                <IonRange
                                    mode='ios'
                                    min={0}
                                    max={100}
                                    value={aggregation.values[i]}
                                    color={getColor(performance[i])}
                                    disabled
                                />
                                <IonText>
                                    <h3
                                        className='talent-performance-vote__value'
                                    >
                                        {getPinLabel(aggregation.values[i] / LABEL_TO_VALUE_FACTOR)}
                                    </h3>
                                </IonText>
                            </IonRow>
                            <div />
                        </Fragment>
                    ))}
                </IonGrid>
            </IonCard>
            <Buttons>
                <Button
                    loading={loading}
                    color='secondary'
                    disabled={voteDisabled}
                    onClick={submitVote}
                >
                    {t('talent.performanceVoteSubmit')}
                </Button>
            </Buttons>
        </>
    )
}

export default TalentPerformanceVoteForm
