import type { IonButton } from '@ionic/react'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
    useCreateNetworkTokenInvitationMutation,
    useInviteNetworkWithTalentMutation,
    useNetworkQuery,
} from '../../../lib/apollo/types'
import { useUser } from '../../../providers/Auth/hooks'
import type { TalentApplication } from '../../Activity/InviteTalentActivity'
import Buttons from '../../Buttons'
import { DEEPLINK_JOIN_NETWORK } from '../../DeeplinkRouter/deeplinkRoutes'
import ItemList from '../../ItemList'
import Modal from '../../Modal'
import TalentCard from '../../NewTalentCard'
import Button from '../index'

import './style.scss'

const AnswerTalentApplicationButton: React.FC<{ application: TalentApplication, userId: number } & React.ComponentProps<typeof IonButton>> = ({
    application,
    userId,
    ...buttonProps
}) => {
    const { t } = useTranslation()
    const user = useUser()
    const [isOpen, setIsOpen] = useState(false)

    const [createNetworkTokenInvitationMutation] = useCreateNetworkTokenInvitationMutation()
    const [inviteNetworkWithTalentMutation] = useInviteNetworkWithTalentMutation()

    const { data: network } = useNetworkQuery({
        variables: {
            userId: user.user.id,
            networkId: `${application.networkId}`,
        },
        skip: !application.networkId,
    })

    const acceptInvitation = useCallback(async () => {
        const response = await createNetworkTokenInvitationMutation({
            variables: {
                networkId: `${application.networkId}`,
                userId: user.user.id,
                input: {
                    type: 'invitationEmail',
                    withObject: true,
                    recipients: [{
                        email: 'aasd@aasd.de',
                    }],
                },
            },
        })
        const shareUrl = DEEPLINK_JOIN_NETWORK +
        response.data?.createNetworkTokenInvitation.tokens[0].token

        await inviteNetworkWithTalentMutation({
            variables: {
                input: {
                    networkId: application.networkId,
                    link: shareUrl,
                    targetUserId: userId,
                },
            },
        })

        setIsOpen(false)
    }, [application])

    const declineInvitation = useCallback(async () => {

        setIsOpen(false)
    }, [application])

    return (
        <>
            <Button
                {...buttonProps}
                onClick={() => setIsOpen(true)}
            >
                {t('buttons.answer')}
            </Button>
            <Modal
                size='fullscreen'
                isOpen={isOpen}
                modalTitle={t('title.invitation')}
                onClose={() => setIsOpen(false)}
            >
                {!!application.talent && (
                    <ItemList>
                        <div
                            className='application-answer__talent-card'
                        >
                            <TalentCard
                                talent={application.talent}
                                type='small'
                                shared
                            />
                        </div>

                        <div
                            className='application-answer__content'
                        >
                            <Buttons
                                subtitle={t('networkTalentApplication.description', { network: network?.network?.caption, talent: application.talent?.caption })}
                            >
                                <Button
                                    color='secondary'
                                    onClick={acceptInvitation}
                                >
                                    {t('buttons.sendInvitation')}
                                </Button>
                                <Button
                                    onClick={declineInvitation}
                                >
                                    {t('buttons.decline')}
                                </Button>
                            </Buttons>
                        </div>
                    </ItemList>
                )}
            </Modal>
        </>
    )
}

export default AnswerTalentApplicationButton
