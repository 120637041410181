import { arrowBack, football } from 'ionicons/icons'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import type { Talent } from '../../lib/apollo/types'
import { PermissionLevel } from '../../lib/apollo/types'
import useTabContext from '../../lib/hooks/useTabContext'
import useWindowResizeListener from '../../lib/hooks/useWindowResizeListener'
import type { TalentCardDetailPageMode } from '../../pages/Talent'
import Button from '../Button'
import Buttons from '../Buttons'
import GetYourSkillsConfirmedButton from '../GetYourSkillsConfirmedButton'
import ShareTalentButton from '../ShareTalentButton'
import TalentPerformanceVoteButton from '../Talent/TalentPerformanceVoteButton'
import TalentVoteButton from '../Talent/TalentVoteButton'
import TopSkills from '../Talent/TopSkills'
import ChoseTopSkillsButton from '../Talent/TopSkills/ChoseTopSkills/ChoseTopSkillsButton'

import './style.scss'

interface TalentActionButtonsProps {
    talent?: Talent
    mode?: TalentCardDetailPageMode
    permissionLevel?: PermissionLevel
    networkId?: string
}

const TalentActionButtons: React.FC<TalentActionButtonsProps> = ({
    talent,
    networkId,
    permissionLevel= talent?.permissionLevel ?? PermissionLevel.None
    , mode= 'default',
}) => {
    const tab = useTabContext()
    const { isDesktop } = useWindowResizeListener()
    const { t } = useTranslation()

    const buttonsTitle = useMemo(() => {
        if (permissionLevel === PermissionLevel.Personal || permissionLevel === PermissionLevel.Administrative) {
            return talent?.personification
                ? 'buttons.getYourSkillsConfirmed'
                : 'buttons.shareTalent'
        }
        return 'buttons.talentVote'
    }, [permissionLevel, talent?.personification])

    if (!talent) return null

    return (
        <div
            className='talent-action-buttons'
        >
            {(mode === 'details' && isDesktop) ? (
                <div>
                    <TopSkills
                        asCard
                        talent={talent}
                        withHeadline
                    />
                </div>
            ) : (
                // layout purpose
                <div />
            )}
            <Buttons
                title={t(`${buttonsTitle}Title`)}
                subtitle={t(`${buttonsTitle}Subtitle`, { name: talent.caption })}
            >
                {/* dynamic content can not appear as first child here... Ionic b******t */}
                {(permissionLevel === PermissionLevel.Personal || permissionLevel === PermissionLevel.Administrative) && talent.personification && (
                    <>
                        <GetYourSkillsConfirmedButton />
                        <ShareTalentButton
                            talent={talent}
                        />
                    </>
                )}
                {(mode === 'details' && isDesktop && permissionLevel !== PermissionLevel.None) && (
                    <ChoseTopSkillsButton
                        color='primary'
                        talent={talent}
                    />
                )}
                <TalentVoteButton
                    fill='solid'
                    talent={talent}
                    networkId={networkId}
                />
                {networkId && (
                    <TalentPerformanceVoteButton
                        fill='solid'
                        talent={talent}
                        networkId={networkId}
                    />
                )}
                {mode === 'default' && (
                    <Button
                        fill='solid'
                        routerLink={`/${tab}/network/${networkId}/talents/${talent.id}/details`}
                        icon={mode === 'default' ? football : arrowBack}
                    >
                        {t('talent.details')}
                    </Button>
                )}
            </Buttons>
        </div>
    )
}

export default TalentActionButtons
