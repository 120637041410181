import {
    IonAccordion,
    IonIcon,
    IonItem,
    IonLabel,
    IonSelect,
    IonSelectOption,
    IonText,
} from '@ionic/react'
import { informationCircleOutline } from 'ionicons/icons'
import { useTranslation } from 'react-i18next'

const LanguageSettings: React.FC = () => {
    const { t, i18n } = useTranslation()

    return (
        <IonAccordion
            value='language'
        >
            <IonItem
                slot='header'
            >
                <IonLabel>{t('settings.language.title')}</IonLabel>
            </IonItem>
            <IonItem
                slot='content'
                lines='none'
            >
                <IonIcon
                    icon={informationCircleOutline}
                    slot='start'
                />
                <IonLabel
                    className='ion-text-wrap'
                >
                    <IonText
                        color='medium'
                    >
                        {t('settings.language.description')}
                    </IonText>
                </IonLabel>
            </IonItem>
            <IonItem
                lines='none'
                slot='content'
            >
                <IonSelect
                    onIonChange={e => {
                        i18n.changeLanguage(e.detail.value)
                    }}
                    value={i18n.language}
                >
                    <IonSelectOption
                        value='de-DE'

                    >
                        {t('settings.language.languages.german')}
                    </IonSelectOption>
                    <IonSelectOption
                        value='en-GB'
                    >
                        {t('settings.language.languages.english')}
                    </IonSelectOption>
                    <IonSelectOption
                        value='cn'
                    >
                        {t('settings.language.languages.chinese')}
                    </IonSelectOption>
                </IonSelect>
            </IonItem>
        </IonAccordion>
    )
}

export default LanguageSettings
