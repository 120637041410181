import { useMatomo } from '@datapunt/matomo-tracker-react'
import { IonCard } from '@ionic/react'
import clsx from 'clsx'
import { backspace, barChart, pin } from 'ionicons/icons'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { SwiperSlide } from 'swiper/react/swiper-react'

import {
    MyTalentLayerDocument,
    TalentDocument,
    TalentLatestVoteDocument, useMyTalentLayerQuery,
    useTalentLatestVoteQuery,
    useUpdateVoteMutation,
    useVoteMutation,
} from '../../../lib/apollo/types'
import type { Talent } from '../../../lib/apollo/types'
import getAge from '../../../lib/getAge'
import { MatomoEventCategory } from '../../../lib/tracking/eventCategories'
import { useUser } from '../../../providers/Auth/hooks'
import useToast from '../../../providers/Toast/hooks'
import Button from '../../Button'
import Buttons from '../../Buttons'
import Form from '../../Form'
import FormSlider from '../../Form/FormSlider'
import OnboardingModal from '../../OnboardingWizard/OnboardingModal'
import Segment from '../../Segment'
import TalentID from '../TalentID'
import TopSkills from '../TopSkills'

import './style.scss'

interface TalentVoteFormProps {
    talent: Talent
    onVote?: () => void
    networkId?: string
}

const VOTE_UPDATE_TIME = 60 * 60 * 24 * 1000
export const LABEL_TO_VALUE_FACTOR = 100 / 15
export const VALUE_TO_LABEL_ARRAY = ['6', '5-', '5', '5+', '4-', '4', '4+', '3-', '3', '3+', '2-', '2', '2+', '1-', '1', '1+']

const convertToSplittedArray = (arr: number[], valuesPerSector: number): number[][] => arr.reduce((acc, n, i) => {
    if (i !== 0 && i % valuesPerSector === 0) {
        acc.push([])
    }
    (acc[acc.length - 1] as number[]).push(n as number)
    return acc
}, [[]]) ?? [[]]

const TalentVoteForm: React.FC<TalentVoteFormProps> = ({ talent, onVote, networkId }) => {
    const user = useUser()
    const { trackEvent } = useMatomo()
    const [showAggregation, setShowAggregation] = useState(false)

    const { data: myTalentLayerData, loading: aggregationLoading } = useMyTalentLayerQuery({
        skip: !showAggregation,
        variables: {
            userId: user.user.id,
            talentId: talent.id,
            layerParams: '',
        },
    })
    const aggregation = useMemo(() => myTalentLayerData?.talentLayer?.[0].dataViews?.[0].values ?? [], [myTalentLayerData])

    const [vote, { loading: voteLoading }] = useVoteMutation({
        update: ((cache, { data }) => {
            if (!data?.vote) {
                return
            }
            cache.writeQuery({
                query: TalentLatestVoteDocument,
                data: {
                    talentLatestVote: data.vote,
                },
                variables: { userId: user.user.id, talentId: talent.id },
            })
        }),
        refetchQueries: [
            {
                query: TalentDocument,
                variables: { userId: user.user.id, talentId: talent.id },
            }, {
                query: MyTalentLayerDocument,
                variables: {
                    userId: user.user.id,
                    talentId: talent?.id ?? '',
                    layerParams: '&layers=self',
                },
            }],
    })

    const [updateVote, { loading: updateVoteLoading }] = useUpdateVoteMutation({
        update: ((cache, { data }) => {
            if (!data?.updateVote) {
                return
            }
            cache.writeQuery({
                query: TalentLatestVoteDocument,
                data: {
                    talentLatestVote: data.updateVote,
                },
                variables: { userId: user.user.id, talentId: talent.id },
            })
        }),
        refetchQueries: [
            {
                query: TalentDocument,
                variables: { userId: user.user.id, talentId: talent.id },
            },{
                query: MyTalentLayerDocument,
                variables: {
                    userId: user.user.id,
                    talentId: talent?.id ?? '',
                    layerParams: '&layers=self',
                },
            }],
    })

    const { t, i18n } = useTranslation()
    const [present] = useToast()

    const { data: tLVData, loading: tLVDataLoading } = useTalentLatestVoteQuery({ variables: { userId: user.user.id, talentId: talent.id } })
    const { talentLatestVote } = tLVData ?? {}

    const valuesPerSector = useMemo(() => ((talent.dataViews[0]?.values?.length ?? 1) / 4), [talent.dataViews[0]?.values?.length])

    const [showPreview, setShowPreview] = useState(false)

    const [activeSegment, setActiveSegment] = useState('0')
    const [splittedRating, setSplittedRating] = useState<number[][]>([])
    useEffect(() => {
        if (tLVDataLoading) return

        setSplittedRating(() => convertToSplittedArray(
            talentLatestVote?.values ?? (new Array(talent.dataViews[0]?.values?.length)).fill(0),
            valuesPerSector,
        ))
    }, [tLVDataLoading])

    const flatRating = splittedRating.flat(1).map(v => +v.toFixed(0))
    const zeroCount = flatRating.reduce((acc, n) => n === 0 ? acc + 1 : acc, 0)

    const getPinLabel = useCallback(value => VALUE_TO_LABEL_ARRAY[+value.toFixed(0)], [])

    const doUpdateVote = useCallback(async (values) => {
        if (!talentLatestVote?.id) {
            return
        }

        try {
            await updateVote({
                variables: {
                    voteId: talentLatestVote.id,
                    input: {
                        values,
                        networkId,
                    },
                },
                refetchQueries: aggregation?.length > 0 ? [{
                    query: MyTalentLayerDocument,
                    variables: {
                        userId: user.user.id,
                        talentId: talent.id,
                        layerParams: '',
                    },
                }] : undefined,
            })

            present(t('talent.voteUpdated', { name: talent.caption }), 'success')
            onVote?.()
        } catch (_e) {
            // console.log(_e)
        }
    }, [talentLatestVote?.id, networkId, talent, user])

    const doVote = useCallback(async (values) => {
        if (talentLatestVote?.id && +new Date() - (talentLatestVote.date * 1000) < VOTE_UPDATE_TIME) {
            doUpdateVote(values)
            return
        }

        try {
            await vote({
                variables: {
                    talentId: talent.id,
                    input: {
                        values,
                        networkId,
                    },
                },
                refetchQueries: aggregation?.length > 0 ? [{
                    query: MyTalentLayerDocument,
                    variables: {
                        userId: user.user.id,
                        talentId: talent.id,
                        layerParams: '',
                    },
                }] : undefined,
            })

            present(t('talent.voteComplete', { name: talent.caption }), 'success')
            onVote?.()

        } catch (e) {
            if (e.networkError.statusCode === 403 && talentLatestVote?.id) {
                doUpdateVote(values)
            }
        }
    }, [zeroCount, vote, present, doUpdateVote, talentLatestVote?.id, networkId])

    const onSubmit = () => {
        if (zeroCount > 1) {
            present(t('talent.voteZeroCount'), 'warning')
            return
        }

        trackEvent({
            action: 'save-vote',
            category: MatomoEventCategory.Talent,
        })

        doVote(flatRating)
    }

    const getTranslation = useCallback((key: string) => {
        if((getAge(talent.metas.date) ?? 99) < 16 && i18n.exists(`${key}_u16`)) {
            return t(`${key}_u16`)
        }

        return t(key)
    }, [talent, i18n])

    if (splittedRating.length === 0) return null

    return (
        <Form
            className={`talent-vote-form talent-vote-form--active-${activeSegment}`}
            onSubmit={onSubmit}
        >
            <OnboardingModal
                title={t('vote.onboarding.title')}
                id='vote-form'
            >
                <SwiperSlide>
                    <h1>
                        {t('vote.title')}
                    </h1>
                    <div>
                        {t('vote.onboarding.1')}
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div>
                        {t('vote.onboarding.2')}
                    </div>
                </SwiperSlide>
            </OnboardingModal>
            <div
                className='talent-vote-form__segment'
            >
                <Segment
                    value={activeSegment}
                    onIonChange={(e) => {
                        setActiveSegment(() => e.detail.value as string)
                    }}
                    tabs={[0, 1, 2, 3].map(i => ({ tab: t(`i18n.group.${i}`) }))}
                />
            </div>
            <div
                className='talent-vote-form__card'
            >
                <div
                    className='talent-vote-form__overflow'
                >
                    <div
                        className='talent-vote-form__sectors'
                    >
                        {splittedRating?.map((sector, i) => (
                            <div
                                // eslint-disable-next-line react/no-array-index-key
                                key={i}
                                className='talent-vote-form__sector'
                            >
                                {sector.map((value, j) => {
                                    const combinedIndex = (i * valuesPerSector) + j
                                    const marker = aggregation[combinedIndex]

                                    return (
                                        <div
                                            key={combinedIndex}
                                            className='talent-vote-form__slider-wrapper'
                                        >
                                            <FormSlider
                                                // eslint-disable-next-line react/no-array-index-key
                                                key={`${(talentLatestVote?.id ?? 0)} ${combinedIndex}`}
                                                onChange={(e) => {
                                                    setSplittedRating((prev) => {
                                                        const next = [...prev]
                                                        next[i][j] = +e.detail.value
                                                        return next
                                                    })
                                                }}
                                                defaultValue={value}
                                                label={getTranslation(`i18n.class.${talent.metas.fieldPosition === 'goal' ? 'goalie' : 'player'}.scalar_${combinedIndex}`)}
                                                info={getTranslation(`i18n.class.${talent.metas.fieldPosition === 'goal' ? 'goalie' : 'player'}.scalar_${combinedIndex}_tip`)}
                                                name={`value${combinedIndex}`}
                                                step={1}
                                                min={0}
                                                max={100}
                                                required
                                                referenceValue={showAggregation ? (marker || 0) : undefined}
                                            />
                                            <h3
                                                className='talent-vote-form__value'
                                            >
                                                {getPinLabel(value / LABEL_TO_VALUE_FACTOR)}
                                            </h3>
                                        </div>
                                    )
                                })}
                            </div>
                        ))}
                    </div>
                    <div
                        className='talent-vote-form__talent-id-wrapper'
                    >
                        <TalentID
                            dataView1={[{ key: 0, values: flatRating }]}
                            talentPosition={talent.metas.fieldPosition}
                            under16={(getAge(talent.metas.date) ?? 99) < 16}
                        />
                    </div>

                </div>
            </div>
            <Buttons>
                <Button
                    onClick={() => {
                        setShowPreview(() => true)
                    }}
                    className='talent-vote-form__preview-btn'
                    fill='solid'
                    color='secondary'
                    icon={barChart}
                >
                    {t('talent.votePreview')}
                </Button>
                <Button
                    loading={aggregationLoading}
                    onClick={() => {
                        setShowAggregation((sa) => !sa)
                    }}
                    className='talent-vote-form__preview-btn'
                    fill='solid'
                    icon={pin}
                >
                    {t(showAggregation ? 'talent.hideAggregation' : 'talent.showAggregation')}
                </Button>
            </Buttons>

            <div
                className={clsx('talent-vote-form__preview', { 'talent-vote-form__preview--active': showPreview })}
            >
                <div
                    className='talent-vote-form__preview__talent-id-wrapper'
                >
                    <TalentID
                        dataView1={[{ key: 0, values: flatRating }]}
                        dataView2={talentLatestVote?.values ? [{ key: 0, values: talentLatestVote?.values }] : undefined}
                        talentPosition={talent.metas.fieldPosition}
                        under16={(getAge(talent.metas.date) ?? 99) < 16}
                    />
                </div>
                <IonCard
                    className='talent-vote-form__top-skills-wrapper'
                    color='primary'
                >
                    <TopSkills
                        newTitle={t('talent.newTopSkills')}
                        talent={talent}
                        values={flatRating}
                    />
                </IonCard>
                <div
                    className='talent-vote-form__submit-wrapper'
                >
                    <Button
                        fill='solid'
                        icon={backspace}
                        onClick={() => {
                            setShowPreview(() => false)
                        }}
                    >
                        {t('buttons.cancel')}
                    </Button>
                    <Button
                        loading={updateVoteLoading || voteLoading}
                        type='submit'
                        fill='solid'
                        color='secondary'
                        icon={barChart}
                    >
                        {t('talent.vote')}
                    </Button>
                </div>
            </div>
        </Form>
    )
}

export default TalentVoteForm
