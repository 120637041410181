import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import { useRouteMatch } from 'react-router'

import Content from '../../../components/Content'
import Header from '../../../components/Header'
import ItemList from '../../../components/ItemList'
import TalentCard from '../../../components/NewTalentCard'
import Page from '../../../components/Page'
import RoleModel from '../../../components/RoleModel'
import TalentActionButtons from '../../../components/TalentActionButtons'
import { useSharedTalentQuery } from '../../../lib/apollo/types'

const SharedTalent: React.FC = () => {
    const { params: { hash } } = useRouteMatch<{ hash: string }>()
    const { t } = useTranslation()

    const { data } = useSharedTalentQuery({
        variables: {
            hash,
        },
        skip: !hash,
    })

    return (
        <Page
            trackingTitle={data?.sharedTalent ? `shared-talent-${data?.sharedTalent.talent.id}` : undefined}
            title={data?.sharedTalent.talent.caption}
        >
            <Header
                title={data?.sharedTalent.talent.caption ?? ''}
                buttonLeft='back'
            />
            <Content
                fullscreen
                className='shared-talent'
            >
                {data?.sharedTalent.talent.id && (
                    <>
                        <Helmet>
                            <title>{`Join My Talent - ${data?.sharedTalent.talent.caption}`}</title>
                            <meta
                                property='og:title'
                                content={`Join My Talent - ${data?.sharedTalent.talent.caption}`}
                            />
                            <meta
                                property='og:description'
                                content={t(`og.${data?.sharedTalent.sharedRoleModel ? 'talentWithRoleModel' : 'talent'}.description`, {
                                    name: data?.sharedTalent.talent.caption,
                                    roleModel: data?.sharedTalent.roleModel?.caption,
                                })}
                            />
                            <meta
                                property='og:image'
                                content={data?.sharedTalent.talent.image ?? 'https://app.joinmytalent.com/assets/icon/favicon.png'}
                            />
                        </Helmet>
                        <>
                            <ItemList>
                                <div
                                    id='shared-talent-card'
                                    className='display-flex ion-justify-content-center'
                                >
                                    <TalentCard
                                        talent={data?.sharedTalent.talent}
                                        shared
                                    />
                                </div>
                                <TalentActionButtons
                                    mode='details'
                                    talent={data?.sharedTalent.talent}
                                />
                            </ItemList>

                            <RoleModel
                                talent={data?.sharedTalent.talent}
                                roleModel={data?.sharedTalent.roleModel ?? undefined}
                            />
                        </>
                    </>
                )}
            </Content>
        </Page>
    )
}

export default SharedTalent
