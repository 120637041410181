import {
    IonAccordion,
    IonIcon,
    IonItem,
    IonLabel,
    IonText,
} from '@ionic/react'
import { informationCircleOutline, pencil } from 'ionicons/icons'
import { useTranslation } from 'react-i18next'

import Button from '../../../components/Button'
import Buttons from '../../../components/Buttons'
import Form from '../../../components/Form'
import FormTextField from '../../../components/Form/FormTextField'
import ItemList from '../../../components/ItemList'
import { useUpdatePasswordMutation } from '../../../lib/apollo/types'
import { useUser } from '../../../providers/Auth/hooks'
import useToast from '../../../providers/Toast/hooks'

import './style.scss'

const PasswordSettings: React.FC = () => {
    const { t } = useTranslation()
    const [present] = useToast()
    const me = useUser()
    const [updatePassword, { loading }] = useUpdatePasswordMutation()

    const onSubmit = async (values: any, form: any) => {
        if (!values.password || !values.oldPassword || values.password.length < 5 || values.oldPassword.length < 5) {
            present(t('settings.password.minLength'), 'danger')
            return
        }

        if (values.password !== values.password2) {
            present(t('settings.password.differentPasswords'), 'danger')
        } else {
            try {
                await updatePassword({
                    variables: {
                        userId: me.user.id,
                        input: {
                            password: values.password,
                            oldPassword: values.oldPassword,
                        },
                    },
                })

                form.reset()
                present(t('settings.password.changed'), 'success')
            } catch (e) {
                if (e.networkError.statusCode === 400) {
                    Object.entries(e.networkError.result.issues).forEach(([key, value]: any) => {
                        present(`${t(`settings.password.${key}`)}: ${value}`, 'danger')
                    })
                }
            }
        }
    }

    return (
        <IonAccordion
            value='password'
            className='password-settings'
        >
            <IonItem
                slot='header'
            >
                <IonLabel>{t('settings.password.change')}</IonLabel>
            </IonItem>
            <IonItem
                lines='none'
                slot='content'
            >
                <IonIcon
                    icon={informationCircleOutline}
                    slot='start'
                />
                <IonLabel
                    className='ion-text-wrap'
                >
                    <IonText
                        color='medium'
                    >
                        {t('settings.password.description')}
                    </IonText>
                </IonLabel>
            </IonItem>
            <IonItem
                lines='none'
                slot='content'
            >
                <Form
                    onSubmit={onSubmit}
                    className='password-settings__form'
                >
                    <ItemList
                        className='password-settings__input-wrapper'
                    >
                        <FormTextField
                            type='password'
                            name='oldPassword'
                            label={t('settings.password.oldPassword')}
                        />
                        <FormTextField
                            type='password'
                            name='password'
                            label={t('settings.password.password')}
                        />
                        <FormTextField
                            type='password'
                            name='password2'
                            label={t('settings.password.password2')}
                        />
                    </ItemList>
                    <Buttons>
                        <Button
                            loading={loading}
                            fill='solid'
                            color='secondary'
                            type='submit'
                            icon={pencil}
                            size='default'
                            expand='block'
                        >
                            {t('settings.password.changeSubmit')}
                        </Button>
                    </Buttons>
                </Form>
            </IonItem>
        </IonAccordion>
    )
}

export default PasswordSettings
