import { useTranslation } from 'react-i18next'
import { SwiperSlide } from 'swiper/react/swiper-react'

import Content from '../../components/Content'
import Header from '../../components/Header'
import InfiniteScrollWithHook from '../../components/InfiniteScrollWithHook'
import ItemList from '../../components/ItemList'
import OnboardingModal from '../../components/OnboardingWizard/OnboardingModal'
import Page from '../../components/Page'
import PlaylistListItem from '../../components/Playlist/PlaylistListItem'
import type { Playlist } from '../../lib/apollo/types'
import { usePlaylistsLazyQuery } from '../../lib/apollo/types'

import PlaylistsContextMenu from './PlaylistsContextMenu'

const Playlists: React.FC = () => {
    const { t } = useTranslation()

    return (
        <Page
            trackingTitle='playlists'
        >
            <Header
                buttonLeft='menu'
                title={t('title.playlists')}
            >
                <PlaylistsContextMenu />
            </Header>
            <Content
                fullscreen
            >
                <OnboardingModal
                    title={t('playlists.onboarding.title')}
                    id='playlists-page'
                >
                    <SwiperSlide>
                        <h1>
                            {t('playlists.title')}
                        </h1>
                        <div>
                            {t('playlists.onboarding.1')}
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div>
                            {t('playlists.onboarding.2')}
                        </div>
                    </SwiperSlide>
                </OnboardingModal>
                <InfiniteScrollWithHook
                    lazyHook={usePlaylistsLazyQuery}
                >
                    {(playlists: Playlist[]) => (
                        <ItemList>
                            {playlists.map(playlist => (
                                <PlaylistListItem
                                    key={playlist.id}
                                    playlist={playlist}
                                />
                            ))}
                        </ItemList>
                    )}
                </InfiniteScrollWithHook>

            </Content>
        </Page>
    )
}

export default Playlists
