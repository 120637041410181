import { helpCircle } from 'ionicons/icons'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { useUpdateUserMetaDataMutation, useUserMetaDataQuery } from '../../../lib/apollo/types'
import { useUser } from '../../../providers/Auth/hooks'
import ContextMenuItem from '../../ContextMenu/ContextMenuItem/ContentMenuItem'

interface ShowOnboardingContextMenuItemProps {
    ids: string[]
}

const ShowOnboardingContextMenuItem: React.FC<ShowOnboardingContextMenuItemProps> = ({ ids }) => {
    const { t } = useTranslation()
    const user = useUser()
    const [updateUserMetaDataMutation] = useUpdateUserMetaDataMutation()
    const metaData = useUserMetaDataQuery({
        variables: {
            userId: user?.user?.id ?? '',
        },
        skip: !user?.user?.id,
    })
    const onClick = useCallback(async () => {
        if (!metaData.data?.userMetaData?.user?.data?.behavior) {
            return
        }

        const onboardingDone = (metaData.data.userMetaData.user.data.behavior.onboardingDone ?? []).filter(id => !ids.some(i => id === i))

        updateUserMetaDataMutation({
            variables: {
                input: {
                    ...metaData.data.userMetaData.user.data,
                    behavior: {
                        ...metaData.data.userMetaData.user.data.behavior,
                        onboardingDone,
                    },
                },
            },
        })
    }, [ids, metaData])

    return (
        <ContextMenuItem
            icon={helpCircle}
            label={t('onboarding.showHelp')}
            onClick={onClick}
        />
    )
}

export default ShowOnboardingContextMenuItem
