import { useMatomo } from '@datapunt/matomo-tracker-react'
import type { Color } from '@ionic/core'
import { barChart } from 'ionicons/icons'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import type { Talent } from '../../../lib/apollo/types'
import usePlayerTypes from '../../../lib/hooks/usePlayerTypes'
import { MatomoEventCategory } from '../../../lib/tracking/eventCategories'
import isTalentOwnPersonification from '../../../lib/utils/isTalentOwnPersonification'
import LoginButton from '../../Button/LoginButton'
import Modal from '../../Modal'
import TalentVoteForm from '../TalentVoteForm'

interface TalentVoteButtonProps {
    talent?: Talent
    networkId?: string
    color?: Color
    fill?: 'solid' | 'outline' | 'clear'
}

const TalentVoteButton: React.FC<TalentVoteButtonProps> = ({ fill, talent, networkId, color = 'secondary' }) => {
    const { t } = useTranslation()
    const [showModal, setShowModal] = useState(false)
    const { trackEvent } = useMatomo()
    const playerTypes = usePlayerTypes()
    const playerTypesIds = useMemo(() => playerTypes?.collection.map(type => type.id), [playerTypes])

    return (
        <>
            { talent && (
                <Modal
                    onClose={() => { setShowModal(() => false) }}
                    modalTitle={t('talent.voteModalTitle', { name: talent.caption })}
                    isOpen={showModal}
                >
                    <div>
                        <TalentVoteForm
                            onVote={() => { setShowModal(() => false) }}
                            talent={talent}
                            networkId={networkId}
                        />
                    </div>
                </Modal>
            )}

            <LoginButton
                disabled={!talent || (!isTalentOwnPersonification(talent) && playerTypesIds?.includes(talent.id))}
                onClick={() => {
                    trackEvent({
                        action: 'start-vote',
                        category: MatomoEventCategory.Talent,
                    })
                    setShowModal(() => true)
                }}
                fill={fill}
                color={color}
                icon={barChart}
            >
                { t('talent.vote') }
            </LoginButton>
        </>
    )
}

export default TalentVoteButton
