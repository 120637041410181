import { IonCol, IonGrid, IonRow, useIonAlert } from '@ionic/react'
import { trashBinOutline } from 'ionicons/icons'
import { useTranslation } from 'react-i18next'

import ContextMenuItem from '../../../components/ContextMenu/ContextMenuItem/ContentMenuItem'
import InfiniteScrollWithHook from '../../../components/InfiniteScrollWithHook'
import TalentCard from '../../../components/NewTalentCard'
import type { Network, Talent } from '../../../lib/apollo/types'
import {
    NetworkRole,
    NetworkTalentsDocument,
    PermissionLevel,
    useNetworkTalentsLazyQuery,
    useRemoveTalentFromNetworkMutation,
} from '../../../lib/apollo/types'
import { useUser } from '../../../providers/Auth/hooks'

import './style.scss'

interface NetworkTalentsProps {
    network: Network,
}

const NetworkTalents: React.FC<NetworkTalentsProps> = ({ network }) => {
    const { t } = useTranslation()
    const user = useUser()
    const [present] = useIonAlert()

    const [removeTalentMutation] = useRemoveTalentFromNetworkMutation({
        refetchQueries: [
            {
                query: NetworkTalentsDocument,
                variables: {
                    userId: user.user.id,
                    networkId: network.id,
                },
            },
        ],
    })

    const onRemoveClick = (tlnt: Talent) => {
        present({
            message: t('networks.removeTalentAlert', { name: tlnt.caption }),
            buttons: [
                {
                    text: t('buttons.cancel'),
                    role: 'cancel',
                },
                {
                    text: t('buttons.remove'),
                    handler: async () => {
                        await removeTalentMutation({
                            variables: {
                                id: network.id,
                                talent: {
                                    _links: {
                                        objectByNetwork: {
                                            url: [`/api/object/${tlnt.id}`],
                                        },
                                    },
                                },
                            },
                        })
                    },
                }],
        })
    }

    return (
        <div
            className='network-talent'
        >
            <IonGrid>
                <IonRow>
                    <InfiniteScrollWithHook
                        variables={{
                            networkId: network.id,
                        }}
                        placeholder={(
                            <>
                                <IonCol
                                    sizeLg='3'
                                    sizeMd='4'
                                    sizeSm='6'
                                    sizeXs='6'
                                >
                                    <TalentCard
                                        type='small'
                                    />
                                </IonCol>
                                <IonCol
                                    sizeLg='3'
                                    sizeMd='4'
                                    sizeSm='6'
                                    sizeXs='6'
                                >
                                    <TalentCard
                                        type='small'
                                    />
                                </IonCol>
                                <IonCol
                                    sizeLg='3'
                                    sizeMd='4'
                                    sizeSm='6'
                                    sizeXs='6'
                                >
                                    <TalentCard
                                        type='small'
                                    />
                                </IonCol>
                            </>
                        )}
                        lazyHook={useNetworkTalentsLazyQuery}
                    >
                        {(talents: Talent[]) => talents.map(talent => (
                            <IonCol
                                sizeLg='3'
                                sizeMd='4'
                                sizeSm='6'
                                sizeXs='6'
                                key={talent.id}
                                className='ion-text-center'
                            >
                                <TalentCard
                                    type='small'
                                    talent={talent}
                                    networkId={network.id}
                                    contextMenuItems={
                                        network.networkRoles?.some(role => role === NetworkRole.Owner || role === NetworkRole.Mod) ||
                                            talent.permissionLevel === PermissionLevel.Administrative || talent.permissionLevel === PermissionLevel.Personal
                                            ? (
                                                <ContextMenuItem
                                                    label={t('networks.removeTalent')}
                                                    icon={trashBinOutline}
                                                    onClick={() => onRemoveClick(talent)}
                                                />
                                            ) : undefined
                                    }
                                />
                            </IonCol>
                        ))}
                    </InfiniteScrollWithHook>
                </IonRow>
            </IonGrid>
        </div>
    )
}

export default NetworkTalents
