import { IonContent, useIonRouter } from '@ionic/react'
import clsx from 'clsx'
import { forwardRef, useLayoutEffect, useState } from 'react'

import './style.scss'
import { showTabBarRoutes } from '../TabBar'

const Content = forwardRef<HTMLIonContentElement | null, React.ComponentProps<typeof IonContent>>(({ children, ...props }, ref) => {
    const [hideTabBar, setHideTabBar] = useState(true)
    const { routeInfo: { pathname } } = useIonRouter()

    useLayoutEffect(() => {
        setHideTabBar(!showTabBarRoutes.some((route: string) => pathname.startsWith(route)))
    }, [pathname])

    return (
        <IonContent
            ref={ref}
            {...props}
            className={clsx('content', props.className, { 'content--with-tab-bar': !hideTabBar })}
        >
            { children }
        </IonContent>
    )
})

export default Content
