import { useIonRouter } from '@ionic/react'
import { useCallback, useMemo } from 'react'

import type { Talent, PermissionLevel } from '../../lib/apollo/types'
import { usePerformanceVoteQuery } from '../../lib/apollo/types'
import useTabContext from '../../lib/hooks/useTabContext'

import TalentCardMedium from './TalentCardMedium'
import TalentCardOnboardingModal from './TalentCardOnboardingModal'
import TalentCardSmall from './TalentCardSmall'
import type { TalentCardType } from './TalentCardTypes'
import TalentListItem from './TalentListItem'
import type { TalentCardContextType } from './_Core/Context'
import { TalentCardContext } from './_Core/Context'
import useIsPreviewMode from './_Core/useIsPreviewMode'
import useTalentType from './_Core/useTalentType'

interface TalentCardProps {
    type?: TalentCardType
    talent?: Talent
    similarTalent?: Talent
    onClick?: () => void
    routerLink?: string
    networkId?: string
    contextMenuItems?: JSX.Element
    permissionLevel?: PermissionLevel
    shared?: boolean
}

const TalentCard: React.FC<TalentCardProps> = ({
    type = 'medium',
    networkId,
    onClick,
    routerLink: rl,
    talent,
    similarTalent,
    contextMenuItems,
    shared,
    permissionLevel = talent?.permissionLevel,
}) => {
    const talentType = useTalentType(talent)
    const preview = useIsPreviewMode(talent)
    const { routeInfo: { pathname } } = useIonRouter()

    const getChild = useCallback((_type) => {
        switch (_type) {
            case 'medium':
                return <TalentCardMedium />
            case 'small':
                return <TalentCardSmall />
            default:
                return <TalentListItem />
        }
    }, [])

    const tab = useTabContext()
    const routerLink = useMemo(() => {
        if (rl) {
            return rl
        }
        if (pathname.includes('details')) {
            return undefined
        }

        return `/${tab}/network/${networkId}/talents/${talent?.id ?? 0}/details`
    }, [type, rl, tab, networkId, talent, pathname])

    const { data } = usePerformanceVoteQuery({
        variables: {
            talentId: talent?.id ?? '',
            network: networkId ? `&network=${networkId}` : '',
        },
        skip: !talent,
    })

    const ctx = useMemo<TalentCardContextType>(() => ({
        talentType,
        talent,
        similarTalent,
        onClick,
        routerLink,
        networkId,
        contextMenuItems,
        preview,
        shared,
        permissionLevel,
        type,
        performance: data?.performanceVote,
    }), [onClick, talent, similarTalent, talentType, networkId, contextMenuItems, preview, permissionLevel, type, routerLink, data])

    return (
        <TalentCardContext.Provider
            value={ctx}
        >
            {getChild(type)}
            {type === 'medium' && <TalentCardOnboardingModal />}
        </TalentCardContext.Provider>
    )
}

export default TalentCard
