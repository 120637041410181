import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { SwiperSlide } from 'swiper/react/swiper-react'

import type { Network } from '../../../lib/apollo/types'
import { useJmtNetworksQuery, useNetworkTalentsQuery } from '../../../lib/apollo/types'
import { useUser } from '../../../providers/Auth/hooks'
import TalentCard from '../../NewTalentCard'
import Slider from '../../Slider'

const RoleModelTeaserSlider: React.FC = () => {
    const { t } = useTranslation()
    const user = useUser()
    const { data: jmtNetworks } = useJmtNetworksQuery()

    const roleModelNetwork = useMemo<Network | undefined>(() => {
        return jmtNetworks?.jmtNetworks.JMTnetworks.collection.find(network => network.caption === 'Join.My.Talent.Engine.RoleModels')
    }, [jmtNetworks])

    const { data: roleModels } = useNetworkTalentsQuery({
        variables: {
            networkId: roleModelNetwork?.id ?? '',
            userId: user.user.id,
        },
        skip: !roleModelNetwork?.id,
    })

    const talents = useMemo(() => {
        if (!roleModels?.networkTalents.collection) {
            return undefined
        }
        const shuffled = [...roleModels.networkTalents.collection].sort(() => 0.5 - Math.random())

        return shuffled.slice(0, 6)
    }, [roleModels])

    return (
        <Slider
            freeMode
            title={t('home.roleModelTeaser.title')}
            subtitle={t('home.roleModelTeaser.subtitle')}
            link='/home/rolemodels'
            itemWidth={166}
        >
            {talents?.map(tal => (
                <SwiperSlide
                    key={tal.id}
                >
                    <TalentCard
                        type='small'
                        talent={tal}
                        networkId={roleModelNetwork?.id}
                    />
                </SwiperSlide>
            )) ?? [
                <SwiperSlide
                    key='preview-1'
                >
                    <TalentCard
                        type='small'
                    />
                </SwiperSlide>,
                <SwiperSlide
                    key='preview-2'
                >
                    <TalentCard
                        type='small'
                    />
                </SwiperSlide>,
                <SwiperSlide
                    key='preview-3'
                >
                    <TalentCard
                        type='small'
                    />
                </SwiperSlide>,
            ]}
        </Slider>
    )
}

export default RoleModelTeaserSlider
