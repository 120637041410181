import type { Activity } from '../types'

import talentTransform from './talentTransform'

const activityDataTransform = (activity: Activity): any => {
    switch (activity.type) {
        case 'user': {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            return activity.data._links?.follow_user?.url
        }

        case 'object.vote': {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            return activity.data.values
        }

        case 'network.invitationtalent':
        case 'invitation': {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            return JSON.stringify(activity.data)
        }

        case 'network.invitetalent': {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            // eslint-disable-next-line no-param-reassign
            activity.data.talent = talentTransform(activity.data.talent)
            return JSON.stringify(activity.data)
        }

        default:
            return 'activityDataTransform.ts: type not found.'
    }
}

export default activityDataTransform
