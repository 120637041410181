import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import usePlayerTypes from '../../../../lib/hooks/usePlayerTypes'
import type { SimilarCategoryTabsMap } from '../SimilarCategoryTabs'
import SimilarCategoryTabs from '../SimilarCategoryTabs'

const MarketplacePlayertypesSearch: React.FC = () => {
    const { t } = useTranslation()
    // RoleModels
    const playerTypes = usePlayerTypes()

    const mappedPlayerTypes = useMemo<SimilarCategoryTabsMap | undefined>(() => {
        if (!playerTypes?.collection) return undefined
        return playerTypes.collection.reduce((map: SimilarCategoryTabsMap, talent) => {
            const key = t(`talent.position.${talent.metas.fieldPosition}`)
            return {
                ...map,
                [key]: [
                    ...(map[key] ?? []),
                    talent,
                ],
            }
        }, {})
    }
    , [playerTypes])

    return (
        <SimilarCategoryTabs
            title={t('marketplace.whatTypeOfPlayer')}
            tabs={mappedPlayerTypes}
        />
    )
}

export default MarketplacePlayertypesSearch
