import type { AutocompleteTypes, TextFieldTypes } from '@ionic/core'
import { IonInput, IonItem, IonLabel, IonText } from '@ionic/react'
import clsx from 'clsx'
import { useState } from 'react'
import type { RegisterOptions } from 'react-hook-form/dist/types/validator'

import CollapsibleContent from '../../../Collapsible/CollapsibleContent'

import '../style.scss'

interface TextFieldProps {
    onChange: (value?: string | number | null) => void
    value: string | number
    name?: string
    error?: string
    label: string
    prefix?: string
    autoComplete?: AutocompleteTypes
    maxLength?: number
    multiline?: boolean
    rules?: Omit<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs'>
    required?: boolean
    disabled?: boolean
    type?: TextFieldTypes
    defaultValue?: string | number | null
    className?: string
}

const TextField: React.FC<TextFieldProps> = ({
    value,
    error,
    name,
    label,
    autoComplete,
    required,
    disabled,
    type = 'text',
    className,
    onChange,
}) => {

    const [focused, setFocused] = useState<boolean>(false)

    return (
        <>
            <div
                className={clsx('form-input', { 'form-input--focused': focused })}
            >
                <IonItem
                    lines='none'
                    className='form-input__item'
                >
                    <IonLabel
                        position={type === 'date' ? 'stacked' : 'floating'}
                        className={clsx('form-input__label', { 'error': !!error, 'form-input__label--date': type === 'date' })}
                    >
                        {label + (required ? '*' : '')}
                    </IonLabel>

                    <div
                        className='form-input__wrapper'
                    >
                        <IonInput
                            autocomplete={autoComplete}
                            className={clsx(className, 'form-input__input', { 'form-input__input__password': type === 'password' })}
                            name={name}
                            value={value}
                            onIonChange={e => { onChange(e.detail.value) }}
                            onIonFocus={() => {
                                setFocused(true)
                            }}
                            onBlur={() => {
                                setFocused(false)
                            }}
                            disabled={disabled}
                        />
                    </div>
                </IonItem>
            </div>
            <CollapsibleContent
                className='form-input__error-container'
                open={!!error}
            >
                <IonText
                    className='form-input__error-text font-s-regular'
                    color='danger'
                >
                    {error}
                </IonText>
            </CollapsibleContent>
        </>
    )
}

export default TextField
