import { addOutline } from 'ionicons/icons'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useRouteMatch } from 'react-router'

import Content from '../../../../components/Content'
import ContextMenuItem from '../../../../components/ContextMenu/ContextMenuItem/ContentMenuItem'
import ItemList from '../../../../components/ItemList'
import TalentCard from '../../../../components/NewTalentCard'
import Page from '../../../../components/Page'
import SearchHeader from '../../../../components/Search/SearchHeader'
import SearchResultList from '../../../../components/Search/SearchResultList'
import {
    NetworkTalentsDocument,
    useNetworkQuery,
    useSearchTalentLazyQuery,
    useAddTalentsToNetworkMutation,
} from '../../../../lib/apollo/types'
import type { MapEntry, Talent } from '../../../../lib/apollo/types'
import { useUser } from '../../../../providers/Auth/hooks'
import useToast from '../../../../providers/Toast/hooks'

const NetworkAddTalents: React.FC = () => {
    const { t } = useTranslation()
    const { params: { networkId } } = useRouteMatch<{ networkId: string }>()
    const user = useUser()

    const { data: network } = useNetworkQuery({
        variables: {
            networkId,
            userId: user.user.id,
        },
    })

    const [search, setSearch] = useState<{ value?: string, event: any }>()
    const [results, setResults] = useState<Talent[]>([])
    const [sortOptions, setSortOptions] = useState<MapEntry[]>([])
    const [sortOption, setSortOption] = useState<string>()

    const [present] = useToast()

    const [addTalentsToNetworkMutation] = useAddTalentsToNetworkMutation({
        onError: () => {
            present(t('network.talent.alreadyInNetwork'), 'tertiary')
        },
        refetchQueries: [
            {
                query: NetworkTalentsDocument,
                variables: {
                    userId: user.user.id,
                    networkId: network?.network.id,
                },
            },
        ],
    })

    const [lazySearchTalentQuery, { loading }] = useSearchTalentLazyQuery({
        onCompleted: data => {
            setResults(data.searchTalent?.collection ?? [])
            setSortOptions(data.searchTalent?.meta.sortOptions ?? [])
        },
    })

    const onAddTalentClick = useCallback(async (talent: Talent) => {
        if (!network?.network.id) {
            return
        }

        const response = await addTalentsToNetworkMutation({
            variables: {
                id: network.network.id,
                talents: {
                    _links: {
                        objectByNetwork: {
                            url: [`/api/object/${talent.id}`],
                        },
                    },
                },
            },
        })

        if (!response.errors) {
            present(t('network.talent.addSuccessful', { talent: talent.caption }), 'success')
        }
    }, [network])

    useEffect(() => {
        if (!search?.value) {
            return
        }

        lazySearchTalentQuery({
            variables: {
                query: search.value,
                sort: sortOption ?? '',
            },
        })
    }, [search])

    useEffect(() => {
        if (sortOption && search?.value) {
            lazySearchTalentQuery({
                variables: {
                    query: search.value,
                    sort: sortOption ?? '',
                },
            })
        }
    }, [sortOption])

    return (
        <Page
            trackingTitle='network add users'
            className='network-add-user'
        >
            <SearchHeader
                setSearch={setSearch}
                search={search}
            />
            <Content
                fullscreen
            >
                <SearchResultList
                    headline={t('search.talents')}
                    loading={loading}
                    results={results}
                    sortOption={sortOption}
                    sortOptions={sortOptions}
                    onSortOptionChange={setSortOption}
                >
                    { (listResults: Talent[]) => (
                        <ItemList>
                            { listResults.map(r => (
                                <TalentCard
                                    type='listItem'
                                    key={r.id}
                                    talent={r}
                                    contextMenuItems={(
                                        <ContextMenuItem
                                            label={t('talent.addToNetwork')}
                                            icon={addOutline}
                                            onClick={() => onAddTalentClick(r)}
                                        />
                                    )}
                                />
                            ))}
                        </ItemList>
                    )}
                </SearchResultList>
            </Content>
        </Page>
    )
}

export default NetworkAddTalents
