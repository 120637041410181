import { IonCard, IonText } from '@ionic/react'
import { Trans } from 'react-i18next'

import type { Talent } from '../../../lib/apollo/types'
import { useNetworkQuery } from '../../../lib/apollo/types'
import { useUser } from '../../../providers/Auth/hooks'
import AnswerTalentApplicationButton from '../../Button/AnswerTalentApplicationButton'
import CustomAvatar from '../../CustomAvatar'
import type { ActivityProps } from '../ActivityProps'
import useHours from '../useHours'

export type TalentApplication = { talent: Talent, targetUserIds: { [key: number]: string }, networkId: number }

const InviteTalentActivity: React.FC<ActivityProps> = ({ activity }) => {
    const me = useUser()
    const invite = activity.userID.toString() === me.user.id.toString()
    const application = JSON.parse(activity.data) as TalentApplication

    const hours = useHours(activity)

    const { data: network } = useNetworkQuery({
        variables: {
            userId: me.user.id,
            networkId: `${application.networkId}`,
        },
    })

    return (
        <IonCard
            className='invite-activity'
        >
            <CustomAvatar
                type='person'
                className='invite-activity__avatar'
                img={application.talent.image}
                alt={application.talent.id}
            />
            <IonText
                className='invite-activity__text'
                color='dark'
            >
                <Trans
                    i18nKey={`activity.invite.${invite ? 'invite' : 'network'}`}
                    values={{
                        network: network?.network?.caption,
                        user: `${application.talent.caption}`,
                        date: hours,
                    }}
                    components={{
                        strong: <strong />,
                        date: (
                            <IonText
                                className='follow-activity__text--small'
                                color='medium'
                            />
                        ),
                    }}
                />
            </IonText>
            <AnswerTalentApplicationButton
                size='small'
                fill='solid'
                color='secondary'
                application={application}
                userId={activity.userID}
            />
        </IonCard>
    )
}

export default InviteTalentActivity
