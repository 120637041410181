import type { ActivityProps } from './ActivityProps'
import ApplyTalentActivity from './ApplyTalentActivity'
import DeletedActivity from './DeletedActivity'
import FollowActivity from './FollowActivity'
import InvitationActivity from './InvitationActivity'
import InviteTalentActivity from './InviteTalentActivity'
import VoteActivity from './VoteActivity'

const Activity: React.FC<ActivityProps> = ({ activity }) => {

    switch (activity.type) {
        // VOTES
        case 'object.vote': {
            return (
                <VoteActivity
                    activity={activity}
                />
            )
        }
        // FOLLOW
        case 'user': {
            return (
                <FollowActivity
                    activity={activity}
                />
            )
        }
        // INVITATION
        case 'invitation': {
            return (
                <InvitationActivity
                    activity={activity}
                />
            )
        }
        // INVITE TALENT
        case 'network.invitetalent': {
            return (
                <InviteTalentActivity
                    activity={activity}
                />
            )
        }
        // INVITE TALENT
        case 'network.invitationtalent': {
            return (
                <ApplyTalentActivity
                    activity={activity}
                />
            )
        }
        default: {
            return (
                <DeletedActivity />
            )
        }
    }
}

export default Activity
