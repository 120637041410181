import { IonInfiniteScroll, IonInfiniteScrollContent } from '@ionic/react'
import { repeat } from 'ionicons/icons'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import Button from '../Button'

import './style.scss'

type Callback = (start: number, limit: number, pageSize: number) => Promise<void>

type InfiniteScrollProps = {
    disabled: boolean,
    onLoadMore?: Callback,
    onMount: Callback,
    onUnmount?: Callback,
    skip?: boolean
}

const PAGE_SIZE = 50
const START_PAGE = 0

const InfiniteScroll: React.FC<InfiniteScrollProps> = ({ skip = false, disabled, onMount, onUnmount, onLoadMore, children  }) => {
    const { t  } = useTranslation()
    const pageRef = useRef(START_PAGE)
    const onUnmountRef = useRef<Callback | undefined>(undefined)
    const [loading, setLoading] = useState(true)
    useEffect(() => { onUnmountRef.current = onUnmount }, [onUnmount])

    useEffect(() => {
        if (skip) return

        (async () => {
            if (!loading) setLoading(() => true)
            await onMount(1, PAGE_SIZE, PAGE_SIZE)
            setLoading(() => false)
        })()

        return () => {
            const currentIndex = (PAGE_SIZE * pageRef.current) + pageRef.current
            onUnmountRef.current?.(currentIndex,currentIndex + PAGE_SIZE, PAGE_SIZE) }
    }, [skip])

    return (
        <>
            { children }
            {!!children && (
                <>
                    {!disabled && !skip && (
                        <div
                            className='infinite-scroll__loading-btn-wrapper'
                        >
                            <Button
                                loading={loading}
                                color='secondary'
                                icon={repeat}
                                onClick={async () => {
                                    if (!onLoadMore) return

                                    pageRef.current += 1
                                    setLoading(() => true)
                                    await onLoadMore(PAGE_SIZE * pageRef.current + 1, PAGE_SIZE * (pageRef.current + 1), PAGE_SIZE)

                                    setLoading(() => false)
                                }}
                            >
                                {t('buttons.loadMore')}
                            </Button>
                        </div>
                    )}
                    <IonInfiniteScroll
                        disabled={loading || disabled || skip}
                        onIonInfinite={(ev: any) => {
                            const loadData = async () => {
                                if (!onLoadMore || loading) return

                                pageRef.current += 1
                                setLoading(() => true)
                                await onLoadMore(PAGE_SIZE * pageRef.current + 1, PAGE_SIZE * (pageRef.current + 1), PAGE_SIZE)

                                ev.target?.complete?.()
                                setLoading(() => false)
                            }

                            loadData()
                        }}
                        threshold='500px'
                    >
                        <IonInfiniteScrollContent
                            loadingSpinner='bubbles'
                            loadingText={t('loading')}
                        />
                    </IonInfiniteScroll>
                </>
            )}
        </>
    )
}

export default InfiniteScroll
