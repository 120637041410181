import { ApolloClient, ApolloLink, InMemoryCache } from '@apollo/client'
import type { ServerError } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { Preferences } from '@capacitor/preferences'
import { RestLink } from 'apollo-link-rest'
import { createNetworkStatusNotifier } from 'react-apollo-network-status'

import { signOut } from '../../providers/Auth/hooks'
import STORAGE_KEYS from '../storageKeys'

import { API_TOKEN, BASE_URL } from './config'
import handleCollection from './helper/handleCollection'
import responseTransformer from './responseTransformer'
import type { TalentCustomMetaInput } from './types'
import { PermissionLevel } from './types'

const { link, useApolloNetworkStatus: uANS } = createNetworkStatusNotifier()

export const useApolloNetworkStatus = uANS

const errorLink = onError(({ networkError }) => {
    if ((networkError as ServerError).statusCode === 401) {
        signOut()
    }
})

const authLink = setContext(async (_, { headers }) => {
    const { value: token } = await Preferences.get({ key: STORAGE_KEYS.API.LOGIN_TOKEN })
    return {
        headers: {
            ...headers,
            'X-Authorization': `EMOTIONUM apikey="${API_TOKEN}", user="${token ?? ''}"`,
        },
    }
})

const restLink = new RestLink({
    uri: BASE_URL,
    headers: {
        'Content-Type': 'application/json',
    },
    responseTransformer,
    bodySerializers: {
        noBody: (_, headers) => ({ headers, body: {} }),
        imageEncode: (image: string, headers: Headers) => {
            headers.set('Content-Type', 'application/base64')
            return { body: image, headers }
        },
        talentCustomMeta: (input: TalentCustomMetaInput, headers: Headers) => {
            const body = JSON.stringify({
                data: {
                    status: !input.status ? undefined : {
                        value: {
                            en: input.status,
                        },
                    },
                    club: !input.club ? undefined : {
                        value: {
                            en: input.club,
                        },
                    },
                    team: !input.team ? undefined : {
                        value: {
                            en: input.team,
                        },
                    },
                    rolemodelid: !input.rolemodelid ? undefined : {
                        value: {
                            en: `${input.rolemodelid}`,
                        },
                    },
                    topskillsindexes: !input.topskillsindexes ? undefined : {
                        value: {
                            en: `${input.topskillsindexes}`,
                        },
                    },
                    foot: {
                        value: {
                            en: input.foot,
                        },
                    },
                    height: {
                        value: {
                            en: input.height,
                        },
                    },
                    weight: {
                        value: {
                            en: input.weight,
                        },
                    },
                    salutation: !input.salutation ? undefined : {
                        value: {
                            en: input.salutation,
                        },
                    },
                },
            })

            return { body, headers }
        },

    },
})

// Setup your client
const apolloClient = new ApolloClient({
    cache: new InMemoryCache({
        typePolicies: {
            Query: {
                fields: {
                    activities: handleCollection('Activity'),
                    playlists: handleCollection('Playlist'),
                    playlistTalents: handleCollection('Talent'),
                    networks: handleCollection('Network'),
                    listMyTalents: handleCollection('Talent'),
                    similarNetwork: handleCollection('Talent'),
                    networkUsers: handleCollection('User'),
                    networkTalents: handleCollection('Talent'),
                },
            },
            UserMetaData: {
                keyFields: ['username'],
            },
            Talent: {
                fields: {
                    permissionLevel: {
                        merge(existing, incoming) {
                            if(existing && incoming === PermissionLevel.None) {
                                return existing
                            }
                            return incoming
                        },
                    },
                    dataViews: {
                        merge(existing, incoming) {
                            if(existing?.length > incoming?.length) {
                                return existing
                            }
                            return incoming
                        },
                    },
                    publicRating: {
                        merge(existing, incoming) {
                            if(incoming.length === 0) {
                                return existing
                            }
                            return incoming
                        },
                    },
                    rateCount: {
                        merge(existing, incoming) {
                            if(!incoming) {
                                return existing
                            }
                            return incoming
                        },
                    },
                },
            },
            Network: {
                fields: {
                    networkRoles: {
                        merge(existing, incoming) {
                            if(!incoming || incoming.length === 0 || existing?.length > incoming?.length) {
                                return existing
                            }
                            return incoming
                        },
                    },
                },
            },
        },
    }),
    link: ApolloLink.from([errorLink, link, authLink, restLink]),
    uri: '',
})

export default apolloClient
