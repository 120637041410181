import { useIonAlert } from '@ionic/react'
import { share } from 'ionicons/icons'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import type { Talent } from '../../../../lib/apollo/types'
import ContextMenuItem from '../../../ContextMenu/ContextMenuItem/ContentMenuItem'

const TalentRateRoleModelContextMenuItem: React.FC<{ talent: Talent }> = ({ talent }) => {
    const { t } = useTranslation()
    const [alert] = useIonAlert()

    const onClick = useCallback(() => {
        alert('Hier erscheint später der Share-Dialog mit der Bitte um eine Einschätzung zu diesem Role Model')
    }, [talent])

    return (
        <ContextMenuItem
            disableCloseContextMenuOnClick
            onClick={onClick}
            icon={share}
            label={t('roleModel.rate.button')}
        />
    )
}

export default TalentRateRoleModelContextMenuItem
