import { useMemo } from 'react'

import { createRadarGraphs } from './talentIdUtils'

interface GraphProps {
    id: string
    values: number[]
    compareValues?: number[]
}

const Graph: React.FC<GraphProps> = ({
    id,
    values,
    compareValues,
}) => {
    const radarGraphsVals = useMemo(() => createRadarGraphs(values), [values])
    const radarGraphsCompareVals = useMemo(() => compareValues ? createRadarGraphs(compareValues) : undefined, [compareValues])

    if (!radarGraphsCompareVals) {
        return (
            <path
                transform='translate(250, 250)'
                className='radar l-global radar-transform path--color1'
                d={radarGraphsVals}
            />
        )
    }

    return (
        <g
            transform='translate(250, 250)'
        >
            <defs>
                <mask
                    id={`${id}-graph-background`}
                >
                    <rect
                        x='-300'
                        y='-300'
                        width='600'
                        height='600'
                        style={{ fill:'white' }}
                    />
                    <path
                        d={radarGraphsCompareVals}
                        style={{ fill:'black', fillOpacity: 1 }}
                    />
                </mask>

                <mask
                    id={`${id}-graph-foreground`}
                >
                    <rect
                        x='-300'
                        y='-300'
                        width='600'
                        height='600'
                        style={{ fill: 'white' }}
                    />
                    <path
                        d={radarGraphsVals}
                        style={{ fill:'black', fillOpacity: 1 }}
                    />
                </mask>

                <mask
                    id={`${id}-graph-top`}
                >
                    <path
                        d={radarGraphsVals}
                        style={{ fill:'white', fillOpacity: 1 }}
                    />
                </mask>
            </defs>

            <path
                d={radarGraphsCompareVals}
                mask={`url(#${id}-graph-foreground`}
                className='radar-transform path--color2'
            />
            <path
                d={radarGraphsVals}
                mask={`url(#${id}-graph-background`}
                className='radar-transform path--color1'
            />
            <path
                d={radarGraphsCompareVals}
                mask={`url(#${id}-graph-top`}
                className='radar-transform path--overlap'
            />

            <path
                d={radarGraphsVals}
                className='radar-transform path--combined'
            />
        </g>

    )
}

export default Graph
