import { IonCard } from '@ionic/react'
import clsx from 'clsx'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import type { Talent } from '../../../lib/apollo/types'
import { TalentPosition, useTalentQuery } from '../../../lib/apollo/types'
import isTalentOwnPersonification from '../../../lib/utils/isTalentOwnPersonification'
import { useUser } from '../../../providers/Auth/hooks'

import TopSkillsSkeleton from './TopSkillsSkeleton'

import './style.scss'

interface TopSkillsProps {
    talent?: Talent
    amount?: number
    values?: number[] // overrides publicrating
    compareValues?: number[]
    oldTitle?: string
    newTitle?: string
    className?: string
    asCard?: boolean
    withHeadline?: boolean
}

interface SkillsI {
    value: number,
    translation: string
    rotation: number
}

const TopSkills: React.FC<TopSkillsProps> = ({
    withHeadline,
    asCard,
    className,
    talent,
    amount = 4,
    values: v,
    compareValues,
    oldTitle,
    newTitle,
}) => {
    const { t } = useTranslation()
    const values = v ?? talent?.dataViews[0]?.values
    const user = useUser()
    const { data } = useTalentQuery({
        variables: {
            talentId: talent?.id ?? '',
            userId: user?.user.id || '',
        },
        skip: !talent || !user,
        fetchPolicy: 'cache-and-network',
    })

    const usedTalent = useMemo<Talent | undefined>(() => {
        if(talent) {
            return talent
        }

        return data?.talent

    }, [talent, data])

    const orderedTranslations = useMemo<SkillsI[]>(() => {
        // SAVED SKILLS
        if (usedTalent?.customMeta?.topskillsindexes && usedTalent?.customMeta?.topskillsindexes.length > 0) {
            return usedTalent.customMeta.topskillsindexes.map((value) => ({
                value: usedTalent.dataViews[0].values[value || 0],
                translation: t(`i18n.class.${usedTalent?.metas.fieldPosition === TalentPosition.Goal ? 'goalie' : 'player'}.scalar_${value}`),
                rotation: Math.floor((value ?? 0) / ((values?.length ?? 1) / 4)) * 90,
            }))
                .slice(0, amount)
        }

        // DEFAULT TOP ORDER
        return values?.map((value, i) => ({
            value: value ?? 0,
            translation: t(`i18n.class.${usedTalent?.metas.fieldPosition === TalentPosition.Goal ? 'goalie' : 'player'}.scalar_${i}`),
            rotation: Math.floor(i / ((values?.length ?? 1) / 4)) * 90,
        }))
            .sort((left, right) => right.value - left.value)
            .slice(0, amount) ?? []
    },
    [usedTalent, amount, values])

    const orderedCompareTranslations = useMemo<SkillsI[] | null>(() => compareValues?.map((value, i) => ({
        value: value ?? 0,
        translation: t(`i18n.class.${usedTalent?.metas.fieldPosition === TalentPosition.Goal ? 'goalie' : 'player'}.scalar_${i}`),
        rotation: Math.floor(i / ((values?.length ?? 1) / 4)) * 90,
    }))
        .sort((left, right) => right.value - left.value)
        .slice(0, amount) ?? null,
    [usedTalent, amount, compareValues])

    const headline = useMemo<string>(() => {
        if (!usedTalent?.personification) {
            return t('talent.topskills.scoutHeadline', { name: usedTalent?.caption })
        }

        const key = !isTalentOwnPersonification(usedTalent)
            ? 'talent.topskills.headline'
            : 'talent.topskills.mineHeadline'
        return t(key, { name: usedTalent.caption })
    }, [usedTalent])

    if (!talent) {
        return (
            <TopSkillsSkeleton
                amount={amount}
            />
        )
    }

    const SkillComponent = asCard ? IonCard : 'div'

    return (
        <>
            {withHeadline && (
                <h2>
                    {headline}
                </h2>
            )}
            <div
                className={clsx(className, 'talent-top-skills', { 'talent-top-skills--compare': !!orderedCompareTranslations })}
            >
                {!!orderedCompareTranslations && (
                    <div
                        className='talent-top-skills__row'
                    >
                        {oldTitle && (
                            <h4
                                className='talent-top-skills__title'
                            >
                                {oldTitle}
                            </h4>
                        )}
                        {orderedCompareTranslations?.map(({ translation, rotation }) => (
                            <SkillComponent
                                color={asCard ? 'primary' : undefined}
                                key={translation}
                                className={clsx('talent-top-skills__skill-wrapper', {
                                    'talent-top-skills__skill-wrapper--old': !orderedTranslations.find((s) => s.translation === translation),
                                })}
                            >
                                <img
                                    style={{ transform: `rotate(${rotation}deg)` }}
                                    className='talent-top-skills__img'
                                    alt='skill-indicator'
                                    src='/assets/icon/logo.svg'
                                />

                                <h4
                                    className='talent-top-skills__skill ion-text-left'
                                >
                                    {translation}
                                </h4>
                            </SkillComponent>
                        )) ?? null}
                    </div>
                )}

                <div
                    className='talent-top-skills__row'
                >
                    {newTitle && (
                        <h4
                            className='talent-top-skills__title'
                        >
                            {newTitle}
                        </h4>
                    )}
                    {orderedTranslations.map(({ translation, rotation }) => (
                        <SkillComponent
                            color={asCard ? 'primary' : undefined}
                            key={translation}
                            className={clsx('talent-top-skills__skill-wrapper', {
                                'talent-top-skills__skill-wrapper--new': orderedCompareTranslations && !orderedCompareTranslations.find((s) => s.translation === translation),
                            })}
                        >
                            <img
                                style={{ transform: `rotate(${rotation}deg)` }}
                                className='talent-top-skills__img'
                                alt='skill-indicator'
                                src='/assets/icon/logo.svg'
                            />

                            <h4
                                className='talent-top-skills__skill ion-text-left'
                            >
                                {translation}
                            </h4>
                        </SkillComponent>
                    ))}
                </div>
            </div>
        </>
    )
}

export default TopSkills
