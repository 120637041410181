import { useMatomo } from '@datapunt/matomo-tracker-react'
import type { Color } from '@ionic/core'
import { fitness } from 'ionicons/icons'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import type { Talent } from '../../../lib/apollo/types'
import { useMyTalentLayerQuery } from '../../../lib/apollo/types'
import usePlayerTypes from '../../../lib/hooks/usePlayerTypes'
import { MatomoEventCategory } from '../../../lib/tracking/eventCategories'
import { useUser } from '../../../providers/Auth/hooks'
import LoginButton from '../../Button/LoginButton'
import Modal from '../../Modal'
import TalentPerformanceVoteForm from '../TalentPerformanceVoteForm'

interface TalentVoteButtonProps {
    talent?: Talent
    networkId: string
    color?: Color
    fill?: 'solid' | 'outline' | 'clear'
}

const TalentPerformanceVoteButton: React.FC<TalentVoteButtonProps> = ({ fill, talent, networkId, color = 'primary' }) => {
    const { t } = useTranslation()
    const user = useUser()
    const [showModal, setShowModal] = useState(false)
    const { trackEvent } = useMatomo()
    const playerTypes = usePlayerTypes()
    const playerTypesIds = useMemo(() => playerTypes?.collection.map(type => type.id), [playerTypes])

    const { data: myTalentLayerData } = useMyTalentLayerQuery({
        skip: !user?.user.id || !talent?.id,
        variables: {
            userId: user.user.id,
            talentId: talent?.id ?? '',
            layerParams: `&layers=self&networkId=${networkId}`,
        },
    })

    const { data: networkLayerData } = useMyTalentLayerQuery({
        skip: !user?.user.id || !talent?.id,
        variables: {
            userId: user.user.id,
            talentId: talent?.id ?? '',
            layerParams: `&layers=network&networkId=${networkId}`,
        },
    })

    const aggregation = useMemo(() => {
        let result = myTalentLayerData?.talentLayer?.[0].dataViews.find(dataView => dataView.values.length === 8)
        if(result && result.values.reduce((a, b) => a + b, 0) > 0) {
            return result
        }
        // no user votes
        result = networkLayerData?.talentLayer?.[0].dataViews.find(dataView => dataView.values.length === 8)
        if(result && result.values.reduce((a, b) => a + b, 0) > 0) {
            return result
        }
        return undefined
    }, [myTalentLayerData, networkLayerData])

    return (
        <>
            { (talent && networkId) && (
                <Modal
                    onClose={() => { setShowModal(() => false) }}
                    modalTitle={t('talent.performanceVoteModalTitle', { name: talent.caption })}
                    isOpen={showModal}
                >
                    <div>
                        <TalentPerformanceVoteForm
                            onVote={() => { setShowModal(() => false) }}
                            talent={talent}
                            networkId={networkId}
                            aggregation={aggregation}
                        />
                    </div>
                </Modal>
            )}

            <LoginButton
                disabled={!talent || playerTypesIds?.includes(talent.id) || !aggregation}
                onClick={() => {
                    trackEvent({
                        action: 'start-performance-vote',
                        category: MatomoEventCategory.Talent,
                    })
                    setShowModal(() => true)
                }}
                fill={fill}
                color={color}
                icon={fitness}
            >
                { t('talent.performanceVote') }
            </LoginButton>
        </>
    )
}

export default TalentPerformanceVoteButton
