import {
    useCallback, useEffect, useRef, useState,
} from 'react'

import { DESKTOP_BREAKPOINT, DESKTOP_BREAKPOINT_MENU } from '../constants'

const useWindowResizeListener = () : { width: number, height: number, isDesktop: boolean, hideMenuButton: boolean } => {
    const timeoutRef = useRef<NodeJS.Timeout | null>(null)
    const [dimensions, setDimensions] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
        isDesktop: window.innerWidth >= DESKTOP_BREAKPOINT,
        hideMenuButton: window.innerWidth >= DESKTOP_BREAKPOINT_MENU,
    })

    const handleResize = useCallback(() => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current)
        }
        timeoutRef.current = setTimeout(() => {
            setDimensions({
                width: window.innerWidth,
                height: window.innerHeight,
                isDesktop: window.innerWidth >= DESKTOP_BREAKPOINT,
                hideMenuButton: window.innerWidth >= DESKTOP_BREAKPOINT_MENU,
            })
        }, 250)
    }, [])

    useEffect(() => {
        window.addEventListener('resize', handleResize)
        return () => { window.removeEventListener('resize', handleResize) }
    }, [])

    return dimensions
}

export default useWindowResizeListener
