import { IonCard } from '@ionic/react'
import { arrowForward, checkmark, statsChart } from 'ionicons/icons'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { SwiperSlide } from 'swiper/react/swiper-react.js'

import type { Talent } from '../../../../../lib/apollo/types'
import {
    Salutation,
    TalentDocument,
    TalentPosition,
    useJmtNetworksQuery,
    useSimilarNetworkQuery,
    useUpdateTalentCustomMetaMutation,
} from '../../../../../lib/apollo/types'
import { useUser } from '../../../../../providers/Auth/hooks'
import useToast from '../../../../../providers/Toast/hooks'
import Button from '../../../../Button'
import Buttons from '../../../../Buttons'
import TalentCard from '../../../../NewTalentCard'
import Segment from '../../../../Segment'
import ShareTalentButton from '../../../../ShareTalentButton'
import Slider from '../../../../Slider'

import './style.scss'

interface ChoseRoleModelFormProps {
    talent: Talent
    onSelect: () => void
}

const tabs = ['male', 'female']

const ChoseRoleModelForm: React.FC<ChoseRoleModelFormProps> = ({ talent, onSelect }) => {
    const [showOnboarding, setShowOnboarding] = useState(!talent.customMeta?.rolemodelid)
    useEffect(() => {
        if (showOnboarding === !!talent.customMeta?.rolemodelid)
            setShowOnboarding((so) => !so)
    }, [talent.customMeta?.rolemodelid])

    const { t } = useTranslation()
    const [present] = useToast()
    const user = useUser()

    const [currentTab, setCurrentTab] = useState<string>('0')

    const onSegmentChange = useCallback((e: CustomEvent) => {
        setCurrentTab(e.detail.value)
    }, [setCurrentTab])

    const [updateCustomMetaMutation] = useUpdateTalentCustomMetaMutation({
        refetchQueries: [
            {
                query: TalentDocument,
                variables: {
                    userId: user.user.id,
                    talentId: talent.id,
                },
            },
        ],
    })

    const values = useMemo(() => {
        if (!talent) return null
        return talent.dataViews.find(({ key }) => key === 0)?.values
    }, [talent])

    const talentClass = useMemo(() => {
        if (!talent) return undefined
        return talent.metas.fieldPosition ===  TalentPosition.Goal ? '2' : '1'
    }, [talent])

    const networks = useJmtNetworksQuery()
    const roleModelNetwork = useMemo(() =>
        networks?.data?.jmtNetworks.JMTnetworks.collection.find((network) => network.caption === 'Join.My.Talent.Engine.RoleModels')
    , [networks])

    const { data } = useSimilarNetworkQuery({
        skip: !roleModelNetwork?.id || !values || !talentClass,
        variables: {
            userId: user.user.id,
            networkId: roleModelNetwork?.id ?? '',
            queryString: `&class=${talentClass}&values=${values?.map(n => Math.round(+n))}&limit=2`,
        },
    })

    const femaleRoleModelNetwork = useMemo(() =>
        networks?.data?.jmtNetworks.JMTnetworks.collection.find((network) => network.caption === 'Join.My.Talent.Engine.FemaleRoleModels')
    , [networks])

    const { data: femaleData } = useSimilarNetworkQuery({
        skip: !femaleRoleModelNetwork?.id || !values || !talentClass,
        variables: {
            userId: user.user.id,
            networkId: femaleRoleModelNetwork?.id ?? '',
            queryString: `&class=${talentClass}&values=${values?.map(n => Math.round(+n))}&limit=2`,
        },
    })

    const onButtonClick = (rm: Talent) => async () => {
        if (!talent?.customMeta?.id) return
        await updateCustomMetaMutation({
            variables: {
                input: {
                    ...talent.customMeta,
                    rolemodelid: +rm.id,
                },
                talentId: talent.id,
                metaDataId: talent.customMeta.id,
            },
        })
        present(t('talent.updated', { name: talent.caption }), 'success')
        onSelect()
    }

    useEffect(() => {
        if(!talent.customMeta || !femaleData?.similarNetwork?.collection.length) return
        setCurrentTab(`${tabs.indexOf(talent.customMeta?.salutation ?? 'male')}` ?? '0')
    }, [talent.customMeta, femaleData?.similarNetwork?.collection])

    if (!((data?.similarNetwork?.collection?.length ?? 0) > 0)) return null

    if (showOnboarding) {
        return (
            <IonCard
                color='light'
                className='chose-rolemodel-form__onboarding'
            >
                <h2
                    className='ion-text-center'
                >
                    {t('talent.choseRoleModel')}
                </h2>

                <div
                    className='chose-rolemodel-form__onboarding__position-helper'
                >
                    <div
                        className='chose-rolemodel-form__onboarding__rolemodel-card-wrapper'
                    >
                        { talent.customMeta?.salutation === Salutation.Female ? (
                            (femaleData?.similarNetwork?.collection?.map(roleModel => (
                                <TalentCard
                                    type='small'
                                    key={roleModel.id}
                                    similarTalent={talent}
                                    talent={roleModel}
                                />
                            )))
                        ) : (data?.similarNetwork?.collection?.map(roleModel => (
                            <TalentCard
                                type='small'
                                key={roleModel.id}
                                similarTalent={talent}
                                talent={roleModel}
                            />
                        )))}
                    </div>
                    <Button
                        className='chose-rolemodel-form__onboarding__btn'
                        expand='block'
                        color='secondary'
                        icon={arrowForward}
                        onClick={() => { setShowOnboarding(() => false) }}
                    >
                        { t('talent.choseRoleModel') }
                    </Button>
                </div>

                <h3
                    className='ion-text-center'
                >
                    {t('talent.roleModelOnboarding')}
                </h3>
            </IonCard>
        )
    }

    return (
        <>
            { !!femaleData?.similarNetwork?.collection.length && (
                <Segment
                    value={currentTab}
                    onIonChange={onSegmentChange}
                    tabs={tabs.map((v: string) => ({ tab: t(`rolemodel.${v}`) }))}
                />
            )}
            {currentTab === '0' ? (
                <Slider
                    title={t('talent.choseRoleModel')}
                    className='chose-rolemodel-form'
                    freeMode
                    itemWidth={320}
                >
                    { data?.similarNetwork?.collection?.map(roleModel => (
                        <SwiperSlide
                            key={roleModel.id}
                        >
                            <div
                                className='chose-rolemodel-form__card'
                            >
                                <TalentCard
                                    similarTalent={talent}
                                    talent={roleModel}
                                />
                                <Buttons>
                                    <Button
                                        expand='block'
                                        color='secondary'
                                        className='ion-margin-top ion-margin-bottom'
                                        icon={checkmark}
                                        onClick={onButtonClick(roleModel)}
                                    >
                                        {t('buttons.chose')}
                                    </Button>

                                    <ShareTalentButton
                                        talent={talent}
                                        roleModel={roleModel}
                                    />

                                    <Button
                                        expand='block'
                                        icon={statsChart}
                                        routerLink={`/comparison/${talent.id}/${roleModel.id}`}
                                        onClick={onSelect}
                                    >
                                        {t('buttons.compare')}
                                    </Button>
                                </Buttons>
                            </div>
                        </SwiperSlide>
                    ))}
                </Slider>
            ) : (
                <Slider
                    title={t('talent.choseRoleModel')}
                    className='chose-rolemodel-form'
                    freeMode
                    itemWidth={320}
                >
                    { femaleData?.similarNetwork?.collection?.map(roleModel => (
                        <SwiperSlide
                            key={roleModel.id}
                        >
                            <div
                                className='chose-rolemodel-form__card'
                            >
                                <TalentCard
                                    similarTalent={talent}
                                    talent={roleModel}
                                />
                                <Buttons>
                                    <Button
                                        expand='block'
                                        color='secondary'
                                        className='ion-margin-top ion-margin-bottom'
                                        icon={checkmark}
                                        onClick={onButtonClick(roleModel)}
                                    >
                                        {t('buttons.chose')}
                                    </Button>

                                    <ShareTalentButton
                                        talent={talent}
                                        roleModel={roleModel}
                                    />

                                    <Button
                                        expand='block'
                                        icon={statsChart}
                                        routerLink={`/comparison/${talent.id}/${roleModel.id}`}
                                        onClick={onSelect}
                                    >
                                        {t('buttons.compare')}
                                    </Button>
                                </Buttons>
                            </div>
                        </SwiperSlide>
                    ))}
                </Slider>
            )}
        </>
    )
}

export default ChoseRoleModelForm
