import { IonText } from '@ionic/react'
import { ellipsisVertical } from 'ionicons/icons'
import { useMemo } from 'react'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import { useRouteMatch } from 'react-router'

import Content from '../../components/Content'
import ContextMenu from '../../components/ContextMenu'
import Divider from '../../components/Divider'
import Header from '../../components/Header'
import ItemList from '../../components/ItemList'
import NetworkApplyContextMenuItem from '../../components/Network/NetworkApplyContextMenuItem'
import NetworkListItem from '../../components/Network/NetworkListItem'
import Page from '../../components/Page'
import UserCardMedium from '../../components/User/UserCard/UserCardMedium'
import type { User } from '../../lib/apollo/types'
import { useJmtNetworksQuery, useUserQuery, useNetworksOfUserQuery } from '../../lib/apollo/types'
import { useUser } from '../../providers/Auth/hooks'

import './style.scss'

interface UserParams {
    userId: string
}

const UserPage: React.FC = () => {
    const {
        params: {
            userId,
        },
    } = useRouteMatch<UserParams>()
    const me = useUser()
    const { t } = useTranslation()
    const { data: jmtNetworks } = useJmtNetworksQuery()
    const jmtNetworkId = useMemo(() => jmtNetworks?.jmtNetworks.JMTnetworks.collection.find(jn => jn.caption === 'Join.My.Talent.Engine')?.id, [jmtNetworks])
    const user = useUserQuery({ variables: { userId, meId: me.user.id , networkId: jmtNetworkId ?? '0' }, skip: !jmtNetworkId })
    // const personification = useUserPersonificationQuery({ variables: { userId }, skip: !user })
    const title = user?.data?.user
        ? `${user.data.user.firstName} ${user.data.user.lastName}`
        : ''

    const { data: userNetworks } = useNetworksOfUserQuery({
        variables: {
            userId: user.data?.user?.id ?? '',
        },
        skip: !user.data?.user?.id,
    })

    return (
        <Page
            trackingTitle='user'
            className='user-page'
        >
            <Header
                title={title}
            />
            <Content
                fullscreen
            >
                <Helmet>
                    <title>{`Join My Talent - ${title}`}</title>
                    <meta
                        property='og:title'
                        content={`Join My Talent - ${title}`}
                    />
                    <meta
                        property='og:description'
                        content={t('og.user.description', { name: title })}
                    />
                    <meta
                        property='og:image'
                        content={user?.data?.user?.image ?? 'https://app.joinmytalent.com/assets/icon/favicon.png'}
                    />
                </Helmet>
                <UserCardMedium
                    user={user?.data?.user as User ?? null}
                />
                {(userNetworks?.networks?.collection?.length ?? 0) > 0 && (
                    <>
                        <Divider />
                        <div>
                            <h2
                                className='ion-margin-bottom'
                            >
                                {t('user.networks.headline', { firstName: user?.data?.user?.firstName })}
                            </h2>
                            <IonText
                                className='ion-text-left ion-margin-bottom n2br'
                                color='medium'
                            >
                                {t('user.networks.description', { firstName: user?.data?.user?.firstName })}
                            </IonText>
                        </div>
                        <ItemList>
                            {userNetworks?.networks?.collection.map(network => (
                                <NetworkListItem
                                    key={network.id}
                                    onClick={e => {
                                        e.preventDefault()
                                    }}
                                    network={network}
                                >
                                    <ContextMenu
                                        size='small'
                                        fill='clear'
                                        icon={ellipsisVertical}
                                    >
                                        <NetworkApplyContextMenuItem
                                            network={network}
                                        />
                                    </ContextMenu>
                                </NetworkListItem>
                            ))}
                        </ItemList>
                    </>
                )}
            </Content>
        </Page>
    )
}

export default UserPage
