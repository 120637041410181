import { IonCol, IonGrid, IonRow } from '@ionic/react'
import { useCallback, useState } from 'react'
import type { Swiper as SwiperClass } from 'swiper'
import { Controller } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js'

import ItemList from '../../../../components/ItemList'
import TalentCard from '../../../../components/NewTalentCard'
import Segment from '../../../../components/Segment'
import type { Talent } from '../../../../lib/apollo/types'

import './style.scss'

const getRouterLink = (talent: Talent): string => `/marketplace/similar/${talent.id}`

export type SimilarCategoryTabsMap = {
    [key: string]: Talent[]
}

interface SimilarCategoryTabsProps {
    title: string
    tabs?: SimilarCategoryTabsMap
}

const SimilarCategoryTabs: React.FC<SimilarCategoryTabsProps> = ({ title, tabs }) => {
    const [controlledSwiper, setControlledSwiper] = useState<SwiperClass | null>(null)

    const [activeSegment, setActiveSegment] = useState('0')

    const onSegmentChange = useCallback((e) => {
        if (controlledSwiper) {
            controlledSwiper.slideTo(e.detail.value)
            setActiveSegment(() => e.detail.value)
        }
    }, [controlledSwiper, setActiveSegment])

    const onIonSlideWillChange = useCallback((e: SwiperClass) => {
        setActiveSegment(e.activeIndex.toString())
    }, [setActiveSegment])

    return (
        <IonGrid
            className='similar-category-tabs'
        >
            <IonRow>
                <IonCol>
                    <h2
                        className='ion-margin-bottom ion-margin-top'
                    >
                        { title }
                    </h2>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol>
                    <Segment
                        className='ion-margin-top'
                        value={activeSegment}
                        onIonChange={onSegmentChange}
                        tabs={tabs ? Object.keys(tabs).map(tab => ({ tab })) : undefined}
                    />
                    <Swiper
                        modules={[Controller]}
                        className='ion-margin-top'
                        onSwiper={setControlledSwiper}
                        onSlideChange={onIonSlideWillChange}
                    >
                        {tabs
                            ? Object.entries(tabs).map(([key, talents]) => (
                                <SwiperSlide
                                    key={key}
                                >
                                    <ItemList
                                        className='similar-category-tabs__talent-wrapper'
                                    >
                                        { talents.map(talent => (
                                            <TalentCard
                                                type='listItem'
                                                routerLink={getRouterLink(talent)}
                                                key={talent.id}
                                                talent={talent}
                                            />
                                        )) }
                                    </ItemList>
                                </SwiperSlide>
                            ))
                            : (
                                <ItemList
                                    className='similar-category-tabs__talent-wrapper'
                                >
                                    <TalentCard
                                        type='listItem'
                                    />
                                    <TalentCard
                                        type='listItem'
                                    />
                                    <TalentCard
                                        type='listItem'
                                    />
                                    <TalentCard
                                        type='listItem'
                                    />
                                </ItemList>
                            )}
                    </Swiper>
                </IonCol>
            </IonRow>
        </IonGrid>
    )
}

export default SimilarCategoryTabs
