const talentTransform = (json: any): any => {
    if (!json) return undefined
    const talent = { ...json }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    talent.dataViews = [{ key: 0, values: talent.publicRating }, ...Object.entries(talent.dataViews).map(([key, { values }]) => ({ key: +key, values }))]
    talent.permissionLevel = talent.permissionLevel ?? 'NONE'
    talent.profileId = talent._links.profile?.url?.split('/')[talent._links.profile?.url?.split('/').length - 1]
    talent.metas = {
        ...talent.metas,
        position: talent.metas.position ? talent.metas.position : talent.metas.fieldPosition,
        birthdate: talent.metas.birthdate ? talent.metas.birthdate : talent.metas.date,
        fifacountry: talent.metas.fifacountry ? talent.metas.fifacountry : talent.metas.fifaCountry,
    }
    talent.asset =  talent?._links?.image?.url?.split('/')[talent?._links?.image?.url?.split('/').length - 1]
    if (talent.customMeta?.collection.length > 0) {
        talent.customMeta = {
            id: talent.customMeta.collection[0].id,
            height: talent.customMeta.collection[0].data?.height?.value.en,
            weight: talent.customMeta.collection[0].data?.weight?.value.en,
            foot: talent.customMeta.collection[0].data?.foot?.value.en,
            club: talent.customMeta.collection[0].data?.club?.value.en,
            team: talent.customMeta.collection[0].data?.team?.value.en,
            rolemodelid: talent.customMeta.collection[0].data?.rolemodelid?.value.en,
            status: talent.customMeta.collection[0].data?.status?.value.en,
            salutation: talent.customMeta.collection[0].data?.salutation?.value.en,
            topskillsindexes: talent.customMeta.collection[0].data?.topskillsindexes?.value.en.split(',').filter(Boolean),
        }
    }

    return talent
}

export default talentTransform
