import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import useRoleModels from '../../../../lib/hooks/useRoleModels'
import type { SimilarCategoryTabsMap } from '../SimilarCategoryTabs'
import SimilarCategoryTabs from '../SimilarCategoryTabs'

const MarketplaceRolemodelSearch: React.FC = () => {
    const { t } = useTranslation()
    // RoleModels
    const roleModels = useRoleModels()

    const mappedRoleModels = useMemo<SimilarCategoryTabsMap | undefined>(() => {
        if (!roleModels?.collection) return undefined
        const unsorted = roleModels.collection.reduce((map: SimilarCategoryTabsMap, talent) => {
            const key = t(`talent.position.${talent.metas.fieldPosition}`)
            return {
                ...map,
                [key]: [
                    ...(map[key] ?? []),
                    talent,
                ],
            }
        }, {})
        return {
            [t('talent.position.goal')] : unsorted[t('talent.position.goal')],
            [t('talent.position.defensive')] : unsorted[t('talent.position.defensive')],
            [t('talent.position.midfield')] : unsorted[t('talent.position.midfield')],
            [t('talent.position.offensive')] : unsorted[t('talent.position.offensive')],
        }
    }
    , [roleModels])

    return (
        <SimilarCategoryTabs
            title={t('marketplace.whatTypeOfPlayer')}
            tabs={mappedRoleModels}
        />
    )
}

export default MarketplaceRolemodelSearch
