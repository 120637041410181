import { useMemo } from 'react'

import type { User } from '../../lib/apollo/types'
import { useRelationshipsQuery } from '../../lib/apollo/types'
import { useUser } from '../../providers/Auth/hooks'

import { UserRelationship } from './types'

const useRelationship = (user?: User): UserRelationship | null => {
    const me = useUser()
    const { data } = useRelationshipsQuery({ skip: !user, fetchPolicy: 'cache-only', variables: { userId: me.user.id } })

    return useMemo(() => {
        if (!data || !user) return null
        const isFollower = data.follower?.collection.find(u => u.id === user.id)
        const iFollow = data.followed?.collection.find(u => u.id === user.id)

        if (isFollower && iFollow) return UserRelationship.Friend
        if (isFollower) return UserRelationship.Follower
        if (iFollow) return UserRelationship.Following
        return UserRelationship.None
    }, [user, data])
}

export default useRelationship
