import type { PositionSide } from '@ionic/core'
import { IonCard, IonCardContent, IonCardTitle, IonPopover, useIonRouter } from '@ionic/react'
import clsx from 'clsx'
import type { CSSProperties } from 'react'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { createContext } from 'use-context-selector'

import Button from '../../components/Button'
import { useUpdateUserMetaDataMutation, useUserMetaDataQuery } from '../../lib/apollo/types'
import { useUser } from '../Auth/hooks'

import './style.scss'

export type OnboardingProviderType = {
    mount: (el: OnboardingElement) => void
    unmount: (id: string) => void
    finish: (id: string) => void
    current: OnboardingElement | null
}

interface OnboardingOverlay {
    type: 'overlay'
    id: string
    side: PositionSide
    title: string
    description: string | JSX.Element
    buttonText?: string
    sort: number
    round: boolean
}

interface OnboardingModal {
    type: 'modal'
    id: string
    sort: number
}

type OnboardingElement = OnboardingModal | OnboardingOverlay

const Onboarding = createContext<OnboardingProviderType>({
    mount: () => {},
    unmount: () => {},
    finish: () => {},
    current: null,
})

const useOnboarding = (): OnboardingProviderType => {
    const user = useUser()
    const { data: userMetaData } = useUserMetaDataQuery({
        variables: {
            userId: user?.user?.id ?? '',
        },
        skip: !user?.user?.id,
    })

    const [updateUserMetaData] = useUpdateUserMetaDataMutation()

    const [queue, setQueue] = useState<OnboardingElement[]>([])
    const [current, setCurrent] = useState<OnboardingElement | null>(null)

    const timeoutRef = useRef<NodeJS.Timeout | null>(null)

    const mount = useCallback((el: OnboardingElement) => {
        setQueue((q) => {
            if(!q.some(ele => el.id === ele.id)) {
                return [...q, el].sort((e1, e2) => e1.sort - e2.sort)
            }
            return q
        })
    }, [setQueue])

    const unmount = useCallback((id: string) => {
        setQueue((q) => [...q].filter(el => id !== el.id))
    }, [setQueue])

    const finish = useCallback(async (id: string) => {

        if (!userMetaData?.userMetaData?.user?.data?.behavior) {
            return
        }
        await updateUserMetaData({
            variables: {
                input: {
                    ...userMetaData.userMetaData.user.data,
                    behavior: {
                        ...userMetaData.userMetaData.user.data.behavior,
                        onboardingDone: [...(userMetaData.userMetaData.user.data.behavior?.onboardingDone ?? []).filter(el => el !== id), id],
                    },
                },
            },
        })
    }, [queue, updateUserMetaData, userMetaData])

    useEffect(() => {
        if(!userMetaData?.userMetaData?.user?.data?.behavior) return
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current)
        }
        timeoutRef.current = setTimeout(() => {
            const filteredQueue = queue.sort((e1, e2) => e1.sort - e2.sort).filter(el => !(userMetaData?.userMetaData?.user?.data?.behavior?.onboardingDone ?? []).some(l => l === el.id))
            setCurrent(userMetaData?.userMetaData?.user?.data?.behavior ? (filteredQueue[0] ?? null) : null)
        }, 150)
    }, [userMetaData?.userMetaData?.user?.data?.behavior, queue])

    return { mount, current, unmount, finish }
}

export const OnboardingProvider: React.FC = ({ children }) => {
    const onboarding = useOnboarding()
    const router = useIonRouter()
    const { t } = useTranslation()
    const [showOverlay, setShowOverlay] = useState(false)
    const [holeStyle, setHoleStyle] = useState<CSSProperties>({})
    const popoverRef = useRef<HTMLIonPopoverElement>(null)
    const onboardingOverlay = useMemo<OnboardingOverlay | null>(() => {
        if (onboarding.current?.type === 'overlay') {
            return onboarding.current
        }

        return null
    }, [onboarding.current])

    useEffect(() => {
        setHoleStyle({ width: 0, height: 0 })
        window.setTimeout(() => {
            if (onboardingOverlay?.type === 'overlay') {
                (document.querySelector('.ion-page:not(ion-app):not(.ion-page-hidden) .content') as HTMLIonContentElement).scrollToPoint(0, (document.getElementById(onboardingOverlay.id)?.getBoundingClientRect().top || 75) - 75, 300)
                setTimeout(() => {
                    const target: HTMLElement | null = document.getElementById(onboardingOverlay.id)
                    setShowOverlay(true)
                    const style: CSSProperties = { top: target?.getBoundingClientRect().top, left: target?.getBoundingClientRect().left, width: target?.getBoundingClientRect().width, height: target?.getBoundingClientRect().height }
                    setHoleStyle(style)
                    popoverRef.current?.present({ target } as MouseEvent)
                }, 450)
            } else {
                setShowOverlay(false)
            }
        }, 600)
    }, [onboardingOverlay])

    useEffect(() => {
        popoverRef.current?.dismiss()
    }, [router.routeInfo.pathname])

    return (
        <Onboarding.Provider
            value={onboarding}
        >
            {children}
            {(!!onboardingOverlay && showOverlay ) && (
                <div
                    className='explain-overlay'
                >
                    <div
                        className={clsx('explain-overlay__hole', { round: onboardingOverlay.round })}
                        style={holeStyle}
                    >
                        <IonPopover
                            className='explain-overlay__hole__popover'
                            arrow
                            backdropDismiss={false}
                            showBackdrop={false}
                            side={onboardingOverlay.side}
                            alignment='center'
                            ref={popoverRef}
                        >
                            <IonCard>
                                <IonCardTitle>{onboardingOverlay.title}</IonCardTitle>
                                <IonCardContent>{onboardingOverlay.description}</IonCardContent>
                                <div
                                    className='explain-overlay__backdrop__card__footer'
                                >
                                    <Button
                                        size='small'
                                        onClick={() => {
                                            onboarding.finish(onboardingOverlay?.id ?? '')
                                            popoverRef.current?.dismiss()
                                        }}
                                    >
                                        {onboardingOverlay.buttonText ?? t('buttons.gotIt')}
                                    </Button>
                                </div>
                            </IonCard>
                        </IonPopover>
                    </div>
                </div>
            )}
        </Onboarding.Provider>
    )
}

export default Onboarding
