import type { ReactNode } from 'react'
import React from 'react'

import type { Meta } from '../../lib/apollo/types'
import { useUser } from '../../providers/Auth/hooks'
import InfiniteScroll from '../InfiniteScroll'

type ChildrenAlwaysRender = (collection: any[] | undefined) => ReactNode
type Children = (collection: any[]) => ReactNode

type InfiniteScrollWithHookProps = {
    lazyHook: any,
    children: ChildrenAlwaysRender | Children,
    variables?: Record<string, unknown>,
    placeholder?: ReactNode,
    onLoaded?: (collection: any[]) => void,
    skip?: boolean,
    alwaysRender?: boolean
}

type CollectionResponse = {
    collection: any[],
    meta: Meta
}

const InfiniteScrollWithHook: React.FC<InfiniteScrollWithHookProps> = ({ alwaysRender = false, skip = false, onLoaded, placeholder = null, variables = {}, lazyHook, children }) => {
    const user = useUser()

    const [fetch, { data, fetchMore }] = lazyHook({
        fetchPolicy: 'network-only',
    })

    const rsp = data ? Object.values(data)[0] as CollectionResponse : undefined

    return (
        <InfiniteScroll
            key={lazyHook.toJSON}
            skip={skip}
            disabled={(rsp && (+(rsp.meta.range?.total ?? 0) <= rsp.collection?.length)) ?? false}
            onMount={async (start, limit) => {
                fetch({
                    variables: {
                        userId: user.user.id,
                        queryParams: `&start=${start}&limit=${limit}`,
                        ...variables,
                    },
                })
            }}
            onLoadMore={async (start, limit) => {
                const r = await fetchMore({
                    variables: {
                        userId: user.user.id,
                        queryParams: `&start=${start}&limit=${limit}`,
                        ...variables,
                    },
                })

                onLoaded?.(r?.collection)
            }}
        >
            { alwaysRender
                ? ((children as ChildrenAlwaysRender)(rsp?.collection))
                : ((rsp ? (children as Children)(rsp.collection) : placeholder))}
        </InfiniteScroll>
    )
}

export default InfiniteScrollWithHook
