import { createContext, useContextSelector } from 'use-context-selector'

import type { PerformanceVote, Talent } from '../../../../lib/apollo/types'
import { PermissionLevel } from '../../../../lib/apollo/types'
import type { TalentCardType, TalentType } from '../../TalentCardTypes'

export type TalentCardContextType = {
    type?: TalentCardType
    talent?: Talent,
    similarTalent?: Talent,
    talentType?: TalentType,
    onClick?: () => void,
    routerLink?: string,
    networkId?: string
    contextMenuItems?: JSX.Element
    preview?: boolean
    shared?: boolean
    permissionLevel?: PermissionLevel
    performance?: PerformanceVote
}

export const TalentCardContext = createContext<TalentCardContextType>({
    type: 'medium',
    talentType: undefined,
    onClick: () => {},
    routerLink: '',
    networkId: undefined,
    talent: undefined,
    similarTalent: undefined,
    contextMenuItems: undefined,
    preview: false,
    shared: false,
    permissionLevel: PermissionLevel.None,
})

export const useTalentCardContext = (): TalentCardContextType => useContextSelector(TalentCardContext, ctx => ctx)
