import clsx from 'clsx'

import LogoWithText from '../../../../assets/img/logo_with_text_white.svg'
import { useTalentCardContext } from '../Context'
import TalentCardPaperSvg from '../TalentCardPaperSvg'

import './style.scss'

interface TalentCardPaperProps {
    className?: string,
    isBackground?: boolean
}

const TalentCardPaper: React.FC<TalentCardPaperProps> = ({ isBackground, className, children }) => {
    const { onClick } = useTalentCardContext()

    return (
        <div
            className={clsx(className, 'talentcard-paper', { 'talentcard-paper--is-background': isBackground })}
        >
            <TalentCardPaperSvg
                isBackground={isBackground}
            />

            <div
                tabIndex={-1}
                role='button'
                onKeyDown={(e) => { if (e.key === 'Enter') onClick?.() }}
                onClick={onClick}
                className='talentcard-paper__overlay'
            >
                { children }
            </div>

            <img
                alt='Logo'
                src={LogoWithText}
                className='talentcard-paper__logo'
            />
        </div>
    )
}

export default TalentCardPaper
