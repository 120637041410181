import { Capacitor } from '@capacitor/core'
import { IonFooter, IonModal } from '@ionic/react'
import clsx from 'clsx'
import { closeOutline } from 'ionicons/icons'
import type { ReactNode } from 'react'
import { forwardRef } from 'react'

import isIOS from '../../lib/isIOS'
import Button from '../Button'
import Content from '../Content'
import Header from '../Header'

interface ModalI {
    modalTitle: string | JSX.Element
    onClose?: () => void
    footer?: ReactNode
    size?: 'fullscreen' | 'large' | 'alert' | 'default'
}

type ModalProps = React.ComponentProps<typeof IonModal> & ModalI

const Modal: React.FC<ModalProps> = forwardRef(({ onClose, modalTitle, footer, size = 'fullscreen', children, ...modalProps }, ref) => (
    <IonModal
        {...modalProps}
        ref={ref}
        className={clsx(`${modalProps.className} modal--${size}`, { 'modal--safari-fix': isIOS && !Capacitor.isNativePlatform() })}
    >
        <Header
            isModal
            buttonLeft='none'
            title={modalTitle}
        >
            {onClose && (
                <Button
                    icon={closeOutline}
                    onClick={onClose}
                />
            )}
        </Header>

        {size !== 'alert' ? (
            <Content
                fullscreen
            >
                { children }
            </Content>
        ) : (
            <div
                className='inner-content'
            >
                {children}
            </div>
        )}

        {footer && (
            <IonFooter>
                { footer }
            </IonFooter>
        )}
    </IonModal>
))

export default Modal
