import { useMemo } from 'react'

import type { Talent } from '../../../lib/apollo/types'

export const PREVIEW_THRESHOLD = 1 // NUMBER OF VOTES REQUIRED TO SHOW TALENTCARD

const useIsPreviewMode = (talent?: Talent): boolean => useMemo(() => {
    // isScoutcard?
    if (!talent?.personification) {
        return false
    }
    return +(talent.rateCount ?? '0') < PREVIEW_THRESHOLD
}, [talent])

export default useIsPreviewMode
