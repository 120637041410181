import { IonGrid, IonRow, useIonRouter } from '@ionic/react'
import { personAdd, shirt, shirtOutline } from 'ionicons/icons'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import Button from '../../../components/Button'
import Buttons from '../../../components/Buttons'
import NetworkCardSmall from '../../../components/Network/NetworkCard/NetworkCardSmall/NetworkCardSmall'
import type { Network } from '../../../lib/apollo/types'
import {
    NetworkRole,
    NetworkState,
    useApplyNetworkWithTalentMutation,
    useIsTalentInNetworkQuery,
    useListMyPersonificationsQuery,
} from '../../../lib/apollo/types'
import useNetworkInvite from '../../../lib/hooks/useNetworkInvite'
import { useUser } from '../../../providers/Auth/hooks'
import useToast from '../../../providers/Toast/hooks'

interface NetworkDetailsProps {
    network: Network
}

const NetworkDetails: React.FC<NetworkDetailsProps> = ({ network }) => {
    const user = useUser()
    const { t } = useTranslation()
    const { push } = useIonRouter()
    const inviteUser = useNetworkInvite(network, false)
    const inviteTalent = useNetworkInvite(network, false, true)
    const [present] = useToast()

    const { data: myPersonifications } = useListMyPersonificationsQuery({
        variables: {
            userId: user.user.id,
        },
    })

    const { data: isInNetwork } = useIsTalentInNetworkQuery({
        variables: {
            networkId: network.id,
            userId: user.user.id,
            talentId: myPersonifications?.listMyPersonifications?.collection[0]?.id ?? '',
        },
        skip: !myPersonifications?.listMyPersonifications?.collection[0]?.id,
    })

    const isAdmin = useMemo(() =>
        network.networkRoles?.some(role => role === NetworkRole.Owner || role === NetworkRole.Mod),
    [network])

    const [applyNetworkWithTalent] = useApplyNetworkWithTalentMutation()
    const applyMyTalent = useCallback(async () => {
        if(!myPersonifications?.listMyPersonifications?.collection[0]?.id) return

        await applyNetworkWithTalent({
            variables: {
                input: {
                    talentId: +myPersonifications.listMyPersonifications.collection[0].id,
                    networkId: +network.id,
                },
            },
        })

        present(t('networks.joinComplete'), 'success')
    }, [user, myPersonifications])

    return (
        <div
            className='network-detail'
        >
            <NetworkCardSmall
                network={network}
            />
            <IonGrid>
                <>
                    {isAdmin && (
                        <IonRow>
                            <Buttons
                                subtitle={t('buttons.network.description')}
                            >
                                <Button
                                    color='secondary'
                                    icon={shirt}
                                    onClick={inviteTalent}
                                >
                                    {t('networks.inviteTalents')}
                                </Button>
                                <Button
                                    icon={personAdd}
                                    onClick={inviteUser}
                                >
                                    {t('networks.addUsers')}
                                </Button>
                                <Button
                                    icon={shirtOutline}
                                    onClick={() => { push(`/home/network/${network.id}/edit/talents/new` ) }}
                                >
                                    {t('networks.createTalent')}
                                </Button>
                            </Buttons>
                        </IonRow>
                    )}
                    {!isAdmin && !!isInNetwork?.isTalentInNetwork.state && isInNetwork.isTalentInNetwork.state !== NetworkState.InNetwork && (
                        <IonRow>
                            <Buttons>
                                <Button
                                    color='secondary'
                                    icon={shirt}
                                    onClick={applyMyTalent}
                                >
                                    {t('networks.applyMyTalent')}
                                </Button>
                            </Buttons>
                        </IonRow>
                    )}
                </>
            </IonGrid>
        </div>
    )
}

export default NetworkDetails
