import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import type { TalentDataView } from '../../../lib/apollo/types'
import { ChartType, TalentPosition, useTalentClassQuery } from '../../../lib/apollo/types'

import type { LayerI } from './Layer'

const useLayers = (talentPosition: TalentPosition, dataView1?: TalentDataView[], dataView2?: TalentDataView[], under16?: boolean): LayerI[] => {
    const { t, i18n } = useTranslation()
    const { data } = useTalentClassQuery({
        variables: {
            id: talentPosition ===  TalentPosition.Goal ? '2' : '1',
        },
    })

    const getTranslation = useCallback((key: string) => {
        if(under16 && i18n.exists(`${key}_u16`)) {
            return t(`${key}_u16`)
        }

        return t(key)
    }, [under16, i18n])

    return useMemo<LayerI[]>(() => {
        if (!data?.talentClass) return []
        return [{
            id: 'global',
            withHover: true,
            withGroupLabels: true,
            values: dataView1?.find(view => view.key === 0)?.values ?? [],
            compareValues: dataView2?.find(view => view.key === 0)?.values ?? [],
            translations: dataView1?.find(view => view.key === 0)?.values?.map((_, i) => getTranslation(`i18n.class.${talentPosition === TalentPosition.Goal ? 'goalie' : 'player'}.scalar_${i}`)) ?? [],
            translationKeys: dataView1?.find(view => view.key === 0)?.values?.map((_, i) => `i18n.class.${talentPosition === TalentPosition.Goal ? 'goalie' : 'player'}.scalar_${i}`) ?? [],
            type: ChartType.Radar,
        }, ...(data?.talentClass.dataViews || [])
            .filter(dataView => +dataView.id >= 0)
            .map(dataView => ({
                id: dataView.id,
                withHover: dataView.withLabelHover ?? false,
                withGroupLabels: dataView.withGroupLabels ?? false,
                values: dataView1?.find((dv) => dv.key === +dataView.id)?.values ?? [],
                compareValues: dataView2?.find((dv) => dv.key === +dataView.id)?.values ?? [],
                translations: dataView.voteScalarGroups[0].scalars.map(scalar => t(scalar.caption)),
                translationKeys: dataView.voteScalarGroups[0].scalars.map(scalar => scalar.caption),
                type: dataView.chartType,
            })),
        ]
    }, [data?.talentClass, dataView1, dataView2, t, talentPosition, getTranslation])
}

export default useLayers
