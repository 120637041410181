import { useTranslation } from 'react-i18next'

import ItemList from '../../../components/ItemList'
import TalentCard from '../../../components/NewTalentCard'
import type { Talent } from '../../../lib/apollo/types'

interface MarketplaceKeywordSearchProps {
    talents?: Talent[]
}

const MarketplaceKeywordSearch: React.FC<MarketplaceKeywordSearchProps> = ({ talents }) => {
    const { t } = useTranslation()

    if (!talents) {
        return (
            <h3
                className='ion-margin-top ion-text-center'
            >
                { t('marketplace.searchHint') }
            </h3>
        )
    }

    if (talents.length === 0) {
        return (
            <h3
                className='ion-margin-top ion-text-center'
            >
                { t('marketplace.searchNoResults') }
            </h3>
        )
    }

    return (
        <ItemList>
            {talents.map(talent => (
                <TalentCard
                    type='listItem'
                    key={talent.id}
                    talent={talent}
                />
            ))}
        </ItemList>
    )

}

export default MarketplaceKeywordSearch
