import {
    IonBadge,
    IonSkeletonText,
    useIonViewWillLeave,
} from '@ionic/react'
import React, { useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'

import Activity from '../../components/Activity'
import VoteActivitySkeleton from '../../components/Activity/VoteActivity/VoteActivitySkeleton'
import Content from '../../components/Content'
import Divider from '../../components/Divider'
import Header from '../../components/Header'
import InfiniteScroll from '../../components/InfiniteScroll'
import ItemList from '../../components/ItemList'
import Page from '../../components/Page'
import type { ActivityFragment, UserMetaDataInput } from '../../lib/apollo/types'
import {
    NewActivitiesDocument,
    useActivitiesLazyQuery,
    UserMetaDataDocument,
    useUpdateUserMetaDataMutation,
    useUserMetaDataQuery,
} from '../../lib/apollo/types'
import { useUser } from '../../providers/Auth/hooks'

import './style.scss'

interface ActivitiesPageProps {
    newActivities?: ActivityFragment[]
}

const Activities: React.FC<ActivitiesPageProps> = ({ newActivities }) => {
    const contentRef = useRef<HTMLIonContentElement | null>(null)
    const { t } = useTranslation()
    const user = useUser()

    const { data: userMetaData } = useUserMetaDataQuery({
        variables: {
            userId: user.user.id,
        },
    })

    const isoDate = useMemo(() => {
        const aLV = userMetaData?.userMetaData?.user?.data?.activitiesLastViewed
        if (aLV) return aLV
        const createdTimeStamp = userMetaData?.userMetaData?.user?._created
        if (createdTimeStamp) return new Date(createdTimeStamp).toISOString()
        return ''
    },[userMetaData, newActivities])

    const [fetchActivities, { data: activities, fetchMore }] = useActivitiesLazyQuery({
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-first',
    })

    const [updateUserMetaData] = useUpdateUserMetaDataMutation()
    useIonViewWillLeave(() => {
        if (!userMetaData?.userMetaData) return
        // eslint-disable-next-line @typescript-eslint/naming-convention
        const { __typename, ...userMetaDataRest } = userMetaData.userMetaData.user?.data as UserMetaDataInput & { __typename: string }

        const lastViewed = new Date().toISOString()

        if (contentRef?.current) {
            contentRef.current.scrollToTop()
        }

        updateUserMetaData({
            variables: {
                input: {
                    ...userMetaDataRest,
                    activitiesLastViewed: lastViewed,
                },
            },
            refetchQueries: [{
                query: UserMetaDataDocument,
                variables: {
                    userId: user.user.id,
                },
            }, {
                query: NewActivitiesDocument,
                variables: {
                    isoDate: lastViewed,
                },
            }],
        })
    }, [userMetaData?.userMetaData, updateUserMetaData])

    const combinedActivities: (ActivityFragment & { isNew?: boolean })[] = [
        ...(newActivities ?? []).map(a => ({ isNew: true, ...a })),
        ...(activities?.activities.collection ?? []),
    ]

    const hasActivities = (newActivities?.length ?? 0) > 0 || (!activities || (activities?.activities.collection?.length ?? 0) > 0)

    return (
        <Page
            trackingTitle='activities'
            className='activity-page'
        >
            <Header
                buttonLeft='menu'
                title={t('title.activities')}
            />
            <Content
                fullscreen
                ref={contentRef}
                scrollEvents
            >
                {isoDate && (
                    <InfiniteScroll
                        disabled={(activities && (activities.activities.meta.range.total <= activities.activities.collection?.length)) ?? false}
                        onMount={async (_, limit) => {
                            fetchActivities({
                                variables: {
                                    queryParams: `&from=${isoDate}&limit=${limit}`,
                                },
                            })
                        }}
                        onLoadMore={async (nextPage, limit) => {
                            await fetchMore({
                                variables: {
                                    queryParams: `&from=${isoDate}&start=${nextPage}&limit=${limit}`,
                                },
                            })
                        }}
                    >
                        {hasActivities && (
                            <ItemList
                                className='activity-page__item-wrapper'
                            >
                                {combinedActivities.length > 0 ? combinedActivities
                                    .map((activity, i) => {
                                        const date = new Date(activity.date)
                                            .toLocaleDateString(navigator.language, {
                                                year: '2-digit',
                                                month: '2-digit',
                                                day: '2-digit',
                                            })

                                        const prevDate = i !== 0 ? new Date(combinedActivities[i - 1].date)
                                            .toLocaleDateString(navigator.language, {
                                                year: '2-digit',
                                                month: '2-digit',
                                                day: '2-digit',
                                            }) : undefined

                                        const differentDates = prevDate !== date

                                        return (
                                            <React.Fragment
                                                key={activity.id}
                                            >
                                                {(!prevDate || differentDates) && (
                                                    <div
                                                        className='activity-page__date'
                                                    >
                                                        { prevDate && (<Divider /> )}
                                                        <h2>
                                                            {date}
                                                        </h2>
                                                    </div>
                                                )}
                                                <div
                                                    className='activity-page__activity-wrapper'
                                                >
                                                    { activity.isNew && (
                                                        <IonBadge
                                                            className='activity-page__badge'
                                                        >
                                                            {' '}
                                                        </IonBadge>
                                                    )}
                                                    <Activity
                                                        activity={activity}
                                                    />
                                                </div>
                                            </React.Fragment>
                                        )
                                    }) : (
                                    <>
                                        <h2
                                            className='activity-page__date'
                                        >
                                            <IonSkeletonText
                                                style={{
                                                    width: '96px',
                                                    height: '26px',
                                                }}
                                            />
                                        </h2>
                                        <div
                                            className='activity-page__activity-wrapper'
                                        >
                                            <VoteActivitySkeleton />
                                        </div>
                                        <div
                                            className='activity-page__activity-wrapper'
                                        >
                                            <VoteActivitySkeleton />
                                        </div>
                                        <h2
                                            className='activity-page__date'
                                        >
                                            <IonSkeletonText
                                                style={{
                                                    width: '96px',
                                                    height: '26px',
                                                }}
                                            />
                                        </h2>
                                        <div
                                            className='activity-page__activity-wrapper'
                                        >
                                            <VoteActivitySkeleton />
                                        </div>
                                    </>
                                )}
                            </ItemList>
                        )}
                    </InfiniteScroll>
                )}

                { !hasActivities && (
                    <h3
                        className='ion-text-center'
                    >
                        {t('activities.noActivities')}
                    </h3>
                )}
            </Content>
        </Page>
    )
}

export default Activities
