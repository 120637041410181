import type { URLOpenListenerEvent } from '@capacitor/app'
import { App } from '@capacitor/app'
import { Capacitor } from '@capacitor/core'
import { useIonRouter } from '@ionic/react'
import { useEffect, useState } from 'react'

import { FE_URL } from '../../lib/apollo/config'

const DeeplinkRouter: React.FC = ({ children }) => {
    const [render, setRender] = useState<boolean>(!Capacitor.isNativePlatform())
    const { push } = useIonRouter()

    useEffect(() => {
        if(!render) {
            App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
                const path = event.url.split(FE_URL).pop()
                if (path) {
                    push(path, 'root', 'replace')
                }
            })

            setTimeout(() => {
                setRender(true)
            }, 150)
        }
    }, [])

    return !render ? null : (
        <>
            {children}
        </>
    )
}

export default DeeplinkRouter
