import { Preferences } from '@capacitor/preferences'
import { useIonRouter } from '@ionic/react'
import { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router'
import { Redirect } from 'react-router-dom'

import { useUserMetaDataLazyQuery } from '../../lib/apollo/types'
import STORAGE_KEYS from '../../lib/storageKeys'

import { useSignInWithToken, signOut, useUser } from './hooks'

const publicRoutes = ['/login', '/register', '/confirm', '/s/', '/forgotpassword']

const date = new Date('2022-04-28T19:06:07.051Z')

const AuthProvider: React.FC = ({ children }) => {
    const [isInit, setIsInit] = useState(false)
    const [isUserMetaDataChecked, setIsUserMetaDataChecked] = useState(false)
    const [signinWithToken] = useSignInWithToken()

    const user = useUser()
    const [userMetaDataQuery, { data: userMetaData }] = useUserMetaDataLazyQuery()
    const history = useHistory()
    const redirectUrl = useRef<string>('')

    useEffect(() => {
        if(user?.token) {
            userMetaDataQuery({
                variables: {
                    userId: user.user.id,
                },
            })
        }
    }, [user])

    useEffect(() => {
        if(userMetaData?.userMetaData?.user) {
            setIsUserMetaDataChecked(true)
        }
    }, [userMetaData])

    useEffect(() => {
        if (isInit) return

        const checkLogin = async () => {
            const { value: token } = await Preferences.get({ key: STORAGE_KEYS.API.LOGIN_TOKEN })
            if (!token || token === 'undefined') {
                redirectUrl.current = history.location.pathname
            } else {
                try {
                    await signinWithToken({ variables: { input: { token } } })
                } catch(e) {
                    await signOut()
                }
            }
            setIsInit(true)
        }

        checkLogin()
    }, [isInit])

    const { routeInfo: { pathname } } = useIonRouter()

    if (!isInit) return null

    const isPublicPage = publicRoutes.some(r => pathname.startsWith(r))
    const hasMetaData =
        !!userMetaData?.userMetaData?.user?.data?.behavior?.termsAcceptedAt
        && +new Date(userMetaData.userMetaData.user.data.behavior.termsAcceptedAt) >= +date
    if(!user?.user && !isPublicPage) {
        return (
            <Redirect
                to={{
                    pathname: '/login',
                    state: { referrer: pathname },
                }}
            />
        )
    }

    if(user?.user && isUserMetaDataChecked && !hasMetaData && pathname !== '/onboarding') {
        return (
            <Redirect
                to='/onboarding'
            />
        )
    }

    if(user?.user && isUserMetaDataChecked && hasMetaData && pathname === '/onboarding') {
        return (
            <Redirect
                to='/home'
            />
        )
    }

    return (
        <>
            {children}
        </>
    )
}

export default AuthProvider
