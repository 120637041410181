import { IonText, useIonRouter } from '@ionic/react'
import { trashBin } from 'ionicons/icons'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useRouteMatch } from 'react-router'

import Content from '../../components/Content'
import ContextMenuItem from '../../components/ContextMenu/ContextMenuItem/ContentMenuItem'
import Header from '../../components/Header'
import InfiniteScrollWithHook from '../../components/InfiniteScrollWithHook'
import ItemList from '../../components/ItemList'
import TalentCard from '../../components/NewTalentCard'
import Page from '../../components/Page'
import { WATCHLIST_CAPTION } from '../../components/Playlist/PlayListConstants'
import type { Playlist as PlaylistType, Talent } from '../../lib/apollo/types'
import {
    PlaylistDocument,
    PlaylistsDocument,
    PlaylistTalentsDocument,
    usePlaylistQuery, usePlaylistTalentsLazyQuery,
    useRemoveTalentsFromPlaylistMutation,
} from '../../lib/apollo/types'
import { useUser } from '../../providers/Auth/hooks'
import useToast from '../../providers/Toast/hooks'

import PlaylistContextMenu from './PlaylistContextMenu'

interface PlaylistParams {
    id: string
}

const Playlist: React.FC = () => {
    const { push } = useIonRouter()
    const { t } = useTranslation()
    const [present] = useToast()
    const me = useUser()
    const { params: { id } } = useRouteMatch<PlaylistParams>()

    const [removeTalentsFromPlaylist] = useRemoveTalentsFromPlaylistMutation({
        onCompleted: () => {
            present(t('playlist.talentRemoved'), 'success')
        },
    })

    const { data: { playlist } = {} } = usePlaylistQuery({
        skip: !id,
        variables: {
            userId: me.user.id,
            id,
        },
    })

    useEffect(() => {
        // 404 Redirect
        if (playlist === null) {
            push('/playlists', 'back')
        }
    }, [playlist])

    const onContextMenuClick = (talent: Talent) => () => {
        removeTalentsFromPlaylist({
            variables: {
                id,
                talents: { _links: { objectByPlaylist: { url: [`/api/object/${talent.id}`] } } },
            },
            refetchQueries: [{
                query: PlaylistsDocument,
                variables: {
                    userId: me.user.id,
                },
            }, {
                query: PlaylistDocument,
                variables: {
                    userId: me.user.id,
                    id,
                },
            }, {
                query: PlaylistTalentsDocument,
                variables: {
                    userId: me.user.id,
                    id,
                },
            }],
        })
    }

    return (
        <Page
            trackingTitle='playlist'
        >
            <Header
                title={t('title.playlist')}
            >
                <PlaylistContextMenu
                    playlist={playlist as PlaylistType}
                    redirectAfterDelete
                />
            </Header>
            <Content
                fullscreen
            >
                <div>
                    <h2
                        className='ion-margin-bottom'
                    >
                        { playlist?.caption === WATCHLIST_CAPTION ? t('playlist.watchlist') : playlist?.caption }
                    </h2>
                    {playlist?.public && (
                        <IonText
                            className='ion-margin-top'
                            color='medium'
                        >
                            <h3>
                                { t('playlist.isPublic')}
                            </h3>
                        </IonText>
                    )}
                </div>

                <InfiniteScrollWithHook
                    lazyHook={usePlaylistTalentsLazyQuery}
                    placeholder={(
                        <ItemList>
                            <TalentCard
                                type='listItem'
                            />
                            <TalentCard
                                type='listItem'
                            />
                            <TalentCard
                                type='listItem'
                            />
                            <TalentCard
                                type='listItem'
                            />
                        </ItemList>
                    )}
                    variables={{
                        id,
                    }}
                >
                    {(playlistTalents: Talent[]) => {

                        if (playlistTalents.length === 0) {
                            return (
                                <h3
                                    className='ion-text-center'
                                >
                                    {t('playlist.noTalents')}
                                </h3>
                            )
                        }

                        return (
                            <ItemList>
                                { playlistTalents?.map(talent => (
                                    <TalentCard
                                        type='listItem'
                                        key={talent.id}
                                        talent={talent}
                                        contextMenuItems={(
                                            <ContextMenuItem
                                                label={t('playlist.removeTalent')}
                                                icon={trashBin}
                                                onClick={onContextMenuClick(talent)}
                                            />
                                        )}
                                    />
                                ))}
                            </ItemList>
                        )
                    }}
                </InfiniteScrollWithHook>
            </Content>
        </Page>
    )
}

export default Playlist
