import type { ReactNode } from 'react'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useContextSelector } from 'use-context-selector'

import { AppContext } from '../../components/AppState'
import { AppActionType } from '../../components/AppState/types'
import Content from '../../components/Content'
import Page from '../../components/Page'
import SearchHeader from '../../components/Search/SearchHeader'
import Segment from '../../components/Segment'
import { useJmtNetworksQuery, useSearchNetworkTalentsLazyQuery } from '../../lib/apollo/types'
import { useUser } from '../../providers/Auth/hooks'

import MarketplaceSearchHistory from './History'
import MarketplaceKeywordSearch from './Keyword'
import MarketplacePlayertypesSearch from './Similar/PlayerTypes'
import MarketplaceRolemodelSearch from './Similar/RoleModel'

enum TABS {
    RoleModels,
    PrefapSearch,
    RecentSearches,
    Search,
}

const MarketplaceSearch: React.FC = () => {
    const me = useUser()
    const dispatch = useContextSelector(AppContext, state => state.dispatch)
    const { t } = useTranslation()
    const [searchString, setSearchString] = useState<{ value?: string, event: any }>({ value: '', event: null })

    const networks = useJmtNetworksQuery()

    const marketplaceNetwork = useMemo(() =>
        networks?.data?.jmtNetworks.JMTnetworks.collection.find((network) => network.caption === 'Join.My.Talent.Engine.Marketplace')
    , [networks])

    const [lazySearchTalentQuery, { data: { searchNetworkTalents = undefined } = {} }] = useSearchNetworkTalentsLazyQuery({
        variables: {
            userId: me.user.id,
            query: searchString.value ?? '',
            networkId: `&networkId=${marketplaceNetwork?.id}`,
        },
    })
    // Active Segment
    const [activeSegment, setActiveSegment] = useState(TABS.RoleModels)

    const onKeyDown = useCallback((e) => {
        if (e.keyCode === 13) {
            lazySearchTalentQuery({
                variables: {
                    userId: me.user.id,
                    query: searchString?.value ?? '',
                    networkId: marketplaceNetwork?.id ?? '',
                },
            })
            setActiveSegment(() => TABS.Search)
        }
    }, [searchString?.value, marketplaceNetwork?.id])

    const tabs = useMemo(() => Object.values(TABS)
        .filter((key: number | string) => typeof key === 'number')
        .map(tab => ({
            tab: t(`marketplace.search.${tab}`),
            disabled: tab === TABS.Search && !searchNetworkTalents,
        }))
    , [t, searchNetworkTalents])

    const getChild = (): ReactNode => {
        switch (activeSegment) {
            case TABS.RoleModels: return <MarketplaceRolemodelSearch />
            case TABS.PrefapSearch: return <MarketplacePlayertypesSearch />
            case TABS.RecentSearches: {
                return (
                    <MarketplaceSearchHistory
                        onClick={value => {
                            setSearchString(() => ({ value, event: null }))
                            setActiveSegment(() => TABS.Search)
                            lazySearchTalentQuery({
                                variables: {
                                    userId: me.user.id,
                                    query: value,
                                    networkId: marketplaceNetwork?.id ?? '',
                                },
                            })
                        }}
                    />
                )
            }
            case TABS.Search: return (
                <MarketplaceKeywordSearch
                    talents={!searchString.value ? undefined : searchNetworkTalents?.collection}
                />
            )
            default: return null
        }
    }

    useEffect(() => {
        if (!searchString?.value || !searchNetworkTalents?.collection || searchNetworkTalents?.collection.length === 0) return
        dispatch({
            type: AppActionType.MarketplaceSearchHistoryAdd,
            payload: {
                type: 'search',
                data: searchString.value,
            },
        })
    }, [searchNetworkTalents?.collection])

    return (
        <Page
            trackingTitle='marketplace-search'
            className='marketplace-search'
        >
            <SearchHeader
                backButtonLabel={t('marketplace.browse')}
                defaultHref='/marketplace'
                onKeyDown={onKeyDown}
                setSearch={setSearchString}
                search={searchString}
            />
            <Content
                fullscreen
            >
                <Segment
                    value={activeSegment.toString()}
                    onIonChange={(e) => { setActiveSegment(() => +(e.detail.value ?? '0')) }}
                    tabs={tabs}
                />

                {getChild()}
            </Content>
        </Page>
    )
}

export default MarketplaceSearch
