import { personAdd } from 'ionicons/icons'
import { useTranslation } from 'react-i18next'

import type { Network } from '../../../lib/apollo/types'
import useNetworkInvite from '../../../lib/hooks/useNetworkInvite'
import ContextMenuItem from '../../ContextMenu/ContextMenuItem/ContentMenuItem'

interface NetworkAddUsersContextMenuItemProps {
    network: Network,
    personalSpace?: boolean,
}

const NetworkAddUsersContextMenuItem: React.FC<NetworkAddUsersContextMenuItemProps> = ({ network, personalSpace = false }) => {
    const { t } = useTranslation()

    const invite = useNetworkInvite(network, personalSpace)

    return (
        <ContextMenuItem
            icon={personAdd}
            label={t('networks.addUsers')}
            onClick={invite}
        />
    )
}

export default NetworkAddUsersContextMenuItem
