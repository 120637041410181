import { Capacitor } from '@capacitor/core'
import { useIonRouter } from '@ionic/react'
import * as IoniCons from 'ionicons/icons'
import OneSignal from 'onesignal-cordova-plugin'
import { useEffect, useMemo } from 'react'

import { useUser } from '../../providers/Auth/hooks'
import useToast from '../../providers/Toast/hooks'
import type { ActivityFragment } from '../apollo/types'
import { useNewActivitiesQuery, useRelationshipsLazyQuery, UserType, useUserMetaDataQuery } from '../apollo/types'

type GlobalHooksReturnType = {
    newActivities: ActivityFragment[] | undefined
    newForeignActivities: ActivityFragment[] | undefined
}

const useGlobalHooks = (): GlobalHooksReturnType => {
    const user = useUser()
    const [present] = useToast()
    const { push } = useIonRouter()
    const { data: userMetaData } = useUserMetaDataQuery({ skip: !user, variables: { userId: user?.user.id } })
    const isoDate = useMemo(() => {
        const aLV = userMetaData?.userMetaData?.user?.data?.activitiesLastViewed
        if (aLV) {
            return aLV
        }
        const createdTimeStamp = userMetaData?.userMetaData?.user?._created
        if (createdTimeStamp) {
            return new Date(createdTimeStamp).toISOString()
        }
        return ''
    }, [userMetaData])

    const { data: { newActivities } = {} } = useNewActivitiesQuery({
        pollInterval: 30000,
        skip: !isoDate,
        variables: {
            isoDate,
        },
    })

    const newForeignActivities = useMemo(() => (newActivities ?? []).filter(a => a.userID.toString() !== user.user.id), [newActivities])

    // REFETCH FFF
    const [fetchFFF] = useRelationshipsLazyQuery({ fetchPolicy: 'network-only', variables: { userId: user?.user.id } })
    useEffect(() => {
        if (newForeignActivities?.filter(a => a.type === 'user').length > 0) {
            fetchFFF()
        }
    }, [newForeignActivities])

    useEffect(() => {
        if(!Capacitor.isNativePlatform()) return

        if (userMetaData?.userMetaData?.user?.data?.userType) {
            OneSignal.setAppId('f546689b-3647-4017-9742-059cde042a02')
            OneSignal.setNotificationOpenedHandler((jsonData) => {
                push((jsonData.notification.additionalData as any).target)
            })

            OneSignal.setNotificationWillShowInForegroundHandler((event) => {
                present(event.getNotification().body, 'secondary', {
                    position: 'bottom',
                    header: event.getNotification().title,
                    duration: 0,
                    icon: IoniCons.informationCircleOutline,
                    buttons: (event.getNotification().additionalData as any).target ? [
                        {
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            icon: IoniCons[(event.getNotification().additionalData as any).icon ?? 'arrowRedoOutline'],
                            handler: () => {
                                push((event.getNotification().additionalData as any).target)
                            },
                        },
                    ] : undefined,
                })
            })

            OneSignal.addSubscriptionObserver(event => {
                if (event.to.isSubscribed) {
                    if(user?.user?.id) {
                        OneSignal.setExternalUserId(user.user.id.toString())
                    }
                    if(userMetaData?.userMetaData?.user?.data?.userType) {
                        OneSignal.sendTag('trainer', userMetaData.userMetaData.user.data.userType.includes(UserType.Trainer) ? '1' : '0')
                        OneSignal.sendTag('player', userMetaData.userMetaData.user.data.userType.includes(UserType.Player) ? '1' : '0')
                        OneSignal.sendTag('scout', userMetaData.userMetaData.user.data.userType.includes(UserType.Scout) ? '1' : '0')
                        OneSignal.sendTag('relatives', userMetaData.userMetaData.user.data.userType.includes(UserType.Relatives) ? '1' : '0')
                    }
                }
            })

            OneSignal.getDeviceState((state) => {
                if(state.subscribed && user?.user?.id && userMetaData?.userMetaData?.user?.data?.userType) {
                    OneSignal.setExternalUserId(user.user.id.toString())
                    OneSignal.sendTag('trainer', userMetaData.userMetaData.user.data.userType.includes(UserType.Trainer) ? '1' : '0')
                    OneSignal.sendTag('player', userMetaData.userMetaData.user.data.userType.includes(UserType.Player) ? '1' : '0')
                    OneSignal.sendTag('scout', userMetaData.userMetaData.user.data.userType.includes(UserType.Scout) ? '1' : '0')
                    OneSignal.sendTag('relatives', userMetaData.userMetaData.user.data.userType.includes(UserType.Relatives) ? '1' : '0')
                }
            })
        }
    }, [userMetaData])

    return {
        newActivities,
        newForeignActivities,
    }
}

export default useGlobalHooks
