import { useEffect } from 'react'

import { useTalentQuery } from '../../../lib/apollo/types'
import { useUser } from '../../../providers/Auth/hooks'
import TalentCard from '../../NewTalentCard'

interface TalentQueryContainerProps {
    talentId: string
    type?: 'TalentCardMedium' | 'TalentCardSmall' | 'TalentListItem'
    contextMenuItems?: JSX.Element
    routerLink?: string
    notFoundCallback?: (talentId: string) => any
}

const TalentQueryContainer: React.FC<TalentQueryContainerProps> = ({
    talentId,
    type = 'TalentListItem',
    contextMenuItems,
    routerLink,
    notFoundCallback,
}) => {
    const user = useUser()
    const { data } = useTalentQuery({ variables: { talentId, userId: user.user.id } })

    useEffect(() => {
        if(data?.talent === null || (data && data.talent === undefined)) {
            notFoundCallback?.(talentId)
        }
    }, [data, notFoundCallback])

    switch (type) {
        case 'TalentCardMedium': {
            return (
                <TalentCard
                    talent={data?.talent}
                    contextMenuItems={contextMenuItems}
                />
            )
        }

        case 'TalentCardSmall': {
            return (
                <TalentCard
                    type='small'
                    talent={data?.talent}
                    contextMenuItems={contextMenuItems}
                />
            )
        }

        default: {
            return (
                <TalentCard
                    type='listItem'
                    talent={data?.talent}
                    contextMenuItems={contextMenuItems}
                    routerLink={routerLink}
                />
            )
        }
    }
}

export default TalentQueryContainer
