import { add, bookmark } from 'ionicons/icons'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useContextSelector } from 'use-context-selector'

import type { Talent ,Playlist } from '../../../../lib/apollo/types'
import {
    PlaylistDocument,
    PlaylistsDocument,
    useAddTalentsToPlaylistMutation,
    usePlaylistsQuery,
} from '../../../../lib/apollo/types'
import { useUser } from '../../../../providers/Auth/hooks'
import useToast from '../../../../providers/Toast/hooks'
import Button from '../../../Button'
import ContextMenuItem from '../../../ContextMenu/ContextMenuItem/ContentMenuItem'
import { ContextMenuContext } from '../../../ContextMenu/context'
import Modal from '../../../Modal'
import PlaylistListItem from '../../../Playlist/PlaylistListItem'

interface TalentPlaylistContextMenuItemProps {
    talent: Talent
}

const TalentPlaylistContextMenuItem: React.FC<TalentPlaylistContextMenuItemProps> = ({ talent }) => {
    const { t } = useTranslation()
    const [present] = useToast()
    const me = useUser()

    const [showModal, setShowModal] = useState(false)
    const closeContextMenu = useContextSelector(ContextMenuContext, ctx => ctx.closeContextMenu)

    const [addTalentsToPlaylist] = useAddTalentsToPlaylistMutation({
        onError: (e: any) => {
            if (e.networkError?.statusCode === 409) {
                present(t('playlist.alreadyAdded'), 'warning')
            } else {
                present(t('errors.unknown'),'danger')
            }
        },
        onCompleted: () => {
            present(t('playlist.talentAdded'), 'success')
            setShowModal(() => false)
            closeContextMenu()
        },
    })
    const { data: { playlists } = {} } = usePlaylistsQuery({ variables: { userId: me.user.id } })

    const onClick = useCallback((e) => {
        e.persist()
        e.stopPropagation()
        setShowModal(() => true)
    }, [setShowModal])

    return (
        <>
            <ContextMenuItem
                disableCloseContextMenuOnClick
                onClick={onClick}
                icon={bookmark}
                label={t('playlist.addTalent')}
            />
            <Modal
                onClose={() => {
                    setShowModal(() => false)
                }}
                isOpen={showModal}
                modalTitle={t('playlist.addTalent')}
            >
                <h2>
                    {t('playlist.addTalent')}
                </h2>
                <div>
                    {playlists?.collection.map(playlist => (
                        <PlaylistListItem
                            hideContextMenu
                            onClick={() => {
                                addTalentsToPlaylist({
                                    variables: {
                                        id: playlist.id,
                                        talents: { _links: { objectByPlaylist: { url: [`/api/object/${talent.id}`] } } },
                                    },
                                    refetchQueries: [{
                                        query: PlaylistsDocument,
                                        variables: {
                                            userId: me.user.id,
                                        },
                                    }, {
                                        query: PlaylistDocument,
                                        variables: {
                                            userId: me.user.id,
                                            id: playlist?.id,
                                        },
                                    }],
                                })
                            }}
                            key={playlist.id}
                            playlist={playlist as Playlist}
                        />
                    ))}
                </div>
                <Button
                    icon={add}
                    color='secondary'
                    routerLink={`/playlists/addoredit/new/[${talent.id}]`}
                    expand='block'
                    onClick={() => {
                        setShowModal(() => false)
                        closeContextMenu()
                    }}
                >
                    { t('playlist.add') }
                </Button>
            </Modal>
        </>
    )
}

export default TalentPlaylistContextMenuItem

