import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { SwiperSlide } from 'swiper/react/swiper-react.js'

import TalentCard from '../../../components/NewTalentCard'
import Slider from '../../../components/Slider'
import type { Talent } from '../../../lib/apollo/types'
import { TalentPosition, useJmtNetworksQuery, useSimilarNetworkQuery } from '../../../lib/apollo/types'
import { useUser } from '../../../providers/Auth/hooks'

import PlayerTypeTeaserCard from './PlayerTypeTeaserCard'

interface MarketplaceTeaserProps {
    isSlider?: boolean,
    talent?: Talent
}

const MarketplaceTeaser: React.FC<MarketplaceTeaserProps> = ({ talent, isSlider }) => {
    const { t } = useTranslation()
    const me = useUser()
    const networks = useJmtNetworksQuery()
    const marketplaceNetwork = useMemo(() =>
        networks?.data?.jmtNetworks.JMTnetworks.collection.find((network) => network.caption === 'Join.My.Talent.Engine.Marketplace')
    , [networks])

    const values = useMemo(() => {
        if (!talent) return null
        return talent.dataViews.find(({ key }) => key === 0)?.values
    }, [talent])

    const talentClass = useMemo(() => {
        if (!talent) return undefined
        return talent.metas.fieldPosition ===  TalentPosition.Goal ? '2' : '1'
    }, [talent])

    const { data: talents } = useSimilarNetworkQuery({

        fetchPolicy: 'network-only',
        skip: !marketplaceNetwork?.id || !talentClass || !values,
        variables: {
            userId: me.user.id,
            networkId: marketplaceNetwork?.id ?? '',
            queryString: `&class=${talentClass}&values=${values?.map(n => Math.round(+n))}`,
        },
    })

    const title = (talent?.caption.startsWith('playerTypes.')
        ? t(talent.caption)
        : talent?.caption) ?? ''

    if (isSlider) {
        return (
            <Slider
                freeMode
                title={title}
                link={`/marketplace/similar/${talent?.id}`}
                itemWidth={166}
            >
                {talents?.similarNetwork.collection.map(tal => (
                    <SwiperSlide
                        key={tal.id}
                    >
                        <TalentCard
                            type='small'
                            talent={tal}
                        />
                    </SwiperSlide>
                )) ?? [
                    <SwiperSlide
                        key='preview-1'
                    >
                        <TalentCard
                            type='small'
                        />
                    </SwiperSlide>,
                    <SwiperSlide
                        key='preview-2'
                    >
                        <TalentCard
                            type='small'
                        />
                    </SwiperSlide>,
                    <SwiperSlide
                        key='preview-3'
                    >
                        <TalentCard
                            type='small'
                        />
                    </SwiperSlide>,
                ]}
            </Slider>
        )
    }

    return (
        <PlayerTypeTeaserCard
            talent={talent}
        />
    )
}

export default MarketplaceTeaser
