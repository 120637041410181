import { IonCard, IonSkeletonText, IonText } from '@ionic/react'
import { useMemo } from 'react'
import { Trans } from 'react-i18next'

import type { Activity, UserQuery } from '../../../lib/apollo/types'
import { useJmtNetworksQuery, useUserQuery } from '../../../lib/apollo/types'
import { useUser } from '../../../providers/Auth/hooks'
import FollowButton from '../../Button/FollowButton'
import CustomAvatar from '../../CustomAvatar'
import type { ActivityProps } from '../ActivityProps'
import useHours from '../useHours'

import './style.scss'

const getTargetId = (myAction: boolean, activity: Activity): number => {
    if (myAction) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return +activity.data.split('/').reverse()[0]
    }

    return +activity.userID
}

const getName = (user?: UserQuery): (string | undefined) => {
    if (user?.user?.firstName && user?.user?.lastName) {
        return `${user.user.firstName} ${user.user.lastName}`
    }

    if (user?.user?.firstName) {
        return user.user.firstName
    }

    return 'Anonymous'
}

const FollowActivity: React.FC<ActivityProps> = ({ activity }) => {
    const me = useUser()
    const myAction = activity.userID.toString() === me.user.id.toString()

    const you = getTargetId(myAction, activity)

    const { data: jmtNetworks } = useJmtNetworksQuery()
    const jmtNetworkId = useMemo(() => jmtNetworks?.jmtNetworks.JMTnetworks.collection.find(jn => jn.caption === 'Join.My.Talent.Engine')?.id, [jmtNetworks])

    const { data: userData, loading } = useUserQuery({
        variables: {
            userId: you.toString(),
            meId: me.user.id,
            networkId: jmtNetworkId ?? '0',
        },
        skip: !jmtNetworkId,
    })

    const hours = useHours(activity)

    return (
        <IonCard
            className='follow-activity'
        >
            <CustomAvatar
                className='follow-activity__avatar'
                img={myAction ? me.user.image : userData?.user?.image}
                alt={myAction ? me.user.id : userData?.user?.id}
            />
            <IonText
                className='follow-activity__text'
                color='dark'
            >
                <Trans
                    i18nKey={`activity.${activity.method === 'LINK' ? '' : 'un'}follow.${myAction ? 'you' : 'me'}`}
                    values={{
                        name: getName(userData),
                        date: hours,
                    }}
                    components={{
                        strong: !loading ? <strong /> : (
                            <IonSkeletonText
                                className='follow-activity__skeleton'
                            />
                        ),
                        date: (
                            <IonText
                                className='follow-activity__text--small'
                                color='medium'
                            />
                        ),
                    }}
                />
            </IonText>
            <FollowButton
                showProfileOnFollowed
                user={userData?.user ?? undefined}
                size='small'
                fill='solid'
                color='secondary'
            />
        </IonCard>
    )
}

export default FollowActivity
