import { ApolloError, useApolloClient } from '@apollo/client'
import type { MutationHookOptions } from '@apollo/client'
import { Capacitor } from '@capacitor/core'
import { Preferences } from '@capacitor/preferences'
import OneSignal from 'onesignal-cordova-plugin'
import { useCallback } from 'react'

import { useSigninMutation, useSigninWithTokenMutation, useWhoamiQuery, WhoamiDocument } from '../../lib/apollo/types'
import type { Authentication , SigninMutation, SigninMutationVariables } from '../../lib/apollo/types'
import STORAGE_KEYS from '../../lib/storageKeys'

export const useUser = (): Authentication =>
    useWhoamiQuery({ fetchPolicy: 'cache-only' }).data?.whoami as Authentication

export const useSignIn = (baseOptions?: MutationHookOptions<SigninMutation, SigninMutationVariables>): (name: string, password: string, permanent: boolean) => Promise<void> => {
    const client = useApolloClient()
    const [signin] = useSigninMutation(baseOptions)

    return useCallback(async (name: string, password: string, permanent: boolean): Promise<void> => {
        const response = await signin({ variables: { input: { name, password, permanent } } })

        if (response.data?.signin && !response.errors) {
            await Preferences.set({
                key: STORAGE_KEYS.API.LOGIN_TOKEN,
                value: response.data?.signin.token,
            })

            client.writeQuery({
                query: WhoamiDocument,
                data: { whoami: response.data?.signin },
            })
        } else if(baseOptions?.onError) {
            baseOptions.onError(new ApolloError({}))
        }
    },[client, signin])
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useSignInWithToken = () =>
    useSigninWithTokenMutation({
        update: (cache, response) => {
            if (!response.data) return

            cache.writeQuery({
                query: WhoamiDocument,
                data: { whoami: response.data?.signinWithToken },
            })
        },
    })

export const signOut: () => Promise<void> = async () => {
    await Preferences.remove({ key: STORAGE_KEYS.API.LOGIN_TOKEN })
    if(Capacitor.isNativePlatform()) {
        OneSignal.removeExternalUserId()
    }
    window.location.href = '/login'
}
