import { IonSkeletonText, useIonRouter } from '@ionic/react'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'

import getAge from '../../../../lib/getAge'
import Divider from '../../../Divider'
import PerformanceIcon from '../../../Icon/PerformanceIcon'
import TalentContextMenu from '../../../Talent/TalentContextMenu'
import TalentID from '../../../Talent/TalentID'
import TalentIDSkeleton from '../../../Talent/TalentID/TalentIDSkeleton'
import TopSkills from '../../../Talent/TopSkills'
import { useTalentCardContext } from '../../_Core/Context'
import FootIcon from '../../_Core/FootIcon'
import JerseySvg from '../../_Core/JerseySvg'
import RolemodelPraise from '../../_Core/RolemodelPraise'
import TalentCardPaper from '../../_Core/TalentCardPaper'
import useBackgroundColor from '../../_Core/useBackgroundColor'

import './style.scss'

interface TalentCardMediumFrontProps {
    visible: boolean
    onFlipCardClick: () => void
}

const TalentCardMediumFront: React.FC<TalentCardMediumFrontProps> = ({ visible, onFlipCardClick }) => {
    const { talent, similarTalent, talentType, contextMenuItems, routerLink, preview, permissionLevel, performance } = useTalentCardContext()
    const backgroundColor = useBackgroundColor()
    const age = getAge(talent?.metas.date)
    const { push } = useIonRouter()
    const { t } = useTranslation()

    const caption = talent?.caption.startsWith('playerTypes.')
        ? t(talent.caption)
        : talent?.caption

    return (
        <TalentCardPaper
            className={clsx(`talentcard-medium-front talentcard-medium-front--${talentType}`, {
                'talentcard-medium-front--visible': visible,
                'talentcard-medium-front--preview': preview,
            })}
        >
            <div
                className='talentcard-medium-front__top-container'
            >
                <div
                    className='display-flex-column ion-justify-content-center'
                >
                    <h2
                        className='ion-text-center'
                    >
                        {age ?? (
                            <IonSkeletonText
                                style={{ width: 40 }}
                            />
                        ) }
                    </h2>
                    { talent?.metas.fifaCountry && (
                        <img
                            className='talentcard-medium-front__fifa-country'
                            alt='fifacountry'
                            src={`/assets/flags/${talent?.metas.fifaCountry}.webp`}
                        />
                    )}
                    <PerformanceIcon
                        className='talentcard-medium-front__performance-icon'
                        performance={performance}
                        talent={talent}
                    />
                </div>
            </div>

            <div
                className='talentcard-medium-front__bottom-container'
            >
                {similarTalent && (
                    <>
                        <RolemodelPraise
                            talent={similarTalent}
                        />
                        <Divider
                            margin={6}
                        />
                    </>
                )}

                <div
                    className='talentcard-medium-front__profile-link'
                    tabIndex={-1}
                    role='button'
                    onKeyDown={(e) => {
                        if (talent && e.key === 'Enter' && routerLink) {
                            push(routerLink)
                        }
                    }}
                    onClick={() => {
                        if (talent && routerLink) {
                            push(routerLink)
                        }
                    }}
                >
                    <h2
                        className='ion-text-center'
                    >
                        {talent?.caption ? caption : (
                            <IonSkeletonText
                                style={{ width: 200, margin: '0 auto 4px' }}
                            />
                        )}
                    </h2>
                </div>

                <Divider
                    margin={6}
                />
                { !similarTalent && (
                    <>
                        {(!talent || talent?.customMeta?.club || talent?.customMeta?.team) && (
                            <>
                                <div
                                    className='talentcard-medium-front__team-container'
                                >
                                    <JerseySvg />

                                    <div
                                        className='talentcard-medium-front__team-container__meta'
                                    >
                                        <h4
                                            className='ion-text-left'
                                        >
                                            {talent
                                                ? (talent?.customMeta?.club ?? '')
                                                : (
                                                    <IonSkeletonText
                                                        style={{ width: 160 }}
                                                    />
                                                )}
                                        </h4>
                                        <h4
                                            className='ion-text-left talentcard-medium-front__team'
                                        >
                                            {talent
                                                ? (talent?.customMeta?.team ?? '')
                                                : (
                                                    <IonSkeletonText
                                                        style={{ width: 150 }}
                                                    />
                                                )}
                                        </h4>
                                    </div>
                                </div>
                                <Divider
                                    margin={6}
                                />
                            </>
                        )}

                        { (!talent || talent.customMeta?.foot || talent.customMeta?.height || talent.customMeta?.weight  || talent.metas.fieldPosition) && (
                            <>
                                <div
                                    className='talentcard-medium-front__attr-wrapper'
                                >
                                    <div
                                        className='talentcard-medium-front__attr-wrapper__attr'
                                    >
                                        <h3
                                            className='talentcard-medium-front__attr-title'
                                        >
                                            {t('talent.heightPrefix')}
                                        </h3>
                                        <span
                                            className='talentcard-medium-front__attr-text'
                                        >
                                            {talent
                                                ? (talent?.customMeta?.height ?? '')
                                                : (
                                                    <IonSkeletonText
                                                        style={{ width: 20 }}
                                                    />
                                                )}
                                        </span>
                                    </div>

                                    <div
                                        className='talentcard-medium-front__attr-wrapper__attr'
                                    >
                                        <h3
                                            className='talentcard-medium-front__attr-title'
                                        >
                                            {t('talent.foot.prefix')}
                                        </h3>

                                        <FootIcon
                                            talent={talent}
                                        />
                                    </div>

                                    <div
                                        className='talentcard-medium-front__attr-wrapper__attr'
                                    >

                                        <h3
                                            className='talentcard-medium-front__attr-title'
                                        >
                                            {t('talent.weightPrefix')}
                                        </h3>
                                        <span
                                            className='talentcard-medium-front__attr-text'
                                        >
                                            {talent
                                                ? (talent?.customMeta?.weight ?? '')
                                                : (
                                                    <IonSkeletonText
                                                        style={{ width: 20 }}
                                                    />
                                                )}
                                        </span>

                                    </div>
                                    <div
                                        className='talentcard-medium-front__attr-wrapper__attr'
                                    >
                                        <h3
                                            className='talentcard-medium-front__attr-title'
                                        >
                                            {t('talent.card.position.label')}
                                        </h3>
                                        <span
                                            className='talentcard-medium-front__attr-text'
                                        >
                                            {talent?.metas.fieldPosition ?
                                                t(`talent.position.${talent?.metas.fieldPosition}Short`)
                                                : (
                                                    <IonSkeletonText
                                                        style={{ width: 20 }}
                                                    />
                                                )}
                                        </span>

                                    </div>
                                </div>
                                <Divider
                                    margin={6}
                                />
                            </>
                        )}
                    </>
                )}

                <div
                    className='talentcard-medium-front__skills-wrapper'
                >
                    <TopSkills
                        talent={talent}
                    />

                    <div
                        className='talentcard-medium-front__talentid-wrapper'
                        tabIndex={-1}
                        role='button'
                        onKeyDown={(e) => { if (e.key === 'Enter') onFlipCardClick?.() }}
                        onClick={(e) => {
                            e.stopPropagation()
                            onFlipCardClick()
                        }}
                    >
                        { talent
                            ? (
                                <TalentID
                                    dataView1={talent.dataViews}
                                    talentPosition={talent.metas.fieldPosition}
                                    backgroundColor={backgroundColor}
                                    color1={talentType === 'roleModel' ? 'black' : undefined}
                                    under16={(getAge(talent.metas.date) ?? 99) < 16}
                                />
                            ) : (
                                <TalentIDSkeleton />
                            )}

                    </div>

                </div>
            </div>

            { talent && (
                <div
                    className='talentcard-medium-front__ctx-menu'
                >
                    <TalentContextMenu
                        color='dark'
                        horizontalIcon={false}
                        talent={talent}
                        permissionLevel={permissionLevel}
                    >
                        {contextMenuItems}
                    </TalentContextMenu>
                </div>
            )}
        </TalentCardPaper>
    )
}

export default TalentCardMediumFront
