import { checkmark, footballOutline, logOut } from 'ionicons/icons'
import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import Button from '../../components/Button'
import Content from '../../components/Content'
import Header from '../../components/Header'
import Page from '../../components/Page'
import {
    NetworkDocument, NetworkState, useIsTalentInNetworkQuery,
    useJmtNetworksQuery,
    useListMyPersonificationsQuery, useNetworkQuery,
    useRemoveTalentFromNetworkMutation,
    useRemoveUserFromNetworkMutation, useTalentJoinMarketplaceMutation, useUserJoinMarketplaceMutation,
} from '../../lib/apollo/types'
import { useUser } from '../../providers/Auth/hooks'
import useToast from '../../providers/Toast/hooks'

const SubscriptionsPage: React.FC = () => {
    const { t } = useTranslation()
    const user = useUser()
    const networks = useJmtNetworksQuery()
    const [notInNetwork, setNotInNetwork] = useState<boolean | undefined>(undefined)
    const [talentIsNotInMarketplace, setTalentIsNotInMarketplace] = useState<boolean | undefined>(undefined)
    const [joinMarketplace] = useUserJoinMarketplaceMutation()

    const marketplaceNetwork = useMemo(() =>
        networks?.data?.jmtNetworks.JMTnetworks.collection.find((network) => network.caption === 'Join.My.Talent.Engine.Marketplace')
    , [networks])
    const { data: myPersonifications } = useListMyPersonificationsQuery({
        variables: {
            userId: user.user.id,
        },
    })

    useNetworkQuery({
        fetchPolicy: 'network-only',
        skip: !marketplaceNetwork?.id,
        variables: {
            userId: user.user.id,
            networkId: marketplaceNetwork?.id ?? '',
        },
        onError: () => {
            setNotInNetwork(() => true)
        },
    })

    const myTalentId = useMemo(() => {
        if (myPersonifications?.listMyPersonifications.collection?.[0]?.id) {
            return myPersonifications?.listMyPersonifications.collection[0].id
        }
        return undefined
    }, [myPersonifications])

    const [leaveTalentNetworkMutation] = useRemoveTalentFromNetworkMutation()
    const [leaveUserNetworkMutation] = useRemoveUserFromNetworkMutation({
        refetchQueries: [{
            query: NetworkDocument,
            variables: {
                userId: user.user.id,
                netowrkId: marketplaceNetwork?.id ?? '',
            },
        }],
    })

    const leaveMarketPlace = useCallback(async () => {
        if (!marketplaceNetwork) return

        if (myTalentId) {
            try {
                await leaveTalentNetworkMutation({
                    variables: {
                        id: marketplaceNetwork.id,
                        talent: {
                            _links: {
                                objectByNetwork: {
                                    url: [`/api/object/${myTalentId}`],
                                },
                            },
                        },
                    },
                })
            }
            catch (e) {
                // nothing to do here, because talent not present in network
            }
        }

        await leaveUserNetworkMutation({
            variables: {
                id: marketplaceNetwork.id,
                user: {
                    _links: {
                        userByNetwork: {
                            url: [`/api/user/${user.user.id}`],
                        },
                    },
                },
            },
        })
        setNotInNetwork(() => true)
        setTalentIsNotInMarketplace(() => true)
    }, [marketplaceNetwork])

    useIsTalentInNetworkQuery({
        skip: !marketplaceNetwork?.id || !myTalentId,
        variables: {
            userId: user.user.id,
            talentId: myTalentId ?? '',
            networkId: marketplaceNetwork?.id ?? '',
        },
        onCompleted: ({ isTalentInNetwork }) => {
            if (isTalentInNetwork.state === NetworkState.InNetwork) {
                setTalentIsNotInMarketplace(() => false)
            } else {
                setTalentIsNotInMarketplace(() => true)
            }
        },
        onError: () => { setTalentIsNotInMarketplace(() => true) },
    })

    const [present] = useToast()
    const [joinMarketplaceWithTalentMutation] = useTalentJoinMarketplaceMutation()
    const joinMarketplaceWithTalent = useCallback(async (e) => {
        e.persist()
        e.stopPropagation()

        if (!talentIsNotInMarketplace) {
            // TODO: LEAVE
            // setIsInMarketplace(() => false)
        } else {
            await joinMarketplaceWithTalentMutation()
            present(t('talent.addedToMarketplace'), 'success')
            setTalentIsNotInMarketplace(() => false)
            setNotInNetwork(() => false)
        }
    }, [talentIsNotInMarketplace])

    return (
        <Page
            className='subscriptions-page'
            trackingTitle='subscriptions'
        >
            <Header
                title={t('subscriptions.title')}
            />
            <Content
                fullscreen
            >
                <div>
                    <h2>
                        { t('title.marketplace') }
                    </h2>
                    <p
                        className='n2br'
                    >
                        { t(`marketplace.${notInNetwork ? '' : 'leave.'}intro`) }
                    </p>
                    {notInNetwork ? (
                        <Button
                            onClick={async () => {
                                await joinMarketplace()
                                setNotInNetwork(() => false)
                            }}
                            icon={checkmark}
                            color='secondary'
                        >
                            { t('networks.join') }
                        </Button>
                    ): (
                        <Button
                            onClick={async () => {
                                await leaveMarketPlace()
                            }}
                            icon={logOut}
                            color='secondary'
                        >
                            { t('marketplace.leave.button') }
                        </Button>
                    )}
                    {talentIsNotInMarketplace && (
                        <>
                            <p
                                className='n2br'
                            >
                                { t('marketplace.joinWithTalentIntro') }
                            </p>
                            <Button
                                onClick={async (e) => {
                                    await joinMarketplaceWithTalent(e)
                                    setNotInNetwork(() => false)
                                }}
                                icon={footballOutline}
                                color='secondary'
                            >
                                { t('networks.join') }
                            </Button>
                        </>
                    )}
                </div>
            </Content>
        </Page>
    )
}

export default SubscriptionsPage
