import { Capacitor } from '@capacitor/core'
import { Haptics, ImpactStyle } from '@capacitor/haptics'
import type { RangeChangeEventDetail } from '@ionic/core'
import { IonIcon, IonLabel, IonRange, IonText, useIonAlert } from '@ionic/react'
import clsx from 'clsx'
import { informationCircleOutline } from 'ionicons/icons'
import { useCallback, useMemo, useRef } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import CollapsibleContent from '../../Collapsible/CollapsibleContent'

import './style.scss'

interface FormSliderProps {
    name: string
    label: string
    required?: boolean
    defaultValue?: number
    referenceValue?: number
    className?: string
    min?: number
    max?: number
    step?: number
    onChange?: (e: CustomEvent<RangeChangeEventDetail>) => void
    getPinLabel?: (n: number) => string
    info?: string
}

const FormSlider: React.FC<FormSliderProps> = ({
    required,
    name,
    label,
    defaultValue,
    referenceValue,
    className,
    min = 0,
    max = 100,
    step = 1,
    onChange,
    getPinLabel,
    info,
}) => {
    const { control, formState } = useFormContext()
    const error = formState.errors[name]

    const { t } = useTranslation()

    const rules = useMemo<{ validate: { isNumber: (val:any) => boolean | string } } | undefined>(() => {
        if (required) return {
            validate: {
                isNumber: (val: any): boolean | string => {
                    return (typeof val === 'number' && !Number.isNaN(val)) || (t('errors.required', { label }) as string)
                },
            },
        }
        return undefined
    }, [required])

    const debounceRef = useRef<ReturnType<typeof setTimeout>>()
    const oC = useCallback((value) => {
        if (onChange) {
            if (debounceRef.current) {
                clearTimeout(debounceRef.current)
            }
            debounceRef.current = setTimeout(() => {
                onChange(value)
            }, 150)
        }
    }, [onChange])

    const [present] = useIonAlert()
    const onInfoClick = useCallback(() => {
        if(!info) {
            return
        }

        present(info)
    }, [])

    return (
        <div
            className='form-slider'
        >
            <div
                className='form-slider__wrapper'
            >
                <IonLabel
                    className={clsx({ 'error': !!error })}
                    onClick={onInfoClick}
                >
                    { label + (required ? '*' : '') }
                    {info && (
                        <IonIcon
                            icon={informationCircleOutline}
                        />
                    )}
                </IonLabel>

                <Controller
                    defaultValue={defaultValue}
                    control={control}
                    name={name}
                    rules={rules}
                    render={({ field }) => {
                        return (
                            <div
                                className='form-slider__range-wrapper'
                            >
                                <IonRange
                                    ref={(target: HTMLIonRangeElement) => {
                                        if (!Capacitor.isNativePlatform() || !target?.shadowRoot) return
                                        const container = target?.shadowRoot?.querySelector('.range-slider')
                                        const knob = target?.shadowRoot?.querySelector('.range-knob-handle')
                                        if (!container || !knob) return
                                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                        // @ts-ignore
                                        // eslint-disable-next-line
                                        container.style.pointerEvents = 'none'
                                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                        // @ts-ignore
                                        // eslint-disable-next-line
                                        knob.style.pointerEvents = 'auto'
                                    }}
                                    mode='md'
                                    pin
                                    snaps
                                    color='secondary'
                                    min={min}
                                    max={max}
                                    step={step}
                                    className={className}
                                    value={field.value}
                                    onBlur={field.onBlur}
                                    onPointerDown={(e: any) => {
                                        if (getPinLabel) {
                                            e.target.shadowRoot.querySelector('.range-pin').innerText = getPinLabel(e.target.value)
                                        }
                                    }}
                                    onIonChange={(e: any) => {
                                        if (Capacitor.isNativePlatform()) {
                                            Haptics.impact({ style: ImpactStyle.Light })
                                        }

                                        if (getPinLabel) {
                                            e.target.shadowRoot.querySelector('.range-pin').innerText = getPinLabel(e.detail.value)
                                        }
                                        field.onChange(e.detail.value)
                                        if (onChange) oC(e)
                                    }}
                                />
                                {referenceValue !== undefined && (
                                    <div
                                        className='form-slider__reference-value'
                                        style={{ left: `${(referenceValue * 100) / max}%` }}
                                    />
                                )}
                            </div>
                        )
                    }}
                />
                <CollapsibleContent
                    open={!!error}
                >
                    <IonText
                        className='form-slider__error-text font-s-regular'
                        color='danger'
                    >
                        {error?.message}
                    </IonText>
                </CollapsibleContent>
            </div>
        </div>
    )
}

export default FormSlider
